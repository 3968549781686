import React from 'react';
import AppContext from '../../../AppContext';
import SignatureForm from './SignatureForm';

export default class OwnerScreen extends React.Component {
    static contextType = AppContext;
    state = {
        objSignature: null,
        notFirstLoad: false
    }
    SignatureObj = (obj) => {
        this.setState({ objSignature: obj })
    }
    render() {
        return (
            <SignatureForm
                signatureObj={this.SignatureObj}
                notFirstLoad={this.context.notFirstLoad2}
                objSignature={this.state.objSignature}
                navigate={this.props.navigate} 
                location={this.props.location} 
                requestId={this.props.requestId}
                contactName={this.props.contactName}
            />
        )
    }
}