import React from 'react';


//USAGE
//data must be: {value: "x", name: "X"}
//search must contain one paremeter, the string to search -> search(string){} and return an array of data.
//<DynamicSearchSelect  id={'currency-list'} placeholder="Country..." searchFunction={this.search.bind(this)} data={this.state.data} getValue={this.getCountryValue.bind(this)}/>
/*
getCountry(value){
        this.setState({selectedCountry: value}, ()=> {
            console.log('Selected Country: ');
            console.log(this.state.selectedCountry);
        });
    }

    search(query){ 
        testCountries(query).then(
            (value) => {
                var helper = [];
                if(query !== ''){
                    for(var i=0; i<value.length; i++){
                        helper.push({name:value[i].name, value: value[i].alpha2Code});
                    }
                    console.log(helper);
                    this.setState({countries: helper});
                }
            }
        );
    }
*/

export default class DynamicSearchSelect extends React.Component {
    state = {
        data: [],
        value: '',
    }

    componentDidMount(){
        var propValue = '';
        if(this.props.objValue !== undefined){
            propValue = this.props.objValue.name;
            console.table(this.props.objValue);
        }
        this.setState({value: propValue}, ()=>{
            this.props.getValue(this.state.value);
        });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.objValue !== undefined && this.props.objValue !== undefined){
            if (prevProps.objValue.name !== this.props.objValue.name){
                this.setState({value: this.props.objValue.name});
            }
        }
    }

    onChange(event){
        console.group('onChange - DynamicSearchSelect');
        this.props.searchFunction(event.target.value);
        var newData = this.props.data;
        this.setState({data: newData, value: event.target.value});
        console.groupEnd();
    }

    onClick(event){
        event.target.select();
    }

    onSelect(event){
        //Check
        var helper = {};
        for(var i=0; i<this.props.data.length; i++){
            if (this.props.data[i].name === event.target.value){
                helper = this.props.data[i];
            }
        }
        this.props.getValue(helper);
        console.groupEnd();
    }

    render(){
        
        var options = [];
        
        if(this.props.data !== undefined){
            for(var i=0; i<this.props.data.length; i++){
                var opt = this.props.data[i];
                options.push(<option value={opt.name} key={opt.value}>{opt.value}</option>);
            }
        } 
        
        var className = "uk-input";
        if(this.props.className !== undefined){
            className = this.props.className;
        }

        var disabled = false;
        if (this.props.disabled !== undefined){
            disabled = this.props.disabled;
        }

        return(
            <div>
                <input value={this.state.value} disabled={disabled} className={className} placeholder={this.props.placeholder} type="text" list={this.props.id} onChange={this.onChange.bind(this)} onSelect={this.onSelect.bind(this)} onClick={this.onClick.bind(this)}/>
                <datalist id={this.props.id}>
                {options}
                </datalist>
            </div>
        );
    }
}