export const stringEmptyOrNull = (str) => {return (str === undefined || str === null || str === '')}

export const textIncludesWord = (wordsToSearch, textString) => {
    let wordFoundInText = false
    if (textString !== null) {
        wordsToSearch.forEach(x => {
            wordFoundInText = wordFoundInText || (textString + '').toLowerCase().includes(x)
        })
    }
    return wordFoundInText
}

export const sanitizeStringForPDF = (value) => value != null && value !== '' ? value : ' ';