import { genericCallWithBody, genericDeleteWithParameters, genericGetWithParameters, genericGetWithParametersNoEncrypted } from './Networking';

export async function getAttachments(linkType, linkID) {
    try {
        if (linkType && linkID) {
            let params = {
                'LinkType': linkType,
                'LinkId': linkID
            }
            let response = await genericGetWithParameters('Attachment/GetAttachmentByLink', params);
            return response;
        } else {
            return {};
        }
    } catch (error) {
        console.error(error);
    }
}

export async function getAttachment(id) {
    try {
        let params = {
            'id': id,
        }
        let response = await genericGetWithParametersNoEncrypted('Attachment/GetAttachmentContent', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteE2EAttachment(id) {
    try {
        let params = {
            'attachmentId': id,
        }
        let response = await genericDeleteWithParameters('Attachment/Delete', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function uploadAttachment(description, linkType, linkID, fileName, fileContent) {
    try {
        let userID = localStorage.getItem('UserID');
        var date = new Date();
        let isoDate = date.toISOString();
        let addFileModel = {
            'Description': description,
            'UploadedBy': userID,
            'LinkType': linkType,
            'LinkId': linkID,
            'UpdateToken': '0',
        };
        //AddFileCall
        let addFileResponse = await genericCallWithBody('POST', 'Attachment/AddFile', addFileModel);
        // debugger
        if (addFileResponse.httpStatusCode === 200) {
            let uploadFileModel = {
                'Id': addFileResponse.id,
                'Filename': fileName,
                'Description': addFileResponse.description,
                'UploadedBy': userID,
                'UploadedOn': isoDate,
                'FileContent': fileContent,
                'UpdateToken': addFileResponse.updateToken,
            };
            //UploadFileCall
            let uploadFileResponse = await genericCallWithBody('POST', 'Attachment/UploadFile', uploadFileModel);
            return uploadFileResponse;
        } else {
            return addFileResponse;
        }
    } catch (error) {
        // debugger
        console.error(error);
    }
}

export async function getAttachmentsBySubEntityType(subEntityType, countryId) {
    try {
        let params = {
            'subEntityType' : subEntityType,
            'language'      : sessionStorage.getItem('360Language'),
            'countryId'     : countryId
        }

        let response = await genericGetWithParameters('AttachmentsBySubEntityType/GetAttachmentsBySubEntityTypeRegForm', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getAttachmentBySubEntityTypeByID(attachmentBySubEntityTypeID) {
    try {
        let params = {
            attachmentBySubEntityTypeID: attachmentBySubEntityTypeID
        }
        let response = await genericGetWithParameters('AttachmentsBySubEntityType/GetAttachmentBySubEntityTypeByID', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteAttachmentsBySubEntityType(attachmentBySubEntityTypeID) {
    let params = {
        'attachmentBySubEntityTypeID': attachmentBySubEntityTypeID
    };
    let response = await genericDeleteWithParameters('AttachmentsBySubEntityType/Delete', params);
    return response;
}

export async function saveAttachmentsBySubEntityType(objAttachmentsBySubEntityType) {
    var method = '';
    var apiName = '';
    method = 'POST';
    apiName = 'AttachmentsBySubEntityType/Save';
    let response = await genericCallWithBody(method, apiName, objAttachmentsBySubEntityType);
    return response;
}