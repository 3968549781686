import React from 'react';
export default class SuccessForm extends React.Component {

    /* componentDidMount() {
        const status = sessionStorage.getItem('CustomerStatus');
        this.message(status);
    }
    message(status) {
        switch (status) {
            case 'Incomplete':
                this.setState({ textMessage: 'You will be able to return to our secure site and attach the signed document at a later time.' })
                break;
            case 'Complete':
                this.setState({ textMessage: 'Thanks for submitting your application. We will contact you shortly.' })
                break;

            case 'Partial':
                this.setState({ textMessage: 'You will be able to return to our secure site and attach the signed document at a later time.' })
                break;
            default:
                break;
        }
    } */
    render() {
        return (
            <div className="">
                <h2>Thank You, your registration information has been received</h2>
                <hr></hr>
                {/* <p>{this.state.textMessage}</p>
                <p>Please sign your Customer Agreement and either scan/email it to <a href="mailto:info@nexolink.com" target="_blank" rel="noopener noreferrer">info@nexolink.com</a></p>
                {<p>If you have any questions regarding your application please feel free to call us on 1300 939 068 or +61 2 8907 2530 for international callers.</p> */}
            </div>
        );
    }
}