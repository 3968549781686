import React from 'react';
import UIkit from 'uikit';
import AppContext from '../../AppContext';
import { getCurrentCompanyName } from '../../helper/PackageJsonHelper';
import { getResource, getURLTermsANDCondition } from '../../helper/ResourcesHelper';
import { checkValidReferralCustomer, getCustomerInfoByID, getParameterByName } from '../../networking/Networking';
import { getAdditionalPropertiesDefs, getEntityProperties } from '../../networking/NetworkingAdditionalPropertiesDefs';
import { getAttachmentsBySubEntityType } from '../../networking/NetworkingAttachments';
import { sendCompleteRegistrationEmail, sendCustomerRegistrationEmail, sendValidateEmail, submit360Form } from '../../networking/NetworkingRegForm';
import AlertBox from '../shared/AlertBox';
import LoadingBox from '../shared/LoadingBox';
import AdditionalInformation from './AdditionalInformation';
import ApplicantGeneralInformation from './ApplicantGeneralInformation';
import CustomerAgreement from './CustomerAgreement';
import TaskAttachments from './TaskAttachments';


//To Online Login
const onlineLoginUrl = window.REACT_APP_ONLINE_URL;

const documentName1 = 'Identity Card';
const front1 = 'Personal Identification document - 1';
const back1 = 'Personal Identification document - 2';
const documentName2 = 'Driving License';
const front2 = 'Driving License Document - 1';
const back2 = 'Driving License Document - 2';
const documentName3 = 'Passport';
const front3 = 'Personal Passport Document - 1';
const personalIdentificationAdditional = 'Personal Identification document - Add. Pages';
const drivinLicenseAdditional = 'Driving License Document - Add. Pages';
const passportAdditional = 'Personal Passport Document - Add. Pages';
export default class FormScreen extends React.Component {
    static contextType = AppContext;
    state = {
        activeTab: 'applicant-general-info',
        isOpen: false,
        emailVerifiedSubmit: false,
        showSentEmailAlert: false,
        alertTitleSentEmail: '',
        alertMessageSentEmail: '',
        //OBJFILTERS
        ApplicantGeneralInformation: null,

        //OBJFILTERS
        PersonDetails: null,
        DetailsAbout: null,
        AuthorisedPersons: null,
        PreliminaryDetails: null,
        IdentificationDocuments: null,
        TaskAttachments: null,
        CustomerAgreement: null,

        objPersonDetails: null,
        objDetailsAbout: null,
        objAuthorisedPersons: null,
        objPreliminaryDetails: null,
        objIdentificationDocuments: null,
        objCustomerAgreement: null,

        //------------Attachments---------
        TaskAttachments_Step1: null,
        TaskAttachments_Step2: null,

        attachmentGenerate_Step1: [],
        objTaskAttachments_Step1: [],

        attachmentGenerate_Step2: [],
        objTaskAttachments_Step2: [],

        objApplicantGeneralInformation: null,
        //objGcceptanceoftermsandconditions: null,
        acceptanceoftermsandconditions: null,
        showSuccessAlert: false,
        alertTitle: '',
        alertMessage: '',

        showInvalidReferralAlert: false,
        saveType: '', // 'full' or 'partial',
        validReferral: false, // If false, saves AffiliateReferredBy with null

        loading: false,
        readOnly: false,
        targetID: 'applicant-general-info-li',
        clickNext: false,

        //Additional
        valuesOfAdditionalPropertiesDefs1: [],
        valuesOfAdditionalPropertiesDefs2: [],

        validationsOfAdditionalPropertiesDefs1: [],
        validationsOfAdditionalPropertiesDefs2: [],

        allAdditionalPropertiesDefs1: [],
        allAdditionalPropertiesDefs2: [],
        attachmentGenerate: [],

        customerAdditionalProperties: null,
        contactAdditionalProperties: null,


        tabIndex: 0,
        nextTabIndex: 0,
        buttonPressed: false
    }
    componentDidMount() {
        sessionStorage.setItem('signatureImg', null)
        sessionStorage.setItem('signatureDate', '')
        sessionStorage.setItem('signatureFullName', '')
        sessionStorage.setItem('signatureTitle', '')
        window.scrollTo(0, 0)
        let country = '', id = '';
        if (this.props.location.state != null && this.props.location.state.customer != null) {
            country = this.props.location.state.customer.CustomerAddresses[0].countryid;
            id = this.props.location.state.customer.CustomerAddresses[0].id;
            const customerAdditionalProperties = this.props.location.state.customer.AdditionalProperties;
            //--TermsPDF
            let urlTermsPDF = getURLTermsANDCondition('Individual', country);
            sessionStorage.setItem('360Language', !this.props.location.state.customer.PreferredLanguage.includes('Select') ? this.props.location.state.customer.PreferredLanguage : 'English')
            this.setState({
                customer: this.props.location.state.customer,
                customerAdditionalProperties,
                urlTermsPDF,
            }, () => {
                window.scrollTo(0, 0)
                this.adaptList(this.props.location.state.customer, this.props.location.state.originOnline);
            });
        }
        this.getAttachments('Customer - Individual', country)
        this.getAttachments('Contact', country)
        if (country !== '') {
            window.scrollTo(0, 0)
            this.getAddProperties(country);
            this.getAddPropertiesByCountry(id);
        }
        UIkit.util.on('#accordion-contact', 'beforeshow', (event) => {
            this.setState({ targetID: event.target.id });
            switch (event.target.id) {
                case 'additional-info-li':
                    this.validateErrors(event, 1)
                    break;
                case 'acceptance-of-terms-and-conditions-li':
                    this.validateErrors(event, 2)
                    break;
                default:
                    break;
            }
        });
        UIkit.util.on('#accordion-contact', 'beforehide', (event) => {
            const validation = this.state.buttonPressed ?
                this.state.nextTabIndex > this.state.tabIndex :
                this.transformToIndex(this.state.targetID) > this.transformToIndex(event.target.id);
            if (validation) {
                if (this.state.buttonPressed || this.transformToIndex(this.state.targetID) - this.transformToIndex(event.target.id) === 1) {
                    switch (event.target.id) {
                        case 'applicant-general-info-li':
                            this.validateErrors(event, 1)
                            break;
                        case 'additional-info-li':
                            this.validateErrors(event, 2)
                            break;
                        case 'acceptance-of-terms-and-conditions-li':
                            this.validateErrors(event, 3)
                            break;
                        default:
                            break;
                    }
                } else {
                    this.validateErrors(event, this.transformToIndex(this.state.targetID) - 1);
                }
            }
            if (this.state.buttonPressed) {
                this.setState({ buttonPressed: false });
            }
            //this.setState({ tabIndex: this.state.nextTabIndex });
        });
    }

    getAttachments = (customerType, country) => {
        getAttachmentsBySubEntityType(customerType, country).then(
            (json) => {
                window.scrollTo(0, 0)
                if (json.totalCount > 0) {
                    if (customerType === 'Customer - Individual') {
                        json.attachments.forEach(element => {
                            switch (element['Display Order']) {
                                case 2:
                                    window.scrollTo(0, 0)
                                    this.setState({ attachmentGenerate_Step2: [element] })
                                    break;
                                default:
                                    break;
                            }
                        });
                    } else {
                        json.attachments.forEach(element => {
                            switch (element['Display Order']) {
                                case 1:
                                    window.scrollTo(0, 0)
                                    this.setState({ attachmentGenerate_Step1: [element] })
                                    break;
                                default:
                                    break;
                            }
                        });
                    }
                }
            }
        );
    }
    getAddPropertiesByCountry = (country) => {
        getEntityProperties(country, 'Country').then(
            (jsonResponse) => {
                window.scrollTo(0, 0)
                let region = '', urlTermsPDF = '', url = '';
                if (jsonResponse.totalCount > 0) {
                    jsonResponse.entityProperties.forEach(element => {
                        switch (element.Description) {
                            case 'Regions':
                                region = element.TextValue != null ? element.TextValue : sessionStorage.getItem('360Language');
                                this.props.navigate(this.props.location.pathname.split(':regionID')[0] + region);
                                break;
                            /* case 'Terms & Conditions Corporate':
                                if (country === 37) { url = element.TextValue.split(', '); }
                                urlTermsPDF = country === 37 ? url[0] : element.TextValue;
                                break; */
                            case 'Terms & Condition Individual':
                                if (country === 37) { url = element.TextValue.split(', '); }
                                urlTermsPDF = country === 37 ? url[0] : element.TextValue;
                                break;
                            default:
                                break;
                        }
                        this.setState({ region, urlTermsPDF })
                    })
                }
            }
        );
    }
    componentDidUpdate(prevProps) {
        const changeLanguage = sessionStorage.getItem('ChangeLanguage');
        if (changeLanguage === 'true') {
            sessionStorage.setItem('ChangeLanguage', false);
            let country = '';
            if (this.state.customer != null) {
                country = this.state.customer.CustomerAddresses[0].countryid;
                if (country !== '') {
                    this.context.clearAllValidations()
                    this.setState({
                        //Additional
                        valuesOfAdditionalPropertiesDefs1: [],
                        valuesOfAdditionalPropertiesDefs2: [],
                        validationsOfAdditionalPropertiesDefs1: [],
                        validationsOfAdditionalPropertiesDefs2: [],

                        allAdditionalPropertiesDefs1: [],
                        allAdditionalPropertiesDefs2: [],

                        //------------Attachments---------
                        attachmentGenerate_Step1: [],
                        attachmentGenerate_Step2: [],

                    }, () => {
                        this.getAddProperties(country)
                        this.getAttachments('Customer - Corporate', country)
                        this.getAttachments('Contact', country)
                    })
                }
            }
        }
    }

    getAddProperties = (country) => {
        getAdditionalPropertiesDefs(country, 'Customer - Individual', 1).then(
            (jsonResponse) => {
                window.scrollTo(0, 0)
                console.log("getAdditionalPropertiesDefs: " + JSON.stringify(jsonResponse));
                let objAll2 = [...this.state.allAdditionalPropertiesDefs2];

                if (jsonResponse.additionalPropertiesDef != null) {
                    objAll2 = jsonResponse.additionalPropertiesDef.filter(element => element.pageNumber === 2).sort((a, b) => a.displayOrder - b.displayOrder);
                }
                this.setState({ allAdditionalPropertiesDefs2: objAll2 });
            }
        );
        getAdditionalPropertiesDefs(country, 'Contact - Individual', 1).then(
            (jsonResponse) => {
                window.scrollTo(0, 0)
                console.log("getAdditionalPropertiesDefs: " + JSON.stringify(jsonResponse));
                let objAll = [...this.state.allAdditionalPropertiesDefs1];

                if (jsonResponse.additionalPropertiesDef != null) {
                    objAll = jsonResponse.additionalPropertiesDef.filter(element => element.pageNumber === 1).sort((a, b) => a.displayOrder - b.displayOrder);
                }
                this.setState({ allAdditionalPropertiesDefs1: objAll });
            }
        );
    }

    Submit = async () => {
        await this.checkValidReferralCustomer(); // Check referral before saving;
    }

    submit360Click = async () => {
        let validatedEmail = false;

        const json = await getCustomerInfoByID(this.state.customer.CustomerId);
        if (json != null) {
            json.CustomerProperties.forEach(element => {
                if (element.name === 'EmailVerified') {
                    // debugger;
                    this.setState({emailVerified:element.value});
                    switch (element.value) {
                        default:
                        case 'NO':
                            validatedEmail = false;
                            break;
                        case 'YES':
                            validatedEmail = true;
                            break;
                    }
                }
            });
        }

        if (validatedEmail) {
            await this.submitAfterEmailVerification();
        } else {
            const parameterResponse = await getParameterByName('DisableSendEmailRegform');
            // skip "verify your email" popup if global is true
            if (parameterResponse != null && parameterResponse.ParameterValue != null && parameterResponse.ParameterValue.toLowerCase() === 'true') {
                await this.submitAfterEmailVerification();
            } else {
                this.setState({
                    emailVerifiedSubmit: true
                });
            }
        }
    }

    submitAfterEmailVerification = async (status, validReferral = null) => {
        let model = {
            TypeSave: status != null ? status : (this.state.CustomerAgreement != null ? this.state.CustomerAgreement.isComplete ? 'Complete' : 'Incomplete' : 'Incomplete'),
            FormToken: "",
            PdfFileToken: "",
            FormTimeStamp: "",
            FormStatusAction: "",
            TypeForm: "",

            CustomerID: this.state.customer.CustomerId,
            CustomerUpdateToken: this.state.customer.UpdateToken,

            AddressID: this.state.ApplicantGeneralInformation.AddressId,
            AddressUpdateToken: this.state.ApplicantGeneralInformation.AddressUpdateToken,

            ContactID: this.state.ApplicantGeneralInformation.ContactId,
            ContactAddressID: this.state.ApplicantGeneralInformation.ContactAddressId,
            ContactAddressUpdateToken: this.state.ApplicantGeneralInformation.ContactAddressUpdateToken,

            PreferredLanguage: sessionStorage.getItem('360Language'),

            //-------accountprimarycontact
            Salutation: this.state.ApplicantGeneralInformation.saludationID !== null ? this.state.ApplicantGeneralInformation.saludationID : '',
            IndividualRoles: [],
            JobTitle: "",
            JobTitleOther: "",
            FirstName: this.state.ApplicantGeneralInformation.FirstName,
            ShortName: this.state.ApplicantGeneralInformation.ShortName,
            LastName: this.state.ApplicantGeneralInformation.LastName,
            DateOfBirth: this.state.ApplicantGeneralInformation.DateOfBirth,
            Gender: "",
            ResidentialAddressStreet: this.state.ApplicantGeneralInformation.ResidentialAddressStreet,
            ResidentialAddressLine2: this.state.ApplicantGeneralInformation.ResidentialAddressLine2,
            ResidentialAddressCountry: this.state.ApplicantGeneralInformation.ResidentialAddressStreet !== '' ? this.state.ApplicantGeneralInformation.countryID : '',
            ResidentialAddressStateName: this.state.ApplicantGeneralInformation.OtherState !== '' ? this.state.ApplicantGeneralInformation.OtherState : this.state.ApplicantGeneralInformation.ResidentialAddressStateName,
            ResidentialAddressStateId: this.state.ApplicantGeneralInformation.OtherState !== '' ? null: parseInt(this.state.ApplicantGeneralInformation.ResidentialAddressStateId),
            ResidentialAddressCity: this.state.ApplicantGeneralInformation.ResidentialAddressCity,
            ResidentialAddressPostalCode: this.state.ApplicantGeneralInformation.ResidentialAddressPostalCode,
            ResidentialPhoneNumber: this.state.ApplicantGeneralInformation.ResidentialPhoneNumber,
            Fax: "",
            MobileNumber: this.state.ApplicantGeneralInformation.MobileNumber != null ? this.state.ApplicantGeneralInformation.MobileNumber : '',
            EmailAddress: this.state.ApplicantGeneralInformation.EmailAddress,
            PoliticallyExposedPerson: this.state.ApplicantGeneralInformation.PEP != null ? this.state.ApplicantGeneralInformation.PEP : true,
            ReferralID: validReferral ? this.state.ApplicantGeneralInformation.AffiliateReferredBy : null,

            ContactPrincipal_AdditionalProperties: this.transformAddProperties('Contact Principal') != null ? this.transformAddProperties('Contact Principal') : [],
            PercentOwnership: "",
            Citizenship: this.state.ApplicantGeneralInformation.CitizenshipID,
            SSN: '',
            IdType: '',
            IdNo: '',
            IdExpirationDate: '',
            IssueJurisdiction: '',

            //-------not tab
            AccountPrimaryContact: "",
            AFEXDirectUserRole: "",

            CustomerDocuments: this.customerDoc(this.state.ApplicantGeneralInformation),//this.state.TaskAttachments_Step1 != null ? this.customerDoc(this.state.TaskAttachments_Step1) : [],
            ContactDocuments: [],
            AdditionalProperties: this.transformAddProperties('Individual') != null ? this.transformAddProperties('Individual') : [],
            AdditionalAttachments: this.state.TaskAttachments_Step2 != null ? this.state.TaskAttachments_Step2.documentFiles : [],

            //CustomerAgreement
            Terms: this.state.CustomerAgreement != null ? (this.state.CustomerAgreement.customerAgreement ? 'Yes' : 'No') : 'No',
            DocumentForm: this.state.CustomerAgreement != null ? this.state.CustomerAgreement.accountFile : '',
            DocumentForm_FileName: this.state.CustomerAgreement != null ? this.state.CustomerAgreement.accountFileName : '',

            //Terms: this.state.acceptanceoftermsandconditions != null ? (this.state.acceptanceoftermsandconditions.customerAgreement ? 'Yes' : 'No') : 'No',
            AccountNumber: "",

            DriverLicenseNumber: "",
            PassportNumber: "",
            EmployerName: "",
            TitleOccupation: "",
            CurrenciesInterestedTrading: "",
            CurrenciesInterestedTradingOther: "",
            AmountTradedMonth: "",
            PurposeExchangingCurrency: "",
            PurposeExchangingCurrencyOther: "",
            WhereYouHearAboutUs: "",
            WhereYouHearAboutUsSalesCall: "",
            WhereYouHearAboutUsReferral: "",
            WhereYouHearAboutUsEvent: "",
            WhereYouHearAboutUsOther: "",

            HasTraders: false,
            Trader01ContactID: "",
            Trader01ContactAddressID: "",
            Trader01ContactAddressUpdateToken: "",
            FirstNameTrader01: "",
            LastNameTrader01: "",
            DateOfBirthTrader01: "",
            ResidentialAddressStreetTrader01: "",
            ResidentialAddressLine2Trader01: "",
            ResidentialAddressCountryTrader01: "",
            ResidentialAddressStateNameTrader01: "",
            ResidentialAddressStateIdTrader01: "",
            ResidentialAddressCityTrader01: "",
            ResidentialAddressPostalCodeTrader01: "",
            ResidentialPhoneNumberTrader01: "",
            MobileNumberTrader01: "",
            EmailAddressTrader01: "",
            PoliticallyExposedPersonTrader01: true,
            Trader01_AdditionalProperties: [],
            Trader02ContactID: "",
            Trader02ContactAddressID: "",
            Trader02ContactAddressUpdateToken: "",
            FirstNameTrader02: "",
            LastNameTrader02: "",
            DateOfBirthTrader02: "",
            ResidentialAddressStreetTrader02: "",
            ResidentialAddressLine2Trader02: "",
            ResidentialAddressCountryTrader02: "",
            ResidentialAddressStateNameTrader02: "",
            ResidentialAddressStateIdTrader02: "",
            ResidentialAddressCityTrader02: "",
            ResidentialAddressPostalCodeTrader02: "",
            ResidentialPhoneNumberTrader02: "",
            MobileNumberTrader02: "",
            EmailAddressTrader02: "",
            PoliticallyExposedPersonTrader02: true,
            Trader02_AdditionalProperties: [],
            Trader03ContactID: "",
            Trader03ContactAddressID: "",
            Trader03ContactAddressUpdateToken: "",
            FirstNameTrader03: "",
            LastNameTrader03: "",
            DateOfBirthTrader03: "",
            ResidentialAddressStreetTrader03: "",
            ResidentialAddressLine2Trader03: "",
            ResidentialAddressCountryTrader03: "",
            ResidentialAddressStateNameTrader03: "",
            ResidentialAddressStateIdTrader03: "",
            ResidentialAddressCityTrader03: "",
            ResidentialAddressPostalCodeTrader03: "",
            ResidentialPhoneNumberTrader03: "",
            MobileNumberTrader03: "",
            EmailAddressTrader03: "",
            PoliticallyExposedPersonTrader03: true,
            Trader03_AdditionalProperties: [],
            Trader04ContactID: "",
            Trader04ContactAddressID: "",
            Trader04ContactAddressUpdateToken: "",
            FirstNameTrader04: "",
            LastNameTrader04: "",
            DateOfBirthTrader04: "",
            ResidentialAddressStreetTrader04: "",
            ResidentialAddressLine2Trader04: "",
            ResidentialAddressCountryTrader04: "",
            ResidentialAddressStateNameTrader04: "",
            ResidentialAddressStateIdTrader04: "",
            ResidentialAddressCityTrader04: "",
            ResidentialAddressPostalCodeTrader04: "",
            ResidentialPhoneNumberTrader04: "",
            MobileNumberTrader04: "",
            EmailAddressTrader04: "",
            PoliticallyExposedPersonTrader04: "",
            Trader04_AdditionalProperties: [],
            Trader05ContactID: "",
            Trader05ContactAddressID: "",
            Trader05ContactAddressUpdateToken: "",
            FirstNameTrader05: "",
            LastNameTrader05: "",
            DateOfBirthTrader05: "",
            ResidentialAddressStreetTrader05: "",
            ResidentialAddressLine2Trader05: "",
            ResidentialAddressCountryTrader05: "",
            ResidentialAddressStateNameTrader05: "",
            ResidentialAddressStateIdTrader05: "",
            ResidentialAddressCityTrader05: "",
            ResidentialAddressPostalCodeTrader05: "",
            ResidentialPhoneNumberTrader05: "",
            MobileNumberTrader05: "",
            EmailAddressTrader05: "",
            PoliticallyExposedPersonTrader05: true,
            Trader05_AdditionalProperties: [],
            UtilityBill: "",
            UtilityBill_FileName: "",
            ContactAddress3: "",
            ContactPrimaryIdentificationExpirationDate: "",
            ContactPrimaryIdentificationIssuingAgency: "",
            EmploymentStatus: "",
            Occupation: "",
            SecondaryIdentificationExpirationDate: "",
            SecondaryIdentificationIssuingAgency: "",
            SecondaryIdentificationNumber: "",
            SecondaryIdentificationType: "",
            SourceOfWealth: [],
            TaxIdentificationNumber: "",
            //DocumentForm: "AFEX",
            //DocumentForm_FileName: "AFEX",
            RegForm_Origin: "",
            DocumentCountryID: "",

            //Xushi fields
            RemitterName: this.state.ApplicantGeneralInformation.RemitterName != null ? this.state.ApplicantGeneralInformation.RemitterName : '',
            RemitterContactNumber: this.state.ApplicantGeneralInformation.RemitterContactNumber != null ? this.state.ApplicantGeneralInformation.RemitterContactNumber : '',
            RemitterRelationship: this.state.ApplicantGeneralInformation.RemitterRelationship != null ? this.state.ApplicantGeneralInformation.RemitterRelationship : '',
            //
            ReferredBy: this.state.ApplicantGeneralInformation.ReferredBy != null ? this.state.ApplicantGeneralInformation.ReferredBy.toString() : '',
            ReferredByName: this.state.ApplicantGeneralInformation.ReferredByName != null ? this.state.ApplicantGeneralInformation.ReferredByName : '',
            SourceOfIncome: this.state.ApplicantGeneralInformation.sourceOfWealthID != null ? this.state.ApplicantGeneralInformation.sourceOfWealthID : '',
            SourceOfIncomeOther: this.state.ApplicantGeneralInformation.SourceOfIncomeOther != null && this.state.ApplicantGeneralInformation.sourceOfWealthID === 'Other' ? this.state.ApplicantGeneralInformation.SourceOfIncomeOther : '',
            DataIP: this.context.IP
        }
        this.setState({ loading: true });
        const jsonResponse = await submit360Form(model);
        this.setState({ loading: false });
        switch (jsonResponse.httpStatusCode) {
            case 200: {
                if (jsonResponse.Response) {
                    const parameterResponse = await getParameterByName('DisableSendEmailRegform');
                    const disableSendEmailRegform = parameterResponse != null && parameterResponse.ParameterValue != null && parameterResponse.ParameterValue.toLowerCase() === 'true';
                    if (model.TypeSave === 'Complete') {
                        // if parameter doesn't exists or is false, send email to customer
                        if (!disableSendEmailRegform) {
                            sendCustomerRegistrationEmail(this.state.ApplicantGeneralInformation.EmailAddress, this.state.customer.CustomerId, true).then((response) => console.log(response));
                        }
                        // false = Compliance
                        sendCustomerRegistrationEmail(this.state.ApplicantGeneralInformation.EmailAddress, this.state.customer.CustomerId, false).then((response) => console.log(response));
                    } else if (model.TypeSave === 'Partial') {
                        sendCompleteRegistrationEmail(this.state.ApplicantGeneralInformation.EmailAddress, this.state.customer.CustomerId).then((response) => console.log(response));
                    }
                    if (model.TypeSave === 'Complete' && (this.state.emailVerifiedSubmit || this.state.emailVerified === 'YES' || disableSendEmailRegform)) {
                        sessionStorage.setItem('CustomerStatus', 'Complete');
                        this.setState({ showSuccessAlert: true, alertTitle: 'Success', alertMessage: 'Thanks for submitting your application. We will contact you shortly.' });
                    } else {
                        sessionStorage.setItem('CustomerStatus', 'Incomplete');
                        if (model.TypeSave === 'Partial') {
                            sessionStorage.setItem('CustomerStatus', 'Partial');
                        }
                        this.setState({ showSuccessAlert: true, alertTitle: 'Success', alertMessage: 'You will be able to return to our secured site and complete your application at a later date.' });
                    }
                } else {
                    this.context.showAlert('Error', 'An error ocurred while sending Individual Account Registration. Please, try again.')
                }
                break;
            }
            case 503: {
                this.context.showAlert('Error', 'An error ocurred while sending Individual Account Registration. Please, try again.')
                break;
            }
            default: {
                this.context.showAlert('Error', jsonResponse.Message)
                break;
            }
        }
    }

    customerDoc = (obj) => {
        // debugger;
        let helper = [
            {
                index: 0,
                TaskDescription: front1,
                DocumentType: documentName1,
                DocumentNumber: obj.numberIdentification,
                DocumentCountryID: obj.countryIDIdentification,
                DocumentName: obj.documents.personalIdentification1FileName !== '' ? obj.documents.personalIdentification1FileName : null,
                DocumentContent: obj.documents.personalIdentification1File !== '' ? obj.documents.personalIdentification1File : null,
                TaskDescriptionBack: back1,
                DocumentNameBack: obj.documents.personalIdentification2FileName !== '' ? obj.documents.personalIdentification2FileName : null,
                DocumentContentBack: obj.documents.personalIdentification2File !== '' ? obj.documents.personalIdentification2File : null,
            },
            {
                index: 0,
                TaskDescription: front2,
                DocumentType: documentName2,
                DocumentNumber: obj.DriverLicenseNumber,
                DocumentCountryID: obj.countryIDIdentification,
                DocumentName: obj.documents.drivingLicense1FileName !== '' ? obj.documents.drivingLicense1FileName : null,
                DocumentContent: obj.documents.drivingLicense1File !== '' ? obj.documents.drivingLicense1File : null,
                TaskDescriptionBack: back2,
                DocumentNameBack: obj.documents.drivingLicense2FileName !== '' ? obj.documents.drivingLicense2FileName : null,
                DocumentContentBack: obj.documents.drivingLicense2File !== '' ? obj.documents.drivingLicense2File : null,
            },
            {
                index: 0,
                TaskDescription: front3,
                DocumentType: documentName3,
                DocumentNumber: obj.PassportNumber,
                DocumentCountryID: obj.countryIDIdentification,
                DocumentName: obj.documents.passportFileName !== '' ? obj.documents.passportFileName : null,
                DocumentContent: obj.documents.passportFile !== '' ? obj.documents.passportFile : null,
                TaskDescriptionBack: '',
                DocumentNameBack: '',
                DocumentContentBack: '',
            }
        ];

        if (getCurrentCompanyName() === 'Xushi') {
            helper = [...helper, ...[{
                index: 0,
                TaskDescription: personalIdentificationAdditional,
                DocumentType: documentName1,
                DocumentNumber: obj.numberIdentification,
                DocumentCountryID: obj.countryIDIdentification,
                AdditionalDocuments: obj.documents.identityAdditionalFiles
            },
            {
                index: 0,
                TaskDescription: drivinLicenseAdditional,
                DocumentType: documentName2,
                DocumentNumber: obj.DriverLicenseNumber,
                DocumentCountryID: obj.countryIDIdentification,
                AdditionalDocuments: obj.documents.drivingLicenseAdditionalFiles
            },
            {
                index: 0,
                TaskDescription: passportAdditional,
                DocumentType: documentName3,
                DocumentNumber: obj.PassportNumber,
                DocumentCountryID: obj.countryIDIdentification,
                AdditionalDocuments: obj.documents.passportAdditionalFiles
            }]];
        }
        return helper;
    }

    CustomerAgreement = (obj) => {
        this.setState({ CustomerAgreement: obj });
    }

    closeSuccessAlert = async () => {
        const status = sessionStorage.getItem('CustomerStatus');
        this.setState({ showSuccessAlert: false });
        if (this.state.originOnline !== '') {
            window.open(onlineLoginUrl, "_self")
        } else {
            // const parameterResponse = await getParameterByName('DisableSendEmailRegform');
            // const disableSendEmailRegform = parameterResponse != null && parameterResponse.ParameterValue != null && parameterResponse.ParameterValue.toLowerCase() === 'true';
            // if (disableSendEmailRegform) {
            //     this.props.navigate('/success-account');
            // } else {
            // }
            switch (status) {
                case 'Incomplete':
                    this.props.navigate('/');
                    break;
                case 'Complete':
                    this.props.navigate('/success-account');
                    break;
                case 'Partial':
                    this.props.navigate('/');
                    break;
                default:
                    break;
            }
        }
    }

    transformAddProperties = (type) => {
        let table = [];
        switch (type) {
            default:
            case 'Individual':
                table = this.state.valuesOfAdditionalPropertiesDefs2;
                break;
            case 'Contact Principal':
                table = this.state.valuesOfAdditionalPropertiesDefs1;
                break;
        }
        let helper = [];
        for (var j = 0; j < table.length; j++) {
            helper.push({
                Description: table[j].description,
                Value: table[j].value
            });
        }
        return helper;
    }

    adaptList = (customer, originOnline) => {
        if (customer != null) {
            let contactAdditionalProperties = [];
            let objApplicantGeneralInformation = {
                ContactAddressId: customer.Contacts != null ? customer.Contacts[0].AddressId : '',
                ContactAddressUpdateToken: customer.Contacts != null ? customer.Contacts[0].AddressUpdateToken : '',
                ContactId: customer.Contacts != null ? customer.Contacts[0].ContactId : '',
                ContactUpdateToken: customer.Contacts != null ? customer.Contacts[0].UpdateToken : '',

                AddressId: customer.CustomerAddresses[0].AddressId,
                AddressUpdateToken: customer.CustomerAddresses[0].UpdateToken,

                FirstName: '',
                ShortName: '',
                LastName: '',
                DateOfBirth: '',
                ResidentialAddressStreet: customer.CustomerAddresses[0].address1,
                ResidentialAddressLine2: customer.CustomerAddresses[0].address2,
                ResidentialAddressCity: customer.CustomerAddresses[0].city,
                ResidentialAddressStateId: customer.CustomerAddresses[0].stateid,
                ResidentialAddressStateName: '',
                OtherState: customer.CustomerAddresses[0].statename,
                AffiliateReferredBy: customer.AffiliateReferredBy != null ? customer.AffiliateReferredBy : '',

                countryIDIdentification: customer.CustomerAddresses[0].countryid,
                StateIdIdentification: '',
                StateNameIdentification: '',
                OtherStateIdentification: '',

                ResidentialCityId: '',
                ResidentialCityName: '',
                ResidentialAddressPostalCode: customer.CustomerAddresses[0].postalcode,
                ResidentialPhoneNumber: customer.Contacts != null && customer.Contacts[0].phonenumber != null ? customer.Contacts[0].phonenumber : '',
                EmailAddress: '',
                Fax: '',
                MobileNumber: customer.Contacts != null && customer.Contacts[0].mobilenumber != null ? customer.Contacts[0].mobilenumber : '',
                Citizenship: '',
                SSN: '',
                IDNo: '',
                IDType: '',
                IDTypeName: '',
                IDExpirationDate: '',
                saludationID: '',
                saludationName: '',
                individualRolesID: '',
                individualRolesName: '',
                jobTitlesID: '',
                jobTitlesName: '',
                countryID: customer.CustomerAddresses[0].countryid,
                countryName: '',
                CitizenshipID: '',
                CitizenshipName: '',
                PercentOwnership: '',
                IssueJurisdiction: '',

                //Xushi fields
                RemitterName: '',
                RemitterContactNumber: '',
                RemitterRelationship: '',
                ReferredBy: false,
                ReferredByName: '',
                sourceOfWealthID: '',
                sourceOfWealthName: '',
                SourceOfIncomeOther: '',
                PEP: true
            };
           /* let objGcceptanceoftermsandconditions = {
                customerAgreement: false,
            };*/
            let objCustomerAgreement = {
                customerAgreement: '',
                TaskList: customer.CustomerTask != null ? customer.CustomerTask : []
            }
            if (customer.Contacts != null) {
                customer.Contacts.forEach((value, index) => {
                    switch (index) {
                        case 0:
                            objApplicantGeneralInformation.saludationID = value.Salutation != null ? value.Salutation : '';
                            objApplicantGeneralInformation.FirstName = value.firstname;
                            objApplicantGeneralInformation.ShortName = value.middlename;
                            objApplicantGeneralInformation.LastName = value.lastname;
                            objApplicantGeneralInformation.DateOfBirth = value.dateofbirth != null ? value.dateofbirth : '';
                            objApplicantGeneralInformation.EmailAddress = value.email;
                            // objApplicantGeneralInformation.ResidentialPhoneNumber = (customer.phonenumber != null && customer.phonenumber !== '') ? customer.phonenumber : customer.mobilenumber;
                            contactAdditionalProperties = value.AdditionalProperties != null ? value.AdditionalProperties : [];

                            //Xushi
                            objApplicantGeneralInformation.PEP = value.politicallyexposedperson != null && value.politicallyexposedperson === 'Yes'
                            //----Properties
                            
                            // objApplicantGeneralInformation.numberIdentification = this.contactProperty(value.ContactProperties, 'Identification Number');
                            // objApplicantGeneralInformation.DriverLicenseNumber = this.contactProperty(value.ContactProperties, "Driver's License Number");
                            // objApplicantGeneralInformation.PassportNumber = this.contactProperty(value.ContactProperties, 'Passport Number');
                            
                            objApplicantGeneralInformation.TaskList = value.ContactTask != null ? value.ContactTask : [];
                            objApplicantGeneralInformation.documents = {
                                personalIdentification1File: '',
                                personalIdentification1FileName: '',
                                personalIdentification2File: '',
                                personalIdentification2FileName: '',
                                drivingLicense1File: '',
                                drivingLicense1FileName: '',
                                drivingLicense2File: '',
                                drivingLicense2FileName: '',
                                passportFile: '',
                                passportFileName: '',
                                identityAdditionalFiles: [],
                                drivingLicenseAdditionalFiles: [],
                                passportAdditionalFiles: []
                            };
                            if (value.ContactTask != null) {
                                value.ContactTask.forEach((document) => {
                                    switch (document.Title) {
                                        case 'Driving License Document - 1': {
                                            if (document.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                                objApplicantGeneralInformation.documents['drivingLicense1File'] = document.Files[0]['$content'];
                                                objApplicantGeneralInformation.documents['drivingLicense1FileName'] = document.Files[0]['filename'];
                                                objApplicantGeneralInformation.DriverLicenseNumber = document.DocumentNumber != null ? document.DocumentNumber : '';
                                            }
                                            break;
                                        }
                                        case 'Driving License Document - 2': {
                                            if (document.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                                objApplicantGeneralInformation.documents['drivingLicense2File'] = document.Files[0]['$content'];
                                                objApplicantGeneralInformation.documents['drivingLicense2FileName'] = document.Files[0]['filename'];
                                                objApplicantGeneralInformation.DriverLicenseNumber = document.DocumentNumber != null ? document.DocumentNumber : '';
                                            }
                                            break;
                                        }
                                        case 'Personal Identification document - 1': {
                                            if (document.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                                objApplicantGeneralInformation.documents['personalIdentification1File'] = document.Files[0]['$content'];
                                                objApplicantGeneralInformation.documents['personalIdentification1FileName'] = document.Files[0]['filename'];
                                                objApplicantGeneralInformation.numberIdentification = document.DocumentNumber != null ? document.DocumentNumber : '';
                                            }
                                            break;
                                        }
                                        case 'Personal Identification document - 2': {
                                            if (document.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                                objApplicantGeneralInformation.documents['personalIdentification2File'] = document.Files[0]['$content'];
                                                objApplicantGeneralInformation.documents['personalIdentification2FileName'] = document.Files[0]['filename'];
                                                objApplicantGeneralInformation.numberIdentification = document.DocumentNumber != null ? document.DocumentNumber : '';
                                            }
                                            break;
                                        }
                                        case 'Personal Passport Document - 1': {
                                            if (document.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                                objApplicantGeneralInformation.documents['passportFile'] = document.Files[0]['$content'];
                                                objApplicantGeneralInformation.documents['passportFileName'] = document.Files[0]['filename'];
                                                objApplicantGeneralInformation.PassportNumber = document.DocumentNumber != null ? document.DocumentNumber : '';
                                            }
                                            break;
                                        }
                                        default: break;
                                    }
                                });
                            }
                            break;
                        default: break;
                    }
                });
            }

            let emailVerified = 'NO';
            let showEmailVerifiedAlert = false;

            if (customer.CustomerProperties != null) {
                customer.CustomerProperties.forEach(value => {
                    switch (value.name) {
                        case 'EmailVerified':
                            emailVerified = value.value;

                            switch (value.value) {
                                case 'NO':
                                    showEmailVerifiedAlert = true;
                                    break;
                                case 'YES':
                                    showEmailVerifiedAlert = false;
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case 'Citizenship':
                            objApplicantGeneralInformation.CitizenshipID = value.value;
                            break;
                        case 'Customer Agreement':
                           // objGcceptanceoftermsandconditions.customerAgreement = value.value !== '' ? (value.value === 'Yes' ? true : false) : false;
                            objCustomerAgreement.customerAgreement = value.value !== '' ? (value.value === 'Yes' ? true : false) : false;
                            break;
                        case 'Include Signature':
                            objCustomerAgreement.signatureResponse = (value.value != null && value.value !== 'Yes') ? false : true;
                            break;
                        case 'Remitter Name':
                            objApplicantGeneralInformation.RemitterName = value.value;
                            break;
                        case 'Remitter Contact Number':
                            objApplicantGeneralInformation.RemitterContactNumber = value.value;
                            break;
                        case 'Remitter Relationship':
                            objApplicantGeneralInformation.RemitterRelationship = value.value;
                            break;
                        case 'Referred By':
                            objApplicantGeneralInformation.ReferredBy = value.value === 'Yes';
                            break;
                        case 'Referred By Name':
                            objApplicantGeneralInformation.ReferredByName = value.value;
                            break;
                        case 'Source of Funds':
                            objApplicantGeneralInformation.sourceOfWealthID = value.value;
                            objApplicantGeneralInformation.sourceOfWealthName = value.value;
                            break;
                        case 'Source of Funds Other':
                            objApplicantGeneralInformation.SourceOfIncomeOther = value.value;
                            break;
                        default:
                            break;
                    }
                })
            }

            sessionStorage.setItem('ActualEmailVerified', showEmailVerifiedAlert);
            sessionStorage.setItem('ActualCustomerID', customer.CustomerId);
            sessionStorage.setItem('ActualCustomerName', customer.name);
            sessionStorage.setItem('ActualCustomerEmail', customer.email);

            this.setState({
                objApplicantGeneralInformation,
                contactAdditionalProperties,
                //objGcceptanceoftermsandconditions,
                objCustomerAgreement,
                emailVerified,
                originOnline: originOnline != null ? originOnline : ''
            });
        }
    }

    handleActiveTab = (tab) => {
        return this.state.activeTab === tab ? 'accordion-header uk-accordion-title clearfix active' : 'accordion-header uk-accordion-title clearfix';
    }
    clickTitle = (tab) => {
        this.setState({ activeTab: tab });
    }

    contactProperty = (obj, name) => {
        let value = '';
        if (obj != null) {
            obj.forEach(element => {
                switch (element.name) {
                    case name:
                        value = element.value != null ? element.value : '';
                        break;
                    default:
                        break;
                }
            });
        }
        return value;
    }

    checkValidReferralCustomer = async (saveType = 'Partial') => {
        if (this.state.ApplicantGeneralInformation.AffiliateReferredBy == null ||
            this.state.ApplicantGeneralInformation.AffiliateReferredBy === '' ||
            this.state.ApplicantGeneralInformation.AffiliateReferredBy === 0) {
                await this.selectSaveMethod(saveType, true);
        } else {
            const json = await checkValidReferralCustomer(this.state.ApplicantGeneralInformation.AffiliateReferredBy);
            if (json != null && json.validReferralCustomer != null) {
                const validReferralCustomer = json.validReferralCustomer;
                if (validReferralCustomer) { // Valid referral. Don't show referral alert and proceed with normal save flow.
                    await this.selectSaveMethod(saveType, true);
                } else {
                    // Show invalid referral alert. Also, save saveType to return to normal flow after closing alert.
                    this.setState({ showInvalidReferralAlert: true, saveType: saveType });
                }
            }
        }
    };

    selectSaveMethod = async (saveType, validReferral = null) => {
        if (saveType === 'Partial') {
            await this.submitAfterEmailVerification('Partial', validReferral); // normal Partial save flow
        } else if (saveType === 'Full') {
            await this.submit360Click(); // Show validation email alert
        }
    }

    validateErrors = (event, number) => {
        var objErrors = [
            this.context.errors_Step1.length,
            this.context.errors_Step2.length,
            this.context.errors_Step3.length,
        ];

        for (let i = 0; i < number; i++) {
            if (objErrors[i] > 0) {
                this.context.showAlertError((i + 1), true);
                this.context.notFirstLoad((i + 1), false);
                event.preventDefault();
                break;
            }
        }
    }
    validatePartialErrors = async (errors, number) => {
        if (errors.length > 0) {
            this.context.showAlertError((number + 8), true, true);
            this.context.notFirstLoad((number + 8), false, true);
        } else {
            await this.checkValidReferralCustomer('Partial');
            // this.submitAfterEmailVerification('Partial');
        }
    }
    submitPartial = async () => {
        switch (this.state.targetID) {
            case 'applicant-general-info-li':
                await this.validatePartialErrors(this.context.errors_Partial_Step1, 1)
                break;
            case 'additional-info-li':
                await this.validatePartialErrors(this.context.errors_Partial_Step2, 2)
                break;
            case 'acceptance-of-terms-and-conditions-li':
                await this.validatePartialErrors(this.context.errors_Partial_Step3, 3)
                break;
            default:
                break;
        }
    }

    closeInvalidReferralAlert = async () => {
        this.setState({ showInvalidReferralAlert: false });
        await this.selectSaveMethod(this.state.saveType);
    };

    updateValueListAdditionalProppertiesDefs = (listValues, listaValidations, page) => {
        console.log("-------------------------");
        console.log("Llegamos al Update");
        console.log(listValues);
        console.log(listaValidations);
        console.log(page);
        console.log("-------------------------");
        console.log(this.state.valuesOfAdditionalPropertiesDefs1)
        console.log(this.state.validationsOfAdditionalPropertiesDefs1)
        console.log("-------------------------");
        console.log(this.state.valuesOfAdditionalPropertiesDefs2)
        console.log(this.state.validationsOfAdditionalPropertiesDefs2)
        console.log("-------------------------");
    };

    //OBJFilters
    ApplicantGeneralInformation = (obj) => {
        this.setState({ ApplicantGeneralInformation: obj });
    }
    Acceptanceoftermsandconditions = (obj) => {
        this.setState({ acceptanceoftermsandconditions: obj });
    }

    OnClickNext = () => {
        this.setState({ clickNext: true })
    }
    NoClickNext = () => {
        this.setState({ clickNext: false })
    }
    PreviousButton = (number) => {
        this.OnClickNext();
        //let step = number === 6 ? (this.state.attachmentGenerate.length > 0 ? 5 : 4) : number;
        this.setState({ nextTabIndex: number, tabIndex: number + 1, buttonPressed: true }, () =>
            UIkit.accordion('#accordion-contact').toggle(number, true)
        );
    }
    NextButton = (number) => {
        this.OnClickNext();
        this.setState({ nextTabIndex: number, tabIndex: number - 1, buttonPressed: true }, () =>
            UIkit.accordion('#accordion-contact').toggle(number, true)
        );
    }

    transformToIndex = (tabID) => {
        switch (tabID) {
            default:
            case 'applicant-general-info-li':
                return 1;
            case 'additional-info-li':
                return 2;
            case 'acceptance-of-terms-and-conditions-li':
                return 3;
        }
    };

    closeSentEmailAlert = () => {
        this.setState({
            showSentEmailAlert: false,
            alertTitleSentEmail: '',
            alertMessageSentEmail: ''
        }, () => {
            // this.submitAfterEmailVerification('Partial');
        });
    }

    closeEmailVerifiedAlert = () => {
        this.setState({ emailVerifiedSubmit: false }, () => {
            // this.submitAfterEmailVerification('Partial');
        });
    }

    yesEmailVerifiedAlert = async () => {
        const customerName = `${this.state.ApplicantGeneralInformation.FirstName}, ${this.state.ApplicantGeneralInformation.LastName}`;
        sendValidateEmail(this.state.customer.CustomerId, customerName, this.state.ApplicantGeneralInformation.EmailAddress).then(
            (jsonResponse) => {
                console.log("sendValidateEmail: " + JSON.stringify(jsonResponse));
                /*
                    sendValidateEmail: {"Message":"","ErrorMessage":null,"httpStatusCode":200}
                */

                let title = '';
                let message = '';

                if (jsonResponse != null) {
                    switch (jsonResponse.httpStatusCode) {
                        case 200:
                            title = 'Successfully';
                            message = 'A new email was sent to you. Please check your inbox or spam folder and confirm it.';
                            break;
                        default:
                            title = 'Error';
                            message = 'Something went wrong. Please try again later.';
                            break;
                    }
                } else {
                    title = 'Error';
                    message = 'Something went wrong. Please try again later.';
                }

                this.setState({
                    emailVerifiedSubmit: false,
                    showSentEmailAlert: true,
                    alertTitleSentEmail: title,
                    alertMessageSentEmail: message
                });
            }
        );
    }


    TaskAttachments_Step1 = (obj) => {
        this.setState({ TaskAttachments_Step1: obj });
    }
    TaskAttachments_Step2 = (obj) => {
        this.setState({ TaskAttachments_Step2: obj });
    }

    addProps_CustomerAgreement = (obj) => {
        this.setState({ addProps_step2: obj })
    }

    render() {
        return (
            <form>
                <h2>{getResource('Individual_Title')}</h2>
                <p className="p-color">{getResource('Individual_Paragraph')}</p>

                <p className="right p-color">{getResource('Field_Required')}</p>

                {getCurrentCompanyName() !== 'Khyber' &&<div class="fab fab-fixed">
                    <button
                        type="button"
                        id="partial_button"
                        className="btn btn-primary"
                        style={{ borderColor: 'white' }}
                        onClick={this.submitPartial}> {getResource('Button_Partial')} </button>
                </div>}

                <div className="accordion-style">
                    <ul id="accordion-contact" uk-accordion="multiple: false; collapsible: true" >
                        <li id="applicant-general-info-li" className="uk-open accordion-header noPadding15">
                            <h3 id="applicant-general-info" className={this.handleActiveTab('applicant-general-info')}>
                                <a href="#applicant-general-info" className="toggle-status">
                                    <i className="fa fa-caret-square-o-down" aria-hidden="true" onClick={this.OnClickNext}></i>
                                    {' ' + getResource('Title_1')}<small className="fr">{getResource("Step1")}</small>
                                </a>
                            </h3>
                            <div className="uk-accordion-content">
                                <section id="applicant-general-info-wrapper" className="clearfix" style={{ display: "block" }}>
                                    <fieldset disabled={this.state.readOnly}>
                                        <ApplicantGeneralInformation
                                            //notFirstLoad={this.state.notFirstLoad_Step1}
                                            //notFirstLoadPartial={this.state.notFirstLoad_Partial_Step1}
                                            notFirstLoad={this.context.notFirstLoad_Step1}
                                            notFirstLoadPartial={this.context.notFirstLoad_Partial_Step1}
                                            //isValidated={this.isValidated_Step1}
                                            //isPartialValidated={this.isValidated_Partial_Step1}
                                            ApplicantGeneralInformation={this.ApplicantGeneralInformation}
                                            objApplicantGeneralInformation={this.state.objApplicantGeneralInformation}
                                            clickNext={this.state.clickNext}
                                            NoClickNext={this.NoClickNext}
                                        />
                                        <AdditionalInformation
                                            notFirstLoad={this.context.notFirstLoad_Step1}
                                            totalList={this.state.allAdditionalPropertiesDefs1}
                                            customerAdditionalProperties={this.state.contactAdditionalProperties}
                                            values={this.state.valuesOfAdditionalPropertiesDefs1}
                                            validations={this.state.validationsOfAdditionalPropertiesDefs1}
                                            onUpdateValues={this.updateValueListAdditionalProppertiesDefs}
                                            step={1}
                                        />
                                        <TaskAttachments
                                            attachmentGenerate={this.state.attachmentGenerate_Step1}
                                            objTaskAttachments={this.state.objTaskAttachments_Step1}
                                            type="Individual"
                                            TaskAttachments={this.TaskAttachments_Step1}
                                            clickNext={this.state.clickNext}
                                            NoClickNext={this.NoClickNext}
                                        />
                                    </fieldset>
                                </section>
                                <div className="uk-margin">
                                    {/**NEXT / PREVIOUS */}
                                    <button
                                        type="button"
                                        id="previous_button"
                                        className="btn btn-primary"
                                        style={{ float: 'right' }}
                                        onClick={this.NextButton.bind(this, 1)}> {getResource('Button_Next')} </button>
                                </div>
                            </div>
                        </li>
                        <li id="additional-info-li" className="accordion-header noPadding15">
                            <h3 id="additional-info" className={this.handleActiveTab('additional-info')}>
                                <a href="#additional-info" className="toggle-status">
                                    <i className="fa fa-caret-square-o-down" aria-hidden="true" onClick={() => this.clickTitle('additional-info')}></i>
                                    {' ' + getResource('Title_2')}<small className="fr">{getResource("Step2")}</small>
                                </a>
                            </h3>
                            <div className="uk-accordion-content">
                                <section id="additional-info-wrapper" className="clearfix" style={{ display: "block" }}>
                                    <fieldset disabled={this.state.readOnly}>
                                        <AdditionalInformation
                                            notFirstLoad={this.context.notFirstLoad_Step2}
                                            totalList={this.state.allAdditionalPropertiesDefs2}
                                            customerAdditionalProperties={this.state.customerAdditionalProperties}
                                            values={this.state.valuesOfAdditionalPropertiesDefs2}
                                            validations={this.state.validationsOfAdditionalPropertiesDefs2}
                                            onUpdateValues={this.updateValueListAdditionalProppertiesDefs}
                                            step={2}
                                        />
                                        <TaskAttachments
                                            attachmentGenerate={this.state.attachmentGenerate_Step2}
                                            objTaskAttachments={this.state.objTaskAttachments_Step2}
                                            type="Individual"
                                            TaskAttachments={this.TaskAttachments_Step2}
                                            clickNext={this.state.clickNext}
                                            NoClickNext={this.NoClickNext}
                                        />
                                    </fieldset>
                                </section>
                                <div className="uk-margin">
                                    {/**NEXT / PREVIOUS */}
                                    <button
                                        type="button"
                                        id="previous_button"
                                        className="btn btn-primary"
                                        onClick={this.PreviousButton.bind(this, 0)}> {getResource('Button_Prev')} </button>
                                    <button
                                        type="button"
                                        id="previous_button"
                                        className="btn btn-primary"
                                        style={{ float: 'right' }}
                                        onClick={this.NextButton.bind(this, 2)}> {getResource('Button_Next')} </button>
                                </div>
                            </div>
                        </li>
                        <li id="acceptance-of-terms-and-conditions-li" className="accordion-header noPadding15">
                            <h3 id="acceptance-of-terms-and-conditions" className={this.handleActiveTab('acceptance-of-terms-and-conditions')} onClick={() => this.clickTitle('acceptance-of-terms-and-conditions')} data-prev-validate="">
                                <a href="#acceptance-of-terms-and-conditions" className="toggle-status">
                                    <i className="fa fa-caret-square-o-down" aria-hidden="true"></i>
                                    {' ' + getResource('Title_Corp_8')}
                                    <small className="fr" onClick={this.OnClickNext}>
                                        {getResource("FinalStep")}
                                    </small>
                                </a>
                            </h3>
                            <div className="uk-accordion-content">
                                <section id="acceptance-of-terms-and-conditions-wrapper" className="clearfix" style={{ display: "block" }}>
                                    <fieldset disabled={this.state.readOnly}>
                                        {/*<AcceptanceOfTermsAndConditions
                                            Acceptanceoftermsandconditions={this.Acceptanceoftermsandconditions}
                                            notFirstLoad={this.state.notFirstLoad_Step3}
                                            notFirstLoadPartial={this.state.notFirstLoad_Partial_Step3}
                                            isValidated={this.isValidated_Step3}
                                            isPartialValidated={this.isValidated_Partial_Step3}
                                            objGcceptanceoftermsandconditions={this.state.objGcceptanceoftermsandconditions}
                                            clickNext={this.state.clickNext}
                                            NoClickNext={this.NoClickNext}
                                            TermsPDF={this.state.urlTermsPDF}
                                        />*/}
                                        <CustomerAgreement navigate={this.props.navigate}
                                        allObjts={{
                                            ApplicantGeneralInformation: this.state.ApplicantGeneralInformation,
                                            AdditionalProperties: {
                                                step1: this.state.valuesOfAdditionalPropertiesDefs1,
                                                step2: this.state.valuesOfAdditionalPropertiesDefs2
                                            }
                                        }}
                                        CustomerAgreement={this.CustomerAgreement}
                                        addProps_CustomerAgreement={this.addProps_CustomerAgreement}
                                        submit360={this.submit360Click}
                                        notFirstLoad={this.context.notFirstLoad2}
                                        notFirstLoadPartial={this.context.notFirstLoad3}
                                        type="Individual"
                                        objCustomerAgreement={this.state.objCustomerAgreement}
                                        readOnly={this.state.readOnly}
                                        clickNext={this.state.clickNext}
                                        NoClickNext={this.NoClickNext}
                                        TermsPDF={this.state.urlTermsPDF}
                                    />
                                    </fieldset>
                                </section>
                                <div className="uk-margin">
                                    {/**NEXT / PREVIOUS */}
                                    <button
                                        type="button"
                                        id="previous_button"
                                        className="btn btn-primary"
                                        onClick={this.PreviousButton.bind(this, 1)}> {getResource('Button_Prev')} </button>

                                    {/*<button
                                        type="button"
                                        id="previous_button"
                                        className="btn btn-primary"
                                        disabled={this.state.CustomerAgreement != null ? !this.state.CustomerAgreement.customerAgreement : true}
                                        style={{ float: 'right' }}
                                    onClick={this.Submit}> {getResource('Button_Submit')}</button> */}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <AlertBox
                    open={this.state.showSuccessAlert}
                    onClose={this.closeSuccessAlert}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    type="Ok"
                    okClick={this.closeSuccessAlert}
                />
                <AlertBox
                    open={this.state.emailVerifiedSubmit}
                    onClose={this.closeEmailVerifiedAlert}
                    title="EMAIL VERIFICATION"
                    message="Your email must be verified. Please look for the email that has been sent to you to finish your validation. Do you want confirmation email forwarded to you?"
                    type="Yes/No"
                    yesClick={this.yesEmailVerifiedAlert}
                    noClick={this.closeEmailVerifiedAlert}
                />

                <AlertBox
                    open={this.state.showSentEmailAlert}
                    onClose={this.closeSentEmailAlert}
                    title={this.state.alertTitleSentEmail}
                    message={this.state.alertMessageSentEmail}
                    type="Ok"
                    okClick={this.closeSentEmailAlert}
                />
                <AlertBox
                    open={this.state.showInvalidReferralAlert}
                    onClose={this.closeInvalidReferralAlert}
                    title='Warning'
                    message='The entered Affiliate Referred by code is not valid.'
                    type="Ok"
                    okClick={this.closeInvalidReferralAlert}
                />
                <LoadingBox loading={this.state.loading} />
            </form>
        );
    }
}