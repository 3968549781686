import React from 'react';
import { getCustomerInfoByID } from '../../networking/Networking';
import LoadingBox from '../shared/LoadingBox';

export default class LoadingBlankScreen extends React.Component {

    state = {
        customerID: 0,
        customerType: '',
        isLogin: false,
        customerBody: null,
        originOnline: ''
    }

    componentDidMount() {
        this.reload();
    }

    reload() {
        if (this.props.originOnline != null) {
            this.setState({ originOnline: this.props.originOnline })
        }
        if (Number(this.props.customerId) > 0) {
            this.setState({ customerID: this.props.customerId }, () => this.getCustomer(this.props.customerId))
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.customerId !== this.props.customerId) {
            this.reload();
        }
    }
    getCustomer = (customerID) => {
        getCustomerInfoByID(customerID).then(
            (json) => {
                console.log(json)
                if (json != null) {//json.Parent = 'Newport'
                    this.setState({ isLogin: true, customerBody: json, customerType: json.type });
                }
            }
        );
    }
    render() {
        if (this.state.isLogin) {
            switch (this.state.customerType) {
                case 'Corporate':
                    this.props.navigate('/corporate-account/:regionID', { state: { email: this.state.email, customerID: this.state.customerID, customer: this.state.customerBody, originOnline: this.state.originOnline } });
                    break;
                case 'MSB':
                    this.props.navigate('/msb-account/:regionID', { state: { email: this.state.email, customerID: this.state.customerID, customer: this.state.customerBody, originOnline: this.state.originOnline } });
                    break;
                case 'Individual':
                    this.props.navigate('/individual-account/:regionID', { state: { email: this.state.email, customerID: this.state.customerID, customer: this.state.customerBody, originOnline: this.state.originOnline } });
                    break;
                default:
                    break;
            }
        }
        return (<LoadingBox loading={true} />);
    }
}