import React from 'react';
import 'react-phone-input-2/lib/style.css';
import AppContext from '../../../AppContext';
import { formatDate, formatStringToDate, formatValidInput } from '../../../helper/FormatHelper';
import { getResource } from '../../../helper/ResourcesHelper';
import { validate } from '../../../helper/ValidateHelper';
import { deleteAttachment, getStatesByCountry, submitOwnerForm, validateContactInvitationId } from '../../../networking/NetworkingRegForm';
import AlertBox from '../../shared/AlertBox';
import AttachmentsDialog from '../../shared/AttachmentsDialog';
import DynamicSelect from '../../shared/DynamicSelect';
import LoadingBox from '../../shared/LoadingBox';
import NewDynamicTable from '../../shared/NewDynamicTable';

const today = new Date();

const documentName1 = 'Identity Card';
const front1 = 'Personal Identification document - 1';
const back1 = 'Personal Identification document - 2';
const documentName2 = 'Driving License';
const front2 = 'Driving License Document - 1';
const back2 = 'Driving License Document - 2';
const documentName3 = 'Passport';
const front3 = 'Personal Passport Document - 1';

export default class OwnerInvitationForm extends React.Component {
    static contextType = AppContext;
    state = {
        FirstName: '',
        ShortName: '',
        LastName: '',
        DateOfBirth: '',
        ResidentialAddressStreet: '',
        ResidentialAddressLine2: '',
        ResidentialAddressCity: '',
        ResidentialAddressStateId: '',
        ResidentialAddressStateName: '',
        ResidentialAddressPostalCode: '',
        ResidentialPhoneNumber: '',
        EmailAddress: '',
        Fax: '',
        MobileNumber: '',
        SSN: '',
        OtherState: '',

        countryID: 'GBR',
        countryName: '',
        CitizenshipID: '',
        CitizenshipName: '',

        countries: [],
        identificationType: [],
        documentTypeID: '',
        documentTypeIDName: '',
        documentTypeNumber: '',

        //--Show & Hidden 
        trader01: true,

        //RadioButtons

        YesState1: 'Yes',
        NoState1: 'No',
        radio1: getResource('Checkbox_Yes'),

        statesByCountryID: [],

        //Validations
        validations: {
            FirstName: true,
            LastName: true,
            DateOfBirth: true,
            CitizenshipID: true,
            ResidentialAddressStreet: true,
            ResidentialAddressCity: true,
            ResidentialAddressStateId: true,
            ResidentialAddressPostalCode: true,
            countryID: true,
            EmailAddress: true,
            countryIDIdentification: true,
            countryIDDrivingLicense: true,
            countryIDPassport: true,
            numberIdentification: true,
            documentTypeID: true,
        },
        documents: {
            personalIdentification1File: '',
            personalIdentification1FileName: '',
            personalIdentification2File: '',
            personalIdentification2FileName: '',
            drivingLicense1File: '',
            drivingLicense1FileName: '',
            drivingLicense2File: '',
            drivingLicense2FileName: '',
            passportFile: '',
            passportFileName: ''
        },

        contactType:'primary',
        requestId:null,
        showSuccessAlert: false,
        alertTitle: '',
        alertMessage: '',
        showDeleteAlert: false,
        
        //Attachments
        showAttachmentsDialogID1: false,
        showAttachmentsDialogID2: false,
        showAttachmentsDialogDL1: false,
        showAttachmentsDialogDL2: false,
        showAttachmentsDialogPS1: false,
        rowClickID1: false,
        rowClickID2: false,
        rowClickDL1: false,
        rowClickDL2: false,
        rowClickPS1: false,
        additionalProperties: []
    }
    componentDidMount() {
        if (this.props.contactType != null && this.props.requestId != null) {
            validateContactInvitationId(this.props.requestId).then(
                (value) => {
                    if(value.response)
                    {
                         this.setState({
                                //...this.props.objShareholderOwner,
                                contactType: this.props.contactType,
                                requestId: this.props.requestId
                            }, () => {
                                this.searchState(this.state.countryID, 1);
                                this.context.validated2(this.validateRequiredInputs());
                                this.props.Owner(this.state);
                                this.props.navigate('/owner-invitation');
                            });
                    }
                    else
                    {
                        this.props.navigate('/invalid-request');
                    }
                }
            );

            
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.notFirstLoad != null && prevProps.notFirstLoad !== this.props.notFirstLoad && !this.props.notFirstLoad) {
            this.validateRequiredFields();
        }
        if (this.props.notFirstLoadPartial != null && prevProps.notFirstLoadPartial !== this.props.notFirstLoadPartial && !this.props.notFirstLoadPartial) {
            this.validateRequiredFields(true);
        }
        if (prevProps.additionalProperties != null && this.props.additionalProperties != null && !this.propertiesAreEqual(this.props.additionalProperties, prevProps.additionalProperties)) {
            this.setState({ additionalProperties: this.props.additionalProperties });
        }
    }

    propertiesAreEqual = (newProperties, oldProperties) => {
        if (oldProperties.length === 0 && newProperties.length > 0) {
            return false;
        }
        let valid = true;
        for (let i = 0; i < newProperties.length; i++) {
            if (newProperties[i].Value !== oldProperties[i].Value) {
                valid = false;
            }
        }
        return valid;
    }

    switchFunction = (label, value) => {
        this.setState({
            [label]: value,
            validations: { ...this.state.validations, [label]: true }
        }, () => {
            this.context.validated2(this.validateRequiredInputs());
            this.props.Owner(this.state)
        });
    }

    ageVerification = () => {
        const dateOfBirth = formatStringToDate(this.state.DateOfBirth);
        //const today = new Date();
        let age = today.getFullYear() - dateOfBirth.getFullYear();
        const m = today.getMonth() - dateOfBirth.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < dateOfBirth.getDate())) {
            age--;
        }
        return age >= 18;
    }

    switchValidateInputs = () => {
        let fields = [
            { displayName: getResource('Label_FirstName'), validateName: 'FirstName', value: this.state['FirstName'], type: 'string' },
            { displayName: getResource('Label_Surname'), validateName: 'LastName', value: this.state['LastName'], type: 'string' },
            // { displayName: getResource('Label_DateOfBirth'), validateName: 'DateOfBirth', value: this.state['DateOfBirth'], type: 'string' },
            { displayName: getResource('Label_StreetAddress'), validateName: 'ResidentialAddressStreet', value: this.state['ResidentialAddressStreet'], type: 'string' },
            { displayName: getResource('Label_City'), validateName: 'ResidentialAddressCity', value: this.state['ResidentialAddressCity'], type: 'string' },
            { displayName: getResource('Label_Country'), validateName: 'countryID', value: this.state['countryID'], type: 'string' },
            { displayName: getResource('Label_Postal'), validateName: 'ResidentialAddressPostalCode', value: this.state['ResidentialAddressPostalCode'], type: 'string' },
            // { displayName: getResource('Label_ResidentialPhoneNumber'), validateName: 'ResidentialPhoneNumber', value: this.state['ResidentialPhoneNumber'], type: 'string' },
            { displayName: getResource('Label_Email'), validateName: 'EmailAddress', value: this.state['EmailAddress'], type: 'string' },
        ]
        // if(this.state.contactType==='primary'){
        //     fields.push({ displayName: getResource('Label_Citizenship'), validateName: 'CitizenshipID', value: this.state.CitizenshipID, type: 'string' });
        // }
        if (this.state.statesByCountryID.length > 0) {
            fields.push({ displayName: getResource('Label_State'), validateName: 'ResidentialAddressStateId', value: this.state['ResidentialAddressStateId'], type: 'number' });
        } else {
            fields.push({ displayName: getResource('Label_State'), validateName: 'ResidentialAddressStateId', value: this.state['OtherState'], type: 'number' });
        }
        // if (this.state['DateOfBirth'] !== '') {
        //     fields.push({ displayName: getResource('Label_DateOfBirth') + ': Must be older than 18 years', validation: this.ageVerification });
        // }
        //----------------Documents: Identity Card----------------
        // let document1 = false, document2 = false;
        // if (this.state.TaskList != null && this.state.TaskList.length > 0) {
        //     this.state.TaskList.forEach(value => {
        //         if (value.Title.includes('document - 1') || value.Title.includes('Document - 1')) {
        //             document1 = true;
        //         } else if (value.Title.includes('document - 2') || value.Title.includes('Document - 2')) {
        //             document2 = true;
        //         }
        //     });
        // }
        // if (this.state.documentTypeNumber !== '') {
        //     if (this.state.documentTypeNumber.includes('Identity')) {
        //         fields.push(
        //             { displayName: getResource('Label_NumberIdentification'), validateName: 'numberIdentification', value: this.state.numberIdentification, type: 'wholeNumber' }
        //         );
        //         if (!document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Identity Card (Front)', validateName: 'id1', value: this.state.documents.personalIdentification1File, type: 'string' },
        //                 { displayName: 'Identity Card (End)', validateName: 'id2', value: this.state.documents.personalIdentification2File, type: 'string' }
        //             );
        //         } else if (document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Identity Card (End)', validateName: 'id2', value: this.state.documents.personalIdentification2File, type: 'string' }
        //             );
        //         } else if (!document1 && document2) {
        //             fields.push(
        //                 { displayName: 'Identity Card (Front)', validateName: 'id1', value: this.state.documents.personalIdentification1File, type: 'string' },
        //             );
        //         }
        //     } else if (this.state.documentTypeNumber.includes('Driving')) {
        //         fields.push(
        //             { displayName: getResource('Label_LicenseNumber'), validateName: 'numberIdentification', value: this.state.DriverLicenseNumber, type: 'wholeNumber' },
        //         );
        //         if (!document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Attachment Driving License (Front)', validateName: 'drivingLicense1File', value: this.state.documents.drivingLicense1FileName, type: 'string' },
        //                 { displayName: 'Attachment Driving License (End)', validateName: 'passportFile', value: this.state.documents.drivingLicense2FileName, type: 'string' }
        //             );
        //         } else if (document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Attachment Driving License (End)', validateName: 'passportFile', value: this.state.documents.drivingLicense2FileName, type: 'string' }
        //             );
        //         } else if (!document1 && document2) {
        //             fields.push(
        //                 { displayName: 'Attachment Driving License (Front)', validateName: 'drivingLicense1File', value: this.state.documents.drivingLicense1FileName, type: 'string' },
        //             );
        //         }
        //     } else if (this.state.documentTypeNumber.includes('Passport')) {
        //         fields.push(
        //             { displayName: getResource('Label_PassportNumber'), validateName: 'numberIdentification', value: this.state.PassportNumber, type: 'wholeNumber' },
        //         );
        //         if (!document1) {
        //             fields.push(
        //                 { displayName: 'Attachment Passport', validateName: 'passportFile', value: this.state.documents.passportFile, type: 'string' }
        //             );
        //         }
        //     }
        // }
        return fields;
    }
    switchValidatePartialInputs = () => {
        const fields = [
            { displayName: getResource('Label_FirstName'), validateName: 'FirstName', value: this.state.FirstName, type: 'string' },
            // { displayName: getResource('Label_DateOfBirth'), validateName: 'DateOfBirth', value: this.state.DateOfBirth, type: 'string' },
            { displayName: getResource('Label_StreetAddress'), validateName: 'ResidentialAddressStreet', value: this.state.ResidentialAddressStreet, type: 'string' },
            { displayName: getResource('Label_City'), validateName: 'ResidentialCityId', value: this.state.ResidentialCityId, type: 'string' },
            { displayName: getResource('Label_Postal'), validateName: 'ResidentialAddressPostalCode', value: this.state.ResidentialAddressPostalCode, type: 'string' },
            { displayName: getResource('Label_Country'), validateName: 'countryID', value: this.state.countryID, type: 'string' },
        ]
        if (this.state.statesByCountryID.length > 0) {
            fields.push({ displayName: getResource('Label_State'), validateName: 'ResidentialAddressStateId', value: this.state.ResidentialAddressStateId, type: 'string' });
        } else {
            fields.push({ displayName: getResource('Label_State'), validateName: 'ResidentialAddressStateId', value: this.state.OtherState, type: 'string' });
        }
        // if (this.state.DateOfBirth !== '') {
        //     fields.push({ displayName: getResource('Label_DateOfBirth') + ': Must be older than 18 years', validation: this.ageVerification });
        // }

        //----------------Documents: Identity Card----------------
        // let document1 = false, document2 = false;
        // if (this.state.TaskList.length > 0) {
        //     this.state.TaskList.forEach(value => {
        //         if (value.Title.includes('document - 1') || value.Title.includes('Document - 1')) {
        //             document1 = true;
        //         } else if (value.Title.includes('document - 2') || value.Title.includes('Document - 2')) {
        //             document2 = true;
        //         }
        //     });
        // }
        // if (this.state.countryIDIdentification !== '') {
        //     fields.push(
        //         { displayName: getResource('Label_DocumentType'), validateName: 'documentTypeID', value: this.state.documentTypeID, type: 'string' },
        //     );
        // }
        // if (this.state.documentTypeNumber !== '') {
        //     if (this.state.documentTypeNumber.includes('Identity')) {
        //         fields.push(
        //             { displayName: getResource('Label_NumberIdentification'), validateName: 'numberIdentification', value: this.state.numberIdentification, type: 'wholeNumber' }
        //         );
        //         if (!document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Identity Card (Front)', validateName: 'id1', value: this.state.documents.personalIdentification1File, type: 'string' },
        //                 { displayName: 'Identity Card (End)', validateName: 'id2', value: this.state.documents.personalIdentification2File, type: 'string' }
        //             );
        //         } else if (document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Identity Card (End)', validateName: 'id2', value: this.state.documents.personalIdentification2File, type: 'string' }
        //             );
        //         } else if (!document1 && document2) {
        //             fields.push(
        //                 { displayName: 'Identity Card (Front)', validateName: 'id1', value: this.state.documents.personalIdentification1File, type: 'string' },
        //             );
        //         }
        //     } else if (this.state.documentTypeNumber.includes('Driving')) {
        //         fields.push(
        //             { displayName: getResource('Label_LicenseNumber'), validateName: 'numberIdentification', value: this.state.DriverLicenseNumber, type: 'wholeNumber' },
        //         );
        //         if (!document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Attachment Driving License (Front)', validateName: 'drivingLicense1File', value: this.state.documents.drivingLicense1FileName, type: 'string' },
        //                 { displayName: 'Attachment Driving License (End)', validateName: 'passportFile', value: this.state.documents.drivingLicense2FileName, type: 'string' }
        //             );
        //         } else if (document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Attachment Driving License (End)', validateName: 'passportFile', value: this.state.documents.drivingLicense2FileName, type: 'string' }
        //             );
        //         } else if (!document1 && document2) {
        //             fields.push(
        //                 { displayName: 'Attachment Driving License (Front)', validateName: 'drivingLicense1File', value: this.state.documents.drivingLicense1FileName, type: 'string' },
        //             );
        //         }
        //     } else if (this.state.documentTypeNumber.includes('Passport')) {
        //         fields.push(
        //             { displayName: getResource('Label_PassportNumber'), validateName: 'numberIdentification', value: this.state.PassportNumber, type: 'wholeNumber' },
        //         );
        //         if (!document1) {
        //             fields.push(
        //                 { displayName: 'Attachment Passport', validateName: 'passportFile', value: this.state.documents.passportFile, type: 'string' }
        //             );
        //         }
        //     }
        // }

        return fields;
    }
    //Inputs Validations
    validateRequiredInputs(partial) {
        var helper = [];
        var fields = [];
        var objOpen = [this.state.trader01];
        //const fields = this.switchValidateInputs(trader);
        var [valid, , errors] = [true, {}, {}];
        var xSum = 0;
        for (let i = 0; i < 1; i++) {
            if (objOpen[i]) {
                xSum = i + 1;
                fields = partial != null ? this.switchValidatePartialInputs(xSum) : this.switchValidateInputs(xSum);
                [valid, , errors] = validate(fields);
                helper = errors;
                //helper.push({ tab: ' 0' + xSum, errors });
                this.setState({ ['valid' + xSum]: valid });
            }
        }
        /*const [valid, validations, errors] = validate(helper);
        this.setState({ validations, listErrors });*/
        //const errors = helper;
        return { valid, errors };
    }
    //Fields Validations
    validateRequiredFields(partial) {
        let listValidations = this.state.validations;
        var helper = [];
        var fields = [];
        var objOpen = [this.state.trader01];
        //const fields = this.switchValidateInputs(trader);
        var [valid, validations, errors] = [true, {}, {}];
        var xSum = 0;
        for (let i = 0; i < 1; i++) {
            if (objOpen[i]) {
                xSum = i + 1;
                fields = partial != null ? this.switchValidatePartialInputs(xSum) : this.switchValidateInputs(xSum);
                [valid, validations, errors] = validate(fields);
                Object.assign(listValidations, validations)

                helper = errors;
                //helper.push({ tab: ' 0' + xSum, errors });
                this.setState({ ['valid' + xSum]: valid, validations: listValidations });
            }
        }
        /*const [valid, validations, errors] = validate(helper);
        this.setState({ validations, listErrors });*/
        return valid;
    }
    searchState = (countryID) => {
        if (countryID != null && countryID !== '') {
            getStatesByCountry(countryID, 0, 0).then(
                (value) => {
                    if (value.states) {
                        const states = value.states.map(c => ({ ...c, name: c["State Name"], value: c["State Id"] }));
                        this.setState({ statesByCountryID: states }, () => {
                            this.context.validated2(this.validateRequiredInputs());
                            this.props.Owner(this.state)
                        });
                    } else {
                        this.setState({ statesByCountryID: [] }, () => {
                            this.context.validated2(this.validateRequiredInputs());
                            this.props.Owner(this.state)
                        });
                    }
                }
            );
        }
    }
    async handleUpdateCountry(value) {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    await this.switchFunction('countryID', value.value);
                    await this.searchState(value.value)
                    await this.switchFunction('countryName', value.name);
                } else {
                    await this.switchFunction('countryID', '');
                    await this.switchFunction('countryName', '');
                }
            }
        }
    }
    handleUpdateFirstName = (event) => {
        this.switchFunction('FirstName', event.target.value);
    }

    handleUpdateLastName = (event) => {
        this.switchFunction('LastName', event.target.value);
    }

    handleUpdateDateOfBirth = (date) => {
        this.switchFunction('DateOfBirth', formatDate(date));
    }

    handleUpdateResidentialAddressStreet = (event) => {
        this.switchFunction('ResidentialAddressStreet', event.target.value);
    }

    handleUpdateResidentialAddressLine2 = (event) => {
        this.switchFunction('ResidentialAddressLine2', event.target.value);
    }

    handleUpdateResidentialAddressCity = (event) => {
        this.switchFunction('ResidentialAddressCity', event.target.value);
    }

    handleUpdateResidentialAddressState = (value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.switchFunction('ResidentialAddressStateId', value.value);
                    this.switchFunction('ResidentialAddressStateName', value.name);
                    this.switchFunction('OtherState', '');
                } else {
                    this.switchFunction('ResidentialAddressStateId', '');
                    this.switchFunction('ResidentialAddressStateName', '');
                }
            }
        }
    }
    handleUpdateState = (event) => {
        this.switchFunction('OtherState', event.target.value);
        this.switchFunction('ResidentialAddressStateId', '');
    }
    handleUpdateResidentialAddressPostalCode = (event) => {
        this.switchFunction('ResidentialAddressPostalCode', event.target.value);
    }

    handleUpdateResidentialPhoneNumber = (phone) => {
        this.switchFunction('ResidentialPhoneNumber', '+' + phone);
    }

    handleUpdateEmailAddress = (event) => {
        this.switchFunction('EmailAddress', event.target.value);
    }

    handleUpdateMobileNumber = (phone) => {
        this.switchFunction('MobileNumber', phone);
    }
    handleUpdateShortName = (event) => {
        this.switchFunction('ShortName', event.target.value);
    }
    handleUpdateCitizenship = (value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.switchFunction('CitizenshipID', value.value);
                    this.switchFunction('CitizenshipName', value.name);
                } else {
                    this.switchFunction('CitizenshipID', '');
                    this.switchFunction('CitizenshipName', '');
                }
            }
        }
        
    }
    handleUpdateSSN = (event) => {
        this.switchFunction('SSN', event.target.value);
    }
    ClearData = () => {
        this.setState({
            FirstName: '',
            ShortName: '',
            LastName: '',
            DateOfBirth: '',
            ResidentialAddressStreet: '',
            ResidentialAddressLine2: '',
            ResidentialAddressCity: '',
            ResidentialAddressStateId: '',
            ResidentialAddressStateName: '',
            ResidentialAddressPostalCode: '',
            ResidentialPhoneNumber: '',
            EmailAddress: '',
            Fax: '',
            MobileNumber: '',
            SSN: '',
            OtherState: '',

            countryID: '',
            countryName: '',
            CitizenshipID: '',
            CitizenshipName: '',

            //--Show & Hidden 
            trader01: true,

            //RadioButtons

            YesState1: 'Yes',
            NoState1: 'No',
            radio1: getResource('Checkbox_Yes'),

            //Validations
            validations: {
                FirstName: true,
                LastName: true,
                DateOfBirth: true,
                CitizenshipID: true,
                ResidentialAddressStreet: true,
                ResidentialAddressCity: true,
                ResidentialAddressStateId: true,
                ResidentialAddressPostalCode: true,
                countryID: true,
                EmailAddress: true
            }
        }, () => {
            this.context.validated2(this.validateRequiredInputs());
            this.props.Owner(this.state);
        })
    }
    SaveOwner = (event) => {
        const validateAddProperties = (addProperties) => {
            const _mandatories = addProperties.filter((ap) => ap.mandatory);
            if (_mandatories.length === 0){
                return true;
            } else {
                const valid = _mandatories.every(ap => {
                    let _valid = true;
                    if (ap.dataType === 'Text') {
                        if (ap.value === '') {
                            _valid = false;
                        }
                    } else if (ap.dataType === 'Numeric') {
                        if (ap.maxNumber !== 0) {
                            if (!(ap.minNumber <= ap.value && ap.value <= ap.maxNumber)) {
                                _valid = false;
                            }
                        } else {
                            if (ap.value === 0) {
                                _valid = false;
                            }
                        }
                    } else if (ap.dataType === 'Date') {
                        if (ap.value === '') {
                            _valid = false;
                        }
                    }
                    return _valid;
                });
                return valid;
            }
        };
        let model = {
            ContactValue: 1,
            ValidateID: this.state.requestId,
            //"CustomerID": 2,
            ShareHolder_Saludation: '',
            FirstNameShareholderOwner: this.state.FirstName,
            MiddleNameShareholderOwner: this.state.ShortName,
            LastNameShareholderOwner: this.state.LastName,
            DateOfBirthShareholderOwner: this.state.DateOfBirth,
            ResidentialAddressStreetShareholderOwner: this.state.ResidentialAddressStreet,
            ResidentialAddressLine2ShareholderOwner: this.state.ResidentialAddressLine2,
            ResidentialAddressCountryShareholderOwner: this.state.countryID,

            //OtherState
            ResidentialAddressStateNameShareholderOwner: this.state.OtherState !== '' ? this.state.OtherState : this.state.ResidentialAddressStateName,
            ResidentialAddressStateIdShareholderOwner: this.state.OtherState !== '' ? null : parseInt(this.state.ResidentialAddressStateId),

            ResidentialAddressCityShareholderOwner: this.state.ResidentialAddressCity,
            ResidentialAddressPostalCodeShareholderOwner: this.state.ResidentialAddressPostalCode,
            ResidentialPhoneNumberShareholderOwner: this.state.ResidentialPhoneNumber,
            MobileNumberShareholderOwner: this.state.MobileNumber,
            EmailAddressShareholderOwner: this.state.EmailAddress,
            PoliticallyExposedPersonShareholderOwner: true,
            //---------AFEX
            CitizenshipShareholderOwner: this.state.CitizenshipID,
            SSNShareholderOwner: this.state.SSN,
            MainContact: false,

            IndividualRolesShareholderOwner: [],
            PercentOwnershipShareholderOwner: '',
            JobTitleShareholderOwner: '',
            JobTitleOtherShareholderOwner: '',
            IdTypeShareholderOwner: '',
            IdNoShareholderOwner: '',
            IdExpirationDateShareholderOwner: '',
            IssueJurisdictionShareholderOwner: '',
            AccountPrimaryContactShareholderOwner: '',
            AFEXDirectUserRoleShareholderOwner: '',
            CountryIDIdentificationShareholderOwner: '',
            StateIDIdentificationShareholderOwner: '',
            StateNameIdentificationShareholderOwner: '',
            ShareHolder_Properties: [{
                Description: 'AFEX Roles',
                Value: 'PRIMARY'
            }],
            ShareHolder_AdditionalProperties: this.state.additionalProperties != null ? this.state.additionalProperties : [],
            ShareHolder_Documents_Task: this.getContactTaskDoc(0)
        }
        if (this.context.isValidated2) {
            if (validateAddProperties(this.props.additionalProperties)) {
                this.setState({ loading: true }, () => {
                    submitOwnerForm(model).then(
                        (jsonResponse) => {
                            switch (jsonResponse.httpStatusCode) {
                                case 200:
                                    if (jsonResponse.Response) {
                                        this.setState({ loading: false, showSuccessAlert: true, alertTitle: 'Success', alertMessage: 'Thanks for submitting your application. We will be in contact with you shortly.' });
                                    } else {
                                        this.setState({ loading: false }, () => {
                                            this.context.showAlert('Error', 'An error ocurred while sending your Corporate Account Registration. Please, try again.')
                                        })
                                    }
                                    break;
                                case 503:
                                    this.setState({ loading: false }, () => {
                                        this.context.showAlert('Error', 'An error ocurred while sending your Corporate Account Registration. Please, try again.')
                                    })
                                    break;
                                default:
                                    this.setState({ loading: false }, () => {
                                        this.context.showAlert('Error', jsonResponse.Message)
                                    })
                                    break;
                            }
                        })
                });
            } else {
                this.context.showAlert('Error', 'Please fill all mandatory fields');
            }
        } else {
            this.validateErrors(event, 2);
        }
    }

    getContactTaskDoc = (i) => {
        // debugger;
        let helper = [];
        let aux1 =
        {
            index: i,
            TaskDescription: front1,
            DocumentType: documentName1,
            DocumentNumber: this.state.numberIdentification,
            DocumentCountryID: this.state.countryIDIdentification,
            DocumentName: this.state.documents != null ? this.state.documents.personalIdentification1FileName !== '' ? this.state.documents.personalIdentification1FileName : null:null,
            DocumentContent: this.state.documents != null ? this.state.documents.personalIdentification1File !== '' ? this.state.documents.personalIdentification1File : null: null,
            TaskDescriptionBack: back1,
            DocumentNameBack: this.state.documents != null ? this.state.documents.personalIdentification2FileName !== '' ? this.state.documents.personalIdentification2FileName: null: null,
            DocumentContentBack: this.state.documents != null ? this.state.documents.personalIdentification2File !== '' ? this.state.documents.personalIdentification2File : null:null,
        }
        let aux2 =
        {
            index: i,
            TaskDescription: front2,
            DocumentType: documentName2,
            DocumentNumber: this.state.DriverLicenseNumber,
            DocumentCountryID: this.state.countryIDIdentification,
            DocumentName: this.state.documents != null ? this.state.documents.drivingLicense1FileName !== '' ? this.state.documents.drivingLicense1FileName : null:null,
            DocumentContent: this.state.documents != null ? this.state.documents.drivingLicense1File !== '' ? this.state.documents.drivingLicense1File : null:null,
            TaskDescriptionBack: back2,
            DocumentNameBack: this.state.documents != null ? this.state.documents.drivingLicense2FileName !== '' ? this.state.documents.drivingLicense2FileName : null:null,
            DocumentContentBack: this.state.documents != null ? this.state.documents.drivingLicense2File !== '' ? this.state.documents.drivingLicense2File : null: null,
        }
        let aux3 =
        {
            index: i,
            TaskDescription: front3,
            DocumentType: documentName3,
            DocumentNumber: this.state.PassportNumber,
            DocumentCountryID: this.state.countryIDIdentification,
            DocumentName: this.state.documents != null ? this.state.documents.passportFileName !== '' ? this.state.documents.passportFileName : null: null,
            DocumentContent: this.state.documents != null ? this.state.documents.passportFile !== '' ? this.state.documents.passportFile : null : null,
            TaskDescriptionBack: '',
            DocumentNameBack: '',
            DocumentContentBack: '',
        }
        helper.push(aux1, aux2, aux3);
        return helper;
    }

    validateErrors = (event, number) => {
        var objErrors = [this.context.errors_Step2.length];
        for (let i = 0; i < number; i++) {
            if (objErrors[i] > 0) {
                this.context.showAlertError((2), true);
                this.context.notFirstLoad((2), false);
                event.preventDefault();
                break;
            }
        }
    }
    closeSuccessAlert = () => {
        this.setState({ showSuccessAlert: false }, () => this.props.navigate('/success-account'));
    }

    handleUpdateCountryIdentification = (value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.setState({
                        countryIDIdentification: value.value, countryNameIdentification: value.name,
                        validations: { ...this.state.validations, countryIDIdentification: true }
                    }, () => {
                        //this.searchState(value.value, true);
                        this.context.validated2(this.validateRequiredInputs());
                        this.props.Owner(this.state);
                    });
                } else {
                    this.setState({
                        countryIDIdentification: '', countryNameIdentification: ''
                    }, () => {
                        this.context.validated2(this.validateRequiredInputs());
                        this.props.Owner(this.state);
                    });
                }
            }
        }
    }

      //RowClickTable
      handleRowClickID1 = () => {
        this.setState({ showAttachmentsDialogID1: true, rowClickID1: true })
    }
    showAttachmentsDialogID1 = () => {
        this.setState({ showAttachmentsDialogID1: false })
    }
    changeStateID1 = () => {
        this.setState({ rowClickID1: false })
    }
    handleRowClickID2 = () => {
        this.setState({ showAttachmentsDialogID2: true, rowClickID2: true })
    }
    showAttachmentsDialogID2 = () => {
        this.setState({ showAttachmentsDialogID2: false })
    }
    changeStateID2 = () => {
        this.setState({ rowClickID2: false })
    }
    handleRowClickDL1 = () => {
        this.setState({ showAttachmentsDialogDL1: true, rowClickDL1: true })
    }
    showAttachmentsDialogDL1 = () => {
        this.setState({ showAttachmentsDialogDL1: false })
    }
    changeStateDL1 = () => {
        this.setState({ rowClickDL1: false })
    }
    handleRowClickDL2 = () => {
        this.setState({ showAttachmentsDialogDL2: true, rowClickDL2: true })
    }
    showAttachmentsDialogDL2 = () => {
        this.setState({ showAttachmentsDialogDL2: false })
    }
    changeStateDL2 = () => {
        this.setState({ rowClickDL2: false })
    }
    handleRowClickPS1 = () => {
        this.setState({ showAttachmentsDialogPS1: true, rowClickPS1: true })
    }
    showAttachmentsDialogPS1 = () => {
        this.setState({ showAttachmentsDialogPS1: false })
    }
    changeStatePS1 = () => {
        this.setState({ rowClickPS1: false })
    }

    handleUpdateDocumentType = (value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.setState({
                        documentTypeID: value.value, documentTypeIDName: value.name, documentTypeNumber: value.name,
                        validations: {
                            ...this.state.validations,
                            documentTypeID: true,
                            countryIDIdentification: true,
                            countryIDDrivingLicense: true,
                            countryIDPassport: true,
                            DriverLicenseNumber: true,
                            PassportNumber: true,
                            numberIdentification: true
                        }
                    }, () => {
                        this.context.validated2(this.validateRequiredInputs());
                        this.props.Owner(this.state);
                    });
                } else {
                    this.setState({
                        documentTypeID: '', documentTypeIDName: '', documentTypeNumber: ''
                    }, () => {
                        this.context.validated2(this.validateRequiredInputs());
                        this.props.Owner(this.state);
                    });
                }
            }
        }
    }
    handleUpdateNumberIdentification = (event) => {
        this.setState({
            numberIdentification: event.target.value,
            validations: { ...this.state.validations, numberIdentification: true }
        }, () => {
            this.context.validated2(this.validateRequiredInputs());
            this.props.Owner(this.state);
        });
    }

    handleChangeID1File = async (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            const newDocuments = { ...this.state.documents, personalIdentification1File: fileContent, personalIdentification1FileName: file.name };
            this.setState({ documents: newDocuments });
            this.context.validated2(this.validateRequiredInputs());
            this.props.Owner(this.state);
        } else {
            const newDocuments = { ...this.state.documents, personalIdentification1File: '', personalIdentification1FileName: '' };
            this.setState({ documents: newDocuments });
            this.context.validated2(this.validateRequiredInputs());
            this.props.Owner(this.state);
        }
    }

    handleChangeID2File = async (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            const newDocuments = { ...this.state.documents, personalIdentification2File: fileContent, personalIdentification2FileName: file.name };
            this.setState({ documents: newDocuments });
            this.context.validated2(this.validateRequiredInputs());
            this.props.Owner(this.state);
        } else {
            const newDocuments = { ...this.state.documents, personalIdentification2File: '', personalIdentification2FileName: '' };
            this.setState({ documents: newDocuments });
            this.context.validated2(this.validateRequiredInputs());
            this.props.Owner(this.state);
        }
    }

    handleChangeDL1File = async (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            const newDocuments = { ...this.state.documents, drivingLicense1File: fileContent, drivingLicense1FileName: file.name };
            this.setState({ documents: newDocuments });
            this.context.validated2(this.validateRequiredInputs());
            this.props.Owner(this.state);
        } else {
            const newDocuments = { ...this.state.documents, drivingLicense1File: '', drivingLicense1FileName: '' };
            this.setState({ documents: newDocuments });
            this.context.validated2(this.validateRequiredInputs());
            this.props.Owner(this.state);
        }
    }

    handleChangeDL2File = async (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            const newDocuments = { ...this.state.documents, drivingLicense2File: fileContent, drivingLicense2FileName: file.name };
            this.setState({ documents: newDocuments });
            this.context.validated2(this.validateRequiredInputs());
            this.props.Owner(this.state);
        } else {
            const newDocuments = { ...this.state.documents, drivingLicense2File: '', drivingLicense2FileName: '' };
            this.setState({ documents: newDocuments });
            this.context.validated2(this.validateRequiredInputs());
            this.props.Owner(this.state);
        }
    }
    handleChangePSSFile = async (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            const newDocuments = { ...this.state.documents, passportFile: fileContent, passportFileName: file.name };
            this.setState({ documents: newDocuments });
            this.context.validated2(this.validateRequiredInputs());
            this.props.Owner(this.state);
        } else {
            const newDocuments = { ...this.state.documents, passportFile: '', passportFileName: '' };
            this.setState({ documents: newDocuments });
            this.context.validated2(this.validateRequiredInputs());
            this.props.Owner(this.state);
        }
    }
    readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                resolve(event.target.result);
            };
            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsDataURL(file);
        });
    }

    deleteAttachmentClick(type, obj) {
        this.setState({ showDeleteAlert: true, readyToDeleteAttachment: obj, type });
    }

    closeDeleteAlert() {
        this.setState({ showDeleteAlert: false, readyToDeleteAttachment: {} });
    }

    openDeleteAlert() {
        this.setState({ showDeleteAlert: true });
    }

    deleteE2EAttachment = (personIdentificationID) => {
        deleteAttachment(personIdentificationID).then((jsonResponse) => { this.setState({ jsonResponse }) });
    }

    yesDeleteAlert() {
        let frontPI = this.state.frontPI;
        let endPI = this.state.endPI;
        let frontDL = this.state.frontDL;
        let endDL = this.state.endDL;
        let frontPss = this.state.frontPss;
        switch (this.state.type) {
            case 'frontPI':
                if (frontPI != null && frontPI !== '') {
                    if (frontPI[0].AttachmentId === this.state.readyToDeleteAttachment.AttachmentId) {
                        this.deleteE2EAttachment(this.state.IdentificationID_frontPI);
                        this.deleteE2EAttachment(this.state.IdentificationID_endPI);
                        this.setState({ frontPI: '', endPI: '', showDeleteAlert: false, }, () => {
                            this.context.validated2(this.validateRequiredInputs());
                            this.props.Owner(this.state);
                        });
                    }
                }
                break;
            case 'endPI':
                if (endPI != null && endPI !== '') {
                    if (endPI[0].AttachmentId === this.state.readyToDeleteAttachment.AttachmentId) {
                        this.deleteE2EAttachment(this.state.IdentificationID_frontPI);
                        this.deleteE2EAttachment(this.state.IdentificationID_endPI);
                        this.setState({ frontPI: '', endPI: '', showDeleteAlert: false, }, () => {
                            this.context.validated2(this.validateRequiredInputs());
                            this.props.Owner(this.state);
                        });
                    }
                }
                break;

            case 'frontDL':
                if (frontDL != null && frontDL !== '') {
                    if (frontDL[0].AttachmentId === this.state.readyToDeleteAttachment.AttachmentId) {
                        this.deleteE2EAttachment(this.state.IdentificationID_frontDL);
                        this.deleteE2EAttachment(this.state.IdentificationID_endDL);
                        this.setState({ frontDL: '', endDL: '', showDeleteAlert: false, }, () => {
                            this.context.validated2(this.validateRequiredInputs());
                            this.props.Owner(this.state);
                        });
                    }
                }
                break;
            case 'endDL':
                if (endDL != null && endDL !== '') {
                    if (endDL[0].AttachmentId === this.state.readyToDeleteAttachment.AttachmentId) {
                        this.deleteE2EAttachment(this.state.IdentificationID_frontDL);
                        this.deleteE2EAttachment(this.state.IdentificationID_endDL);
                        this.setState({ frontDL: '', endDL: '', showDeleteAlert: false, }, () => {
                            this.context.validated2(this.validateRequiredInputs());
                            this.props.Owner(this.state);
                        });
                    }
                }
                break;
            case 'frontPss':
                if (frontPss != null && frontPss !== '') {
                    if (frontPss[0].AttachmentId === this.state.readyToDeleteAttachment.AttachmentId) {
                        this.deleteE2EAttachment(this.state.IdentificationID_frontPss);
                        this.setState({ frontPss: '', showDeleteAlert: false, }, () => {
                            this.context.validated2(this.validateRequiredInputs());
                            this.props.Owner(this.state);
                        });
                    }
                }
                break;
            default:
                break;
        }
    }

    handleUpdateDriverLicenseNumber = (event) => {
        this.setState({
            DriverLicenseNumber: event.target.value,
            //validations: { ...this.state.validations, DriverLicenseNumber: true }
        }, () => {
            this.context.validated2(this.validateRequiredInputs());
            this.props.Owner(this.state);
        });
    }

    handleUpdatePassportNumber = (event) => {
        this.setState({
            PassportNumber: event.target.value,
            //validations: { ...this.state.validations, PassportNumber: true }
        }, () => {
            this.context.validated2(this.validateRequiredInputs());
            this.props.Owner(this.state);
        });
    }
    
    render() {
        let countries = [], citizenship = [];
        countries = this.context != null ? (this.context.countries != null ? this.context.countries : []) : [];
        citizenship = this.context != null ? (this.context.citizenship != null ? this.context.citizenship : []) : [];
        
        let frontPI = '', endPI = '', frontDL = '', endDL = '', frontPss = '';
        
                    frontPI = this.state.frontPI != null ? this.state.frontPI : '';
                    endPI = this.state.endPI != null ? this.state.endPI : '';
                    frontDL = this.state.frontDL != null ? this.state.frontDL : '';
                    endDL = this.state.endDL != null ? this.state.endDL : '';
                    frontPss = this.state.frontPss != null ? this.state.frontPss : '';

        let lookUpList = [];
        if (this.context.lookUpTableList['PersonIdentificationType'].length > 0) {
            this.context.lookUpTableList['PersonIdentificationType'].forEach((value, index) => {
                if (index < 4) {
                    lookUpList.push(value);
                }
            })
        }
        if (lookUpList.length > 0) {
        return (
            <div className="row">
                <div className="col-sm-12 col-xs-12">
                    <div id="traders_wrapper" className="">
                        <div id="trader_01" className="background-style-2 trader-item">
                            <div className="row mrg-t-10p">
                                <div className="col-sm-8 col-xs-12 form-group">
                                    <h5><b>
                                        {this.state.contactType === 'primary' ?
                                            getResource('PrimaryContact_Subtitle') :
                                            (
                                                this.state.contactType === 'director' ?
                                                    getResource('DirectorContact_Subtitle') :
                                                    getResource('UltimateContact_Subtitle')
                                            )
                                        }
                                    </b></h5>
                                    {/*
                                        PrimaryContact_Subtitle
                                        DirectorContact_Subtitle
                                        UltimateContact_Subtitle
                                    */}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                    <div className="row">
                                        <div className={"col-sm-5 col-xs-12" + formatValidInput(this.state.validations.FirstName)}>
                                            <input type="text"
                                                className="form-control"
                                                id="first_name"
                                                name="first_name"
                                                maxLength="70"
                                                data-rule-required="true"
                                                data-msg-required="Field Required."
                                                value={this.state.FirstName}
                                                onChange={this.handleUpdateFirstName} />
                                            <label htmlFor="first_name" className="small">{getResource('Label_FirstName')} *</label>
                                            {(this.state.validations.FirstName != null && !this.state.validations.FirstName) &&
                                                <label id="first_name-error" class="error" htmlFor="first_name">Field Required.</label>
                                            }
                                        </div>
                                        {/* <div className="col-sm-2 col-xs-12 form-group">
                                            <input type="text" className="form-control" id="short_name" name="short_name"
                                                maxLength="50" value={this.state.ShortName}
                                                onChange={this.handleUpdateShortName} />
                                            <label htmlFor="short_name" className="small">{getResource('Label_MiddleName')}</label>
                                        </div> */}
                                        <div className={"col-sm-5 col-xs-12" + formatValidInput(this.state.validations.LastName)}>
                                            <input type="text" className="form-control" id="last_name" name="last_name" maxLength="70"
                                                data-rule-required="true"
                                                data-msg-required="Required"
                                                value={this.state.LastName}
                                                onChange={this.handleUpdateLastName} />
                                            <label htmlFor="last_name" className="small">{getResource('Label_Surname')} *</label>
                                            {(this.state.validations.LastName != null && !this.state.validations.LastName) &&
                                                <label id="last_name-error" class="error" htmlFor="last_name">Field Required.</label>
                                            }
                                        </div>
                                    </div>{/* .row */}
                                    {/* <div className="row">
                                        <div className={"col-sm-3 col-xs-12 form-group  relative col-middle " + formatValidInput(this.state.validations.DateOfBirth)}>
                                            <DatePicker
                                                className="form-control"
                                                readOnly={true}
                                                endDate={today}
                                                value={this.state.DateOfBirth}
                                                onDayChange={this.handleUpdateDateOfBirth} />

                                            <label htmlFor="date_of_birth" className="small">{getResource('Label_DateOfBirth')}*</label>
                                            {(this.state.validations.DateOfBirth != null && !this.state.validations.DateOfBirth) &&
                                                <label id="date_of_birth-error" class="error" htmlFor="date_of_birth">Field Required.</label>
                                            }
                                        </div>
                                        {this.state.contactType === 'primary' &&
                                            <div className={"col-sm-4 col-xs-12" + formatValidInput(this.state.validations.CitizenshipID)}>
                                                <DynamicSelect
                                                    className="form-control use-select2 select2-selection__placeholder" id="Citizenship" name="Citizenship" style={{ width: "100%" }}
                                                    data-select2-type="simple"
                                                    data-select2-paging="false"
                                                    data-select2-placeholder="Select Citizenship"
                                                    data-rule-required="true"
                                                    data-msg-required="Field Required"
                                                    objValue={{ value: this.state.CitizenshipID, name: this.state.CitizenshipName }}
                                                    getValue={this.handleUpdateCitizenship}
                                                    listHasPlaceholder={false}
                                                    placeholder={"Select Citizenship"}
                                                    data={citizenship != null ? citizenship : []} />
                                                <label for="Citizenship" className="small">{getResource('Label_Citizenship')} *</label>
                                                {(this.state.validations.CitizenshipID != null && !this.state.validations.CitizenshipID) &&
                                                    <label id="Citizenship-error" class="error" for="Citizenship">Field Required.</label>
                                                }
                                            </div>
                                        }
                                    </div> */}
                                    <div className="row">
                                        <div className="col-md-12 form-group">
                                            <h5><b>{getResource('Subtitle_ResidentialAddress')}</b></h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className={"col-md-12" + formatValidInput(this.state.validations.ResidentialAddressStreet)}>
                                            <input type="text" className="form-control" id="IDType" name="IDType" maxLength="100"
                                                data-rule-required="true"
                                                data-msg-required="Field Required."
                                                value={this.state.ResidentialAddressStreet}
                                                onChange={this.handleUpdateResidentialAddressStreet} />
                                            <label htmlFor="IDType" className="small">{getResource('Label_StreetAddress')}*</label>
                                            {(this.state.validations.ResidentialAddressStreet != null && !this.state.validations.ResidentialAddressStreet) &&
                                                <label id="IDType-error" class="error" htmlFor="IDType">Field Required.</label>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/**------------------NEW----------------- */}
                                        <div className={"col-sm-6 col-xs-12 form-group" + formatValidInput(this.state.validations.ResidentialCityId)}>
                                            <input type="text" className="form-control" id="residential_address_city" name="residential_address_city" maxLength="100"
                                                value={this.state.ResidentialCityId}
                                                onChange={this.handleUpdateResidentialAddressCity} />
                                            <label htmlFor="residential_address_city" className="small">{getResource('Label_City')}*</label>
                                            {(this.state.validations.ResidentialCityId != null && !this.state.validations.ResidentialCityId) &&
                                                <label id="residential_address_city-error" class="error" htmlFor="residential_address_city">Field Required.</label>
                                            }
                                        </div>
                                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.countryID)}>
                                            <DynamicSelect
                                                className="form-control use-select2 select2-selection__placeholder" id="residential_address_country" name="residential_address_country" style={{ width: "100%" }}
                                                data-select2-type="simple"
                                                data-select2-paging="false"
                                                data-select2-placeholder="Select Country"
                                                data-rule-required="true"
                                                data-msg-required="Field Required"
                                                objValue={{ value: this.state.countryID, name: this.state.countryName }}
                                                getValue={this.handleUpdateCountry.bind(this)}
                                                listHasPlaceholder={false}
                                                placeholder={"Select Country"}
                                                data={countries != null ? countries : []} />
                                            <label htmlFor="residential_address_country" className="small">{getResource('Label_Country')} *</label>
                                            {(this.state.validations.countryID != null && !this.state.validations.countryID) &&
                                                <label id="residential_address_country-error" class="error" htmlFor="residential_address_country">Field Required.</label>
                                            }
                                        </div> {/* .col-md-6 */}
                                    </div> {/* .row */}
                                    <div className="row">
                                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.ResidentialAddressStateId)}>
                                            {
                                                this.state.statesByCountryID.length > 0 ?
                                                    <DynamicSelect
                                                        className="form-control use-select2 select2-selection__placeholder" id="residential_address_state" name="residential_address_state" style={{ width: "100%" }}
                                                        data-select2-type="simple"
                                                        data-select2-paging="false"
                                                        data-select2-placeholder="Select State"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required"
                                                        objValue={{ value: this.state.ResidentialAddressStateId, name: this.state.ResidentialAddressStateName }}
                                                        getValue={this.handleUpdateResidentialAddressState}
                                                        listHasPlaceholder={false}
                                                        placeholder={"Select State"}
                                                        data={this.state.statesByCountryID} />
                                                    :
                                                    <input type="text" className="form-control"
                                                        id="residential_address_postalcode"
                                                        name="residential_address_postalcode" maxLength="50"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required."
                                                        value={this.state.OtherState}
                                                        onChange={this.handleUpdateState} />
                                            }

                                            <label htmlFor="residential_address_state" className="small">{getResource('Label_State')} *</label>
                                            {(this.state.validations.ResidentialAddressStateId != null && !this.state.validations.ResidentialAddressStateId) &&
                                                <label id="residential_address_state_name-error" class="error" htmlFor="residential_address_state_name">Field Required.</label>
                                            }
                                        </div>
                                        <div className={"col-sm-6 col-xs-12 form-group" + formatValidInput(this.state.validations.ResidentialAddressPostalCode)}>
                                            <input type="text" className="form-control" id="residential_address_postalcode" name="residential_address_postalcode" maxLength="50"
                                                data-rule-required="true"
                                                data-msg-required="Field Required."
                                                value={this.state.ResidentialAddressPostalCode}
                                                onChange={this.handleUpdateResidentialAddressPostalCode} />
                                            <label htmlFor="residential_address_postalcode" className="small">{getResource('Label_Postal')}*</label>
                                            {(this.state.validations.ResidentialAddressPostalCode != null && !this.state.validations.ResidentialAddressPostalCode) &&
                                                <label id="residential_address_postalcode-error" class="error" htmlFor="residential_address_postalcode">Field Required.</label>
                                            }
                                        </div>
                                    </div>
                                    {this.state.contactType !== 'director' &&
                                        <div>
                                            <div className="row">
                                                <div className="col-md-12 form-group">
                                                    <h5><b>{getResource('SubTitle_ContactNumbers')}</b></h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                {/* <div className={"col-sm-6 col-xs-12 form-group"}>
                                                    <PhoneInput
                                                        autoFormat
                                                        enableSearch
                                                        placeholder="Enter phone number"
                                                        copyNumbersOnly={false}
                                                        countryCodeEditable={false}
                                                        value={this.state.ResidentialPhoneNumber}
                                                        onChange={this.handleUpdateResidentialPhoneNumber}
                                                    />
                                                    <label htmlFor="residential_phone_number" className="small">{getResource('Label_MainContactNumber')} </label>
                                                </div> */}
                                                <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.EmailAddress)}>
                                                    <input type="email" className="form-control" id="email_address" name="email_address" maxLength="150"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required"
                                                        data-rule-email="true"
                                                        data-msg-email="Email Required"
                                                        value={this.state.EmailAddress}
                                                        onChange={this.handleUpdateEmailAddress} />
                                                    <label htmlFor="email_address" className="small">{getResource('Label_Email')}*</label>
                                                    {(this.state.validations.EmailAddress != null && !this.state.validations.EmailAddress) &&
                                                        <label id="email_address-error" class="error" htmlFor="email_address">Field Required.</label>
                                                    }
                                                </div>
                                                {/* {this.state.contactType === 'primary' &&
                                                    <div className="col-sm-6 col-xs-12 form-group">
                                                        <input type="text" className="form-control" id="SSN" name="SSN" maxLength="100"
                                                            value={this.state.SSN}
                                                            onChange={this.handleUpdateSSN} />
                                                        <label htmlFor="SSN" className="small">{getResource('Label_SSN')}</label>
                                                    </div>} */}
                                            </div>
                                        </div>
                                    }
                                
                        {/* PERSONAL IDENTIFICATION */}
                        <div className="row">
                                    <div className="col-md-12 form-group">
                                        <h5><b>{getResource('SubTitle_PersonalIdentification')}</b></h5>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.countryIDIdentification)}>
                                        <DynamicSelect
                                            className="form-control" id="personal_identification_country_01" name="personal_identification_country_01" style={{ width: "100%" }}
                                            data-select2-type="simple"
                                            data-select2-paging="false"
                                            data-select2-placeholder="Select Country"
                                            data-rule-required="true"
                                            data-msg-required="Field Required"
                                            objValue={{ value: this.state.countryIDIdentification, name: this.state.countryNameIdentification }}
                                            getValue={this.handleUpdateCountryIdentification}
                                            listHasPlaceholder={false}
                                            placeholder={"Select Country"}
                                            data={this.context.countries != null ? this.context.countries : []}
                                        />
                                        <label for="personal_identification_country_01" className='small'><b>{getResource('Label_Country')}*</b></label>
                                        {(this.state.validations.countryIDIdentification != null && !this.state.validations.countryIDIdentification) &&
                                            <label id="personal_identification_country_01-error" class="error" htmlFor="personal_identification_country_01">Field Required.</label>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.documentTypeID)}>
                                        <DynamicSelect
                                            className="form-control use-select2" id="document_type_01" name="document_type_01" style={{ width: "100%" }}
                                            data-select2-type="simple"
                                            data-select2-placeholder="Select ..."
                                            data-rule-required="true"
                                            data-select2-show-wrapper-if="#document_type"
                                            data-select2-show-wrapper-if-values="Other"
                                            data-msg-required="Field Required."
                                            objValue={{ value: this.state.documentTypeID, name: this.state.documentTypeIDName }}
                                            getValue={this.handleUpdateDocumentType}
                                            listHasPlaceholder={false}
                                            data={lookUpList}
                                        />
                                        <label for="document_type_01" className='small'><b>{getResource('Label_DocumentType')}*</b></label>
                                    </div>
                                    {
                                        this.state.documentTypeNumber.includes('Identity') &&

                                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.numberIdentification)}>
                                            <input type="email" className="form-control" id="identification_number_01" name="identification_number_01" maxlength="150"
                                                data-rule-required="true"
                                                data-msg-required="Field Required"
                                                data-rule-email="true"
                                                value={this.state.numberIdentification}
                                                onChange={this.handleUpdateNumberIdentification}
                                            />
                                            <label for="identification_number_01" className='small'><b>{getResource('Label_NumberIdentification')}*</b></label>
                                            {(this.state.validations.numberIdentification != null && !this.state.validations.numberIdentification) &&
                                                <label id="identification_number_01-error" class="error" htmlFor="identification_number_01">Field Required.</label>
                                            }
                                        </div>
                                    }
                                    {this.state.documentTypeNumber.includes('Identity') &&
                                        <div className="col-md-12" style={{ paddingBottom: 10 }}>
                                            {
                                                frontPI === '' &&
                                                <div className="over-hidden" style={{ paddingBottom: 0 }}>
                                                    <input type="file"
                                                        accept="image/png, image/jpg, image/jpeg, image/gif, .pdf, .doc, .docx"
                                                        onChange={this.handleChangeID1File}
                                                        name="personal_identification_01" id="personal_identification_01"
                                                        data-rule-required="true"
                                                        data-msg-required="Attachment required"
                                                        data-accept-size="512000"
                                                        data-accept-size-message="Maximum 500 kb"
                                                        data-accept-file-types="jpg|jpeg|png|gif|xls|xlsx|pps|ppt|pptx|doc|docx|pdf"
                                                        data-wrong-file-types-message="Only Images, PDF or Office Document is allowed" />
                                                </div>
                                            }
                                            {
                                                frontPI !== '' &&
                                                <NewDynamicTable key="personal_identification_01"
                                                    hiddenHeaders={['description', 'AttachmentId']}
                                                    data={frontPI}
                                                    enableClick={true}
                                                    clickFunction={this.handleRowClickID1}
                                                    newSearch={false}
                                                    useDeleteButton={true}
                                                    deleteFunction={this.deleteAttachmentClick.bind(this, 'frontPI')}
                                                    enableSortByHeader={false}
                                                    usePaginator={false}
                                                    useFilter={false}
                                                />
                                            }
                                            {
                                                frontPI !== '' &&
                                                <AttachmentsDialog
                                                    open={this.state.showAttachmentsDialogID1}
                                                    onClose={this.showAttachmentsDialogID2}
                                                    attachmentId={frontPI[0].AttachmentId}
                                                    file={frontPI[0]}
                                                    rowClick={this.state.rowClickID1}
                                                    changeState={this.changeStateID1}
                                                />
                                            }
                                            <label for="personal_identification" className='small'>{getResource('Label_PersonalIdentification1')}*</label>
                                        </div>
                                    }
                                    {this.state.documentTypeNumber.includes('Identity') &&
                                        <div className="col-md-12">
                                            {
                                                endPI === '' &&
                                                <div className="over-hidden">
                                                    <input type="file"
                                                        accept="image/png, image/jpg, image/jpeg, image/gif, .pdf, .doc, .docx"
                                                        name="personal_identification_government" id="personal_identification_government"
                                                        onChange={this.handleChangeID2File}
                                                        data-rule-required="true"
                                                        data-msg-required="Attachment required"
                                                        data-accept-size="512000"
                                                        data-accept-size-message="Maximum 500 kb"
                                                        data-accept-file-types="jpg|jpeg|png|gif|xls|xlsx|pps|ppt|pptx|doc|docx|pdf"
                                                        data-wrong-file-types-message="Only Images, PDF or Office Document is allowed" />
                                                </div>
                                            }
                                            {
                                                endPI !== '' &&
                                                <NewDynamicTable key="personal_identification2"
                                                    hiddenHeaders={['description', 'AttachmentId']}
                                                    data={endPI}
                                                    enableClick={true}
                                                    clickFunction={this.handleRowClickID2}
                                                    newSearch={false}
                                                    useDeleteButton={true}
                                                    deleteFunction={this.deleteAttachmentClick.bind(this, 'endPI')}
                                                    enableSortByHeader={false}
                                                    usePaginator={false}
                                                    useFilter={false}
                                                />
                                            }
                                            {
                                                endPI !== '' &&
                                                <AttachmentsDialog
                                                    open={this.state.showAttachmentsDialogID2}
                                                    onClose={this.showAttachmentsDialogID2}
                                                    attachmentId={endPI[0].AttachmentId}
                                                    file={endPI[0]}
                                                    rowClick={this.state.rowClickID2}
                                                    changeState={this.changeStateID2}
                                                />
                                            }
                                            <label for="personal_identification_government" className='small'>{getResource('Label_PersonalIdentification2')}*</label>
                                        </div>

                                    }
                                    {this.state.documentTypeNumber.includes('Driving') &&
                                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.DriverLicenseNumber)}>
                                            <input type="text" className="form-control" id="australian_driver_license_number" name="australian_driver_license_number" maxlength="30"
                                                data-rule-required="true"
                                                data-msg-required="Field Required"
                                                value={this.state.DriverLicenseNumber}
                                                onChange={this.handleUpdateDriverLicenseNumber} />
                                            <label for="australian_driver_license_number" className="small"><b>{getResource('Label_LicenseNumber')} *</b></label>
                                            {(this.state.validations.DriverLicenseNumber != null && !this.state.validations.DriverLicenseNumber) &&
                                                <label id="australian_driver_license_number-error" class="error" for="australian_driver_license_number">Field Required.</label>
                                            }
                                        </div>
                                    }
                                    {this.state.documentTypeNumber.includes('Driving') &&
                                        <div className="col-md-12">
                                            {
                                                frontDL === '' &&
                                                <div className="over-hidden pd-10p">
                                                    <input type="file"
                                                        accept="image/png, image/jpg, image/jpeg, image/gif, .pdf, .doc, .docx"
                                                        onChange={this.handleChangeDL1File}
                                                        name="driving_License1" id="driving_License1"
                                                        data-rule-required="true"
                                                        data-msg-required="Attachment required"
                                                        data-accept-size="512000"
                                                        data-accept-size-message="Maximum 500 kb"
                                                        data-accept-file-types="jpg|jpeg|png|gif|xls|xlsx|pps|ppt|pptx|doc|docx|pdf"
                                                        data-wrong-file-types-message="Only Images, PDF or Office Document is allowed" />
                                                </div>
                                            }
                                            {
                                                frontDL !== '' &&
                                                <NewDynamicTable key="driving_License1"
                                                    hiddenHeaders={['description', 'AttachmentId']}
                                                    data={frontDL}
                                                    enableClick={true}
                                                    clickFunction={this.handleRowClickDL1}
                                                    newSearch={false}
                                                    useDeleteButton={true}
                                                    deleteFunction={this.deleteAttachmentClick.bind(this,'frontDL')}
                                                    enableSortByHeader={false}
                                                    usePaginator={false}
                                                    useFilter={false}
                                                />
                                            }
                                            {
                                                frontDL !== '' &&
                                                <AttachmentsDialog
                                                    open={this.state.showAttachmentsDialogDL1}
                                                    onClose={this.showAttachmentsDialogDL1}
                                                    attachmentId={frontDL[0].AttachmentId}
                                                    file={frontDL[0]}
                                                    rowClick={this.state.rowClickDL1}
                                                    changeState={this.changeStateDL1}
                                                />
                                            }
                                            <label for="driving_License1" className="small"><b>{getResource('Label_PersonalIdentification1')}*</b></label>
                                        </div>
                                    }
                                    {this.state.documentTypeNumber.includes('Driving') &&
                                        <div className="col-md-12">
                                            {
                                                endDL === '' &&
                                                <div className="over-hidden pd-10p">
                                                    <input type="file"
                                                        accept="image/png, image/jpg, image/jpeg, image/gif, .pdf, .doc, .docx"
                                                        name="driving_License2" id="driving_License2"
                                                        onChange={this.handleChangeDL2File}
                                                        data-rule-required="true"
                                                        data-msg-required="Attachment required"
                                                        data-accept-size="512000"
                                                        data-accept-size-message="Maximum 500 kb"
                                                        data-accept-file-types="jpg|jpeg|png|gif|xls|xlsx|pps|ppt|pptx|doc|docx|pdf"
                                                        data-wrong-file-types-message="Only Images, PDF or Office Document is allowed" />
                                                </div>
                                            }
                                            {
                                                endDL !== '' &&
                                                <NewDynamicTable key="driving_License2"
                                                    hiddenHeaders={['description', 'AttachmentId']}
                                                    data={endDL}
                                                    enableClick={true}
                                                    clickFunction={this.handleRowClickDL2}
                                                    newSearch={false}
                                                    useDeleteButton={true}
                                                    deleteFunction={this.deleteAttachmentClick.bind(this, 'endDL')}
                                                    enableSortByHeader={false}
                                                    usePaginator={false}
                                                    useFilter={false}
                                                />
                                            }
                                            {
                                                endDL !== '' &&
                                                <AttachmentsDialog
                                                    open={this.state.showAttachmentsDialogDL2}
                                                    onClose={this.showAttachmentsDialogDL2}
                                                    attachmentId={endDL[0].AttachmentId}
                                                    file={endDL[0]}
                                                    rowClick={this.state.rowClickDL2}
                                                    changeState={this.changeStateDL2}
                                                />
                                            }
                                            <label for="driving_License2" className="small"><b>{getResource('Label_PersonalIdentification2')}*</b></label>
                                        </div>
                                    }
                                    {this.state.documentTypeNumber.includes('Passport') &&
                                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.PassportNumber)}>
                                            <input type="text" className="form-control" id="australian_passport_number" name="australian_passport_number" maxlength="30"
                                                data-rule-required="true"
                                                data-msg-required="Field Required"
                                                value={this.state.PassportNumber}
                                                onChange={this.handleUpdatePassportNumber} />
                                            <label for="australian_passport_number" className="small"><b>{getResource('Label_PassportNumber')}*</b></label>
                                            {(this.state.validations.PassportNumber != null && !this.state.validations.PassportNumber) &&
                                                <label id="australian_passport_number-error" class="error" for="australian_passport_number">Field Required.</label>
                                            }
                                        </div>
                                    }
                                    {this.state.documentTypeNumber.includes('Passport') &&
                                        <div className="col-md-12">
                                            {
                                                frontPss === '' &&
                                                <div className="over-hidden pd-10p">
                                                    <input type="file"
                                                        accept="image/png, image/jpg, image/jpeg, image/gif, .pdf, .doc, .docx"
                                                        name="passport" id="passport"
                                                        onChange={this.handleChangePSSFile}
                                                        data-rule-required="true"
                                                        data-msg-required="Attachment required"
                                                        data-accept-size="512000"
                                                        data-accept-size-message="Maximum 500 kb"
                                                        data-accept-file-types="jpg|jpeg|png|gif|xls|xlsx|pps|ppt|pptx|doc|docx|pdf"
                                                        data-wrong-file-types-message="Only Images, PDF or Office Document is allowed" />
                                                </div>
                                            }
                                            {
                                                frontPss !== '' &&
                                                <NewDynamicTable key="personal_identification2"
                                                    hiddenHeaders={['description', 'AttachmentId']}
                                                    data={frontPss}
                                                    enableClick={true}
                                                    clickFunction={this.handleRowClickPS1}
                                                    newSearch={false}
                                                    useDeleteButton={true}
                                                    deleteFunction={this.deleteAttachmentClick.bind(this, 'frontPss')}
                                                    enableSortByHeader={false}
                                                    usePaginator={false}
                                                    useFilter={false}
                                                />
                                            }
                                            {
                                                frontPss !== '' &&
                                                <AttachmentsDialog
                                                    open={this.state.showAttachmentsDialogPS1}
                                                    onClose={this.showAttachmentsDialogPS1}
                                                    attachmentId={frontPss[0].AttachmentId}
                                                    file={frontPss[0]}
                                                    rowClick={this.state.rowClickPS1}
                                                    changeState={this.changeStatePS1}
                                                />
                                            }
                                            <label for="passport" className="small"><b>{getResource('Label_PersonalIdentification1')}*</b></label>
                                        </div>
                                    }
                                </div> {/* END PERSON IDENTIFICATION */}
                                    {this.props.children}
                                </div>

                            </div>

                            <div className="mrg-t-10p">
                                <div className="row">
                                    <div className="col-sm-2 col-xs-12 ">
                                        <button id="add-trader-5" className="btn btn-primary"
                                            rel="#trader_01" type="button"
                                            onClick={this.SaveOwner}>{getResource('Final_Submit')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Are you sure you want to delete this file?" message="If you delete this file, you will need to upload all the corresponding files. This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert.bind(this)} noClick={this.closeDeleteAlert.bind(this)} />
            
                <AlertBox
                    open={this.state.showSuccessAlert}
                    onClose={this.closeSuccessAlert}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    type="Ok"
                    okClick={this.closeSuccessAlert}
                />
            </div>
        );
    } else {
        return (
            <div>
                <LoadingBox loading={true}></LoadingBox>
            </div>
        );
    }
    }
}