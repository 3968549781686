import jwt from 'jsonwebtoken'
import { genericCallWithBody } from './Networking'

const apiURL = window.REACT_APP_API_URL
const apiKey = window.REACT_APP_API_KEY
const sessionID = window.REACT_APP_API_SESSION_ID

//Registration/PreRegistration
export async function submitPreReg(objPreReg) {
  let response = await genericCallWithBody('POST', 'Registration/PreRegistration', objPreReg)
  return response
}
export async function searchCountries(country) {
  try {
    let params = {
      country: country
    }

    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&')

    let response = await fetch(apiURL + 'Country/GetCountryName?' + query, {
      method: 'GET',
      headers: {
        SessionID: sessionID,
        APIKey: apiKey
      }
    })
    var helper = []
    let responseJWT = await response.json()
    var decoded = jwt.decode(responseJWT.data)
    console.group('SearchCountriesAPI')
    console.table(decoded)
    console.groupEnd()
    for (var i = 0; i < decoded.response.totalCount; i++) {
      helper.push({ CountryId: decoded.response.countries[i].CountryId, CountryIdName: decoded.response.countries[i].CountryIdName })
    }
    if (decoded.response.totalCount > 0) {
      return decoded.response.countries
    } else {
      return []
    }
  } catch (error) {
    console.error(error)
  }
}

export async function getIP() {
  const response = await fetch('https://api.ipify.org?format=json')
  const data = await response.json()

  return data.ip
}
export async function getCountryByIP(ip) {
  const response = await fetch('https://uatapi.easypayforex.com.au:8013/api/Registration/ValidateIP',{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'APIKey': apiKey,
        },
        body: JSON.stringify({"IP": ip}),
    
  })
  const data = await response.json()
  return data
}
