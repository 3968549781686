import React from 'react';
import AppContext from '../../AppContext';
import { formatDate, formatValidInput } from '../../helper/FormatHelper';
import { getResource } from '../../helper/ResourcesHelper';
import { validate } from '../../helper/ValidateHelper';
import DatePicker from '../shared/DatePicker';
import NumberInput from '../shared/NumberInput';


/* 
const REGEX= {
    number : '/^[0-9]+$/'
} */
export default class ContactAdditionalInformationInvitation extends React.Component {
    static contextType = AppContext;
    state = {
        values: [],
        validations: []
    };

    componentDidUpdate(prevProps) {
        if (this.props.totalList !== prevProps.totalList && Array.isArray(this.props.totalList)) {
            this.loadPropertyList();
        }

        /* if (this.props.values !== prevProps.values) {
            this.setState({
                values: this.props.values,
                validations: this.props.validations
            });
        } */

        if (prevProps.notFirstLoad !== this.props.notFirstLoad && !this.props.notFirstLoad) {
            this.validateRequiredFields();
        }
    }

    componentDidMount() {
        this.loadPropertyList();
    }
    loadPropertyList = () => {
        if (Array.isArray(this.props.totalList)) {
            //Value for Customer
            let customerAdditionalProperties = this.props.customerAdditionalProperties != null ? [...this.props.customerAdditionalProperties] : [];

            var listValues = [];
            if (this.props.values != null) {
                listValues = [...this.props.values];
                //listValues = [...this.props.values]
            }

            var listValidations = [];
            if (this.props.validations != null) {
                listValidations = this.props.validations;
                //listValidations = [...this.props.validations]
            }
            var totalListAux = [...this.props.totalList];
            totalListAux.forEach(addditionalPropertiesDefs => {
                addditionalPropertiesDefs.name = addditionalPropertiesDefs.label;
                addditionalPropertiesDefs.value = '';

                //Value for Customer
                if (customerAdditionalProperties != null) {
                    customerAdditionalProperties.forEach(element => {
                        if (element.Description === addditionalPropertiesDefs.description) {
                            if (element.DataType !== 'Numeric') {
                                addditionalPropertiesDefs.value = element.value;
                            } else {
                                addditionalPropertiesDefs.value = parseInt(element.value);
                            }
                        }
                    })
                }

                if (Array.isArray(listValues)) {
                    listValues.push(addditionalPropertiesDefs);
                }

                if (addditionalPropertiesDefs.mandatory === true) {
                    let modelV = {
                        name: addditionalPropertiesDefs.label,
                        value: true
                    }
                    if (Array.isArray(listValidations))
                        listValidations.push(modelV)
                }
            });

            this.setState({
                values: listValues,
                validations: listValidations
            }, () => {
                this.switchSteps(this.props.principal);
            });
        }
    }
    switchSteps = (step) => {
        this.context.validated5(this.validateRequiredInputs(step))
    }
    handleUpdateValueTypeText = (contact, event) => {
        var aux = [...this.props.values];
        var auxVal = [...this.props.validations];

        if (Array.isArray(aux)) {
            aux.forEach(element => {
                if (element.name === event.target.id) {
                    element.value = event.target.value.toLowerCase().includes('select') ? '' : event.target.value;
                }
            });
        }

        if (Array.isArray(auxVal)) {
            auxVal.forEach(currentVal => {
                if (currentVal.name === event.target.id) {
                    currentVal.value = true;
                }
            });
        }

        this.setState({
            values: aux,
            validations: auxVal
        }, () => {
            this.props.onUpdateValues(aux, auxVal, contact);
            this.switchSteps(contact);
        });
    }

    handleUpdateValueTypeNumber = e => {
        let value = e.target.value;

        /*if (!Number(value)) {
            return;
        }*/

        var aux = [...this.props.values];
        var auxVal = [...this.props.validations];

        aux.forEach(element => {
            if (element.name === e.target.id) {
                element.value = value;
            }
        });

        auxVal.forEach(currentVal => {
            if (currentVal.name === e.target.id) {
                currentVal.value = true;
            }
        });

        this.setState({
            values: aux,
            validations: auxVal
        }, () => {
            this.props.onUpdateValues(aux, auxVal, this.props.principal);
            this.switchSteps(this.props.principal);
        });
    }

    handleUpdateValueTypeDate = (date, dayModifiers, dayPickerInput) => {

        console.log("handleUpdateValueTypeDate: " + JSON.stringify(date));
        console.log("handleUpdateValueTypeDate: " + JSON.stringify(dayPickerInput.props.inputProps.id));

        var aux = [...this.props.values];
        var auxVal = [...this.props.validations];

        aux.forEach(element => {
            if (element.name === dayPickerInput.props.inputProps.id) {
                element.value = formatDate(date);
            }
        });

        auxVal.forEach(currentVal => {
            if (currentVal.name === dayPickerInput.props.inputProps.id) {
                currentVal.value = true;
            }
        });

        this.setState({
            values: aux,
            validations: auxVal
        }, () => {
            this.props.onUpdateValues(aux, auxVal, this.props.principal);
            this.switchSteps(this.props.principal);
        });
    }

    validateRequiredInputs(step) {
        var listValues = this.state.values;
        //var listValidations = this.state.validations;

        const fields = [];
        const others = [];

        listValues.forEach(element => {
            if (element.mandatory === true) {
                var type = '';

                switch (element.dataType) {
                    case 'Text':
                        type = 'string';
                        break;
                    case 'Numeric':
                        {
                            type = 'wholeNumber';
                            break;
                        }
                    default:
                        type = 'string';
                        break;
                }

                var obj = {
                    displayName: element.label,
                    validateName: element.label,
                    value: element.value,
                    type: type
                }

                fields.push(obj);

                if (element.rangeValidate === true) {
                    //primero buscar el valor del elemento en el state
                    let objToValidate = null;
                    this.state.values.forEach(elementState => {
                        if (elementState.label === element.label) {
                            objToValidate = elementState;
                        }
                    });

                    //segun el tipo va a realizar las validaciones de rango
                    switch (objToValidate.dataType) {
                        case 'Text':
                            {
                                if (objToValidate.value !== '') {
                                    others.push({
                                        displayName: 'The value of ' + objToValidate.value + ' must not be more than ' + objToValidate.maxNumber + ' characters.',
                                        validation: this.textLengthVerification(objToValidate.minNumber, objToValidate.maxNumber, objToValidate.value)        //No se puede mandar un method con parametros
                                    });
                                }
                                break;
                            }
                        case 'Numeric':
                            {
                                if (objToValidate.value !== 0 && objToValidate.value !== '') {
                                    others.push({
                                        displayName: 'The ' + objToValidate.label + ' value must be between ' + objToValidate.minNumber + ' and ' + objToValidate.maxNumber + '.',
                                        validation: this.numericVerification(objToValidate.minNumber, objToValidate.maxNumber, objToValidate.value)           //asi que lo guardo en otra lista para luego juntarlo
                                    });
                                }
                                break;
                            }
                        case 'DateTime':
                            {
                                if (objToValidate.value !== null && objToValidate.value !== '') {
                                    others.push({
                                        displayName: 'The ' + objToValidate.label + ' value must be between ' + formatDate(objToValidate.minDate) + ' and ' + formatDate(objToValidate.maxDate) + '.',
                                        validation: this.dateTimeRangeVerification(objToValidate.minDate, objToValidate.maxDate, objToValidate.value)           //asi para todos
                                    });
                                }
                                break;
                            }
                        default: break;
                    }
                }
            }
        });
        const [valid, , errors] = validate(fields);
        let listErrors = [];
        others.forEach(error => {                           //Acá junto con los validations por rango
            if (error.validation !== true) {
                errors.push(error.displayName)
            }
        });
        listErrors = [{ tab: "Contact " + step, errors: errors }]
        return { valid, listErrors, type: 'Additional' };
    }

    textLengthVerification(min, max, value) {
        const newMin = parseInt(min);
        const newMax = parseInt(max);
        const lengthString = value.length;
        return (newMin <= lengthString && lengthString <= newMax);
    }

    numericVerification(min, max, value) {
        return (min <= value && value <= max);
    }

    dateTimeRangeVerification(minDate, maxDate, valueDate) {
        //const currentDate = formatStringToDate(valueDate);
        const miniDate = new Date(minDate);
        const maxiDate = new Date(maxDate);
        const currDate = new Date(valueDate);

        return (miniDate <= currDate && currDate <= maxiDate);
    }

    validateRequiredFields() {
        let listValues = this.state.values;
        let fields = [];
        let others = [];

        listValues.forEach(element => {
            if (element.mandatory === true) {
                var type = '';

                switch (element.dataType) {
                    case 'Text':
                        type = 'string';
                        break;
                    case 'Numeric':
                        {
                            type = 'wholeNumber';
                            break;
                        }
                    default:
                        type = 'string';
                        break;
                }

                var obj = {
                    displayName: element.label,
                    validateName: element.label,
                    value: element.value,
                    type: type
                }

                fields.push(obj);

                if (element.rangeValidate === true) {
                    //primero buscar el valor del elemento en el state
                    let objToValidate = null;
                    this.state.values.forEach(elementState => {
                        if (elementState.label === element.label) {
                            objToValidate = elementState;
                        }
                    });

                    //segun el tipo va a realizar las validaciones de rango
                    switch (objToValidate.dataType) {
                        case 'Text':
                            {
                                if (objToValidate.value !== '') {
                                    others.push({
                                        displayName: 'The value of ' + objToValidate.value + ' must not be more than ' + objToValidate.maxNumber + ' characters.',
                                        validation: this.textLengthVerification(objToValidate.minNumber, objToValidate.maxNumber, objToValidate.value),        //No se puede mandar un method con parametros
                                        name: objToValidate.label
                                    });
                                }
                                break;
                            }
                        case 'Numeric':
                            {
                                if (objToValidate.value !== 0 && objToValidate.value !== '') {
                                    others.push({
                                        displayName: 'The ' + objToValidate.label + ' value must be between ' + objToValidate.minNumber + ' and ' + objToValidate.maxNumber + '.',
                                        validation: this.numericVerification(objToValidate.minNumber, objToValidate.maxNumber, objToValidate.value),           //asi que lo guardo en otra lista para luego juntarlo
                                        name: objToValidate.label
                                    });
                                }
                                break;
                            }
                        case 'DateTime':
                            {
                                if (objToValidate.value !== null && objToValidate.value !== '') {
                                    others.push({
                                        displayName: 'The ' + objToValidate.label + ' value must be between ' + formatDate(objToValidate.minDate) + ' and ' + formatDate(objToValidate.maxDate) + '.',
                                        validation: this.dateTimeRangeVerification(objToValidate.minDate, objToValidate.maxDate, objToValidate.value),
                                        name: objToValidate.label           //asi para todos
                                    });
                                }
                                break;
                            }
                        default: break;
                    }
                }
            }
        });

        const [valid, validations] = validate(fields);

        var valis = this.state.validations;
        valis.forEach(element => {
            if (validations[element.name] != null) {
                element.value = validations[element.name];
            }
        });

        if (others.length !== 0) {
            valis.forEach(vali => {             //{name: "Label Test 3", value: true}
                others.forEach(error => {
                    if (vali.name === error.name && error.validation === false) {
                        vali.value = false
                    }

                });
            });
        }

        this.setState({ validations: valis });
        return valid;
    }

    render() {
        var rows = [];

        if (Array.isArray(this.props.totalList)) {
            var totalListAux = [...this.props.totalList];
            totalListAux.forEach(addditionalPropertiesDefs => {

                var vals = [];
                if (this.state.values != null) {
                    vals = this.state.values;
                }

                var Vals = [];
                if (this.state.validations != null) {
                    Vals = this.state.validations;
                }

                let actualValue = '';
                let actualVali = true;

                if (Array.isArray(vals)) {
                    vals.forEach(element => {
                        if (element.name === addditionalPropertiesDefs.label) {
                            actualValue = element.value;
                        }
                    });
                }

                if (Array.isArray(Vals)) {
                    Vals.forEach(currentVal => {
                        if (currentVal.name === addditionalPropertiesDefs.label) {
                            actualVali = currentVal.value;
                        }
                    });
                }

                switch (addditionalPropertiesDefs.dataType) {
                    default:
                    case 'Text':
                        {
                            if (addditionalPropertiesDefs.lookUpTableID) {
                                if (addditionalPropertiesDefs.lookUpTableDetails.length > 0) {
                                    const details = [...addditionalPropertiesDefs.lookUpTableDetails].sort((a, b) => a.DisplayOrder - b.DisplayOrder);
                                    rows.push(
                                        <div className={"col-sm-6 col-xs-12" + formatValidInput(actualVali)}>
                                            <select
                                                key={'contact_' + this.props.principal}
                                                className="form-control"
                                                id={addditionalPropertiesDefs.label}
                                                name={addditionalPropertiesDefs.label}
                                                maxLength="100"
                                                value={actualValue}
                                                onChange={this.handleUpdateValueTypeText.bind(this,this.props.principal)}
                                            >
                                                {details.map((d, index) => (
                                                    <option key={this.props.principal + '_' + index} id={'option_' + d.Description + this.props.principal} value={d.Description}>{d.Description}</option>
                                                ))}
                                            </select>

                                            <label
                                                className="small">{addditionalPropertiesDefs.input} {addditionalPropertiesDefs.mandatory && <abbr>*</abbr>}
                                            </label>

                                            {!actualVali &&
                                                <label
                                                    id={addditionalPropertiesDefs.label + "-error"}
                                                    class="error"
                                                >Field Required.
                                                </label>
                                            }
                                        </div>
                                    );
                                }
                            } else {
                                rows.push(
                                    <div className={"col-sm-6 col-xs-12" + formatValidInput(actualVali)}>
                                        <input
                                            key={'contact_' + this.props.principal}
                                            type="text"
                                            className="form-control"
                                            id={addditionalPropertiesDefs.label}
                                            name={addditionalPropertiesDefs.label}
                                            maxLength="100"
                                            value={actualValue}
                                            onChange={this.handleUpdateValueTypeText.bind(this,this.props.principal)}
                                        />

                                        <label
                                            className="small">{addditionalPropertiesDefs.input} {addditionalPropertiesDefs.mandatory && <abbr>*</abbr>}
                                        </label>

                                        {!actualVali &&
                                            <label
                                                id={addditionalPropertiesDefs.label + "-error"}
                                                class="error"
                                            >Field Required.
                                            </label>
                                        }
                                    </div>
                                );
                            }
                            break;
                        }
                    case 'Numeric':
                        {
                            rows.push(
                                <div className={"col-sm-6 col-xs-12" + formatValidInput(actualVali)}>
                                    <NumberInput
                                        key={'contacts_' + this.props.principal}
                                        id={addditionalPropertiesDefs.label}
                                        type="Integer"
                                        className="form-control"
                                        value={actualValue}
                                        onChange={this.handleUpdateValueTypeNumber}
                                    />
                                    <label
                                        className="small">{addditionalPropertiesDefs.input} {addditionalPropertiesDefs.mandatory && <abbr>*</abbr>}
                                    </label>

                                    {!actualVali &&
                                        <label
                                            id={addditionalPropertiesDefs.label + "-error"}
                                            class="error"
                                        >Field Required.
                                        </label>
                                    }
                                </div>
                            );
                            break;
                        }
                    case 'DateTime':
                        {
                            rows.push(
                                <div className={"col-sm-6 col-xs-12" + formatValidInput(actualVali)}>
                                    <DatePicker
                                        key={'contact_' + this.props.principal}
                                        id={addditionalPropertiesDefs.label}
                                        className="form-control"
                                        readOnly={true}
                                        value={actualValue}
                                        onDayChange={this.handleUpdateValueTypeDate}
                                    />

                                    <label
                                        className="small">{addditionalPropertiesDefs.input} {addditionalPropertiesDefs.mandatory && <abbr>*</abbr>}
                                    </label>

                                    {!actualVali &&
                                        <label
                                            id={addditionalPropertiesDefs.label + "-error"}
                                            class="error"
                                        >Field Required.
                                        </label>
                                    }
                                </div>
                            );
                            break;
                        }
                }
            });
        }

        if (rows.length === 0) {
            return (<div></div>);
        } else {
            return (
                <div className="row">
                    <div className="col-sm-12 col-xs-12">
                        <div className="row">
                            <div className="col-sm-6 col-xs-12 form-group">
                                <h5><b>{getResource('SubTitle_AdditionalInformation')}</b></h5>
                            </div>
                        </div>

                        <div className="row">
                            {rows}
                        </div>
                    </div>
                </div>
            );
        }
    }
}