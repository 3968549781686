import React from 'react';

import '../../css/react-day-picker.css';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';

import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import { YearMonthForm, currentYear, fromMonth, toMonth } from './Calendar';

const FORMAT = 'yyyy/MM/dd';

export default class DatePicker extends React.Component {
    state = {
        startDate: new Date(),
        endDate: new Date(),
        disabledDays: [],
        month: new Date(currentYear, new Date().getMonth())
    }

    componentDidMount() {
        var startDate = new Date(1901, 0, 1);
        var endDate = new Date(2050, 11, 31);

        if (this.props.startDate != null) {
            startDate = this.props.startDate;
        }

        if (this.props.endDate != null) {
            endDate = this.props.endDate;
        }

        this.setState({
            startDate: startDate,
            endDate: endDate,
        });

        /*if(this.props.after != null && this.props.before != null){
            var dateAfter = this.parseDate(this.props.before, FORMAT, null);
            var dateBefore = this.parseDate(this.props.after, FORMAT, null);
            this.setState({after: dateAfter, before: dateBefore});
        } else {
            this.setState({after: new Date(2050,11,31) , before: new Date(1901,0,1)});
        }*/

        if (this.props.disabledDays != null) {
            this.setState({ disabledDays: this.props.disabledDays });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.startDate != null && this.props.startDate != null) {
            if (prevProps.startDate !== this.props.startDate) {
                this.setState({ startDate: this.props.startDate });
            }
        }

        if (prevProps.endDate != null && this.props.endDate != null) {
            if (prevProps.endDate !== this.props.endDate) {
                this.setState({ endDate: this.props.endDate });
            }
        }
    }

    parseDate(str, format, locale) {
        const parsed = dateFnsParse(str, format, new Date(), { locale });
        if (DateUtils.isDate(parsed)) {
            return parsed;
        }
        return undefined;
    }

    formatDate(date, format, locale) {
        return dateFnsFormat(date, format, { locale });
    }

    handleYearMonthChange = (month) => {
        this.setState({ month });
    }

    render() {
        var disabled = false;
        if (this.props.disabled != null) {
            disabled = this.props.disabled;
        }

        var readOnly = false;
        if (this.props.readOnly != null) {
            readOnly = this.props.readOnly;
        }

        return (
            <div>
                <DayPickerInput
                    inputProps={{ id: this.props.id, className: this.props.className, readOnly: readOnly, disabled: disabled }}
                    dayPickerProps={
                        {
                            disabledDays: [
                                { daysOfWeek: this.state.disabledDays },
                                { after: this.state.endDate, before: this.state.startDate },
                                this.props.isHoliday
                            ],
                            ...!this.props.disableDropdown && {
                                fromMonth: fromMonth,
                                toMonth: toMonth,
                                month: this.state.month,
                                captionElement: ({ date, localeUtils }) => (
                                    <YearMonthForm
                                        date={date}
                                        localeUtils={localeUtils}
                                        onChange={this.handleYearMonthChange}
                                    />
                                )
                            }
                        }
                    }
                    value={this.props.value} onDayChange={this.props.onDayChange} formatDate={this.formatDate.bind(this)} format={FORMAT} parseDate={this.parseDate.bind(this)} placeholder={"Select a date..."}></DayPickerInput>
            </div>
        );
    }
}