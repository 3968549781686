import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
/*r2d2 ------------------------------------------------------------------------------ */
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ErrorOutlineTwoTone from '@material-ui/icons/ErrorOutlineTwoTone';
/*r2d2 ------------------------------------------------------------------------------ */

//<AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Are you sure you want to delete this bank?" message="This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert.bind(this)} noClick={this.closeDeleteAlert.bind(this)}/>
//<AlertBox open={this.state.showSuccessDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Bank successfully removed." message="" type="Ok" okClick={this.okSuccessAlert.bind(this)}/>
export default class AlertBox extends React.Component {

    render() {
        let buttons = [];
        let title = [];
        let message = [];
        let listItems = []

        if (this.props.messageBoldList != null) 
        {
            if(this.props.messageBoldList[0] != null)
            {
                if(typeof this.props.messageBoldList[0] === 'object')
                {
                    this.props.messageBoldList.forEach(element => {
                        listItems.push(element.value)
                    });
                }else
                {
                    listItems = this.props.messageBoldList
                }
            }
            
        }

        switch (this.props.type) {
            case 'Yes/No': {
                buttons.push(
                    <button className="btn btn-primary" key={"Yes-" + buttons.length} type="button" onClick={this.props.yesClick}>Yes</button>
                );
                buttons.push(
                    <button className="btn btn-primary" key={"No-" + buttons.length} type="button" onClick={this.props.noClick}>No</button>
                );
                break;
            }
            case 'Ok': {
                buttons.push(
                    <button className="btn btn-primary" key={"Ok-" + buttons.length} type="button" onClick={this.props.okClick}>Ok</button>
                );
                break;
            }
            default:
                break;
        }

        const titleID = 'alert-dialog-title-' + this.props.id;
        const descriptionID = 'alert-dialog-description-' + this.props.id;

        if (this.props.title !== '' && this.props.title != null) {
            title.push(<DialogTitle key={"title-" + title.length} id={titleID}>{this.props.title}</DialogTitle>);
        }

        if (this.props.message !== '' && this.props.message != null && (this.props.messageBoldList === undefined || this.props.messageBoldList.length === 0 || listItems.every(t => Object.prototype.toString.call(t) === '[object Array]'))) { // List of empty lists for multiple Tabs
            message.push(
                <DialogContent key="content">
                    <DialogContentText id={descriptionID}>
                        {this.props.message}
                    </DialogContentText>
                </DialogContent>);
        } else {
            if (listItems != null && listItems.length > 0) {
                if (listItems.every(t => Object.prototype.toString.call(t) === '[object String]')) { // Regular list of strings for Validations
                    message.push(
                        <DialogContent key="content">
                            <DialogContentText id={descriptionID}>
                                {this.props.message}
                            </DialogContentText>
                            <List>
                                {
                                    listItems.map((field) => (
                                        <ListItem style={{ padding: 5 }} key={field}>
                                            <ListItemAvatar style={{ minWidth: '40px' }}>
                                                <Avatar style={{ backgroundColor: '#500522', width: '24px', height: '24px' }}>
                                                    <ErrorOutlineTwoTone />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText style={{ fontWeight: 'bold', fontSize: 15 }} primary={field} />
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </DialogContent>
                    );
                } else if (listItems.some(t => Object.prototype.toString.call(t) === '[object Object]')) { // List of objects for multiple Tabs
                    message.push(
                        <DialogContent key="content">
                            <DialogContentText id={descriptionID}>
                                {this.props.message}
                            </DialogContentText>
                            {
                                listItems.map((tab, index) => (
                                    <List key={"List-" + index}>
                                        {tab.tab}
                                        {
                                            tab.errors && tab.errors.map((field) => (
                                                <ListItem style={{ padding: 5 }} key={field}>
                                                    <ListItemAvatar style={{ minWidth: '40px' }}>
                                                        <Avatar style={{ backgroundColor: '#500522', width: '24px', height: '24px' }}>
                                                            <ErrorOutlineTwoTone />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText style={{ fontWeight: 'bold', fontSize: 15 }} primary={field} />
                                                </ListItem>
                                            ))
                                        }
                                    </List>
                                ))
                            }
                        </DialogContent>
                    );
                }
            }
        }

        return (
            <Dialog 
            disableBackdropClick
            disableEscapeKeyDown
            open={this.props.open} onClose={this.props.onClose} aria-labelledby={titleID} aria-describedby={descriptionID}>
                {title}
                <hr style={{ margin: '0px' }} />
                {message}
                <hr style={{ margin: '0px' }} />
                <DialogActions>
                    {buttons}
                </DialogActions>
            </Dialog>
        );
    }
}