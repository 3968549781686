import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { formatValidInput } from '../../helper/FormatHelper';
import { validate } from '../../helper/ValidateHelper';
import { sendSignatureEmail } from '../../networking/NetworkingRegForm';
import AlertBox from './AlertBox';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default class EmailInvitation extends React.Component {
    state = {
        email: '',
        ownerName: '',
        validations: {
            email: true,
            ownerName: true
        },
        showAlert: false,
        showAlertResponse: false,
        alertMessage: '',
        alertTitle: '',
        
        //RadioButtons
        clickDirector: true,
        clickShareholder: false,
    }
    componentDidMount() {
        this.validateRequiredInputs();
    }
    handleUpdateOwnerName = (event) => {
        this.setState({
            ownerName: event.target.value,
            validations: { ...this.state.validations, ownerName: true }
        }, () => this.validateRequiredInputs());
    }
    handleUpdateEmail = (event) => {
        this.setState({
            email: event.target.value,
            validations: { ...this.state.validations, email: true }
        }, () => this.validateRequiredInputs());
    }
    validateRequiredInputs = () => {
        let fields = [
            { displayName: 'Owner Name', validateName: 'ownerName', value: this.state.ownerName, type: 'string' },
            { displayName: 'Email', validateName: 'email', value: this.state.email, type: 'email' }
        ];
        const [valid, validations, errors] = validate(fields);
        this.setState({ valid, errors })
        return { valid, errors };
    }
    validateRequiredFields = () => {
        let fields = [
            { displayName: 'Owner Name', validateName: 'ownerName', value: this.state.ownerName, type: 'string' },
            { displayName: 'Email', validateName: 'email', value: this.state.email, type: 'email' }
        ];
        const [valid, validations, errors] = validate(fields);
        this.setState({ validations, errors })
        return { validations, errors };
    }
    handleSendMailClick = () => {
        if (this.state.valid) {
            var customerID = sessionStorage.getItem('ActualCustomerID');
            sendSignatureEmail(parseInt(customerID), this.state.ownerName, this.state.email).then(
                (json) => {
                    if (json.httpStatusCode !== 200) {
                        this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: 'An error occurred while trying to send email. Please try again..' })
                    } else {
                        //let message = this.props.contactType === 'director' ? 'Send Director invitation successfully.' : 'Send Shareholder / Owner invitation successfully.';
                        this.setState({ showAlertResponse: true, alertTitle: 'Success', alertMessage: 'Send signature invitation successfully' }, () => {
                            this.props.sendSignature();
                            //this.clearData();
                        });
                    }
                })
        } else {
            this.setState({ showAlert: true, alertTitle: 'Error', alertMessage: 'Please fill all mandatory fields:' }, () => { this.validateRequiredFields() })
        }
    }
    clearData = () => {
        this.setState({
            ownerName: '',
            email: '',
        
            //RadioButtons
            clickDirector: true,
            clickShareholder: false,

            validations: {
                email: true,
                ownerName: true
            }
        });
    }
    closeAlert = () => {
        this.setState({ showAlert: false, alertMessage: '', alertTitle: '' })
    }
    closeAlertResponse = () => {
        this.setState({ showAlertResponse: false, alertMessage: '', alertTitle: '' },()=>{
             this.props.close()
             this.clearData();
            })
    }
    closeDialog = () => {
        this.clearData();
        this.props.close();
    }
    handleUpdateRadioButtons = (event) => {
        const question = event.target.id;
        var result = event.target.checked;
        switch (question) {
            case 'has_traders_yes':
                this.setState({ clickDirector: result }, () => {
                    this.validateRequiredInputs()
                });
                break;
            case 'has_traders_no':
                this.setState({ clickShareholder: result }, () => {
                    this.validateRequiredInputs()
                });
                break;
            default:
                break;
        }
    }
    render() {
        return (
            <Dialog open={this.props.open} onClose={this.closeDialog} maxWidth={'xs'} fullWidth={true}>
                <DialogTitle id="attachments-dialog-title" onClose={this.closeDialog}>
                    Email Invitation
                </DialogTitle>
                <DialogContent dividers>
                    <div className="row">
                        <div className={"col-md-12" + formatValidInput(this.state.validations.ownerName)}>
                            <input type="text"
                                className="form-control"
                                id="owner_name"
                                name="owner_name"
                                maxlength="150"
                                data-rule-required="true"
                                data-msg-required="Field Required"
                                data-rule-email="true"
                                data-msg-email="Email Required"
                                value={this.state.ownerName}
                                onChange={this.handleUpdateOwnerName} />
                            <label for="owner_name" className="small">Name</label>
                            {(this.state.validations.ownerName != null && !this.state.validations.ownerName) &&
                                <label id="owner_name-error" className="error" for="owner_name">Field Required.</label>
                            }
                        </div> {/* .col-md-12 */}
                    </div>
                    <div className="row">
                        <div className={"col-md-12" + formatValidInput(this.state.validations.email)}>
                            <input type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                maxlength="150"
                                data-rule-required="true"
                                data-msg-required="Field Required"
                                data-rule-email="true"
                                data-msg-email="Email Required"
                                value={this.state.email}
                                onChange={this.handleUpdateEmail} />
                            <label for="email" className="small">Email</label>
                            {(this.state.validations.email != null && !this.state.validations.email) &&
                                <label id="email-error" className="error" for="email">Field Required.</label>
                            }
                        </div> {/* .col-md-12 */}
                    </div>
                    {/* <div className="row">
                        <div className="col-md-12 form-group">
                            <div className="row">
                                <div className="col-md-12 form-group">
                                    <label><input type="checkbox" name="has_traders" id="has_traders_yes"
                                        data-rule-required="true"
                                        data-msg-required="Field Required"
                                        data-show-wrapper="#traders_wrapper"
                                        value={this.state.clickDirector}
                                        checked={this.state.clickDirector}
                                        onChange={this.handleUpdateRadioButtons} /> Director</label>
                                    <label><input type="checkbox" name="has_traders" id="has_traders_no"
                                        data-rule-required="true"
                                        data-msg-required="Field Required"
                                        data-hide-wrapper="#traders_wrapper"
                                        value={this.state.clickShareholder}
                                        checked={this.state.clickShareholder}
                                        onChange={this.handleUpdateRadioButtons} />Shareholder/Owner</label>
                                    <label for="email" className="small">Note: You can choose both types if your Contact is Doirector and Shareholder</label>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </DialogContent>
                <DialogActions>
                    <button className="btn btn-primary" onClick={this.handleSendMailClick} type="button">Send</button>
                    <button className="btn btn-primary" onClick={this.closeDialog} type="button">Cancel</button>
                </DialogActions>
                <AlertBox open={this.state.showAlert} onClose={this.closeAlert} title={this.state.alertTitle} messageBoldList={this.state.errors} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert} />
                <AlertBox open={this.state.showAlertResponse} onClose={this.closeAlertResponse} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlertResponse} />
            </Dialog>
        );
    }
}