import { Document, Font, Image, Page, pdf, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { getExtended } from '../../helper/FormatHelper';
import { getCompanyLogoFromProject } from '../../helper/PackageJsonHelper';
import { getResource, getResourceByLanguage } from '../../helper/ResourcesHelper';
import { sanitizeStringForPDF } from '../../helper/StringHelper';

// var img = getCurrentURLImage();
var img = `${window.location.origin}${getCompanyLogoFromProject()}`;
var checked = require('../../css/images/black-check-box.png');
var checkbox = require('../../css/images/blank-check-box.png');

const saveBlob = (blob, filename) => {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  let url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};


export const savePdfBuffer = async (document) => {
  var blob = await pdf(document).toBlob();
  // var url = window.URL.createObjectURL(blob); 
  return blob;
};

export const savePdf = async (document, filename) => {
  saveBlob(await pdf(document).toBlob(), filename);
};
const addPropertiesStep = (obj) => (
  <View style={styles.section}>
    <View style={styles.columnDetails}>
      <Text style={styles.textLabel}>{obj.description}</Text>
      <Text style={styles.boxText}>
        <Text style={styles.textSmall}>
          {obj.value !== '' ?
            obj.value : ' '}
        </Text>
      </Text>
    </View>
  </View>
);

Font.register({
  family: 'ZCool',
  src: 'https://fonts.gstatic.com/s/zcoolkuaile/v7/tssqApdaRQokwFjFJjvM6h2WpozzoXhC2g.ttf'
});

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30
  },
  section: {
    flexDirection: 'row',
  },
  imageSection: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 6,
    paddingTop: 7,
    // padding: 5,
    marginBottom: 7,
    borderBottom: 1,
    borderBottomColor: '#555555', //backgroundColor: '#00548d',
    flexDirection: 'row',
  },
  titleHeader: {
    position: 'static',
    // flexDirection: 'row',
    textAlign: 'center',
    backgroundColor: '#c2922c',
    color: '#FFFFFF', //#666
    fontSize: 13,
    fontWeight: 'ultrabold',
    // width: '450px',
    height: '20px',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 2,
    paddingBottom: 2
  },
  subTitleDocument: {
    // position: 'static',
    flexDirection: 'column',
    flexGrow: 1,
    textAlign: 'left',
    backgroundColor: '#c2922c',
    color: '#FFFFFF', //#666
    fontSize: 12,
    padding: 1,
    fontWeight: 'ultrabold',
    width: '530px',
    height: '20px',
  },
  footerSection: {
    padding: 10,
    paddingTop: 30,
    borderTop: 1,
    borderBottomColor: '#555555',
    color: '#fff',
    backgroundColor: '#00548d',
    flexDirection: 'row',
    position: 'relative',
  },
  columnDetails: {
    flexDirection: 'column',
    flexGrow: 1,
  },
  rowDetails: {
    flexDirection: 'row',
    flexGrow: 1,
  },
  paragraph: {
    fontSize: 8,
    justifyContent: 'space-between',
    marginTop: 10,
    paddingBottom: 10
  },
  textLarge: {
    fontSize: 12,
    marginTop: 5,
    paddingTop: 5,
  },
  subtitle: {
    color: '#13417a',
    fontSize: 9,
    marginTop: 5,
    paddingTop: 5,
    fontWeight: 700
  },
  textLabel: {
    fontSize: 8, //6
    marginTop: 5,
    paddingTop: 5,
    marginBottom: 1
  },
  whiteBackground: {
    backgroundColor: "#ffffff"
  },
  textLabelBottom: {
    fontSize: 6,
    marginBottom: 5,
    paddingBottom: 5,
    marginTop: 1
  },
  textSmall: {
    fontSize: 9,
    marginTop: 5,
    paddingTop: 5,
    backgroundColor: "#ffffff"
  },
  textSmall1: {
    fontSize: 9,
    // marginTop: 2,
    // paddingTop: 2,
    backgroundColor: "#ffffff"
  },
  boxText: {
    border: 1,
    // borderRadius: 5,
    borderRadius: 0,
    // borderBottomColor: '#555555',
    borderBottomColor: '#000000',
    padding: 5,
    backgroundColor: '#ffffff'
  },
  boxText1: {
    border: 1,
    borderRadius: 0,
    borderBottomColor: '#000000',
    padding: 6,
    backgroundColor: '#ffffff'
  },
  boxText3: {
    border: '1px',
    borderRadius: 0,
    borderBottomColor: '#000000',
    padding: 5,
    bordercollapse: 'collapse',
    width: '100%'
  },
  boxCheckbox: {
    border: 1,
    borderRadius: 0,
    borderBottomColor: '#000000',
    padding: 7,
    width: '15px',
    height: '15px',
  },
  smallPaddingCenter: {
    border: 1,
    borderRadius: 0,
    borderBottomColor: '#000000',
    padding: 0.5,
    width: '15px',
    height: '15px',
    textAlign: 'center'
  },
  boxDeclarationSignature: {
    borderBottom: 0.5,
    // borderBottomColor: '#555555',
    borderBottomColor: '#000000',
    padding: 5,
    lineHeight: 6,
    flexGrow: 4,
    alignSelf: "center",
    width: 270,
    height: 50
  },
  boxDeclarationSignatureByImg: {
    borderBottom: 0.5,
    // borderBottomColor: '#555555',
    borderBottomColor: '#000000',
    padding: 5,
    lineHeight: 6,
    flexGrow: 4,
    alignSelf: "center",
    paddingRight: 35,
    paddingLeft: 35,
  },
  signatureImg: {
    width: 200,
    height: 50
  },
  boxDeclarationDate: {
    //border: 0.5,
    // borderBottomColor: '#555555',
    borderBottomColor: '#000000',
    padding: 5,
    lineHeight: 6,
    flexGrow: 2
  },
  boxDeclarationFullName: {
    //border: 0.5,
    // borderBottomColor: '#555555',
    borderBottomColor: '#000000',
    padding: 5,
    lineHeight: 2
  },
  bulletPoint: {
    width: 6
  },
  spaceTopBottom: {
    paddingBottom: 12
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  footer: {
    position: 'absolute',
    bottom: 25,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  titlefooter: {
    fontSize: 9,
    marginTop: 5,
    marginBottom: 5,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#c2922c'
  },

  breakable: {
    width: '100%',
    height: 25,
  },
});


const styleChinesse = {
  margin: 0,
  marginTop: 10,
  fontSize: 8,
  textAlign: 'justify',
  fontFamily: 'ZCool',
  padding: 0
}

//Document for Steps
/* --------------------STEP 1------------------- */
const Generalbusinessinformation = (obj, addObj, objAddress, objContacts) => (
  <View>

    <Text style={styles.textLabel}>*These fields are mandatory. </Text>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[{ width: '33.3%', paddingRight: 10 }]}>
        <Text style={styles.textLabel}>* Legal Name</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(obj.companyName)}
          </Text>
        </Text>

      </View>
      {/*COLUMN 2 */}
      <View style={[{ width: '33.3%', paddingRight: 10 }]}>
        <Text style={styles.textLabel}>* ABN ID</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(getExtended(addObj.step1, 'ABN ID'))}
          </Text>
        </Text>
      </View>
      {/* COLUMN 3 */}
      <View style={[{ width: '33.4%', paddingRight: 0 }]}>
        <Text style={styles.textLabel}>* Company Registration/ Establishment Date</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(getExtended(addObj.step1, 'Company Registration/ Establishment Date'))}
          </Text>
        </Text>
      </View>
    </View>

    <View style={[styles.section, {marginTop: 10}]}>
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>Trading Name (if applicable)</Text>
        <Text style={[styles.boxText]} break={true}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(getExtended(addObj.step1, 'Trading Name'))}
          </Text>
        </Text>
      </View>
    </View>

    <View style={[styles.section, { marginTop: 10 }]}>
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text>
          <Text style={styles.textLabel}>* Business Structure</Text>
        </Text>
        <View style={[styles.rowDetails, { paddingRight: 10 }]}>
          <View style={[styles.rowDetails, { paddingRight: 5 }]}>
            <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
            {getExtended(addObj.step1, 'Business Structure') === 'Proprietary Company' ? 'X' : ''}
            </Text>
            <View style={styles.section}>
              <View style={[styles.columnDetails, { paddingRight: 5 }]}>
                <Text style={styles.textSmall}></Text>
              </View>
            </View>
            <Text style={styles.textLabel}>Proprietary Company</Text>
          </View>
          <View style={[styles.rowDetails, { paddingRight: 5 }]}>
            <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
            {getExtended(addObj.step1, 'Business Structure') === 'Public Company' ? 'X' : ''}
            </Text>
            <View style={styles.section}>
              <View style={[styles.columnDetails, { paddingRight: 5 }]}>
                <Text style={styles.textSmall}></Text>
              </View>
            </View>
            <Text style={styles.textLabel}>Public Company</Text>
          </View>
        </View>
      </View>
    </View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[{ width: '33.3%', paddingRight: 10 }]}>
        <Text style={styles.textLabel}>* Telephone Number</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(obj.ResidentialPhoneNumber)}
          </Text>
        </Text>

      </View>
      {/*COLUMN 2 */}
      <View style={[{ width: '33.3%', paddingRight: 10 }]}>
        <Text style={styles.textLabel}>* Fax Number</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF('')}
          </Text>
        </Text>
      </View>
      {/* COLUMN 3 */}
      <View style={[{ width: '33.4%', paddingRight: 0 }]}>
        <Text style={styles.textLabel}>* Authorised Person's Email Address</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(objContacts['EmailAddress01'] != null ? objContacts['EmailAddress01'] : '')}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { paddingRight: 15 }]}>
        <Text style={styles.textSmall}>* Officially Registered Office Address</Text>
      </View>
    </View>
    <View style={styles.section}>
      {/* COLUMN 1 */}
      <View style={[{ width: '33.3%', paddingRight: 10 }]}>

        <Text style={styles.textLabel}>Street Address</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(objAddress.BusinessAddressStreet)}
          </Text>
        </Text>

      </View>
      {/* COLUMN 2 */}
      <View style={[{ width: '33.3%', paddingRight: 10 }]}>
        <Text style={styles.textLabel}>City</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(objAddress.BusinessAddressCityID)}
          </Text>
        </Text>

      </View>

      {/* COLUMN 3 */}
      <View style={[{ width: '33.4%', paddingRight: 0 }]}>
        <Text style={styles.textLabel}>State</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(objAddress.BusinessAddressStateName)}
          </Text>
        </Text>

      </View>
    </View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[{ width: '50%', paddingRight: 5 }]}>
        <Text style={styles.textLabel}>Post Code</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(objAddress.BusinessAddressPostalCode)}
          </Text>
        </Text>
      </View>
      {/* COLUMN 2 */}
      <View style={{ width: '50%', paddingLeft: 5 }}>
        <Text style={styles.textLabel}>Country</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(objAddress.countryName)}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.rowDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel} break={true}>*Principal Place of Business (Not a PO Box Address for Contact) (if applicable) </Text>

        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>

        <View style={[styles.rowDetails, { paddingRight: 5 }]}>
          <Text style={[styles.boxCheckbox, styles.textLabel]} break={true}></Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}></Text>
            </View>
          </View>
          <Text style={styles.textLabel}>Same as Registered Office Address</Text>
        </View>

      </View>

    </View>
    <View style={styles.section}>
      {/* COLUMN 1 */}
      <View style={[{ width: '33.3%', paddingRight: 10 }]}>

        <Text style={styles.textLabel}>Street Address</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(objAddress.BusinessAddressStreet)}
          </Text>
        </Text>

      </View>
      {/* COLUMN 2 */}
      <View style={[{ width: '33.3%', paddingRight: 10 }]}>
        <Text style={styles.textLabel}>City</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(objAddress.BusinessAddressCityID)}
          </Text>
        </Text>

      </View>

      {/* COLUMN 3 */}
      <View style={[{ width: '33.4%', paddingRight: 0 }]}>
        <Text style={styles.textLabel}>State</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(objAddress.BusinessAddressStateName)}
          </Text>
        </Text>

      </View>
    </View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[{ width: '50%', paddingRight: 5 }]}>
        <Text style={styles.textLabel}>Post Code</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(objAddress.BusinessAddressPostalCode)}
          </Text>
        </Text>
      </View>
      {/* COLUMN 2 */}
      <View style={{ width: '50%', paddingLeft: 5 }}>
        <Text style={styles.textLabel}>Country</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(objAddress.countryName)}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[{ width: '50%', paddingRight: 5 }]}>
        <Text style={styles.textLabel}>* Transaction Purposes (with EasyPay Forex)</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(getExtended(addObj.step1, 'Purpose of Business'))}
          </Text>
        </Text>
      </View>
      {/* COLUMN 2 */}
      <View style={{ width: '50%', paddingLeft: 5 }}>
        <Text style={styles.textLabel}>* Source of funds</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(getExtended(addObj.step1, 'Source of Funds'))}
          </Text>
        </Text>
      </View>
    </View>

    <View style={[styles.columnDetails, { paddingRight: 10 }]}>
      <Text style={styles.textLabel}></Text>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { paddingRight: 5, paddingTop: 5, backgroundColor: '#c2922c' }]}>
        <Text style={[styles.subTitleDocument, { paddingLeft: 10 }]}>Contact Information (Directors, UBOs, Authorised Person)</Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { paddingRight: 5 }]}>
        <Text style={styles.textSmall}>A director is an individual who manages or supervises
          the business affairs of the company. One company director is required
          to sign this FXapplication form and FX agreement with EasyPay Forex on
          behalf of your organisation.</Text>
        <Text style={styles.textSmall}>An ultimate beneficial owner is an individual who ultimately owns or controls the company, whether directly (such as throughshareholdings) or indirectly (such as through another company's ownership).</Text>
        <Text style={styles.textSmall}>An authorised person is an individual authorised by the company to book, negotiate and agree terms of a transaction with EasyPay Forex.</Text>
      </View>
    </View>

    <View style={[styles.columnDetails, { paddingRight: 10 }]}>
      <Text style={styles.textLabel}></Text>
    </View>

    {/* {
      addObj.step1.length > 0 &&
      <View>

        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>

        {
          addObj.step1.map((detail) => {
            return (addPropertiesStep(detail))
          })
        }
      </View>
    } */}
  </View >
);

/* --------------------STEP 2------------------- */
const PrincipalPlaceOfBusiness = (obj, addObj) => (
  <View>
    <Text style={styles.subtitle}>{getResource('Title_Corp_2')}</Text>
    <Text style={styles.textSmall}>{getResource('Subtitle_Corp_ResidentialAddress')}</Text>
    <Text style={styles.textLabel}>{getResource('Label_Business_StreetAddress')}</Text>
    <Text style={styles.boxText}>
      <Text style={styles.textSmall}>
        {(obj.BusinessAddressStreet !== '' && obj.BusinessAddressStreet != null) ?
          obj.BusinessAddressStreet : ' '}
      </Text>
    </Text>
    <Text style={styles.textLabel}>{getResource('Label_Business_AddressLine2')}</Text>
    <Text style={styles.boxText}>
      <Text style={styles.textSmall}>
        {(obj.BusinessAddressLine2 !== '' && obj.BusinessAddressLine2 != null) ?
          obj.BusinessAddressLine2 : ' '}
      </Text>
    </Text>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_Business_City')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.BusinessAddressCityName !== '' && obj.BusinessAddressCityName != null) ?
              obj.BusinessAddressCityName : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_Business_State')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.BusinessAddressStateName !== '' && obj.BusinessAddressStateName != null) ?
              obj.BusinessAddressStateName : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_Business_Country')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.countryName !== '' && obj.countryName != null) ?
              obj.countryName : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_Business_Postal')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.BusinessAddressPostalCode !== '' && obj.BusinessAddressPostalCode != null) ?
              obj.BusinessAddressPostalCode : ' '}
          </Text>
        </Text>
      </View>
    </View>
    {
      addObj.step2.length > 0 &&
      <View>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>
        {
          addObj.step2.map((detail) => {
            return (addPropertiesStep(detail))
          })
        }
      </View>
    }
  </View>
);

/* --------------------STEP 3------------------- */
const ProductsAndServices = (addObj) => (
  <View>
    <Text style={styles.subtitle}>{getResource('Title_Corp_3')}</Text>
    {
      addObj.step3.length > 0 &&
      <View>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>
        {
          addObj.step3.map((detail) => {
            return (addPropertiesStep(detail))
          })
        }
      </View>
    }
  </View>
);

/* --------------------STEP 4------------------- */
const ForeignExchangeForwards = (addObj) => (
  <View>
    <Text style={styles.subtitle}>{getResource('Title_Corp_4')}</Text>
    {
      addObj.step4.length > 0 &&
      <View>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>
        {
          addObj.step4.map((detail) => {
            return (addPropertiesStep(detail))
          })
        }
      </View>
    }
  </View>
);


/* --------------------STEP 5------------------- */
const Accountprimarycontact = (obj, director) => (
  <View>
    <View style={styles.breakable} />
    <View style={styles.section} break={true}>
      <View style={[styles.columnDetails, { paddingRight: 5, paddingTop: 5, backgroundColor: '#c2922c' }]}>
        <Text style={[styles.subTitleDocument, { paddingLeft: 10 }]}>{getResource('PrimaryContact_Subtitle')}{" " + director}</Text>
      </View>
    </View>
    <View style={styles.section}>
      <View style={[styles.rowDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel} break={true}>{getResourceByLanguage('Label_Salutation', 'English') + ' '}</Text>
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>

        <View style={[styles.rowDetails, { paddingRight: 5 }]}>
          <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
            {obj['SalutationID0' + director] != null && obj['SalutationID0' + director] === 'Mr.' ? 'X' : ''}
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}>

              </Text>
            </View>
          </View>
          <Text style={styles.textLabel}>Mr</Text>
        </View>

        <View style={[styles.rowDetails, { paddingRight: 5 }]}>
          <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
            {obj['SalutationID0' + director] != null && obj['SalutationID0' + director] === 'Miss.' ? 'X' : ''}
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}></Text>
            </View>
          </View>
          <Text style={styles.textLabel}>Miss</Text>
        </View>

        <View style={[styles.rowDetails, { paddingRight: 5 }]}>
          <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
            {obj['SalutationID0' + director] != null && obj['SalutationID0' + director] === 'Mrs.' ? 'X' : ''}
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}></Text>
            </View>
          </View>
          <Text style={styles.textLabel}>Mrs</Text>
        </View>

        <View style={[styles.rowDetails, { paddingRight: 5 }]}>
          <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
            {obj['SalutationID0' + director] != null && obj['SalutationID0' + director] === 'Ms.' ? 'X' : ''}
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}></Text>
            </View>
          </View>
          <Text style={styles.textLabel}>Ms</Text>
        </View>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { paddingRight: 5 }]}>
        <Text style={styles.textSmall}></Text>
      </View>
    </View>

    <View style={styles.section}>

      {/*COLUMN 1 */}

      <View style={[styles.columnDetails, { paddingRight: 10 }]}>

        <Text>
          <Text style={styles.textLabel}>{getResource('Label_FirstName')}</Text>
        </Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['FirstName0' + director] !== '' && obj['FirstName0' + director] != null) ?
              obj['FirstName0' + director] : ' '}
          </Text>
        </Text>

        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>

        <Text>
          <Text style={styles.textLabel}>{getResource('Label_DateOfBirth')}</Text>
        </Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['DateOfBirth0' + director] !== '' && obj['DateOfBirth0' + director] != null) ?
              obj['DateOfBirth0' + director] : ' '}
          </Text>
        </Text>
      </View>

      {/*COLUMN 2 */}

      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text>
          <Text style={styles.textLabel}>{getResource('Label_MiddleName')}</Text>
        </Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['ShortName0' + director] !== '' && obj['ShortName0' + director] != null) ?
              obj['ShortName0' + director] : ' '}
          </Text>
        </Text>

        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>

        <Text>
          <Text style={styles.textLabel}>{getResource('Label_Citizenship')}</Text>
        </Text>
        <Text>
        </Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['CitizenshipName0' + director] !== '' && obj['CitizenshipName0' + director] != null) ?
              obj['CitizenshipName0' + director] : ' '}
          </Text>
        </Text>
      </View>

      {/*COLUMN 3*/}
      <View style={styles.columnDetails}>

        <Text>
          <Text style={styles.textLabel}>{getResource('Label_Surname')}</Text>
        </Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['LastName0' + director] !== '' && obj['LastName0' + director] != null) ?
              obj['LastName0' + director] : ' '}
          </Text>
        </Text>

        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>

      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { paddingRight: 5 }]}>
        <Text style={styles.textSmall}></Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.rowDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel} break={true}>{getResourceByLanguage('Label_Roles', 'English') + ' '}</Text>
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>

        <View style={[styles.rowDetails, { paddingRight: 5 }]}>
          <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
            {obj['Roles0' + director] != null && obj['Roles0' + director].includes('Authorizer') ? 'X' : ''}
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}>

              </Text>
            </View>
          </View>
          <Text style={styles.textLabel}>Authorised Person</Text>
        </View>

        <View style={[styles.rowDetails, { paddingRight: 5 }]}>
          <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
            {obj['Roles0' + director] != null && obj['Roles0' + director].includes('Director') ? 'X' : ''}
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}></Text>
            </View>
          </View>
          <Text style={styles.textLabel}>Director</Text>
        </View>

        <View style={[styles.rowDetails, { paddingRight: 5 }]}>
          <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
            {obj['Roles0' + director] != null && obj['Roles0' + director].includes('UBO') ? 'X' : ''}
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}></Text>
            </View>
          </View>
          <Text style={styles.textLabel}>UBO</Text>
        </View>
      </View>
    </View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('Subtitle_ResidentialAddress')}</Text>
      </View>
    </View>
    <Text style={styles.textLabel}>{getResource('Label_StreetAddress')}</Text>
    <Text style={styles.boxText}>
      <Text style={styles.textSmall}>
        {(obj['ResidentialAddressStreet0' + director] !== '' && obj['ResidentialAddressStreet0' + director] != null) ?
          obj['ResidentialAddressStreet0' + director] : ' '}
      </Text>
    </Text>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[{ width: '50%', paddingRight: 5 }]}>
        <Text style={styles.textLabel}>{getResource('Label_City')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['ResidentialAddressCity0' + director] !== '' && obj['ResidentialAddressCity0' + director] != null) ?
              obj['ResidentialAddressCity0' + director] : ' '}
          </Text>
        </Text>
      </View>
      <View style={{ width: '50%', paddingLeft: 5 }}>
        <Text style={styles.textLabel}>{getResource('Label_Country')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['countryName0' + director] !== '' && obj['countryName0' + director] != null) ?
              obj['countryName0' + director] : ' '}
          </Text>
        </Text>
      </View>
    </View>
      {/*COLUMN 2 */}
    <View style={styles.section}>
      <View style={[{ width: '50%', paddingRight: 5 }]}>
        <Text style={styles.textLabel}>{getResource('Label_State')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['ResidentialAddressStateName0' + director] !== '' && obj['ResidentialAddressStateName0' + director] != null) ?
              obj['ResidentialAddressStateName0' + director] : ' '}
          </Text>
        </Text>
      </View>
      <View style={{ width: '50%', paddingLeft: 5 }}>
        <Text style={styles.textLabel}>{getResource('Label_Postal')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['ResidentialAddressPostalCode0' + director] !== '' && obj['ResidentialAddressPostalCode0' + director] != null) ?
              obj['ResidentialAddressPostalCode0' + director] : ' '}
          </Text>
        </Text>
      </View>
    </View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('SubTitle_ContactNumbers')}</Text>
      </View>
    </View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[{ width: '50%', paddingRight: 5 }]}>
        <Text style={styles.textLabel}>{getResource('Label_MainContactNumber')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF((obj['ResidentialPhoneNumber0' + director] !== '' && obj['ResidentialPhoneNumber0' + director] != null) ?
              obj['ResidentialPhoneNumber0' + director] : ' ')}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={{ width: '50%', paddingLeft: 5 }}>
        <Text style={styles.textLabel}>{getResource('Label_Email')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF((obj['EmailAddress0' + director] !== '' && obj['EmailAddress0' + director] != null) ?
              obj['EmailAddress0' + director] : ' ')}
          </Text>
        </Text>
      </View>
    </View>    
    {/* <View style={{ width: '50%', paddingRight: 5 }}>
      <Text style={styles.textLabel}>{getResource('Label_SSN')}</Text>
      <Text style={styles.boxText}>
        <Text style={styles.textSmall}>
          {(obj['SSN0' + director] !== '' && obj['SSN0' + director] != null) ?
            obj['SSN0' + director] : ' '}
        </Text>
      </Text>
    </View> */}

    {/*Personal Identification */}
    <View style={styles.section} break={true}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('SubTitle_PersonalIdentification')}</Text>
      </View>
    </View>

    <View style={styles.section}>
    {/*COLUMN 1 */}
      <View style={[{ width: '50%', paddingRight: 5 }]}>
        <Text style={styles.textLabel}>{getResource('Label_Country')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['countryNameIdentification0' + director] !== '' && obj['countryNameIdentification0' + director] != null) ?
              obj['countryNameIdentification0' + director] : ' '}
          </Text>
        </Text>
      </View>
    {/*COLUMN 2 */}
    {
      obj['documentTypeID0' + director].includes('Identity') &&
      <View style={[{ width: '50%', paddingLeft: 5 }]}>
        <Text style={styles.textLabel}>{getResource('Label_NumberIdentification')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['numberIdentification0' + director] !== '' && obj['numberIdentification0' + director] != null) ?
              obj['numberIdentification0' + director] : ' '}
          </Text>
        </Text>
      </View>
    }
    {
      obj['documentTypeID0' + director].includes('Driving') &&
      <View style={[{ width: '50%', paddingLeft: 5 }]}>
        <Text style={styles.textLabel}>{getResource('Label_LicenseNumber')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['DriverLicenseNumber0' + director] !== '' && obj['DriverLicenseNumber0' + director] != null) ?
              obj['DriverLicenseNumber0' + director] : ' '}
          </Text>
        </Text>
      </View>
    }
    {
      obj['documentTypeID0' + director].includes('Passport') &&
      <View style={[{ width: '50%', paddingLeft: 5 }]}>
        <Text style={styles.textLabel}>{getResource('Label_PassportNumber')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['PassportNumber0' + director] !== '' && obj['PassportNumber0' + director] != null) ?
              obj['PassportNumber0' + director] : ' '}
          </Text>
        </Text>
      </View>
    }
    </View>

    {/* <View>
      {
        obj['valuesOfAdditionalPropertiesDefs' + director].length > 0 &&
        <View>
          <View style={styles.section}>
            <View style={styles.columnDetails}>
              <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
            </View>
          </View>
          {
            obj['valuesOfAdditionalPropertiesDefs' + director].map((detail) => {
              return (addPropertiesStep(detail))
            })
          }
        </View>
      }
    </View> */}

  </View>
);

/* --------------------STEP 6------------------- */
const DirectorAndAppointedOfficer = (obj, addObj) => (
  <View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_FirstName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.FirstName !== '' && obj.FirstName != null) ?
              obj.FirstName : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_DateOfBirth')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.DateOfBirth !== '' && obj.DateOfBirth != null) ?
              obj.DateOfBirth : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_MiddleName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ShortName !== '' && obj.ShortName != null) ?
              obj.ShortName : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 3 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_Surname')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.LastName !== '' && obj.LastName != null) ?
              obj.LastName : ' '}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('Subtitle_ResidentialAddress')}</Text>
        <Text style={styles.textLabel}>{getResource('Label_StreetAddress')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialAddressStreet !== '' && obj.ResidentialAddressStreet != null) ?
              obj.ResidentialAddressStreet : ' '}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_City')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialCityId !== '' && obj.ResidentialCityId != null) ?
              obj.ResidentialCityId : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_State')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialAddressStateName !== '' && obj.ResidentialAddressStateName != null) ?
              obj.ResidentialAddressStateName : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_Country')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.countryName !== '' && obj.countryName != null) ?
              obj.countryName : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_Postal')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialAddressPostalCode !== '' && obj.ResidentialAddressPostalCode != null) ?
              obj.ResidentialAddressPostalCode : ' '}
          </Text>
        </Text>
      </View>
    </View>

    {/*Personal Identification */}
    <View style={styles.section} break={true}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('SubTitle_PersonalIdentification')}</Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_Country')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.countryNameIdentification !== '' && obj.countryNameIdentification != null) ?
              obj.countryNameIdentification : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      {
        obj.documentTypeID.includes('Identity') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_NumberIdentification')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.numberIdentification !== '' && obj.numberIdentification != null) ?
                obj.numberIdentification : ' '}
            </Text>
          </Text>
        </View>
      }
      {
        obj.documentTypeID.includes('Driving') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_LicenseNumber')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.DriverLicenseNumber !== '' && obj.DriverLicenseNumber != null) ?
                obj.DriverLicenseNumber : ' '}
            </Text>
          </Text>
        </View>
      }
      {
        obj.documentTypeID.includes('Passport') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_PassportNumber')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.PassportNumber !== '' && obj.PassportNumber != null) ?
                obj.PassportNumber : ' '}
            </Text>
          </Text>
        </View>
      }
    </View>

    {
      addObj.step6.length > 0 &&
      <View>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>
        {
          addObj.step6.map((detail) => {
            return (addPropertiesStep(detail))
          })
        }
      </View>
    }


  </View>
);

/* --------------------STEP 7------------------- */
const UltimateBeneficialOwner = (obj, addObj) => (
  <View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_FirstName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.FirstName !== '' && obj.FirstName != null) ?
              obj.FirstName : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_DateOfBirth')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.DateOfBirth !== '' && obj.DateOfBirth != null) ?
              obj.DateOfBirth : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_MiddleName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ShortName !== '' && obj.ShortName != null) ?
              obj.ShortName : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 3 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_Surname')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.LastName !== '' && obj.LastName != null) ?
              obj.LastName : ' '}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('Subtitle_ResidentialAddress')}</Text>
        <Text style={styles.textLabel}>{getResource('Label_StreetAddress')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialAddressStreet !== '' && obj.ResidentialAddressStreet != null) ?
              obj.ResidentialAddressStreet : ' '}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_City')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialCityId !== '' && obj.ResidentialCityId != null) ?
              obj.ResidentialCityId : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_State')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialAddressStateName !== '' && obj.ResidentialAddressStateName != null) ?
              obj.ResidentialAddressStateName : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_Country')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.countryName !== '' && obj.countryName != null) ?
              obj.countryName : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_Postal')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialAddressPostalCode !== '' && obj.ResidentialAddressPostalCode != null) ?
              obj.ResidentialAddressPostalCode : ' '}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('SubTitle_ContactNumbers')}</Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_MainContactNumber')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialPhoneNumber !== '' && obj.ResidentialPhoneNumber != null) ?
              obj.ResidentialPhoneNumber : ' '}
          </Text>
        </Text>
      </View>
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_Email')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.EmailAddress !== '' && obj.EmailAddress != null) ?
              obj.EmailAddress : ' '}
          </Text>
        </Text>
      </View>
    </View>

    {/*Personal Identification */}
    <View style={styles.section} break={true}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('SubTitle_PersonalIdentification')}</Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_Country')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.countryNameIdentification !== '' && obj.countryNameIdentification != null) ?
              obj.countryNameIdentification : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      {
        obj.documentTypeID.includes('Identity') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_NumberIdentification')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.numberIdentification !== '' && obj.numberIdentification != null) ?
                obj.numberIdentification : ' '}
            </Text>
          </Text>
        </View>
      }
      {
        obj.documentTypeID.includes('Driving') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_LicenseNumber')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.DriverLicenseNumber !== '' && obj.DriverLicenseNumber != null) ?
                obj.DriverLicenseNumber : ' '}
            </Text>
          </Text>
        </View>
      }
      {
        obj.documentTypeID.includes('Passport') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_PassportNumber')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.PassportNumber !== '' && obj.PassportNumber != null) ?
                obj.PassportNumber : ' '}
            </Text>
          </Text>
        </View>
      }
    </View>

    {
      addObj.step7.length > 0 &&
      <View>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>
        {
          addObj.step7.map((detail) => {
            return (addPropertiesStep(detail))
          })
        }
      </View>
    }


  </View>
);

/* --------------------STEP 5------------------- */
const TradingRequirements = (obj) => (
  <View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        {/*  ------------STEP 5--------------*/}
        <Text style={styles.subtitle}>{getResource('Title_Corp_5')}</Text>
        <Text style={styles.textLabel}>{getResource('Label_CurrenciesInterested')}</Text>
      </View>
    </View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>
          {
            obj.checkEUR &&
            <Image src={checked} style={styles.bulletPoint} />
          }
          {
            !obj.checkEUR &&
            <Image src={checkbox} style={styles.bulletPoint} />
          } {getResource('Radio_EUR')}</Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>
          {
            obj.checkGBP &&
            <Image src={checked} style={styles.bulletPoint} />
          }
          {
            !obj.checkGBP &&
            <Image src={checkbox} style={styles.bulletPoint} />
          } {getResource('Radio_GBP')}</Text>
      </View>
      {/*COLUMN 3 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>
          {
            obj.checkUSD &&
            <Image src={checked} style={styles.bulletPoint} />
          }
          {
            !obj.checkUSD &&
            <Image src={checkbox} style={styles.bulletPoint} />
          } {getResource('Radio_USD')}</Text>
      </View>
      {/*COLUMN 4 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>
          {
            obj.checkAED &&
            <Image src={checked} style={styles.bulletPoint} />
          }
          {
            !obj.checkAED &&
            <Image src={checkbox} style={styles.bulletPoint} />
          } {getResource('Radio_AED')}</Text>
      </View>
      {/*COLUMN 5 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>
          {
            obj.checkOther &&
            <Image src={checked} style={styles.bulletPoint} />
          }
          {obj.CurrenciesInterestedTradingOther}</Text>
      </View>
    </View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_AmountPerMonth')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.AmountTradedMonthIDName !== '' && obj.AmountTradedMonthIDName != null) ?
              obj.AmountTradedMonthIDName : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_PurposeForExchanging')}</Text>
        {!obj.PurposeExchangingCurrencyIDName.includes('Others') &&
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.PurposeExchangingCurrencyIDName !== '' && obj.PurposeExchangingCurrencyIDName != null) ?
                obj.PurposeExchangingCurrencyIDName : ' '}
            </Text>
          </Text>
        }
        {obj.PurposeExchangingCurrencyIDName.includes('Others') &&
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.PurposeExchangingCurrencyOther !== '' && obj.PurposeExchangingCurrencyOther != null) ?
                obj.PurposeExchangingCurrencyOther : ' '}
            </Text>
          </Text>
        }
      </View>
    </View>

    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_HearAboutUs')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {obj.clickRadio}
          </Text>
        </Text>
      </View>
    </View>
    <View style={styles.section}>
      {obj.clickRadio === obj.SalesCallState &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_SalesCall')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.WhereYouHearAboutUsSalesCall !== '' && obj.WhereYouHearAboutUsSalesCall != null) ?
                obj.WhereYouHearAboutUsSalesCall : ' '}
            </Text>
          </Text>
        </View>
      }
      {obj.clickRadio === obj.ReferralState &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_Referral')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.WhereYouHearAboutUsReferral !== '' && obj.WhereYouHearAboutUsReferral != null) ?
                obj.WhereYouHearAboutUsReferral : ' '}
            </Text>
          </Text>
        </View>
      }
      {obj.clickRadio === obj.EventState &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_Event')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.WhereYouHearAboutUsEvent !== '' && obj.WhereYouHearAboutUsEvent != null) ?
                obj.WhereYouHearAboutUsEvent : ' '}
            </Text>
          </Text>
        </View>
      }
      {obj.clickRadio === obj.OtherState &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_Other')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.WhereYouHearAboutUsOther !== '' && obj.WhereYouHearAboutUsOther != null) ?
                obj.WhereYouHearAboutUsOther : ' '}
            </Text>
          </Text>
        </View>
      }
    </View>
  </View>
);

/* --------------------STEP DECLARATION------------------- */
const Declaration = () => (
  <View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={[styles.subtitle]}>{getResource('Title_Declaration')}</Text>
        <Text style={styles.paragraph}>{getResource('Label_Declaration')}</Text>
      </View>
    </View>
    <View style={[styles.section, { paddingTop: 10 }]}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        {/**------------SIGNATURE IMAGE----------- */}
        {sessionStorage.getItem('signatureImg') !== 'null' ?
          <Text style={styles.boxDeclarationSignatureByImg}>
            <Text style={styles.paragraph}>
              <Image style={styles.signatureImg} src={sessionStorage.getItem('signatureImg')} />
            </Text>
          </Text>
          :
          <Text style={styles.boxDeclarationSignature}>
            <Text style={styles.paragraph}>
              {' '}
            </Text>
          </Text>
        }

        {/**------------SIGNATURE FULLNAME----------- */}
        {sessionStorage.getItem('signatureImg') !== 'null' ?
          <Text style={[styles.paragraph, { width: 300, textAlign: 'right' }]}>
            {/*Full Name: Daniela Soto*/}
            {
              getResource('Label_FullName') + ': ' + sessionStorage.getItem('signatureFullName')
            }
          </Text> :
          <Text style={[styles.paragraph, { width: 210, textAlign: 'right' }]}>
            {/*Full Name: Daniela Soto*/}
            {
              getResource('Label_FullName') + ': '
            }
          </Text>
        }

        {/**------------SIGNATURE TITLE----------- */}
        {sessionStorage.getItem('signatureImg') !== 'null' ?
          <Text style={[styles.paragraph, { width: 300, textAlign: 'right' }]}>
            {/*Title: Engineer*/}
            {
              getResource('Label_TitleOccupation') + ': ' + sessionStorage.getItem('signatureTitle')
            }
          </Text> :
          <Text style={[styles.paragraph, { width: 210, textAlign: 'right' }]}>
            {/*Full Name: Daniela Soto*/}
            {
              getResource('Label_TitleOccupation') + ': '
            }
          </Text>
        }
        {/**------------SIGNATURE DATE----------- */}
        {sessionStorage.getItem('signatureImg') !== 'null' ?
          <Text style={[styles.paragraph, { width: 300, textAlign: 'right' }]}>
            {/*Full Name: Daniela Soto*/}
            {
              getResource('Label_Date') + ': ' + sessionStorage.getItem('signatureDate')
            }
          </Text> :
          <Text style={[styles.paragraph, { width: 210, textAlign: 'right' }]}>
            {/*Full Name: Daniela Soto*/}
            {
              getResource('Label_Date') + ': '
            }
          </Text>
        }
      </View>
    </View>
  </View>
);

const ForeignExchangeAndPaymentRequirements = (objAdd) => (
  <View>
    <View style={styles.breakable} />
    <View style={[styles.section, { marginBottom: 10 }]}>
      <View style={[styles.columnDetails, { paddingRight: 5, paddingTop: 5, backgroundColor: '#c2922c' }]}>
        <Text style={[styles.subTitleDocument, { paddingLeft: 10 }]}>Foreign Exchange and payment requirements</Text>
      </View>
    </View>
    {/* <View style={[styles.section, { marginBottom: 10 }]}>
      <View style={{ marginRight: 20 }}>
        <Text style={styles.textSmall}>* Purpose of transactions</Text>
      </View>
      <Text style={[styles.boxText1, styles.textLabel, { width: '450px' }]}>
        {sanitizeStringForPDF(getExtended(objAdd.step1, 'Purpose of Business'))}
      </Text>
    </View> */}

    <View style={[styles.section, { marginBottom: 10 }]}>
      <View style={{ marginRight: 20 }}>
        <Text style={styles.textSmall}>* Currencies required</Text>
      </View>
      <Text style={[styles.boxText1, styles.textLabel, { width: '450px' }]}>
        {sanitizeStringForPDF(getExtended(objAdd.step1, 'Currencies required'))}
      </Text>
    </View>
    <View style={[styles.section, { marginBottom: 10 }]}>
      <View style={{ marginRight: 20 }}>
        <Text style={styles.textSmall}>* Approx. volume per trade</Text>
      </View>
      <Text style={[styles.boxText1, styles.textLabel, { width: '420px' }]}>
        {sanitizeStringForPDF(getExtended(objAdd.step1, 'Expected monthly trade volume'))}
      </Text>
    </View>
    <View style={[styles.section, { marginBottom: 10 }]}>
      <View style={{ marginRight: 20 }}>
        <Text style={styles.textSmall}>* Approx. number of transactions per month</Text>
      </View>
      <Text style={[styles.boxText1, styles.textLabel, { width: '350px' }]}>
        {(sanitizeStringForPDF(getExtended(objAdd.step1, 'Expected volume of transaction per month')))}
      </Text>
    </View>
    <View style={[styles.section, { marginBottom: 10 }]}>
      <View style={{ marginRight: 20 }}>
        <Text style={styles.textSmall}>* Approx. monthly FX requirement</Text>
      </View>
      <Text style={[styles.boxText1, styles.textLabel, { width: '420px' }]}>
        {sanitizeStringForPDF(getExtended(objAdd.step1, 'Expected monthly FX requirements'))}
      </Text>
    </View>
    <View style={[styles.section, { marginBottom: 10 }]}>
      <View style={{ marginRight: 20 }}>
        <Text style={styles.textSmall}>* Countries which funds transfers are expected to go to</Text>
      </View>
      <Text style={[styles.boxText1, styles.textLabel, { width: '310px' }]}>
        {sanitizeStringForPDF(getExtended(objAdd.step1, 'Trading Countries (which funds transfer to go)'))}
      </Text>
    </View>
    <View style={[styles.section, { marginBottom: 10 }]}>
      <View style={{ marginRight: 20 }}>
        <Text style={styles.textSmall}>* Countries which funds transfers are expected to come from</Text>
      </View>
      <Text style={[styles.boxText1, styles.textLabel, { width: '310px' }]}>
        {sanitizeStringForPDF(getExtended(objAdd.step1, 'Trading Countries (where funds come from)'))}
      </Text>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { paddingRight: 5, paddingTop: 5, backgroundColor: '#c2922c' }]}>
        <Text style={[styles.subTitleDocument, { paddingLeft: 10 }]}>{getResource('Title_Declaration')}</Text>
      </View>
    </View>
    <View style={[styles.section, { paddingTop: 10 }]}>
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text>
          <Text style={styles.textLabel}>•  {getResource('Label_Declaration_Corporate1', 'English')}</Text>
        </Text>
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>
        <Text>
          <Text style={styles.textLabel}>•  {getResource('Label_Declaration_Corporate2', 'English')}</Text>
        </Text>
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>
        <Text>
          <Text style={styles.textLabel}>•  {getResource('Label_Declaration_Corporate3', 'English')}</Text>
        </Text>
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>
        <Text>
          <Text style={styles.textLabel}>•  {getResource('Label_Declaration_Corporate4', 'English')}</Text>
        </Text>
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>
        <Text>
          <Text style={styles.textLabel}>•  {getResource('Label_Declaration_Corporate5', 'English')}</Text>
        </Text>
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>
        <Text>
          <Text style={styles.textLabel}>•  {getResource('Label_Declaration_Corporate6', 'English')}</Text>
        </Text>
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>
        <Text>
          <Text style={styles.textLabel}>•  {getResource('Label_Declaration_Corporate7', 'English')}</Text>
        </Text>
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>
        <Text>
          <Text style={styles.textLabel}>•  {getResource('Label_Declaration_Corporate8', 'English')}</Text>
        </Text>
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>
        <Text>
          <Text style={styles.textLabel}>•  {getResource('Label_Declaration_Corporate9', 'English')}</Text>
        </Text>
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>
        <Text>
          <Text style={styles.textLabel}>•  {getResource('Label_Declaration_Corporate10', 'English')}</Text>
        </Text>
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>
        <Text>
          <Text style={styles.textLabel}>•  {getResource('Label_Declaration_Corporate11', 'English')}</Text>
        </Text>
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>
        <Text>
          <Text style={styles.textLabel}>•  {getResource('Label_Declaration_Corporate12', 'English')}</Text>
        </Text>
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>
        <Text>
          <Text style={styles.textLabel}>•  {getResource('Label_Declaration_Corporate13', 'English')}</Text>
        </Text>
      </View>
    </View>

    {/* <View style={styles.breakable} /> */}
    <View style={styles.section}>
      {/* <View style={styles.section} break={true}> */}

      <View style={[{ width: '33.3%', paddingRight: 10 }]}>
        <Text style={styles.textLabel}>* Print Name</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(sessionStorage.getItem('signatureFullName'))}
          </Text>
        </Text>
      </View>

      <View style={[{ width: '33.3%', paddingRight: 10 }]}>
        <Text style={styles.textLabel}>* Title</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(sessionStorage.getItem('signatureTitle'))}
          </Text>
        </Text>
      </View>

      <View style={[{ width: '33.4%', paddingRight: 0 }]}>
        <Text style={styles.textLabel}>* Date</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(sessionStorage.getItem('signatureDate'))}
          </Text>
        </Text>
      </View>
    </View>

    <Text style={styles.textLabel}>* Signature</Text>
    <Text style={styles.boxText}>
      {sessionStorage.getItem('signatureImg') !== 'null' ?
        <Image style={styles.signatureImg} src={sessionStorage.getItem('signatureImg')} />
        :
        <Text style={styles.textSmall}>
          {sanitizeStringForPDF('')}
        </Text>
      }
    </Text>
  </View >
);

const RequiredProofOfID = () => (
  <View>
    <View style={styles.breakable} />
    <View style={[styles.section, { marginTop: 10 }]}>
      <View style={[styles.columnDetails, { paddingRight: 5, paddingTop: 5, backgroundColor: '#c2922c' }]}>
        <Text style={[styles.subTitleDocument, { paddingLeft: 10 }]}>Required Business documents and Proof of ID</Text>
      </View>
    </View>
    <View style={[styles.section, { paddingTop: 10 }]}>
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text>
          <Text style={styles.textLabel}>In accordance with strict financial rules and regulations, EasyPay Forex collects and verifies the personal identification data of all our account holders. EasyPay Forex logs and tracks all the transactions that are carried out by our clients. In order to open a trading account, you must provide documents as instructed below:</Text>
        </Text>
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>
        <Text>
          <Text style={styles.textLabel}>(1) Domestic proprietary company: Proof of company address and ID of the signing director.</Text>
        </Text>
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>
        <Text>
          <Text style={styles.textLabel}>(2) Domestic public listed company: ID of the signing director.</Text>
        </Text>
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>
        <Text>
          <Text style={styles.textLabel}>(3) Foreign proprietary company: Relevant company registration document from the local Registrar, Proof of company address, Company ownership structure that shows UBOs of the company, IDs of the director(s) and the UBO(s).</Text>
        </Text>
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>
        <Text>
          <Text style={styles.textLabel}>(4) Foreign public listed company: Relevant company registration document with the local Registrar, Proof of company address, ID of the signing director.</Text>
        </Text>
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>
        <Text>
          <Text style={styles.textLabel}>Additional documents specific to your organisation may be required in the case of enhanced due diligence.</Text>
        </Text>
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>
        <Text>
          <Text style={styles.textLabel}>The IDs of the individuals must include a primary form of Photo ID and a secondary form of ID showing name and residential address.</Text>
        </Text>
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>
      </View>
    </View>
    <View style={styles.section}>
      <View style={[styles.columnDetails, { paddingRight: 5 }]}>
        <Text style={styles.textSmall}></Text>
      </View>
    </View>

    {/* Acceptable forms of Primary Photo Identification */}

    <View style={[styles.section, { marginBottom: 10 }]}>
      <View style={[styles.columnDetails, { paddingRight: 5, paddingTop: 5, backgroundColor: '#c2922c' }]}>
        <Text style={[styles.subTitleDocument, { paddingLeft: 10 }]}>Acceptable forms of Primary Identification</Text>
        </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Document type</Text>
          </Text>
        </Text>
      </View>

      <View style={[styles.columnDetails, { width: 150, }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Description</Text>
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>

      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Australian driver’s licence</Text>
          </Text>
        </Text>
      </View>

      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Full licence with photo and signature</Text>
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>

      {/* <View style={[styles.rowDetails]}> */}
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={[styles.boxText3, { height: 92 }]}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Valid Australian passport</Text>
          </Text>
        </Text>
      </View>

      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>With photo, date of birth and signature</Text>
          </Text>
        </Text>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Important: Some documents that look like an Australian passport are not acceptable
              forms of ID. These include:</Text>
          </Text>
        </Text>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>· Certificate of Identity (number begins with C)</Text>
          </Text>
        </Text>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>· Titre de Voyage/Travel Document (number begins with U)</Text>
          </Text>
        </Text>
      </View>

    </View>

    <View style={styles.section}>

      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={[styles.boxText3, { height: 181 }]}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Australian/State Government issued Proof of Age/Identity Card
              only when combined with a Medicare card which includes the
              applicant’s name</Text>
          </Text>
        </Text>
      </View>

      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>QLD: Adult Proof of Age card issued by QLD Govt Transport and Main Roads</Text>
          </Text>
        </Text>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>NT: Evidence of Age card issued by NT Govt Transport Group</Text>
          </Text>
        </Text>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>ACT: Proof of Age card issued by ACT Govt Road User Services</Text>
          </Text>
        </Text>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>SA: Proof of Age card issued by SA Govt Dept for Transport Energy and Infrastructure</Text>
          </Text>
        </Text>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>VIC: Proof of Age card issued by Victorian Commission for Gambling and Liquor
              Regulation</Text>
          </Text>
        </Text>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>WA: Proof of Age card issued by WA Govt Department of Transport</Text>
          </Text>
        </Text>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>TAS: Tasmanian Government Personal Information card issued by Services Tasmania</Text>
          </Text>
        </Text>
      </View>

    </View>

    <View style={styles.section}>

      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={[styles.boxText3]}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Australian learner’s permit</Text>
          </Text>
        </Text>
      </View>

      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Only when combined with a Medicare card which has the applicant’s name</Text>
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={[styles.boxText3, { height: 38.5 }]}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>International passport with valid visa with expiry date beyond the
              contract expiry date</Text>
          </Text>
        </Text>
      </View>

      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Must include date of birth and visa’s expiry date* that is beyond the contract term. If no
              current visa is visible, relevant immigration papers confirming the end date of the
              current visa must be provided</Text>
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={[styles.boxText3, { height: 50.5 }]}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Blind Citizens Australia identity card</Text>
          </Text>
        </Text>
      </View>

      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Card must be current</Text>
          </Text>
        </Text>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Good to know: Blind Citizens Australia have stopped issuing or renewing identity cards
              from 1 January 2015</Text>
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Valid Police/Defence Force ID</Text>
          </Text>
        </Text>
      </View>
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>With photo</Text>
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={[styles.boxText3]}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Valid Shooters/Firearms licence</Text>
          </Text>
        </Text>
      </View>
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>With photo</Text>
          </Text>
        </Text>
      </View>
    </View>

    {/* Acceptable forms of Secondary non-photo Identification */}
    <View style={[styles.columnDetails, { paddingRight: 10 }]} break={true}>
      <Text style={styles.textLabel}></Text>
    </View>
    <View style={styles.breakable} />
    <View style={styles.section}>
      <View style={[styles.columnDetails, { paddingRight: 5, paddingTop: 5, backgroundColor: '#c2922c' }]}>
        <Text style={[styles.subTitleDocument, { paddingLeft: 10 }]}>Acceptable forms of Secondary Identification</Text>
      </View>
    </View>
    <View style={[styles.columnDetails, { paddingRight: 10 }]}>
      <Text style={styles.textLabel}></Text>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Document type</Text>
          </Text>
        </Text>
      </View>

      <View style={[styles.columnDetails, { width: 150, }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Description</Text>
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={[styles.boxText3, { height: 29.8 }]}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Medicare card</Text>
          </Text>
        </Text>
      </View>

      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>The preferred secondary ID option and the first form of secondary ID that will normally
              be requested</Text>
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={[styles.boxText3, { height: 80 }]}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Credit, debit or ATM card</Text>
          </Text>
        </Text>
      </View>

      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>With photo, date of birth and signature</Text>
          </Text>
        </Text>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Only Australian financial institution cards with your name and the financial institution’s
              name printed</Text>
          </Text>
        </Text>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>·Important: Credit, debit or ATM cards are the last option if you are unable to provide
              any other type of secondary ID</Text>
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={[styles.boxText3, { height: 29.5 }]}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Birth certificate or original birth extract</Text>
          </Text>
        </Text>
      </View>

      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Important: If your name has been changed by marriage, you’ll need to provide the
              marriage certificate as well</Text>
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Valid Working with Children card</Text>
          </Text>
        </Text>
      </View>

      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Must include photograph</Text>
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={[styles.boxText3, { height: 29.5 }]}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Valid Australian Government issued benefits card</Text>
          </Text>
        </Text>
      </View>

      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>This can include Aged Pension card, Seniors Card and Disability Support Pension card</Text>
          </Text>
        </Text>
      </View>
    </View>


    <View style={styles.section}>

      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Utility bill, vehicle registration or rates notice</Text>
          </Text>
        </Text>
      </View>

      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Must be less than six months old</Text>
          </Text>
        </Text>
      </View>
    </View>


    <View style={styles.section}>

      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={[styles.boxText3, { height: 50.5 }]}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Tertiary student ID card</Text>
          </Text>
        </Text>
      </View>

      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Card must be current</Text>
          </Text>
        </Text>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Must have photo and student number issuing body such as a university or TAFE</Text>
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Private health insurance membership card</Text>
          </Text>
        </Text>
      </View>

      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Card must be current</Text>
          </Text>
        </Text>
      </View>
    </View>
  </View>
);

// Create Document Component
export const MyDocumentCorporateOther = (props) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {/*-----------HEADER--------------*/}
      <View style={styles.section}>
        <View style={[styles.imageSection, { alignItems: 'center', padding: 2, marginTop: 25 }]}>
          {/* COLUMN 1 */}
          <View style={[{ padding: 1, marginRight: 10 }]}>
            <Image src={{ uri: img, method: 'GET', headers: {}, body: '' }} style={{width: 275.5, height: 33.5}} />
          </View>
          {/* COLUMN 2 */}
          <View style={[{ display: 'flex', width: 'auto', backgroundColor: '#c2922c', paddingTop: 2, paddingLeft: 2, paddingBottom: 2, paddingRight: 25 }]}>
            <Text style={[styles.titleHeader]}>Corporate Client Application Form</Text>
          </View>
        </View>
      </View>
      {/* ------------STEP 1-------------- */}
      {
        <View style={styles.spaceTopBottom}>
          {
            Generalbusinessinformation(props.obj.Generalbusinessinformation, props.obj.AdditionalProperties, props.obj.PrincipalPlaceOfBusiness, props.obj.Accountprimarycontact)
          }
        </View>
      }
      {/* ------------STEP 2-------------- */}
      {/* {
        <View style={styles.spaceTopBottom} break={true}>
          {
            PrincipalPlaceOfBusiness(props.obj.PrincipalPlaceOfBusiness, props.obj.AdditionalProperties)
          }
        </View>
      } */}

      {/* ------------STEP 5--------------*/}
      {
        <View break={true}>
          {/* <View style={styles.section}>
            <View style={styles.columnDetails}>
              <Text style={styles.subtitle}>{getResource('Title_Corp_3')}</Text>
            </View>
          </View> */}
          <View style={styles.spaceTopBottom}>
            {
              Accountprimarycontact(props.obj.Accountprimarycontact, 1)
            }
          </View>
          {
            (props.obj.Accountprimarycontact.trader02) &&
            <View style={styles.spaceTopBottom} break={true}>
              {
                Accountprimarycontact(props.obj.Accountprimarycontact, 2)
              }
            </View>
          }
          {
            (props.obj.Accountprimarycontact.trader03) &&
            <View style={styles.spaceTopBottom} break={true}>
              {
                Accountprimarycontact(props.obj.Accountprimarycontact, 3)
              }
            </View>
          }
          {
            (props.obj.Accountprimarycontact.trader04) &&
            <View style={styles.spaceTopBottom} break={true}>
              {
                Accountprimarycontact(props.obj.Accountprimarycontact, 4)
              }
            </View>
          }
          {
            (props.obj.Accountprimarycontact.trader05) &&
            <View style={styles.spaceTopBottom} break={true}>
              {
                Accountprimarycontact(props.obj.Accountprimarycontact, 5)
              }
            </View>
          }
        </View>
      }

      {/* ------------STEP 6--------------*/}
      {props.obj.DirectorAndAppointedOfficer != null && <View break={true}>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.subtitle}>{getResource('Title_Corp_6')}</Text>

            <Text style={styles.textLabel}>Have you a Director?</Text>
            <Text style={styles.boxText}>
              <Text style={styles.textSmall}>
                {props.obj.DirectorAndAppointedOfficer.clickRadio}
              </Text>
            </Text>
          </View>
        </View>
        {/* ONLY YES 
          DIRECTOR AND APPOINTER OFFICER*/}
        {
          props.obj.DirectorAndAppointedOfficer.clickRadio === getResource('Checkbox_Yes') &&
          <View style={styles.spaceTopBottom}>
            {
              DirectorAndAppointedOfficer(props.obj.DirectorAndAppointedOfficer, props.obj.AdditionalProperties)
            }
          </View>
        }
      </View>
      }

      {/* ------------STEP 7--------------*/}
      {props.obj.UltimateBeneficialOwner != null && <View break={true}>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.subtitle}>{getResource('Title_Corp_7')}</Text>

            <Text style={styles.textLabel}>Have you an Ultimate Beneficial Owner?</Text>
            <Text style={styles.boxText}>
              <Text style={styles.textSmall}>
                {props.obj.UltimateBeneficialOwner.clickRadio}
              </Text>
            </Text>
          </View>
        </View>
        {/* ONLY YES 
          DIRECTOR AND APPOINTER OFFICER*/}
        {
          props.obj.UltimateBeneficialOwner.clickRadio === getResource('Checkbox_Yes') &&
          <View style={styles.spaceTopBottom}>
            {
              UltimateBeneficialOwner(props.obj.UltimateBeneficialOwner, props.obj.AdditionalProperties)
            }
          </View>
        }
      </View>
      }

      {/* ------------STEP 5--------------*/}
      {/*
        props.obj.ShareholderOwner.clickRadio === getResource('Checkbox_Yes') &&
        <View style={styles.spaceTopBottom} break={true}>
          {
            TradingRequirements(props.obj.TradingRequirements)
          }
        </View>
        */
      }

      {
        <View style={styles.spaceTopBottom} break={true}>
          {
            ForeignExchangeAndPaymentRequirements(props.obj.AdditionalProperties)
          }
        </View>
      }

      {
        <View style={styles.spaceTopBottom} break={true}>
          {
            RequiredProofOfID()
          }
        </View>
      }

      {/* ------------STEP DECLARATION-------------- */}
      {
        // <View style={styles.spaceTopBottom}>
        //   {
        //     Declaration()
        //   }
        // </View>
      }

      {/*-----------FOOTER--------------*/}
      <View style={[styles.footer, { alignItems: 'center' }]} fixed={true}>
        <Text style={styles.titlefooter}  >EasyPay Forex <Text style={styleChinesse}>易速汇</Text> | T: +61 3 8080 1533| A: Level 11, 333 Collins Street, Melbourne, VIC 3000| www.xushi-exchange.com.au</Text>
      </View>
      {/* <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed={true} /> */}
    </Page>

  </Document >
);

const returnUrlBlob = (blob) => {
  let url = blob //window.URL.createObjectURL(blob);
  return url;
}

export const returnFileContent = async (document) => {
  return returnUrlBlob(await pdf(document).toBlob())
}