import { Router } from '@reach/router'
import React from 'react'
import { AppProvider } from './AppContext'
import Screen360 from './components/360Form/Screen360'
import NotFound from './components/preregistration/NotFound'
import AlertBox from './components/shared/AlertBox'
import LoadingBox from './components/shared/LoadingBox'
import './css/font-awesome.min.css'
import './css/generalCss/App.scss'
import { getCurrentVersionNumber } from './helper/PackageJsonHelper'
import { getLookUpTable } from './networking/Networking'
import { getCountries } from './networking/NetworkingCountries'
import { getCurrencyList } from './networking/NetworkingCurrencies'
import { getCountryByIP, getIP } from './networking/NetworkingPreReg'
import { getServerVersion } from './networking/NetworkingRegForm'
const en = 'English'

//Development Branch
export default class extends React.Component {
  state = {
    change: false,
    //dropdowns
    countries: [],
    citizenship: [],
    currencies: [],
    trades: [],
    dropdowns: {
      Salutation: [],
      JobTitle: [],
      ContactPrimaryIdentificationType: [],
      CompanyType: [],
      PrimaryAccountIdentificationType: [],
      'AFEX Individual Roles': [],
      'AFEX Direct User Role': [],
      'AFEX Business Identification Type': [],
      'AFEX Account Entity Type': [],
      'AFEX NAICS Codes': [],
      'AFEX Sources of Wealth': [],
      'AFEX Employment Status': [],
      PersonIdentificationType: [],
      'Industry Sectors': [],
      'Source of Funds': [],
      'Source of Income': []
    },
    lookUpTables: [
      'Salutation',
      'JobTitle',
      'ContactPrimaryIdentificationType',
      'CompanyType',
      'PrimaryAccountIdentificationType',
      'AFEX Individual Roles',
      'AFEX Direct User Role',
      'AFEX Business Identification Type',
      'AFEX Account Entity Type',
      'AFEX NAICS Codes',
      'AFEX Sources of Wealth',
      'AFEX Employment Status',
      'PersonIdentificationType',
      'Industry Sectors',
      'Source of Funds',
      'Source of Income'
    ],
    //----
    loading: false,
    isValidated: false,
    isValidated2: false,
    isValidated3: false,
    isValidated4: false,
    isValidated5: false,
    isValidated6: false,
    isValidated7: false,
    isValidated8: false,

    isValidated9: false,
    isValidated10: false,
    isValidated11: false,
    isValidated12: false,
    isValidated13: false,
    isValidated14: false,
    isValidated15: false,
    isValidated16: false,

    count1: 0,
    count2: 0,
    count3: 0,
    count4: 0,
    count5: 0,
    count6: 0,
    count7: 0,
    count8: 0,

    count9: 0,
    count10: 0,
    count11: 0,
    count12: 0,
    count13: 0,
    count14: 0,
    count15: 0,
    count16: 0,

    //AlertBox
    showAlert: false,
    showAlertError1: false,
    showAlertError2: false,
    showAlertError3: false,
    showAlertError4: false,
    showAlertError5: false,
    showAlertError6: false,
    showAlertError7: false,
    showAlertError8: false,

    showAlertError9: false,
    showAlertError10: false,
    showAlertError11: false,
    showAlertError12: false,
    showAlertError13: false,
    showAlertError14: false,
    showAlertError15: false,
    showAlertError16: false,

    alertTitle: '',
    alertMessage: '',
    errors1: [],
    errors2: [],
    errors3: [],
    errors4: [],
    errors5: [],
    errors6: [],
    errors7: [],
    errors8: [],

    errors9: [],
    errors10: [],
    errors11: [],
    errors12: [],
    errors13: [],
    errors14: [],
    errors15: [],
    errors16: [],

    notFirstLoad1: true,
    notFirstLoad2: true,
    notFirstLoad3: true,
    notFirstLoad4: true,
    notFirstLoad5: true,
    notFirstLoad6: true,
    notFirstLoad7: true,
    notFirstLoad8: true,

    notFirstLoad9: true,
    notFirstLoad10: true,
    notFirstLoad11: true,
    notFirstLoad12: true,
    notFirstLoad13: true,
    notFirstLoad14: true,
    notFirstLoad15: true,
    notFirstLoad16: true,

    ip: '',
    countryIsAllowed: true,
    countryIsActivated: false,
    countryError: 'Unfortunately at this time we do not yet service your country. Please come back again. You will be redirected to our website.',
    showAlertErrorCountry: false,

    //Global Parameter for Button Next/Previous
    globalParameterNameCsv: 'AllowPreviousButton',
    localVersion: getCurrentVersionNumber()
  }

  componentDidMount() {
    getIP().then((json) => {
      if (json != null) {
        const ip = json
        this.setState({ ip: ip })
        getCountryByIP(this.state.ip).then((response) => {
          if (response != null) {
            this.setState({ countryIsActivated: response.isActivated })
            if(this.state.countryIsActivated){
              this.setState({countryIsAllowed: response.isValid});
              if (!this.state.countryIsAllowed) {
                this.setState({ showAlertErrorCountry: true });
              }
            }
          }
        });
      }
    })
    getServerVersion().then((json) => {
      if (json != null) {
        const serverVersion = json.version
        if (serverVersion !== '' && serverVersion !== this.state.localVersion) {
          window.location.reload()
        }
      }
    })
    sessionStorage.setItem('360Language', en)
    sessionStorage.setItem('AllowButton', false)
    /* getToken().then(
      (json) => {
        sessionStorage.setItem('Token', json.access_token);
      }
    ) */
    this.setState({ loading: true }, () => {
      getCountries(1, 250).then((json) => {
        if (json != null && json.countries != null) {
          const countries = json.countries.map((c) => ({ ...c, name: c['Country Name'], value: c.Country }))
          const citizenship = json.countries
            .filter((c) => c['Nationality$'] !== '' && c['Nationality$'] != null)
            .map((c) => ({ ...c, name: c['Nationality$'], value: c.Country }))
            .sort((a, b) => a.name.localeCompare(b.name))
          const trades = json.countries.map((c) => ({ ...c, label: c['Country Name'], value: c['ISOCode$'] }))
          const _sortedTrades = [...trades.filter((c) => c.Country === 'GBR'), ...trades.filter((c) => c.Country !== 'GBR')]
          this.setState({ countries: countries, citizenship: citizenship, trades: _sortedTrades }, () => {
            this.loadLookUpTable()
          })
        }
      })
      getCurrencyList().then((json) => {
        if (json != null && json.currencies != null) {
          const currencies = json.currencies.map((c) => ({ ...c, label: c.CurrencyIDName, name: c.CurrencyIDName, value: c.CurrencyID }))
          const _sortedCurrencies = [...currencies.filter((c) => c.value === 'GBP'), ...currencies.filter((c) => c.value !== 'GBP')]
          this.setState({ currencies: _sortedCurrencies })
        }
      })
    })
  }

  loadLookUpTable() {
    getLookUpTable(this.state.lookUpTables.toString()).then((json) => {
      for (var i = 0; i < this.state.lookUpTables.length; i++) {
        let index = json.lookUpTables.map(({ LookUpTableDescription }) => LookUpTableDescription).indexOf(this.state.lookUpTables[i])
        if (index > -1) {
          var table = json.lookUpTables[index].lookUpTableDetails
          if (this.state.lookUpTables.toString() !== 'Salutation') {
            for (var j = 0; j < table.length; j++) {
              table[j].value = table[j].LookUpTableDetail
              table[j].name = table[j].LookUpTableDetail
            }
          } else {
            for (var j = 0; j < table.length; j++) {
              table[j].value = table[j].Field3
              table[j].name = table[j].Field3
            }
          }
          this.setState({ dropdowns: { ...this.state.dropdowns, [this.state.lookUpTables[i]]: table } })
        }
      }
      this.setState({ loading: false })
    })
  }
  closeAlert1() {
    this.setState({ showAlertError1: false, notFirstLoad1: true })
  }
  closeAlert2() {
    this.setState({ showAlertError2: false, notFirstLoad2: true })
  }
  closeAlert3() {
    this.setState({ showAlertError3: false, notFirstLoad3: true })
  }
  closeAlert4() {
    this.setState({ showAlertError4: false, notFirstLoad4: true })
  }
  closeAlert5() {
    this.setState({ showAlertError5: false, notFirstLoad5: true })
  }
  closeAlert6() {
    this.setState({ showAlertError6: false, notFirstLoad6: true })
  }
  closeAlert7() {
    this.setState({ showAlertError7: false, notFirstLoad7: true })
  }
  closeAlert8() {
    this.setState({ showAlertError8: false, notFirstLoad8: true })
  }
  closeAlert9() {
    this.setState({ showAlertError9: false, notFirstLoad9: true })
  }
  closeAlert10() {
    this.setState({ showAlertError10: false, notFirstLoad10: true })
  }
  closeAlert11() {
    this.setState({ showAlertError11: false, notFirstLoad11: true })
  }
  closeAlert12() {
    this.setState({ showAlertError12: false, notFirstLoad12: true })
  }
  closeAlert13() {
    this.setState({ showAlertError13: false, notFirstLoad13: true })
  }
  closeAlert14() {
    this.setState({ showAlertError14: false, notFirstLoad14: true })
  }
  closeAlert15() {
    this.setState({ showAlertError15: false, notFirstLoad15: true })
  }
  closeAlert16() {
    this.setState({ showAlertError16: false, notFirstLoad16: true })
  }
  closeAlertCountry() {
    window.location.replace('https://xushi-exchange.com.au/en/homepage/')
  }

  getNewListValidateNumber(errorsNumber, validatorObject) {
    let actualListErrors = errorsNumber
    let actualAIList = []
    let actualNoList = []

    actualListErrors.forEach((element) => {
      if (element.type === undefined) {
        actualNoList.push(element)
      }

      if (element.type === 'Additional') {
        actualAIList.push(element)
      }
    })

    let newListErrors1 = []

    if (validatorObject.errors.length !== 0) {
      validatorObject.errors.forEach((error) => {
        let obj = {
          type: validatorObject.type,
          value: error
        }
        newListErrors1.push(obj)
      })
    }

    switch (validatorObject.type) {
      default:
      case undefined: {
        actualNoList = newListErrors1
        break
      }
      case 'Additional': {
        actualAIList = newListErrors1
        break
      }
    }

    actualNoList.push(...actualAIList)

    return [actualNoList, actualNoList.length === 0 ? true : false, actualNoList.length]
  }

  isValidated = (validator) => {
    const [errorList, valid, count] = this.getNewListValidateNumber(this.state.errors1, validator)

    this.setState({
      errors1: errorList,
      isValidated: valid,
      count1: count
    })
  }

  isValidated2 = (validator) => {
    const [errorList, valid, count] = this.getNewListValidateNumber(this.state.errors2, validator)

    this.setState({
      errors2: errorList,
      isValidated2: valid,
      count2: count
    })
  }

  isValidated3 = (validator) => {
    const [errorList, valid, count] = this.getNewListValidateNumber(this.state.errors3, validator)

    this.setState({
      errors3: errorList,
      isValidated3: valid,
      count3: count
    })
  }

  isValidated4 = (validator) => {
    const [errorList, valid, count] = this.getNewListValidateNumber(this.state.errors4, validator)

    this.setState({
      errors4: errorList,
      isValidated4: valid,
      count4: count
    })
  }
  /*   isValidated4 = (validator) => {

    
    //listErrors: [
    //  { 
    //    tab: "Trader 01", 
    //    errors: [
    //      "First Name Trader01", "Surname Trader01", "Date of Birth Trader01", 
    //      "Street Address Trader01", "City Trader01", "Country Trader01", "State/Province/Region Trader01",
    //      "Postal / Zip Code Trader01", "Residential Phone Number Trader01", "Email Trader01",
    //      "Politically Exposed Person status (PEP) Trader01", "Document Type Trader01"
    //    ]
    //  }
    //]
    

    let actualListErrors = this.state.errors4;
    let actualAIList = [];
    let actualNoList = [];

    actualListErrors.forEach(element => {

      if (element.type === undefined) {
        actualNoList.push(element);
      }

      if (element.type === 'Additional') {
        actualAIList.push(element);
      }
    });

    let newListErrors = [];

    validator.listErrors.forEach(owner => {
      if (owner.errors.length !== 0) {
        owner.errors.forEach(error => {
          let obj = {
            type: validator.type,
            value: error
          }
          newListErrors.push(obj);
        });
      }
    });

    switch (validator.type) {
      case undefined:
        {
          actualNoList = newListErrors;
          break;
        }
      case 'Additional':
        {
          actualAIList = newListErrors;
          break;
        }
    }

    actualNoList.push(...actualAIList);

    this.setState({
      isValidated4: false,
      errors4: actualNoList,
      count4: actualNoList.length
    });
  }
 */
  /* isValidated5 = (validator) => {
    
    const [errorList, valid, count] = this.getNewListValidateNumber(this.state.errors5, validator);

    this.setState({
      errors5: errorList,
      isValidated5: valid,
      count5: count
    });
  }*/

  isValidated5 = (validator) => {
    /*
      listErrors: [
        { 
          tab: "Trader 01", 
          errors: [
            "First Name Trader01", "Surname Trader01", "Date of Birth Trader01", 
            "Street Address Trader01", "City Trader01", "Country Trader01", "State/Province/Region Trader01",
            "Postal / Zip Code Trader01", "Residential Phone Number Trader01", "Email Trader01",
            "Politically Exposed Person status (PEP) Trader01", "Document Type Trader01"
          ]
        }
      ]
    */
    let actualListErrors = [...this.state.errors3]
    let actualAIList = []
    let actualNoList = []

    actualListErrors.forEach((element) => {
      if (element.type === undefined) {
        actualNoList.push(element)
      }

      if (element.type === 'Additional') {
        actualAIList.push(element)
      }
    })

    let newListErrors = []

    validator.listErrors.forEach((owner) => {
      if (owner.errors.length !== 0) {
        owner.errors.forEach((error) => {
          let obj = {
            type: validator.type,
            value: error
          }
          newListErrors.push(obj)
        })
      }
    })

    switch (validator.type) {
      case undefined: {
        actualNoList = newListErrors
        break
      }
      case 'Additional': {
        actualAIList = newListErrors
        break
      }
      default:
        break
    }

    actualNoList.push(...actualAIList)

    this.setState({
      isValidated3: false,
      errors3: actualNoList,
      count3: actualNoList.length
    })
  }

  isValidated6 = (validator) => {
    const [errorList, valid, count] = this.getNewListValidateNumber(this.state.errors6, validator)

    this.setState({
      errors6: errorList,
      isValidated6: valid,
      count6: count
    })
  }

  isValidated7 = (validator) => {
    const [errorList, valid, count] = this.getNewListValidateNumber(this.state.errors7, validator)

    this.setState({
      errors7: errorList,
      isValidated7: valid,
      count7: count
    })
  }

  isValidated8 = (validator) => {
    const [errorList, valid, count] = this.getNewListValidateNumber(this.state.errors8, validator)

    this.setState({
      errors8: errorList,
      isValidated8: valid,
      count8: count
    })
  }
  isValidatedPartial = (validator, number) => {
    /*if (number != null) {
      //Validations for Step
      var i = number + 8;
      const [errorList, valid, count] = this.getNewListValidateNumber(this.state['errors' + i], validator);

      this.setState({
        ['errors' + i]: errorList,
        ['isValidated' + i]: valid,
        ['count' + i]: count
      });
    }*/
    if (number != null) {
      //Validations for Step
      var i = number + 8
      let actualListErrors = this.state['errors' + i]
      let actualAIList = []
      let actualNoList = []
      let newListErrors = []

      switch (number) {
        case 4:
          actualListErrors.forEach((element) => {
            if (element.type === undefined) {
              actualNoList.push(element)
            }

            if (element.type === 'Additional') {
              actualAIList.push(element)
            }
          })

          validator.listErrors.forEach((owner) => {
            if (owner.errors.length !== 0) {
              owner.errors.forEach((error) => {
                let obj = {
                  type: validator.type,
                  value: error
                }
                newListErrors.push(obj)
              })
            }
          })

          switch (validator.type) {
            case undefined: {
              actualNoList = newListErrors
              break
            }
            case 'Additional': {
              actualAIList = newListErrors
              break
            }
            default:
              break
          }

          actualNoList.push(...actualAIList)

          this.setState({
            ['errors' + i]: actualNoList,
            ['isValidated' + i]: false,
            ['count' + i]: actualNoList.length
          })

          break
        case 3:
          actualListErrors.forEach((element) => {
            if (element.type === undefined) {
              actualNoList.push(element)
            }

            if (element.type === 'Additional') {
              actualAIList.push(element)
            }
          })

          validator.listErrors.forEach((owner) => {
            if (owner.errors.length !== 0) {
              owner.errors.forEach((error) => {
                let obj = {
                  type: validator.type,
                  value: error
                }
                newListErrors.push(obj)
              })
            }
          })

          switch (validator.type) {
            case undefined: {
              actualNoList = newListErrors
              break
            }
            case 'Additional': {
              actualAIList = newListErrors
              break
            }
            default:
              break
          }

          actualNoList.push(...actualAIList)

          this.setState({
            ['errors' + i]: actualNoList,
            ['isValidated' + i]: false,
            ['count' + i]: actualNoList.length
          })

          break
        default:
          const [errorList, valid, count] = this.getNewListValidateNumber(this.state['errors' + i], validator)
          this.setState({
            ['errors' + i]: errorList,
            ['isValidated' + i]: valid,
            ['count' + i]: count
          })
          break
      }
    }
  }

  showAlert = (title, message) => {
    this.setState({
      showAlert: true,
      alertTitle: title,
      alertMessage: message
    })
  }

  closeAlert = () => {
    this.setState({ showAlert: false })
  }

  showAlertError = (numTab, value) => {
    let textLabel = 'showAlertError' + numTab
    this.setState({
      [textLabel]: value,
      alertTitle: 'There are some errors/required field(s) need to fix or fill, please, review.',
      alertMessage: 'Please, correct the following errors'
    })
  }

  notFirstLoad = (numTab, value) => {
    let textLabel = 'notFirstLoad' + numTab
    this.setState({ [textLabel]: value })
  }

  clearAllValidations = () => {
    this.setState({
      count1: 0,
      count2: 0,
      count3: 0,
      count4: 0,
      count5: 0,
      count6: 0,
      count7: 0,
      count8: 0,

      count9: 0,
      count10: 0,
      count11: 0,
      count12: 0,
      count13: 0,
      count14: 0,
      count15: 0,
      count16: 0,
      errors1: [],
      errors2: [],
      errors3: [],
      errors4: [],
      errors5: [],
      errors6: [],
      errors7: [],
      errors8: [],

      errors9: [],
      errors10: [],
      errors11: [],
      errors12: [],
      errors13: [],
      errors14: [],
      errors15: [],
      errors16: []
    })
  }

  render() {
    return (
      <>
        <AppProvider
          value={{
            countries: this.state.countries,
            citizenship: this.state.citizenship,
            trades: this.state.trades,
            currencies: this.state.currencies,
            lookUpTableList: this.state.dropdowns,
            isValidated2: this.state.isValidated2,
            notFirstLoad_Step1: this.state.notFirstLoad1,
            notFirstLoad_Step2: this.state.notFirstLoad2,
            notFirstLoad_Step3: this.state.notFirstLoad3,
            notFirstLoad_Step4: this.state.notFirstLoad4,
            notFirstLoad_Step5: this.state.notFirstLoad5,
            notFirstLoad_Step6: this.state.notFirstLoad6,
            notFirstLoad_Step7: this.state.notFirstLoad7,
            notFirstLoad_Step8: this.state.notFirstLoad8,
            notFirstLoad_Partial_Step1: this.state.notFirstLoad9,
            notFirstLoad_Partial_Step2: this.state.notFirstLoad10,
            notFirstLoad_Partial_Step3: this.state.notFirstLoad11,
            notFirstLoad_Partial_Step4: this.state.notFirstLoad12,
            notFirstLoad_Partial_Step5: this.state.notFirstLoad13,
            notFirstLoad_Partial_Step6: this.state.notFirstLoad14,
            notFirstLoad_Partial_Step7: this.state.notFirstLoad15,
            notFirstLoad_Partial_Step8: this.state.notFirstLoad16,
            errors_Step1: this.state.errors1,
            errors_Step2: this.state.errors2,
            errors_Step3: this.state.errors3,
            errors_Step4: this.state.errors4,
            errors_Step5: this.state.errors5,
            errors_Step6: this.state.errors6,
            errors_Step7: this.state.errors7,
            errors_Step8: this.state.errors8,
            errors_Partial_Step1: this.state.errors9,
            errors_Partial_Step2: this.state.errors10,
            errors_Partial_Step3: this.state.errors11,
            errors_Partial_Step4: this.state.errors12,
            errors_Partial_Step5: this.state.errors13,
            errors_Partial_Step6: this.state.errors14,
            errors_Partial_Step7: this.state.errors15,
            errors_Partial_Step8: this.state.errors16,
            validated: this.isValidated,
            validated2: this.isValidated2,
            validated3: this.isValidated3,
            validated4: this.isValidated4,
            validated5: this.isValidated5,
            validated6: this.isValidated6,
            validated7: this.isValidated7,
            validated8: this.isValidated8,
            validatedPartial: this.isValidatedPartial,
            showAlertError: this.showAlertError,
            showAlert: this.showAlert,
            notFirstLoad: this.notFirstLoad,
            clearAllValidations: this.clearAllValidations,
            IP: this.state.ip,
            countryIsActived: this.state.countryIsActivated
          }}
        >
          <Router>
            <Screen360 path="/" />
            <Screen360 path="/:referralID" />
            <Screen360 path="/individual-account/:regionID" />
            <Screen360 path="/corporate-account/:regionID" />
            <Screen360 path="/msb-account/:regionID" />
            <Screen360 path="/owner-invitation" />
            <Screen360 path="/success-account" />
            <Screen360 path="/invalid-request" />
            <Screen360 path="/customers" />
            <Screen360 path="/email-verified" />
            <NotFound default />
          </Router>

          <AlertBox open={this.state.showAlert} onClose={this.closeAlert.bind(this)} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeAlert.bind(this)} />

          <AlertBox
            open={this.state.showAlertError1}
            onClose={this.closeAlert1.bind(this)}
            title={this.state.alertTitle}
            message={this.state.alertMessage + ' (' + this.state.count1 + '):'}
            messageBoldList={this.state.errors1}
            type="Ok"
            okClick={this.closeAlert1.bind(this)}
          />
          <AlertBox
            open={this.state.showAlertError2}
            onClose={this.closeAlert2.bind(this)}
            title={this.state.alertTitle}
            message={this.state.alertMessage + ' (' + this.state.count2 + '):'}
            messageBoldList={this.state.errors2}
            type="Ok"
            okClick={this.closeAlert2.bind(this)}
          />
          <AlertBox
            open={this.state.showAlertError3}
            onClose={this.closeAlert3.bind(this)}
            title={this.state.alertTitle}
            message={this.state.alertMessage + ' (' + this.state.count3 + '):'}
            messageBoldList={this.state.errors3}
            type="Ok"
            okClick={this.closeAlert3.bind(this)}
          />
          <AlertBox
            open={this.state.showAlertError4}
            onClose={this.closeAlert4.bind(this)}
            title={this.state.alertTitle}
            message={this.state.alertMessage + ' (' + this.state.count4 + '):'}
            messageBoldList={this.state.errors4}
            type="Ok"
            okClick={this.closeAlert4.bind(this)}
          />
          <AlertBox
            open={this.state.showAlertError5}
            onClose={this.closeAlert5.bind(this)}
            title={this.state.alertTitle}
            message={this.state.alertMessage + ' (' + this.state.count5 + '):'}
            messageBoldList={this.state.errors5}
            type="Ok"
            okClick={this.closeAlert5.bind(this)}
          />
          <AlertBox
            open={this.state.showAlertError6}
            onClose={this.closeAlert6.bind(this)}
            title={this.state.alertTitle}
            message={this.state.alertMessage + ' (' + this.state.count6 + '):'}
            messageBoldList={this.state.errors6}
            type="Ok"
            okClick={this.closeAlert6.bind(this)}
          />
          <AlertBox
            open={this.state.showAlertError7}
            onClose={this.closeAlert7.bind(this)}
            title={this.state.alertTitle}
            message={this.state.alertMessage + ' (' + this.state.count7 + '):'}
            messageBoldList={this.state.errors7}
            type="Ok"
            okClick={this.closeAlert7.bind(this)}
          />
          <AlertBox
            open={this.state.showAlertError8}
            onClose={this.closeAlert8.bind(this)}
            title={this.state.alertTitle}
            message={this.state.alertMessage + ' (' + this.state.count8 + '):'}
            messageBoldList={this.state.errors8}
            type="Ok"
            okClick={this.closeAlert8.bind(this)}
          />

          <AlertBox
            open={this.state.showAlertError9}
            onClose={this.closeAlert9.bind(this)}
            title={this.state.alertTitle}
            message={this.state.alertMessage + ' (' + this.state.count9 + '):'}
            messageBoldList={this.state.errors9}
            type="Ok"
            okClick={this.closeAlert9.bind(this)}
          />
          <AlertBox
            open={this.state.showAlertError10}
            onClose={this.closeAlert10.bind(this)}
            title={this.state.alertTitle}
            message={this.state.alertMessage + ' (' + this.state.count10 + '):'}
            messageBoldList={this.state.errors10}
            type="Ok"
            okClick={this.closeAlert10.bind(this)}
          />
          <AlertBox
            open={this.state.showAlertError11}
            onClose={this.closeAlert11.bind(this)}
            title={this.state.alertTitle}
            message={this.state.alertMessage + ' (' + this.state.count11 + '):'}
            messageBoldList={this.state.errors11}
            type="Ok"
            okClick={this.closeAlert11.bind(this)}
          />
          <AlertBox
            open={this.state.showAlertError12}
            onClose={this.closeAlert12.bind(this)}
            title={this.state.alertTitle}
            message={this.state.alertMessage + ' (' + this.state.count12 + '):'}
            messageBoldList={this.state.errors12}
            type="Ok"
            okClick={this.closeAlert12.bind(this)}
          />
          <AlertBox
            open={this.state.showAlertError13}
            onClose={this.closeAlert13.bind(this)}
            title={this.state.alertTitle}
            message={this.state.alertMessage + ' (' + this.state.count13 + '):'}
            messageBoldList={this.state.errors13}
            type="Ok"
            okClick={this.closeAlert13.bind(this)}
          />
          <AlertBox
            open={this.state.showAlertError14}
            onClose={this.closeAlert14.bind(this)}
            title={this.state.alertTitle}
            message={this.state.alertMessage + ' (' + this.state.count14 + '):'}
            messageBoldList={this.state.errors14}
            type="Ok"
            okClick={this.closeAlert14.bind(this)}
          />
          <AlertBox
            open={this.state.showAlertError15}
            onClose={this.closeAlert15.bind(this)}
            title={this.state.alertTitle}
            message={this.state.alertMessage + ' (' + this.state.count15 + '):'}
            messageBoldList={this.state.errors15}
            type="Ok"
            okClick={this.closeAlert15.bind(this)}
          />
          <AlertBox
            open={this.state.showAlertError16}
            onClose={this.closeAlert16.bind(this)}
            title={this.state.alertTitle}
            message={this.state.alertMessage + ' (' + this.state.count16 + '):'}
            messageBoldList={this.state.errors16}
            type="Ok"
            okClick={this.closeAlert16.bind(this)}
          />
          <AlertBox
            open={this.state.showAlertErrorCountry}
            onClose={this.closeAlertCountry.bind(this)}
            title={'Country not allowed'}
            message={this.state.countryError}
            type="Ok"
            okClick={this.closeAlertCountry.bind(this)}
          />
          <LoadingBox loading={this.state.loading} />
        </AppProvider>
      </>
    )
  }
}
