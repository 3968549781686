import React from 'react';
export default class InvalidRequest extends React.Component {

    render() {
        return (
            <div className="">
                <h2>Your invitation has expired.</h2>
                <hr></hr>
                {/* <p>{this.state.textMessage}</p>
                <p>Please sign your Customer Agreement and either scan/email it to <a href="mailto:info@nexolink.com" target="_blank" rel="noopener noreferrer">info@nexolink.com</a></p>
                {<p>If you have any questions regarding your application please feel free to call us on 1300 939 068 or +61 2 8907 2530 for international callers.</p> */}
            </div>
        );
    }
}