import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../AppContext';
import { getAdditionalPropertiesDefs } from '../../../networking/NetworkingAdditionalPropertiesDefs';
import ContactAdditionalInformationInvitation from '../ContactAdditionalInformationInvitation';
import OwnerInvitationForm from './OwnerInvitationForm';

export default function OwnerScreen({ navigate, location, contactType: contactTypeRequest, requestId: requestIDRequest, country: countryRequest }) {
    const context = useContext(AppContext);
    const [properties, setProperties] = useState([]);
    const [values, setValues] = useState([]);
    const [validations, setValidations] = useState([]);
    const [objShareholderOwner, setObjShareholderOwner] = useState(null);
    const [propertiesForForm, setPropertiesForForm] = useState([]);

    useEffect(() => {
        async function loadProperties() {
            const _country = countryRequest ?? sessionStorage.getItem('Country');
            const json = await getAdditionalPropertiesDefs(_country, 'Contact - Corporate', 1);
            if (json != null && json.additionalPropertiesDef != null) {
                const _properties = json.additionalPropertiesDef
                    .filter((p) => p.pageNumber === 3)
                    .map(p => ({ ...p, input: p.label }));
                const mandatories = _properties
                    .filter(p => p.mandatory)
                    .map(p => ({ name: p.label, value: true }));
                setProperties(_properties);
                setValues([..._properties])
                setPropertiesForForm([..._properties])
                setValidations(mandatories);
            }
        };
        loadProperties();
    }, [countryRequest]);

    const ShareholderOwner = (obj) => {
        setObjShareholderOwner(obj);
    };

    const updateProperties = (newProperties) => {
        const _properties = newProperties.map(p => ({ ...p, Description: p.name, Value: p.value }));
        setPropertiesForForm(_properties);
    };

    return (
        <form>
            <OwnerInvitationForm
                Owner={ShareholderOwner}
                notFirstLoad={context.notFirstLoad_Step2}
                objShareholderOwner={objShareholderOwner}
                navigate={navigate}
                location={location}
                contactType={contactTypeRequest ?? sessionStorage.getItem('ContactType')}
                requestId={requestIDRequest ?? sessionStorage.getItem('RequestID')}
                additionalProperties={propertiesForForm}
            >
                <ContactAdditionalInformationInvitation
                    key={'OwnerInvitation'}
                    notFirstLoad={context.notFirstLoad_Step2}
                    totalList={properties}
                    customerAdditionalProperties={null}
                    values={values}
                    validations={validations}
                    onUpdateValues={updateProperties}
                    step={3}
                    principal={1}
                />
            </OwnerInvitationForm>
        </form>
    )
}