import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

export default class LoadingBox extends React.Component {
    onClose(){

    }
    render(){
        return(
            <Dialog open={this.props.loading} onClose={this.onClose.bind(this)} maxWidth="xs" fullWidth={true}>
                <DialogContent>
                    <br/>
                    <br/>
                    <br/>
                    <div className="uk-position-center" uk-spinner="ratio: 3"></div>
                    <br/>
                    <br/>
                    <br/>
                </DialogContent>
            </Dialog>
        );
    }
}