import { DialogActions, DialogTitle } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import React, { Fragment } from 'react';
import AppContext from '../../AppContext';
import { generateCode, validateCode } from '../../networking/NetworkingMobileValidation';
import { getStyle } from '../../styles/styles';
import AlertBox from './AlertBox';
const style = getStyle();

export default class ValidatePhone extends React.Component {
    static contextType = AppContext;
    state = {
        token: '',
        phone: '',
        companyName:'',
        showSuccessAlert: false,
        alertTitle: '',
        alertMessage: '',
        phoneValidated: false
    }

    componentDidUpdate(prevProps) {
        if (prevProps.phone !== this.props.phone) {
            this.setState({ phone: this.props.phone, companyName: this.props.companyName });
        }
    }

    validatePhone = async () => {
        const model = {
            numberMobile: this.state.phone,
            token: this.state.token
        };
        const json = await validateCode(model);
        if (json.httpStatusCode !== 200) {
            this.context.showAlert('Error', json.Message);
        } else {
            if (json.validation) {
                this.setState({ showSuccessAlert: true, alertTitle: 'Success', alertMessage: 'Code validated.', phoneValidated: true });
            } else {
                this.context.showAlert('Error', 'Code invalid.');
            }
        }
    }

    sendCode = async () => {
        const model = {
            numberMobile: this.state.phone,
            token: '',
            companyName: this.state.companyName != null ? this.state.companyName : ''
        };
        const json = await generateCode(model)
        if (json.httpStatusCode !== 200) {
            this.context.showAlert('Error', json.Message);
        } else {
            this.context.showAlert('Success', 'New code sent.');
        }
    }

    exit = () => {
        this.setState({ token: '' });
        this.props.onSuccess(this.state.phoneValidated);
    }

    handleChange = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({ token: event.target.value });
        }
    }

    closeSuccessAlert = () => {
        this.setState({ showSuccessAlert: false });
        this.exit();
    }

    render() {
        return (
            <Fragment>
                <Dialog open={this.props.open} onClose={this.exit} maxWidth="xs" fullWidth={true}>
                    <DialogTitle key="title" id="alert-dialog-title-validate">Code Validation</DialogTitle>
                    <DialogContent>
                        <div className="uk-form-stacked">
                            <div className="uk-form-controls">
                                <div className="uk-grid">
                                    <div className="uk-width-2-3 uk-form-stacked">
                                        <input id="code" className="uk-input" placeholder="Code" maxLength={4} value={this.state.token} onChange={this.handleChange} />
                                    </div>
                                    <div className="uk-width-1-3 uk-form-stacked">
                                        <button className="btn btn-primary" key={"Validate"} style={style.resendCodeButton} type="button" onClick={this.sendCode}>RESEND CODE</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button className="btn btn-primary" key={"Validate"} type="button" onClick={this.validatePhone}>VALIDATE</button>
                        <button className="btn btn-default" key={"Exit"} type="button" onClick={this.exit}>EXIT</button>
                    </DialogActions>
                </Dialog>
                <AlertBox open={this.state.showSuccessAlert} onClose={this.closeSuccessAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeSuccessAlert} />
            </Fragment>
        );
    }
}