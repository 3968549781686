import { genericGetWithParameters } from "./Networking";

export const getAdditionalPropertiesDefs = async (countryID, customerType, pageNumber) => {
    try {
        const params = {
            'countryID': countryID,
            'customerType': customerType,
            'pageNumber': pageNumber,
            'Language': sessionStorage.getItem('360Language')
        };
        const response = await genericGetWithParameters('AdditionalPropertiesDefs/GetAllAdditionalPropertiesDefs', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getEntityProperties(entityID, entityType) {
    try{
        let params = {
            'EntityType': entityType,
            'EntityId': entityID,
            'Page': 0,
            'Items': 0
        };
        let response = await genericGetWithParameters('EntityProperty/GetPropertiesByEntityId', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}