import React from 'react';
import { getCurrentCompanyName } from '../../helper/PackageJsonHelper';
import { getStyle } from '../../styles/styles';
import AlertBox from '../shared/AlertBox';


var style = getStyle();
//To Online Login
const onlineLoginUrl = window.REACT_APP_ONLINE_URL;

export default class Header360 extends React.Component {
    state = {
        customerClick: false,
        //Alert Send Email
        showHeaderEmailVerified: false,
        showSentEmailAlert: false,
        alertTitleSentEmail: '',
        alertMessageSentEmail: '',
        homeULR:'/'
    }

    componentDidMount() {
    }

    componentDidUpdate(prevprops) {
        if (this.props.children !== prevprops.children) {
            if (sessionStorage.getItem('ActualEmailVerified') != null) {
                this.setState({
                    showHeaderEmailVerified: sessionStorage.getItem('ActualEmailVerified')
                });
            }
            if (this.props.location.state != null) {
                if (this.props.location.state.originOnline != null && this.props.location.state.originOnline !== '') {
                    this.setState({ homeULR: onlineLoginUrl });
                }
            }
        }
    }

    handleCustomersClick = () => {
        this.setState({ customerClick: true });
    }

    closeEmailVerification = () => {
        this.setState({ showHeaderEmailVerified: false });
    }

    closeEmailVerificationAndEliminateStorage = () => {
        this.props.navigate('/');
        this.setState({ showHeaderEmailVerified: false }, () => {
            sessionStorage.removeItem('ActualCustomerID');
            sessionStorage.removeItem('ActualCustomerName');
            sessionStorage.removeItem('ActualCustomerEmail');
            sessionStorage.removeItem('ActualEmailVerified');
        });
    }
    closeSentEmailAlert = () => {
        this.setState({
            showSentEmailAlert: false,
        }, () => {
            this.setState({
                alertTitleSentEmail: '',
                alertMessageSentEmail: ''
            });
        });
    }

    toPage = () => {
        this.props.navigate('/');
    }
    handleIndividualClick = () => {
        this.props.navigate('/individual-account');
    }
    handleCorporateClick = () => {
        this.props.navigate('/corporate-account');
    }
    render() {

        // console.log("------------------------------------------")
        // console.log(sessionStorage.getItem('ActualEmailVerified'));
        // console.log(sessionStorage.getItem('ActualCustomerID'));
        // console.log(sessionStorage.getItem('ActualCustomerName'));
        // console.log(sessionStorage.getItem('ActualCustomerEmail'));
        // console.log("------------------------------------------")

        if (this.state.customerClick) {
            this.props.navigate('/customers');
        }
        return (
            <header className="navbar navbar-inverse navbar-fixed-top" id="mainHeaderWebPageWrapper" style={style.headerColor}>
                 {getCurrentCompanyName() === 'Newport' &&
                    <div className="header-p">
                        <div className="header-p2 container">
                            <div className="header-p3 row">
                                <div className="col-lg-6 col-xs-5">
                                    <a href="mailto:info@npsfx.co.uk"><label
                                        style={{
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            fontStyle: 'normal',
                                            marginRight: '15px'
                                        }}><i className="fa fa-envelope" aria-hidden="true"
                                            style={{
                                                marginRight: '5px',
                                            }}></i>info@npsfx.co.uk</label></a>
                                    <a href="tel:+80023456789"><label
                                        style={{
                                            fontSize: '12px',
                                            fontWeight: '500',
                                            fontStyle: 'normal'
                                        }}><i className="fa fa-mobile" aria-hidden="true"
                                            style={{
                                                marginRight: '5px',
                                            }}></i>44 (0) 207 287 7120</label></a>
                                </div>
                                <div className="col-lg-6 col-xs-7">
                                    <a onClick={this.toPage} style={{ float: 'right' }}><label><i className="fa fa-pinterest i-margin" aria-hidden="true"></i></label></a>
                                    <a onClick={this.toPage} style={{ float: 'right' }}><label><i className="fa fa-linkedin i-margin" aria-hidden="true"></i></label></a>
                                    <a onClick={this.toPage} style={{ float: 'right' }}><label><i className="fa fa-twitter i-margin" aria-hidden="true"></i></label></a>
                                    <a onClick={this.toPage} style={{ float: 'right' }}><label><i className="fa fa-facebook i-margin" aria-hidden="true"></i></label></a>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="container">
                    <nav className="nav">
                        <div className="row">
                            <div className="col-lg-6 col-xs-6">
                                <div className="navbar-header">
                                    <a href={this.state.homeULR}>
                                        <img className="logo" src={this.props.logo} alt="360 Markets" onClick={this.closeEmailVerificationAndEliminateStorage} />
                                    </a>
                                </div>
                            </div>

                            <div className="col-lg-6 col-xs-6"
                                style={{
                                    // width: 'fit-content',
                                    display: 'flex',
                                    marginTop: '10px',
                                    float: 'right',
                                    justifyContent: 'flex-end'
                                }}>
                                {(getCurrentCompanyName() === 'Nexolink' && this.props.path === "/") && <div className="navbar-right">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={this.handleCustomersClick}>
                                        Customers
                                </button>
                            </div>}
                                {this.props.path !== "/" && <div className="navbar-right">
                                    <a
                                        className="label"
                                        style={style.navbarLink}
                                        href={this.state.homeULR}
                                        onClick={this.closeEmailVerificationAndEliminateStorage}>Return to home page</a>
                                </div>}
                                {/* <div className="navbar-right">
                                   <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={this.handleIndividualClick}>
                                        Individual
                                </button>
                                   <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={this.handleCorporateClick}>
                                        Corporate
                                </button>
                                </div> */}
                                {this.props.children}
                            </div>
                        </div>
                    </nav>
                </div>
                {/*this.state.showHeaderEmailVerified && <div
                    style={{
                        backgroundColor: '#DCA400',
                        height: 30,
                        display: 'flex',
                        alignItems: 'center',
                        //justifyContent  : 'center',
                        flexDirection: 'row',
                        width: '100%'
                    }}
                >
                    <div
                        style={{
                            color: '#7F2200',
                            fontWeight: 'bolder',
                            width: '50%',
                            textAlign: 'right',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            paddingRight: 10
                        }}>Please verify your email address first.</div>
                    <a
                        style={{
                            color: '#10137C',
                            fontWeight: 'bolder',
                            width: '45%',
                            textAlign: 'left',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        }}
                        onClick={this.sendEmailVerificationClick}
                    > RESEND EMAIL</a>
                    <Icon
                        className="fa fa-times like-link"
                        color="primary"
                        style={{
                            textAlign: 'right',
                            paddingRight: 20,
                            width: '5%'
                        }}
                        onClick={this.closeEmailVerification}
                    />
                    </div>*/}
                <AlertBox
                    open={this.state.showSentEmailAlert}
                    onClose={this.closeSentEmailAlert}
                    title={this.state.alertTitleSentEmail}
                    message={this.state.alertMessageSentEmail}
                    type="Ok"
                    okClick={this.closeSentEmailAlert}
                />
            </header>
        );
    }
}