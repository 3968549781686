import { DialogActions } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import SignatureCanvas from "react-signature-canvas";
import AppContext from '../../AppContext';
import { formatDate, formatValidInput } from '../../helper/FormatHelper';
import { getResource } from '../../helper/ResourcesHelper';
import { validate } from '../../helper/ValidateHelper';
import AlertBox from './AlertBox';
import EmailInvitation from './EmailInvitation';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default class SignatureBox extends React.Component {
    static contextType = AppContext;
    state = {
        signatureDataURL: null,
        FullName: '',
        Title: '',
        validations: {
            FullName: true,
            Title: true,
            Signature: true
        },
        openAlert: false,
        openSendEmail: false,
    };

    componentDidMount() {
        sessionStorage.setItem('signatureImg', null)
    }

    componentDidUpdate(prevProps) {
    }
    close = (clickAdd) => {
        this.setState({
            FullName: '',
            Title: '',
            validations: { ...this.state.validations, FullName: true, Title: true, Signature: true }
        })
        if (clickAdd !== 'AddSignature') {
            this.props.addSignature('No')
        }
        this.props.close()
    }
    Clear = () => {
        sessionStorage.setItem('signatureImg', null)
        sessionStorage.setItem('signatureDate', '')
        sessionStorage.setItem('signatureFullName', '')
        sessionStorage.setItem('signatureTitle', '')
        this.setState({
            FullName: '',
            Title: '',
            validations: { ...this.state.validations, FullName: true, Title: true, Signature: true }
        })
        this.sigCanvas.clear()
    }
    handleUpdateFirstName = (event) => {
        this.setState({
            FullName: event.target.value,
            validations: { ...this.state.validations, FullName: true }
        })
    }
    handleUpdateTitle = (event) => {
        this.setState({
            Title: event.target.value,
            validations: { ...this.state.validations, Title: true }
        })
    }
    SaveSignature = () => {//Your signature is in the PDF AGREEMENT FOR SIGNATURE
        if (this.validateRequiredFields()) {
            let signatureDataURL = this.sigCanvas.getTrimmedCanvas().toDataURL('image/png')
            let signatureDate = new Date();
            sessionStorage.setItem('signatureImg', signatureDataURL)
            sessionStorage.setItem('signatureDate', formatDate(signatureDate))
            sessionStorage.setItem('signatureFullName', this.state.FullName)
            sessionStorage.setItem('signatureTitle', this.state.Title)
            this.setState({ signatureDataURL, openAlert: true }, () => {
                this.props.addSignature('Yes');
                this.close('AddSignature')
            });
        }
    }
    SendSignature = () => {
        this.setState({ openSendEmail: true });
    }
    CloseSendSignature = () => {
        this.setState({ openSendEmail: false })
    }
    //Fields Validations
    validateRequiredFields() {
        const fields = [
            { displayName: 'Full Name', validateName: 'FullName', value: this.state.FullName, type: 'string' },
            { displayName: 'Title', validateName: 'Title', value: this.state.Title, type: 'string' },
            { displayName: 'Signature', validateName: 'Signature', value: this.sigCanvas.isEmpty() ? '' : 'noEmpty', type: 'string' }
        ]
        const [valid, validations] = validate(fields);
        this.setState({ validations });
        return valid;
    }
    closeAlert = () => {
        this.setState({ openAlert: false })
    }
    render() {
        return (
            <React.Fragment>
                <Dialog open={this.props.open} onClose={this.close} fullWidth={true} maxWidth={'sm'}>
                    <DialogTitle id="current-attachment-dialog-title" onClose={this.close}>Signature</DialogTitle>
                    <DialogContent dividers style={{ alignSelf: 'center' }}>
                        <div className="row">
                            <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.FullName)}>
                                <input type="text"
                                    className="form-control"
                                    id="full_name"
                                    name="full_name"
                                    maxLength="70"
                                    data-rule-required="true"
                                    data-msg-required="Field Required."
                                    value={this.state.FullName}
                                    onChange={this.handleUpdateFirstName} />
                                <label htmlFor="full_name" className="small">{getResource('Label_FullName')} *</label>
                                {(this.state.validations.FullName != null && !this.state.validations.FullName) &&
                                    <label id="full_name-error" class="error" htmlFor="full_name"> Field Required.</label>
                                }
                            </div>
                            <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.Title)}>
                                <input type="text"
                                    className="form-control"
                                    id="title"
                                    name="title"
                                    maxLength="70"
                                    data-rule-required="true"
                                    data-msg-required="Field Required."
                                    value={this.state.Title}
                                    onChange={this.handleUpdateTitle} />
                                <label htmlFor="title" className="small">{getResource('Label_TitleOccupation')} *</label>
                                {(this.state.validations.Title != null && !this.state.validations.Title) &&
                                    <label id="title-error" class="error" htmlFor="title"> Field Required.</label>
                                }
                            </div>
                            <div className={"col-md-12" + formatValidInput(this.state.validations.Signature)}>
                                <SignatureCanvas
                                    canvasProps={{
                                        width: 550,
                                        height: 200,
                                        style: { border: '1px solid #e2e2e2e2' }
                                    }}
                                    minWidth={2}
                                    maxWidth={3}
                                    ref={(ref) => { this.sigCanvas = ref }}
                                />
                                <label htmlFor="signature" className="small">Signature *</label>
                                {(this.state.validations.Signature != null && !this.state.validations.Signature) &&
                                    <label id="signature-error" class="error" htmlFor="signature"> Field Required.</label>
                                }
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button className="btn btn-primary" onClick={this.Clear}>Clear</button>
                        {/*<button className="btn btn-primary" onClick={this.SendSignature}>Send Signature</button>*/}
                        <button className="btn btn-primary" onClick={this.SaveSignature}>Add Signature</button>
                    </DialogActions>
                </Dialog>
                <AlertBox
                    open={this.state.openAlert}
                    onClose={this.closeAlert}
                    title={'Success'}
                    message={'Your signature is in the PDF AGREEMENT FOR SIGNATURE'}
                    type="Ok"
                    okClick={this.closeAlert}
                />
                <EmailInvitation
                    open={this.state.openSendEmail}
                    close={this.CloseSendSignature}
                    sendSignature={this.props.sendSignature}
                />
            </React.Fragment>
        );
    }
}
