import { Document, Image, Page, pdf, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { getCurrentURLImage } from '../../helper/PackageJsonHelper';
import { getResource } from '../../helper/ResourcesHelper';


var img = getCurrentURLImage();
var checked = require('../../css/images/black-check-box.png');
var checkbox = require('../../css/images/blank-check-box.png');

const saveBlob = (blob, filename) => {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  let url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};


export const savePdfBuffer = async (document) => {
  var blob = await pdf(document).toBlob();
 // var url = window.URL.createObjectURL(blob); 
  return blob;
};

export const savePdf = async (document, filename) => {
  saveBlob(await pdf(document).toBlob(), filename);
};
const addPropertiesStep = (obj) => (
  <View style={styles.section}>
    <View style={styles.columnDetails}>
      <Text style={styles.textLabel}>{obj.description}</Text>
      <Text style={styles.boxText}>
        <Text style={styles.textSmall}>
          {obj.value !== '' ?
            obj.value : ' '}
        </Text>
      </Text>
    </View>
  </View>
);
// Create styles
const styles = StyleSheet.create({
  page: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30
  },
  section: {
    flexDirection: 'row',
  },
  imageSection: {
    padding: 20,
    marginBottom: 10,
    borderBottom: 1,
    borderBottomColor: '#555555',
    //backgroundColor: '#00548d',
    flexDirection: 'row',
  },
  titleHeader: {
    flexDirection: 'column',
    flexGrow: 1,
    textAlign: 'center',
    color: '#666',
    fontSize: 16,
    fontWeight: 'ultrabold'
  },
  footerSection: {
    padding: 10,
    paddingTop: 30,
    borderTop: 1,
    borderBottomColor: '#555555',
    color: '#fff',
    backgroundColor: '#00548d',
    flexDirection: 'row',
    position: 'relative',
  },
  columnDetails: {
    flexDirection: 'column',
    flexGrow: 1,
  },
  paragraph: {
    fontSize: 8,
    justifyContent: 'space-between',
    marginTop: 10,
    paddingBottom: 10
  },
  textLarge: {
    fontSize: 12,
    marginTop: 5,
    paddingTop: 5,
  },
  subtitle: {
    color: '#13417a',
    fontSize: 9,
    marginTop: 5,
    paddingTop: 5,
    fontWeight: 700
  },
  textLabel: {
    fontSize: 6,
    marginTop: 5,
    paddingTop: 5,
    marginBottom: 1
  },
  textLabelBottom: {
    fontSize: 6,
    marginBottom: 5,
    paddingBottom: 5,
    marginTop: 1
  },
  textSmall: {
    fontSize: 9,
    marginTop: 5,
    paddingTop: 5,
  },
  boxText: {
    border: 0.5,
    borderRadius: 5,
    borderBottomColor: '#555555',
    padding: 5,
  },
  boxDeclarationSignature: {
    borderBottom: 0.5,
    borderBottomColor: '#555555',
    padding: 5,
    lineHeight: 6,
    flexGrow: 4,
    alignSelf: "center",
    width: 270, 
    height: 50
  },
  boxDeclarationSignatureByImg: {
    borderBottom: 0.5,
    borderBottomColor: '#555555',
    padding: 5,
    lineHeight: 6,
    flexGrow: 4,
    alignSelf: "center",
    paddingRight: 35, 
    paddingLeft: 35,
  },
  signatureImg: {
    width: 200, 
    height: 50
  },
  boxDeclarationDate: {
    //border: 0.5,
    borderBottomColor: '#555555',
    padding: 5,
    lineHeight: 6,
    flexGrow: 2
  },
  boxDeclarationFullName: {
    //border: 0.5,
    borderBottomColor: '#555555',
    padding: 5,
    lineHeight: 2
  },
  bulletPoint: {
    width: 6
  },
  spaceTopBottom: {
    paddingBottom: 12
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  }
});

//Document for Steps
/* --------------------STEP 1------------------- */
const Generalbusinessinformation = (obj, addObj) => (
  <View>
    <Text style={styles.subtitle}>{getResource('Title_Corp_1')}</Text>
    <Text style={styles.textLabel}>{getResource('Label_CompanyName')}</Text>
    <Text style={styles.boxText}>
      <Text style={styles.textSmall}>
        {obj.companyName !== '' ? obj.companyName : ' '}
      </Text>
    </Text>
    <Text style={styles.textLabel}>{getResource('Label_Business_Telephone')}</Text>
    <Text style={styles.boxText}>
      <Text style={styles.textSmall}>
        {obj.ResidentialPhoneNumber !== '' ? obj.ResidentialPhoneNumber : ' '}
      </Text>
    </Text>
    {/* <Text style={styles.textLabel}>
      {getResource('Label_Business_Country_Incorporation')}
    </Text>
    <Text style={styles.boxText}>
      <Text style={styles.textSmall}>
        {obj.countryName !== '' ? obj.countryName : ' '}
      </Text>
    </Text> */}

    {/* <Text style={styles.textLabel}>
      {getResource('Label_State') + ' of Incorporation'}
    </Text>
    <Text style={styles.boxText}>
      <Text style={styles.textSmall}>
        {obj.stateName !== '' ? obj.stateName : ' '}
      </Text>
    </Text> */}

    <Text style={styles.textLabel}>
      {getResource('Label_Business_Website')}
    </Text>
    <Text style={styles.boxText}>
      <Text style={styles.textSmall}>
        {obj.website !== '' ? obj.website : ' '}
      </Text>
    </Text>

    {/* <Text style={styles.textLabel}>
      {getResource('Label_AffiliateReferredBy')}
    </Text>
    <Text style={styles.boxText}>
      <Text style={styles.textSmall}>
        {obj.AffiliateReferredBy !== '' ? obj.AffiliateReferredBy : ' '}
      </Text>
    </Text> */}


    {
      addObj.step1.length > 0 &&
      <View>

        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>

        {
          addObj.step1.map((detail) => {
            return (addPropertiesStep(detail))
          })
        }
      </View>
    }
  </View>
);

/* --------------------STEP 2------------------- */
const PrincipalPlaceOfBusiness = (obj, addObj) => (
  <View>
    <Text style={styles.subtitle}>{getResource('Title_Corp_2')}</Text>
    <Text style={styles.textSmall}>{getResource('Subtitle_Corp_ResidentialAddress')}</Text>
    <Text style={styles.textLabel}>{getResource('Label_Business_StreetAddress')}</Text>
    <Text style={styles.boxText}>
      <Text style={styles.textSmall}>
        {(obj.BusinessAddressStreet !== '' && obj.BusinessAddressStreet != null) ?
          obj.BusinessAddressStreet : ' '}
      </Text>
    </Text>
    <Text style={styles.textLabel}>{getResource('Label_Business_AddressLine2')}</Text>
    <Text style={styles.boxText}>
      <Text style={styles.textSmall}>
        {(obj.BusinessAddressLine2 !== '' && obj.BusinessAddressLine2 != null) ?
          obj.BusinessAddressLine2 : ' '}
      </Text>
    </Text>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_Business_City')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.BusinessAddressCityName !== '' && obj.BusinessAddressCityName != null) ?
              obj.BusinessAddressCityName : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_Business_State')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.BusinessAddressStateName !== '' && obj.BusinessAddressStateName != null) ?
              obj.BusinessAddressStateName : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_Business_Country')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.countryName !== '' && obj.countryName != null) ?
              obj.countryName : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_Business_Postal')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.BusinessAddressPostalCode !== '' && obj.BusinessAddressPostalCode != null) ?
              obj.BusinessAddressPostalCode : ' '}
          </Text>
        </Text>
      </View>
    </View>
    {
      addObj.step2.length > 0 &&
      <View>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>
        {
          addObj.step2.map((detail) => {
            return (addPropertiesStep(detail))
          })
        }
      </View>
    }
  </View>
);

/* --------------------STEP 3------------------- */
const ProductsAndServices = (addObj) => (
  <View>
    <Text style={styles.subtitle}>{getResource('Title_Corp_3')}</Text>
    {
      addObj.step3.length > 0 &&
      <View>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>
        {
          addObj.step3.map((detail) => {
            return (addPropertiesStep(detail))
          })
        }
      </View>
    }
  </View>
);

/* --------------------STEP 4------------------- */
const ForeignExchangeForwards = (addObj) => (
  <View>
    <Text style={styles.subtitle}>{getResource('Title_Corp_4')}</Text>
    {
      addObj.step4.length > 0 &&
      <View>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>
        {
          addObj.step4.map((detail) => {
            return (addPropertiesStep(detail))
          })
        }
      </View>
    }
  </View>
);


/* --------------------STEP 5------------------- */
const Accountprimarycontact = (obj, director) => (
  <View>
    <View style={styles.section} break={true}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('PrimaryContact_Subtitle')}{" " + director}</Text>
      </View>
    </View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_FirstName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['FirstName0' + director] !== '' && obj['FirstName0' + director] != null) ?
              obj['FirstName0' + director] : ' '}
          </Text>
        </Text>
        
        <Text style={styles.textLabel}>{getResource('Label_DateOfBirth')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['DateOfBirth0' + director] !== '' && obj['DateOfBirth0' + director] != null) ?
              obj['DateOfBirth0' + director] : ' '}
          </Text>
        </Text>
        
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>    

        <Text style={styles.textLabel}>{getResource('Label_MiddleName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
              {(obj['ShortName0' + director] !== '' && obj['ShortName0' + director] != null) ?
                obj['ShortName0' + director] : ' '}
          </Text>
        </Text>
        
        <Text style={styles.textLabel}>{getResource('Label_Citizenship')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['CitizenshipName0' + director] !== '' && obj['CitizenshipName0' + director] != null) ?
              obj['CitizenshipName0' + director] : ' '}
          </Text>
        </Text>
        

        {/* <Text style={styles.textLabel}>{getResource('Checkbox_Female')}</Text>
            <Text style={styles.textLabel}>{getResource('Checkbox_Male')}</Text> */}
      </View>
      {/*COLUMN 3*/}
      <View style={styles.columnDetails}>  
      <Text style={styles.textLabel}>{getResource('Label_Surname')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
              {(obj['LastName0' + director] !== '' && obj['LastName0' + director] != null) ?
                obj['LastName0' + director] : ' '}
          </Text>
        </Text>
      </View>
    </View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('Subtitle_ResidentialAddress')}</Text>
      </View>
    </View>
        <Text style={styles.textLabel}>{getResource('Label_StreetAddress')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
              {(obj['ResidentialAddressStreet0' + director] !== '' && obj['ResidentialAddressStreet0' + director] != null) ?
                obj['ResidentialAddressStreet0' + director] : ' '}
          </Text>
        </Text>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_City')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
              {(obj['ResidentialAddressCity0' + director] !== '' && obj['ResidentialAddressCity0' + director] != null) ?
                obj['ResidentialAddressCity0' + director] : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_Country')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
              {(obj['countryName0' + director] !== '' && obj['countryName0' + director] != null) ?
                obj['countryName0' + director] : ' '}
          </Text>
        </Text>        
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
      <Text style={styles.textLabel}>{getResource('Label_State')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
              {(obj['ResidentialAddressStateName0' + director] !== '' && obj['ResidentialAddressStateName0' + director] != null) ?
                obj['ResidentialAddressStateName0' + director] : ' '}
          </Text>
        </Text>

        <Text style={styles.textLabel}>{getResource('Label_Postal')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
              {(obj['ResidentialAddressPostalCode0' + director] !== '' && obj['ResidentialAddressPostalCode0' + director] != null) ?
                obj['ResidentialAddressPostalCode0' + director] : ' '}
          </Text>
        </Text>
      </View>
    </View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('SubTitle_ContactNumbers')}</Text>
      </View>
    </View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_MainContactNumber')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
              {(obj['ResidentialPhoneNumber0' + director] !== '' && obj['ResidentialPhoneNumber0' + director] != null) ?
                obj['ResidentialPhoneNumber0' + director] : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_Email')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
              {(obj['EmailAddress0' + director] !== '' && obj['EmailAddress0' + director] != null) ?
                obj['EmailAddress0' + director] : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_SSN')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
              {(obj['SSN0' + director] !== '' && obj['SSN0' + director] != null) ?
                obj['SSN0' + director] : ' '}
          </Text>
        </Text>
      </View>
    </View>

    {/*Personal Identification */}
    <View style={styles.section} break={true}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('SubTitle_PersonalIdentification')}</Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_Country')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj['countryNameIdentification0' + director] !== '' && obj['countryNameIdentification0' + director] != null) ?
              obj['countryNameIdentification0' + director] : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      {
        obj['documentTypeID0' + director].includes('Identity') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_NumberIdentification')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj['numberIdentification0' + director] !== '' && obj['numberIdentification0' + director] != null) ?
                obj['numberIdentification0' + director] : ' '}
            </Text>
          </Text>
        </View>
      }
      {
        obj['documentTypeID0' + director].includes('Driving') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_LicenseNumber')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj['DriverLicenseNumber0' + director] !== '' && obj['DriverLicenseNumber0' + director] != null) ?
                obj['DriverLicenseNumber0' + director] : ' '}
            </Text>
          </Text>
        </View>
      }
      {
        obj['documentTypeID0' + director].includes('Passport') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_PassportNumber')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj['PassportNumber0' + director] !== '' && obj['PassportNumber0' + director] != null) ?
                obj['PassportNumber0' + director] : ' '}
            </Text>
          </Text>
        </View>
      }
    </View>

    <View>
    {
      obj['valuesOfAdditionalPropertiesDefs'+director].length > 0 &&
      <View>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>
        {
          obj['valuesOfAdditionalPropertiesDefs'+director].map((detail) => {
            return (addPropertiesStep(detail))
          })
        }
      </View>
    }
  </View>

  </View>
);

/* --------------------STEP 6------------------- */
const DirectorAndAppointedOfficer = (obj, addObj) => (
  <View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_FirstName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.FirstName!== '' && obj.FirstName != null) ?
              obj.FirstName : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_DateOfBirth')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.DateOfBirth !== '' && obj.DateOfBirth != null) ?
              obj.DateOfBirth : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_MiddleName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ShortName !== '' && obj.ShortName != null) ?
              obj.ShortName : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 3 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_Surname')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.LastName !== '' && obj.LastName != null) ?
              obj.LastName : ' '}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('Subtitle_ResidentialAddress')}</Text>
        <Text style={styles.textLabel}>{getResource('Label_StreetAddress')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialAddressStreet !== '' && obj.ResidentialAddressStreet != null) ?
              obj.ResidentialAddressStreet : ' '}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_City')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialCityId !== '' && obj.ResidentialCityId != null) ?
              obj.ResidentialCityId : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_State')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialAddressStateName !== '' && obj.ResidentialAddressStateName != null) ?
              obj.ResidentialAddressStateName : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_Country')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.countryName !== '' && obj.countryName != null) ?
              obj.countryName : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_Postal')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialAddressPostalCode !== '' && obj.ResidentialAddressPostalCode != null) ?
              obj.ResidentialAddressPostalCode : ' '}
          </Text>
        </Text>
      </View>
    </View>

    {/*Personal Identification */}
    <View style={styles.section} break={true}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('SubTitle_PersonalIdentification')}</Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_Country')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.countryNameIdentification !== '' && obj.countryNameIdentification != null) ?
              obj.countryNameIdentification : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      {
        obj.documentTypeID.includes('Identity') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_NumberIdentification')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.numberIdentification !== '' && obj.numberIdentification != null) ?
                obj.numberIdentification : ' '}
            </Text>
          </Text>
        </View>
      }
      {
        obj.documentTypeID.includes('Driving') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_LicenseNumber')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.DriverLicenseNumber !== '' && obj.DriverLicenseNumber != null) ?
                obj.DriverLicenseNumber : ' '}
            </Text>
          </Text>
        </View>
      }
      {
        obj.documentTypeID.includes('Passport') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_PassportNumber')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.PassportNumber !== '' && obj.PassportNumber != null) ?
                obj.PassportNumber : ' '}
            </Text>
          </Text>
        </View>
      }
    </View>

    {
      addObj.step6.length > 0 &&
      <View>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>
        {
          addObj.step6.map((detail) => {
            return (addPropertiesStep(detail))
          })
        }
      </View>
    }

    
  </View>
);

/* --------------------STEP 7------------------- */
const UltimateBeneficialOwner = (obj, addObj) => (
  <View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_FirstName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.FirstName!== '' && obj.FirstName != null) ?
              obj.FirstName : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_DateOfBirth')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.DateOfBirth !== '' && obj.DateOfBirth != null) ?
              obj.DateOfBirth : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_MiddleName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ShortName !== '' && obj.ShortName != null) ?
              obj.ShortName : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 3 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_Surname')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.LastName !== '' && obj.LastName != null) ?
              obj.LastName : ' '}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('Subtitle_ResidentialAddress')}</Text>
        <Text style={styles.textLabel}>{getResource('Label_StreetAddress')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialAddressStreet !== '' && obj.ResidentialAddressStreet != null) ?
              obj.ResidentialAddressStreet : ' '}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_City')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialCityId !== '' && obj.ResidentialCityId != null) ?
              obj.ResidentialCityId : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_State')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialAddressStateName !== '' && obj.ResidentialAddressStateName != null) ?
              obj.ResidentialAddressStateName : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_Country')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.countryName !== '' && obj.countryName != null) ?
              obj.countryName : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_Postal')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialAddressPostalCode !== '' && obj.ResidentialAddressPostalCode != null) ?
              obj.ResidentialAddressPostalCode : ' '}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('SubTitle_ContactNumbers')}</Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_MainContactNumber')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.ResidentialPhoneNumber !== '' && obj.ResidentialPhoneNumber != null) ?
              obj.ResidentialPhoneNumber : ' '}
          </Text>
        </Text>
      </View>
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_Email')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.EmailAddress !== '' && obj.EmailAddress != null) ?
              obj.EmailAddress : ' '}
          </Text>
        </Text>
      </View>
    </View>

    {/*Personal Identification */}
    <View style={styles.section} break={true}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('SubTitle_PersonalIdentification')}</Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_Country')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.countryNameIdentification !== '' && obj.countryNameIdentification != null) ?
              obj.countryNameIdentification : ' '}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      {
        obj.documentTypeID.includes('Identity') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_NumberIdentification')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.numberIdentification !== '' && obj.numberIdentification != null) ?
                obj.numberIdentification : ' '}
            </Text>
          </Text>
        </View>
      }
      {
        obj.documentTypeID.includes('Driving') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_LicenseNumber')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.DriverLicenseNumber !== '' && obj.DriverLicenseNumber != null) ?
                obj.DriverLicenseNumber : ' '}
            </Text>
          </Text>
        </View>
      }
      {
        obj.documentTypeID.includes('Passport') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_PassportNumber')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.PassportNumber !== '' && obj.PassportNumber != null) ?
                obj.PassportNumber : ' '}
            </Text>
          </Text>
        </View>
      }
    </View>

    {
      addObj.step7.length > 0 &&
      <View>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>
        {
          addObj.step7.map((detail) => {
            return (addPropertiesStep(detail))
          })
        }
      </View>
    }

    
  </View>
);

/* --------------------STEP 5------------------- */
const TradingRequirements = (obj) => (
  <View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        {/*  ------------STEP 5--------------*/}
        <Text style={styles.subtitle}>{getResource('Title_Corp_5')}</Text>
        <Text style={styles.textLabel}>{getResource('Label_CurrenciesInterested')}</Text>
      </View>
    </View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>
          {
            obj.checkEUR &&
            <Image src={checked} style={styles.bulletPoint} />
          }
          {
            !obj.checkEUR &&
            <Image src={checkbox} style={styles.bulletPoint} />
          } {getResource('Radio_EUR')}</Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>
          {
            obj.checkGBP &&
            <Image src={checked} style={styles.bulletPoint} />
          }
          {
            !obj.checkGBP &&
            <Image src={checkbox} style={styles.bulletPoint} />
          } {getResource('Radio_GBP')}</Text>
      </View>
      {/*COLUMN 3 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>
          {
            obj.checkUSD &&
            <Image src={checked} style={styles.bulletPoint} />
          }
          {
            !obj.checkUSD &&
            <Image src={checkbox} style={styles.bulletPoint} />
          } {getResource('Radio_USD')}</Text>
      </View>
      {/*COLUMN 4 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>
          {
            obj.checkAED &&
            <Image src={checked} style={styles.bulletPoint} />
          }
          {
            !obj.checkAED &&
            <Image src={checkbox} style={styles.bulletPoint} />
          } {getResource('Radio_AED')}</Text>
      </View>
      {/*COLUMN 5 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>
          {
            obj.checkOther &&
            <Image src={checked} style={styles.bulletPoint} />
          }
          {obj.CurrenciesInterestedTradingOther}</Text>
      </View>
    </View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_AmountPerMonth')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.AmountTradedMonthIDName !== '' && obj.AmountTradedMonthIDName != null) ?
              obj.AmountTradedMonthIDName : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_PurposeForExchanging')}</Text>
        {!obj.PurposeExchangingCurrencyIDName.includes('Others') &&
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.PurposeExchangingCurrencyIDName !== '' && obj.PurposeExchangingCurrencyIDName != null) ?
                obj.PurposeExchangingCurrencyIDName : ' '}
            </Text>
          </Text>
        }
        {obj.PurposeExchangingCurrencyIDName.includes('Others') &&
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.PurposeExchangingCurrencyOther !== '' && obj.PurposeExchangingCurrencyOther != null) ?
                obj.PurposeExchangingCurrencyOther : ' '}
            </Text>
          </Text>
        }
      </View>
    </View>

    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_HearAboutUs')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {obj.clickRadio}
          </Text>
        </Text>
      </View>
    </View>
    <View style={styles.section}>
      {obj.clickRadio === obj.SalesCallState &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_SalesCall')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.WhereYouHearAboutUsSalesCall !== '' && obj.WhereYouHearAboutUsSalesCall != null) ?
                obj.WhereYouHearAboutUsSalesCall : ' '}
            </Text>
          </Text>
        </View>
      }
      {obj.clickRadio === obj.ReferralState &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_Referral')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.WhereYouHearAboutUsReferral !== '' && obj.WhereYouHearAboutUsReferral != null) ?
                obj.WhereYouHearAboutUsReferral : ' '}
            </Text>
          </Text>
        </View>
      }
      {obj.clickRadio === obj.EventState &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_Event')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.WhereYouHearAboutUsEvent !== '' && obj.WhereYouHearAboutUsEvent != null) ?
                obj.WhereYouHearAboutUsEvent : ' '}
            </Text>
          </Text>
        </View>
      }
      {obj.clickRadio === obj.OtherState &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_Other')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj.WhereYouHearAboutUsOther !== '' && obj.WhereYouHearAboutUsOther != null) ?
                obj.WhereYouHearAboutUsOther : ' '}
            </Text>
          </Text>
        </View>
      }
    </View>
  </View>
);

/* --------------------STEP DECLARATION------------------- */
const Declaration = () => (
  <View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.subtitle}>{getResource('Title_Declaration')}</Text>
        <Text style={styles.paragraph}>{getResource('Label_Declaration')}</Text>
      </View>
    </View>
    <View style={[styles.section, { paddingTop: 10 }]}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        {/**------------SIGNATURE IMAGE----------- */}
        {sessionStorage.getItem('signatureImg') !== 'null' ?
          <Text style={styles.boxDeclarationSignatureByImg}>
            <Text style={styles.paragraph}>
              <Image style={styles.signatureImg} src={sessionStorage.getItem('signatureImg')} />
            </Text>
          </Text>
          :
          <Text style={styles.boxDeclarationSignature}>
            <Text style={styles.paragraph}>
              {' '}
            </Text>
          </Text>
        }

        {/**------------SIGNATURE FULLNAME----------- */}
        {sessionStorage.getItem('signatureImg') !== 'null' ?
          <Text style={[styles.paragraph, { width: 300, textAlign: 'right' }]}>
            {/*Full Name: Daniela Soto*/}
            {
              getResource('Label_FullName') + ': ' + sessionStorage.getItem('signatureFullName')
            }
          </Text> :
          <Text style={[styles.paragraph, { width: 210, textAlign: 'right' }]}>
            {/*Full Name: Daniela Soto*/}
            {
              getResource('Label_FullName') + ': '
            }
          </Text>
        }

        {/**------------SIGNATURE TITLE----------- */}
        {sessionStorage.getItem('signatureImg') !== 'null' ?
          <Text style={[styles.paragraph, { width: 300, textAlign: 'right' }]}>
            {/*Title: Engineer*/}
            {
              getResource('Label_TitleOccupation') + ': ' + sessionStorage.getItem('signatureTitle')
            }
          </Text> :
          <Text style={[styles.paragraph, { width: 210, textAlign: 'right' }]}>
            {/*Full Name: Daniela Soto*/}
            {
              getResource('Label_TitleOccupation') + ': '
            }
          </Text>
        }
        {/**------------SIGNATURE DATE----------- */}
        {sessionStorage.getItem('signatureImg') !== 'null' ?
          <Text style={[styles.paragraph, { width: 300, textAlign: 'right' }]}>
            {/*Full Name: Daniela Soto*/}
            {
              getResource('Label_Date') + ': ' + sessionStorage.getItem('signatureDate')
            }
          </Text> :
          <Text style={[styles.paragraph, { width: 210, textAlign: 'right' }]}>
            {/*Full Name: Daniela Soto*/}
            {
              getResource('Label_Date') + ': '
            }
          </Text>
        }
      </View>
    </View>
  </View>
);
// Create Document Component
export const MyDocumentCorporate = (props) => (
  <Document>
    <Page size="A4" style={styles.page}>
      {/*-----------HEADER--------------*/}
      <View style={styles.imageSection} fixed={true}>
        <Image src={img} />
        <Text style={styles.titleHeader}>Corporate Client Application Form</Text>
      </View>

      <View style={styles.section}>
        <View>
          <Text style={styles.textLarge}>{getResource('Corporate_Title')}</Text>
          <Text style={styles.paragraph}>
            {getResource('Corporate_Paragraph')}
            {getResource('Field_Required')}
          </Text>

          {/* allObjts:{
              PreliminaryDetailsCorporate
              CompanyDetails
              DirectorsDetails
              ShareholderOwner
              TradingRequirements

              Generalbusinessinformation
              PrincipalPlaceOfBusiness
        }*/}
        </View>
      </View>

      {/* ------------STEP 1-------------- */}
      {
        <View style={styles.spaceTopBottom}>
          {
            Generalbusinessinformation(props.obj.Generalbusinessinformation, props.obj.AdditionalProperties)
          }
        </View>
      }
      {/* ------------STEP 2-------------- */}
      {
        <View style={styles.spaceTopBottom} break={true}>
          {
            PrincipalPlaceOfBusiness(props.obj.PrincipalPlaceOfBusiness, props.obj.AdditionalProperties)
          }
        </View>
      }

      {/* ------------STEP 5--------------*/}
      {
        <View break={true}>
          <View style={styles.section}>
            <View style={styles.columnDetails}>
              <Text style={styles.subtitle}>{getResource('Title_Corp_3')}</Text>
            </View>
          </View>
          <View style={styles.spaceTopBottom}>
            {
              Accountprimarycontact(props.obj.Accountprimarycontact, 1)
            }
          </View>
          {
            (props.obj.Accountprimarycontact.trader02) &&
            <View style={styles.spaceTopBottom} break={true}>
              {
                Accountprimarycontact(props.obj.Accountprimarycontact, 2)
              }
            </View>
          }
          {
            (props.obj.Accountprimarycontact.trader03) &&
            <View style={styles.spaceTopBottom} break={true}>
              {
                Accountprimarycontact(props.obj.Accountprimarycontact, 3)
              }
            </View>
          }
          {
            (props.obj.Accountprimarycontact.trader04) &&
            <View style={styles.spaceTopBottom} break={true}>
              {
                Accountprimarycontact(props.obj.Accountprimarycontact, 4)
              }
            </View>
          }
          {
            (props.obj.Accountprimarycontact.trader05) &&
            <View style={styles.spaceTopBottom} break={true}>
              {
                Accountprimarycontact(props.obj.Accountprimarycontact, 5)
              }
            </View>
          }
        </View> 
      }

      {/* ------------STEP 6--------------*/}
      {props.obj.DirectorAndAppointedOfficer != null && <View break={true}>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.subtitle}>{getResource('Title_Corp_6')}</Text>

            <Text style={styles.textLabel}>Have you a Director?</Text>
            <Text style={styles.boxText}>
              <Text style={styles.textSmall}>
                {props.obj.DirectorAndAppointedOfficer.clickRadio}
              </Text>
            </Text>
          </View>
        </View>
        {/* ONLY YES 
          DIRECTOR AND APPOINTER OFFICER*/}
        {
          props.obj.DirectorAndAppointedOfficer.clickRadio === getResource('Checkbox_Yes') &&
          <View style={styles.spaceTopBottom}>
            {
              DirectorAndAppointedOfficer(props.obj.DirectorAndAppointedOfficer, props.obj.AdditionalProperties)
            }
          </View>
        }      
      </View>
     }

     {/* ------------STEP 7--------------*/}
     {props.obj.UltimateBeneficialOwner != null && <View break={true}>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.subtitle}>{getResource('Title_Corp_7')}</Text>

            <Text style={styles.textLabel}>Have you an Ultimate Beneficial Owner?</Text>
            <Text style={styles.boxText}>
              <Text style={styles.textSmall}>
                {props.obj.UltimateBeneficialOwner.clickRadio}
              </Text>
            </Text>
          </View>
        </View>
        {/* ONLY YES 
          DIRECTOR AND APPOINTER OFFICER*/}
        {
          props.obj.UltimateBeneficialOwner.clickRadio === getResource('Checkbox_Yes') &&
          <View style={styles.spaceTopBottom}>
            {
              UltimateBeneficialOwner(props.obj.UltimateBeneficialOwner, props.obj.AdditionalProperties)
            }
          </View>
        }      
      </View>
     }

      {/* ------------STEP 5--------------*/}
      {/*
        props.obj.ShareholderOwner.clickRadio === getResource('Checkbox_Yes') &&
        <View style={styles.spaceTopBottom} break={true}>
          {
            TradingRequirements(props.obj.TradingRequirements)
          }
        </View>
        */
      }

      {/* ------------STEP DECLARATION-------------- */}
      {
        <View style={styles.spaceTopBottom}>
          {
            Declaration()
          }
        </View>
      }

      {/*-----------FOOTER--------------*/}
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed={true} />
    </Page>

  </Document >
);

const returnUrlBlob = (blob) => {
  let url = blob //window.URL.createObjectURL(blob);
  return url;
}

export const returnFileContent = async (document) => {
  return returnUrlBlob(await pdf(document).toBlob())
}