import { genericCallWithBodySession } from "./Networking";

export const generateCode = async (model) => {
    try {
        const response = await genericCallWithBodySession('POST', 'MobileValidation/GenerateCode', model);
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const validateCode = async (model) => {
    try {
        const response = await genericCallWithBodySession('POST', 'MobileValidation/ValidateCode', model);
        return response;
    } catch (error) {
        console.error(error);
    }
};