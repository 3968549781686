import React from 'react';

//USAGE
//<DynamicSelect getValue={this.getCountriesSelectValue.bind(this)} placeholder="Select your item" data={[value: "value", name: "name"]}/>

export default class DynamicSelect extends React.Component {
    state = {
        value: 'select',
        data: [],
        autoFocus: false
    }
    autoRef = (input) => {
        if (this.props.reference != null) {
            this.select_reference = input;
        }
    }
    handleClick() {
        if (this.select_reference) {
            setTimeout(() => {
                this.select_reference.focus();
            }, 100);
        }
    }
    componentDidMount(){
        var propData = this.props.data;
        var propValue = 'select';
        this.setState({ data: propData }, () => {
            if (this.props.objValue != null && this.props.objValue.value !== '') {
                propValue = this.props.objValue.value;
                var obj = { target: { value: this.props.objValue.value } };
                this.change(obj)
            }
            this.setState({ value: propValue }, () => {
                this.props.getValue(this.state.value);
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.objValue != null && this.props.objValue != null) {
            if (prevProps.objValue.value !== this.props.objValue.value) {
                var obj = { target: { value: this.props.objValue.value } };
                this.setState({ value: this.props.objValue.value }, () => {
                    this.change(obj)
                });
            }
        }
        if (prevProps.isAutofocus !== this.props.isAutofocus && this.props.isAutofocus && this.props.autoFocus) {
            this.setState({ autoFocus: this.props.autoFocus }, () => {
                this.select_reference.focus();
                this.handleClick();
                this.props.changeAutoFocus();
            })
        }
    }

    change(event) {
        this.setState({value: event.target.value});
        var helper = {};
        for(var i=0; i<this.props.data.length; i++){
            // eslint-disable-next-line eqeqeq
            if (this.props.data[i].value == event.target.value){
                helper = this.props.data[i];
            }
        }
        helper.controlID = this.props.id;
        helper.value = event.target.value;
        this.props.getValue(helper);
    }
    
    render() {
        var className = "uk-select";
        if(this.props.className != null){
            className = this.props.className;
        }
        var disabled = false;
        if(this.props.disabled != null){
          disabled = this.props.disabled;
        }
        var options = [];
        var placeholder = '';
        var initCount = 0;

        if (this.props.listHasPlaceholder){
            if(this.props.data.length>0){
                placeholder = this.props.data[0].name;
                initCount = 1;
            } else {
                placeholder = 'Loading...';
            }
        } else {
            placeholder = this.props.placeholder;
        }

        if(placeholder !== '' && placeholder != null)
        {
            var placeholderValue = '';
            if(this.props.placeholderValue != null){
                placeholderValue = this.props.placeholderValue;
            }
            options.push(<option key={"select"} value={placeholderValue}>{placeholder}</option>)
        }

        for (var i=initCount; i<this.props.data.length; i++) {
            options.push(<option key={this.props.data[i].value} value={this.props.data[i].value}>{this.props.data[i].name}</option>);
        }
        
        return(
            <div>
                <select
                    name="select_reference"
                    ref={this.autoRef}
                    autoFocus={this.state.autoFocus}
                    className={className}
                    onChange={this.change.bind(this)}
                    value={this.state.value}
                    id={this.props.id}
                    disabled={disabled}
                >
                    {options}
                </select>
            </div>
        );
    }
}