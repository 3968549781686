import React from 'react';
import { IMaskInput } from 'react-imask';

export default class NumberInput extends React.Component {
    handleFocus = (event) => {
        event.target.select();
    }

    render() {
        let type = this.props.type;
        let disabled = false;
        if (this.props.disabled != null) {
            disabled = this.props.disabled;
        }
        let negative = false;
        if (this.props.negative != null) {
            negative = this.props.negative;
        }
        switch (type) {
            case "Integer": {
                let className = this.props.className + " input-number";
                return (<IMaskInput
                    id={this.props.id}
                    mask={Number}
                    className={className}
                    radix="."
                    scale={0}
                    signed={negative}
                    value={this.props.value}
                    unmask={'typed'} // true|false|'typed'
                    // DO NOT USE onChange TO HANDLE CHANGES!
                    // USE onAccept INSTEAD
                    onFocus={this.handleFocus}
                    onAccept={
                        // depending on prop above first argument is
                        // `value` if `unmask=false`,
                        // `unmaskedValue` if `unmask=true`,
                        // `typedValue` if `unmask='typed'`
                        (typedValue, mask) => {
                            let event = {
                                target: {},
                            };
                            let input = mask.el.input;
                            console.log(event.target);
                            console.log(typedValue);
                            event.target.id = input.id;
                            event.target.name = input.name;
                            event.target.value = typedValue;
                            if (this.props.onChange != null) {
                                this.props.onChange(event);
                            }
                        }
                    }
                    // ...and more mask props in a guide

                    // input props also available
                    placeholder={this.props.placeholder}
                    disabled={disabled}
                />);
            }
            case "Rate": {
                let className = this.props.className + " input-number";
                let defaultScale = 4;
                if (this.props.scale != null) {
                    defaultScale = this.props.scale
                }
                let padFractional = true;
                if (defaultScale === 0) {
                    padFractional = false;
                }
                return (<IMaskInput
                    id={this.props.id}
                    mask={Number}
                    className={className}
                    radix="."
                    signed={negative}
                    mapToRadix={[","]}
                    scale={defaultScale}
                    padFractionalZeros={padFractional}
                    value={this.props.value}
                    unmask={'typed'} // true|false|'typed'
                    // DO NOT USE onChange TO HANDLE CHANGES!
                    // USE onAccept INSTEAD
                    onFocus={this.handleFocus}
                    onAccept={
                        // depending on prop above first argument is
                        // `value` if `unmask=false`,
                        // `unmaskedValue` if `unmask=true`,
                        // `typedValue` if `unmask='typed'`
                        (typedValue, mask) => {
                            let event = {
                                target: {},
                            };
                            let input = mask.el.input;
                            console.log(event.target);
                            console.log(typedValue);
                            event.target.id = input.id;
                            event.target.name = input.name;
                            event.target.value = typedValue;
                            if (this.props.onChange != null) {
                                this.props.onChange(event);
                            }
                        }
                    }
                    // ...and more mask props in a guide

                    // input props also available
                    placeholder={this.props.placeholder}
                    disabled={disabled}
                />);
            }
            case "Currency": {
                let className = this.props.className + " input-number";
                let defaultScale = 2;
                if (this.props.scale != null) {
                    defaultScale = this.props.scale
                }
                let padFractional = true;
                if (defaultScale === 0) {
                    padFractional = false;
                }

                return (<IMaskInput
                    id={this.props.id}
                    mask={Number}
                    className={className}
                    radix="."
                    thousandsSeparator=","
                    scale={defaultScale}
                    signed={negative}
                    padFractionalZeros={padFractional}
                    value={this.props.value}
                    unmask={'typed'} // true|false|'typed'
                    // DO NOT USE onChange TO HANDLE CHANGES!
                    // USE onAccept INSTEAD
                    onFocus={this.handleFocus}
                    onAccept={
                        // depending on prop above first argument is
                        // `value` if `unmask=false`,
                        // `unmaskedValue` if `unmask=true`,
                        // `typedValue` if `unmask='typed'`
                        (typedValue, mask) => {
                            let event = {
                                target: {},
                            };
                            let input = mask.el.input;
                            console.log(event.target);
                            console.log(typedValue);
                            event.target.id = input.id;
                            event.target.name = input.name;
                            event.target.value = typedValue;
                            if (this.props.onChange != null) {
                                this.props.onChange(event);
                            }
                        }
                    }
                    // ...and more mask props in a guide

                    // input props also available
                    placeholder={this.props.placeholder}
                    disabled={disabled}
                />);
            }
            case "PhoneNumber": {
                let className = this.props.className + " input-number";
                let defaultScale = 2;
                if (this.props.scale != null) {
                    defaultScale = this.props.scale
                }
                let padFractional = true;
                if (defaultScale === 0) {
                    padFractional = false;
                }

                return (<IMaskInput
                    id={this.props.id}
                    mask='(000) 000-0000'
                    className={className}
                    radix="."
                    thousandsSeparator=","
                    scale={defaultScale}
                    signed={negative}
                    padFractionalZeros={padFractional}
                    value={this.props.value}
                    unmask={'typed'} // true|false|'typed'
                    // DO NOT USE onChange TO HANDLE CHANGES!
                    // USE onAccept INSTEAD
                    onFocus={this.handleFocus}
                    onAccept={
                        // depending on prop above first argument is
                        // `value` if `unmask=false`,
                        // `unmaskedValue` if `unmask=true`,
                        // `typedValue` if `unmask='typed'`
                        (typedValue, mask) => {
                            let event = {
                                target: {},
                            };
                            let input = mask.el.input;
                            console.log(event.target);
                            console.log(typedValue);
                            event.target.id = input.id;
                            event.target.name = input.name;
                            event.target.value = typedValue;
                            if (this.props.onChange != null) {
                                this.props.onChange(event);
                            }
                        }
                    }
                    // ...and more mask props in a guide

                    // input props also available
                    placeholder={this.props.placeholder}
                    disabled={disabled}
                />);
            }
            default:
                return (<div></div>);
        }
    }
}