import React from 'react';
import PhoneInput from 'react-phone-input-2';
import AppContext from '../../AppContext';
import { formatDate, formatStringToDate, formatValidInput } from '../../helper/FormatHelper';
import { getDefaultMobileCountry } from '../../helper/PackageJsonHelper';
import { getResource } from '../../helper/ResourcesHelper';
import { validate } from '../../helper/ValidateHelper';
import { getStatesByCountry } from '../../networking/NetworkingRegForm';
import DynamicSearchSelect from '../shared/DynamicSearchSelect';
const today = new Date();
const defaultMobileCountry = getDefaultMobileCountry();

export default class GeneralBusinessInformation extends React.Component {
    static contextType = AppContext;
    state = {
        company: {
            companyName: '',
            companytypeID: '',
            countryID: '',
            countryName: '',
            stateID: '',
            stateName: '',
            companytypeName: '',
            naicsID: '',
            naicsName: '',
            FederalTaxID: '',
            FederalTaxName: '',
            registrationNumber: '',
            DateOfBirth: '',
            website: '',
            ResidentialPhoneNumber: '',
            doingBusinessAs: '',

            OtherState: '',
            AffiliateReferredBy: '',
            IndustrySector: ''
        },
        validations: {
            companyName: true,
            companyStructure: true,
            doingBusinessAs: true,
            ResidentialPhoneNumber: true,
            website: true,
            dateOfFormation: true,
            stateID: true,
            countryID: true,
            FederalTaxID: true,
            companyRegNo: true,
            naicsID: true,
            IndustrySector: true
        },
        naics: [],
        FederalTax: [],
        companytype: [],
        statesByCountryID:[]
    };

    componentDidMount() {
        this.context.validated(this.validateRequiredInputs(this.state.company));
        this.context.validatedPartial(this.validateRequiredInputsPartial(this.state.company), 1);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.notFirstLoad !== this.props.notFirstLoad && !this.props.notFirstLoad) {
            this.validateRequiredFields();
        }
        if (prevProps.notFirstLoadPartial !== this.props.notFirstLoadPartial && !this.props.notFirstLoadPartial) {
            this.validateRequiredFieldsPartial();
        }
        if (prevProps.objGeneralbusinessinformation !== this.props.objGeneralbusinessinformation && this.props.objGeneralbusinessinformation != null) {
            this.setState({ company: this.props.objGeneralbusinessinformation }, () => {
                if(this.props.objGeneralbusinessinformation.countryID != '')
                {
                    // debugger;
                    this.setState({ ...this.state.company, countryID: this.props.objGeneralbusinessinformation.countryID});
                }
                if(this.props.objGeneralbusinessinformation.stateID != '')
                {
                    this.setState({ ...this.state.company, stateID: this.props.objGeneralbusinessinformation.stateID});
                }
                this.context.validated(this.validateRequiredInputs(this.state.company));
                this.context.validatedPartial(this.validateRequiredInputsPartial(this.state.company), 1);
                this.props.Generalbusinessinformation(this.state.company);
            });
        }
    }

    ageVerification = () => {
        const dateOfBirth = formatStringToDate(this.state.company.DateOfBirth);
        //const today = new Date();
        let age = today.getFullYear() - dateOfBirth.getFullYear();
        const m = today.getMonth() - dateOfBirth.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < dateOfBirth.getDate())) {
            age--;
        }
        return age >= 18;
    }

    validateRequiredFields = () => {
        const fields = [
            { displayName: getResource("Label_CompanyName"), validateName: 'companyName', value: this.state.company.companyName, type: 'string' },
            //{ displayName: getResource("Label_CompanyStructure"), validateName: 'companyStructure', value: this.state.company.companytypeID, type: 'string' },
            //{ displayName: 'Doing Business As', validateName: 'doingBusinessAs', value: this.state.company.doingBusinessAs, type: 'string' },
            { displayName: 'Business Telephone No', validateName: 'ResidentialPhoneNumber', value: this.state.company.ResidentialPhoneNumber, type: 'string' },
            { displayName: 'Business website', validateName: 'website', value: this.state.company.website, type: 'string' },
            //{ displayName: 'Date of Formation', validateName: 'dateOfFormation', value: this.state.company.DateOfBirth, type: 'string' },
            // { displayName: 'Country of Incorporation', validateName: 'countryID', value: this.state.company.countryID, type: 'string' },
            //{ displayName: 'Federal Tax ID', validateName: 'FederalTaxID', value: this.state.company.FederalTaxID, type: 'string' },
            { displayName: 'IndustrySector', validateName: 'IndustrySector', value: this.state.company.IndustrySector, type: 'string' },
            //{ displayName: 'Business Registration No', validateName: 'companyRegNo', value: this.state.company.registrationNumber, type: 'string' },
            //{ displayName: 'NAICS', validateName: 'naicsID', value: this.state.company.naicsID, type: 'string' },
        ];/* 
        if (this.state.company.DateOfBirth !== '') {
            fields.push({ displayName: 'Date of Formation' + ': Must be older than 18 years', validation: this.ageVerification });
        } */

        const [, validations,] = validate(fields);
        this.setState({ validations });
    }
    validateRequiredInputs = (company) => {
        const fields = [
            { displayName: getResource("Label_CompanyName"), validateName: 'companyName', value: company.companyName, type: 'string' },
            //{ displayName: getResource("Label_CompanyStructure"), validateName: 'companyStructure', value: company.companytypeID, type: 'string' },
            //{ displayName: 'Business Registration No', validateName: 'companyRegNo', value: company.registrationNumber, type: 'string' },
            // { displayName: 'Country of Incorporation', validateName: 'countryID', value: company.countryID, type: 'string' },
            //{ displayName: 'NAICS', validateName: 'naicsID', value: company.naicsID, type: 'string' },
            //{ displayName: 'Federal Tax ID', validateName: 'FederalTaxID', value: company.FederalTaxID, type: 'string' },
            { displayName: 'IndustrySector', validateName: 'IndustrySector', value: company.IndustrySector, type: 'string' },
            //{ displayName: 'Date of Formation', validateName: 'dateOfFormation', value: company.DateOfBirth, type: 'string' },
            { displayName: 'Business website', validateName: 'website', value: company.website, type: 'string' },
            { displayName: 'Business Telephone No', validateName: 'ResidentialPhoneNumber', value: company.ResidentialPhoneNumber, type: 'string' },
            //{ displayName: 'Doing Business As', validateName: 'doingBusinessAs', value: company.doingBusinessAs, type: 'string' },
        ];

        const [valid, , errors] = validate(fields);
        return { valid, errors };
    }

    validateRequiredFieldsPartial = () => {
        const fields = [
            { displayName: getResource("Label_CompanyName"), validateName: 'companyName', value: this.state.company.companyName, type: 'string' },
        ];

        const [, validations,] = validate(fields);
        this.setState({ validations });
    }
    validateRequiredInputsPartial = (company) => {
        const fields = [
            { displayName: getResource("Label_CompanyName"), validateName: 'companyName', value: company.companyName, type: 'string' },
        ];

        const [valid, , errors] = validate(fields);
        return { valid, errors };
    }
    handleChangeCompanyName = (event) => {
        const newCompany = { ...this.state.company, companyName: event.target.value };
        this.setState({
            company: newCompany,
            validations: { ...this.state.validations, companyName: true }
        });
        this.context.validated(this.validateRequiredInputs(newCompany));
        this.context.validatedPartial(this.validateRequiredInputsPartial(newCompany), 1);
        this.props.Generalbusinessinformation(newCompany);
    }
    handleChangeDoingBusinessAs = (event) => {
        const newCompany = { ...this.state.company, doingBusinessAs: event.target.value };
        this.setState({
            company: newCompany,
            validations: { ...this.state.validations, doingBusinessAs: true }
        });
        this.context.validated(this.validateRequiredInputs(newCompany));
        this.context.validatedPartial(this.validateRequiredInputsPartial(newCompany), 1);
        this.props.Generalbusinessinformation(newCompany);
    }
    handleChangeCompanyStructure = (value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    const newCompany = { ...this.state.company, companytypeID: value.value, companytypeName: value.name };
                    this.setState({
                        company: newCompany,
                        validations: { ...this.state.validations, companyStructure: true }
                    });
                    this.context.validated(this.validateRequiredInputs(newCompany));
                    this.context.validatedPartial(this.validateRequiredInputsPartial(newCompany), 1);
                    this.props.Generalbusinessinformation(newCompany);
                }
            }
        }
    }
    handleChangeRegistrationNumber = (event) => {
        const newCompany = { ...this.state.company, registrationNumber: event.target.value };
        this.setState({
            company: newCompany,
            validations: { ...this.state.validations, companyRegNo: true }
        });
        this.context.validated(this.validateRequiredInputs(newCompany));
        this.context.validatedPartial(this.validateRequiredInputsPartial(newCompany), 1);
        this.props.Generalbusinessinformation(newCompany);
    }
    handleChangeCompanyWebsite = (event) => {
        const newCompany = { ...this.state.company, website: event.target.value };
        this.setState({
            company: newCompany,
            validations: { ...this.state.validations, website: true }
        });
        this.context.validated(this.validateRequiredInputs(newCompany));
        this.context.validatedPartial(this.validateRequiredInputsPartial(newCompany), 1);
        this.props.Generalbusinessinformation(newCompany);
    }

    handleAffiliateReferredBy = (event) => {
        const newCompany = { ...this.state.company, AffiliateReferredBy: event.target.value };
        this.setState({
            company: newCompany
        });
        this.context.validated(this.validateRequiredInputs(newCompany));
        this.context.validatedPartial(this.validateRequiredInputsPartial(newCompany), 1);
        this.props.Generalbusinessinformation(newCompany);
    }

    handleUpdateCountry = (value) => {
        // debugger;
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    const newCompany = { ...this.state.company, countryID: value.value, countryName: value.name, BusinessAddressStateId: 0, OtherState: '', };
                    this.setState({
                        company: newCompany,
                        validations: { ...this.state.validations, countryID: true }
                    }, () => {
                        this.searchState(value.value);
                        this.context.validated(this.validateRequiredInputs(newCompany));
                        this.context.validatedPartial(this.validateRequiredInputsPartial(newCompany), 1);
                        this.props.Generalbusinessinformation(newCompany);
                    });
                } else {
                    const newCompany = { ...this.state.company, countryID: '', countryName: '' };
                    this.setState({
                        company: newCompany
                    }, () => {
                        this.context.validated(this.validateRequiredInputs(newCompany));
                        this.context.validatedPartial(this.validateRequiredInputsPartial(newCompany), 1);
                        this.props.Generalbusinessinformation(newCompany);
                    });
                }
            }
        }
    }
    searchState = (countryID) => {
        if (countryID != null && countryID !== '') {
            getStatesByCountry(countryID, 0, 0).then(
                (value) => {
                    if (value.states) {
                        const states = value.states.map(c => ({ ...c, name: c["State Name"], value: c["State Code"] }));
                        this.setState({ statesByCountryID: states });
                    } else {
                        this.setState({ statesByCountryID: [] });
                    }
                }
            );
        }
    }
    handleUpdateState = (event) => {
        this.setState({
            validations: { ...this.state.validations, stateID: true },
            company: { ...this.state.company, OtherState: event.target.value, stateID: '' }
        },
            () => {
                this.context.validated(this.validateRequiredInputs(this.state.company));
                this.context.validatedPartial(this.validateRequiredInputsPartial(this.state.company), 1);
                this.props.Generalbusinessinformation(this.state.company);
            }
        );
    }
    handleUpdateResidentialAddressState = (value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.setState({
                        company: { ...this.state.company, stateID: value.value, stateName: value.name, OtherState: '' },
                        validations: { ...this.state.validations, stateID: true }
                    }, () => {
                        this.context.validated(this.validateRequiredInputs(this.state.company));
                        this.context.validatedPartial(this.validateRequiredInputsPartial(this.state.company), 1);
                        this.props.Generalbusinessinformation(this.state.company);
                    });
                } else {
                    this.setState({
                        company: { ...this.state.company, stateID: '', stateName: '' }
                    }, () => {
                        this.context.validated(this.validateRequiredInputs(this.state.company));
                        this.context.validatedPartial(this.validateRequiredInputsPartial(this.state.company), 1);
                        this.props.Generalbusinessinformation(this.state.company);
                    });
                }
            }
        }
    }
    handleUpdateNAICS = (value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    const newCompany = { ...this.state.company, naicsID: value.value, naicsName: value.name };
                    this.setState({
                        company: newCompany,
                        validations: { ...this.state.validations, naicsID: true }
                    }, () => {
                        this.context.validated(this.validateRequiredInputs(newCompany));
                        this.props.Generalbusinessinformation(newCompany);
                    });
                } else {
                    const newCompany = { ...this.state.company, naicsID: '', naicsName: '' };
                    this.setState({
                        company: newCompany
                    }, () => {
                        this.context.validated(this.validateRequiredInputs(newCompany));
                        this.context.validatedPartial(this.validateRequiredInputsPartial(newCompany), 1);
                        this.props.Generalbusinessinformation(newCompany);
                    });
                }
            }
        }
    }
    handleChangeFederalTax = (value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.setState({
                        company: { ...this.state.company, FederalTaxID: value.value, FederalTaxName: value.name },
                        validations: { ...this.state.validations, FederalTaxID: true }
                    }, () => {
                        this.context.validated(this.validateRequiredInputs(this.state.company));
                        this.context.validatedPartial(this.validateRequiredInputsPartial(this.state.company), 1);
                        this.props.Generalbusinessinformation(this.state.company);
                    });
                } else {
                    this.setState({
                        company: { ...this.state.company, FederalTaxID: '', FederalTaxName: '' }
                    }, () => {
                        this.context.validated(this.validateRequiredInputs(this.state.company));
                        this.context.validatedPartial(this.validateRequiredInputsPartial(this.state.company), 1);
                        this.props.Generalbusinessinformation(this.state.company);
                    });
                }
            }
        }
    }

    searchIndustrySector = (value) => {
        this.setState({
            company: { ...this.state.company, IndustrySector: value },
            validations: { ...this.state.validations, IndustrySector: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs(this.state.company));
            this.context.validatedPartial(this.validateRequiredInputsPartial(this.state.company), 1);
            this.props.Generalbusinessinformation(this.state.company);
        });
    };

    handleChangeIndustrySector = (value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.setState({
                        company: { ...this.state.company, IndustrySector: value.value },
                        validations: { ...this.state.validations, IndustrySector: true }
                    }, () => {
                        this.context.validated(this.validateRequiredInputs(this.state.company));
                        this.context.validatedPartial(this.validateRequiredInputsPartial(this.state.company), 1);
                        this.props.Generalbusinessinformation(this.state.company);
                    });
                } else {
                    this.setState({
                        company: { ...this.state.company, IndustrySector: '' }
                    }, () => {
                        this.context.validated(this.validateRequiredInputs(this.state.company));
                        this.context.validatedPartial(this.validateRequiredInputsPartial(this.state.company), 1);
                        this.props.Generalbusinessinformation(this.state.company);
                    });
                }
            }
        }
    }

    handleUpdateDateOfBirth = (date) => {
        this.setState({
            company: { ...this.state.company, DateOfBirth: formatDate(date) },
            validations: { ...this.state.validations, dateOfFormation: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs(this.state.company));
            this.context.validatedPartial(this.validateRequiredInputsPartial(this.state.company), 1);
            this.props.Generalbusinessinformation(this.state.company);
        });
    }
    handleUpdateResidentialPhoneNumber = (phone) => {
        const newCompany = { ...this.state.company, ResidentialPhoneNumber: '+' + phone };
        this.setState({
            company: newCompany,
            validations: { ...this.state.validations, ResidentialPhoneNumber: true }
        }, () => {
            this.context.validated(this.validateRequiredInputs(this.state.company));
            this.context.validatedPartial(this.validateRequiredInputsPartial(this.state.company), 1);
            this.props.Generalbusinessinformation(this.state.company);
        });
    }
    render() {
        let countries = [];
        countries = this.context != null ? (this.context.countries != null ? this.context.countries : []) : [];
        return (
            <div className="row">
                <div className="col-sm-12 col-xs-12">
                    <div className="row">
                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.companyName)}>
                            <input type="text" className="form-control" id="company_name" name="company_name" value={this.state.company.companyName} onChange={this.handleChangeCompanyName} />
                            <label htmlFor="company_name" className="small">{getResource("Label_CompanyName")}*</label>
                            {!this.state.validations.companyName &&
                                <label id="company_name-error" className="error" for="company_name">Field required.</label>}
                        </div>
                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.ResidentialPhoneNumber)}>
                            <PhoneInput
                                autoFormat
                                country={defaultMobileCountry}
                                placeholder="Enter phone number"
                                copyNumbersOnly={false}
                                countryCodeEditable={false}
                                value={this.state.company.ResidentialPhoneNumber}
                                onChange={this.handleUpdateResidentialPhoneNumber}
                            />
                            <label for="residential_phone_number" className="small">{getResource('Label_Business_Telephone')}*</label>
                            {!this.state.validations.ResidentialPhoneNumber &&
                                <label id="phone_number-error" className="error" for="phone_number">Field required.</label>}
                        </div>
                        {/* <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.countryID)}>
                            <DynamicSelect
                                className="form-control use-select2 select2-selection__placeholder" id="residential_address_country" name="residential_address_country" style={{ width: "100%" }}
                                data-select2-type="simple"
                                data-select2-paging="false"
                                data-select2-placeholder="Select Country"
                                data-rule-required="true"
                                data-msg-required="Field Required"
                                objValue={{ value: this.state.company.countryID, name: this.state.company.countryName }}
                                getValue={this.handleUpdateCountry}
                                listHasPlaceholder={false}
                                placeholder={"Select Country"}
                                data={countries != null ? countries : []} />
                            <label htmlFor="residential_address_country" className="small">{getResource('Label_Business_Country_Incorporation')}</label>
                            {!this.state.validations.countryID &&
                                <label id="country-error" className="error" for="country">Field required.</label>}
                        </div> */}
                        {/* <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.stateID)}>
                            {
                                this.state.statesByCountryID.length > 0 ?
                                    <DynamicSelect
                                        className="form-control use-select2 select2-selection__placeholder" id="residential_address_state02" name="residential_address_state02" style={{ width: "100%" }}
                                        data-select2-type="simple"
                                        data-select2-paging="false"
                                        data-select2-placeholder="Select State"
                                        data-rule-required="true"
                                        data-msg-required="Field Required"
                                        objValue={{ value: this.state.company.stateID, name: this.state.company.stateName }}
                                        getValue={this.handleUpdateResidentialAddressState}
                                        listHasPlaceholder={false}
                                        placeholder={"Select State"}
                                        data={this.state.statesByCountryID} />
                                    :
                                    <input type="text" className="form-control"
                                        id="residential_address_postalcode02"
                                        name="residential_address_postalcode02" maxLength="50"
                                        data-rule-required="true"
                                        data-msg-required="Field Required."
                                        value={this.state.OtherState02}
                                        onChange={this.handleUpdateState.bind(this, 2)} />
                            }

                            <label htmlFor="residential_address_state02" className="small">{getResource('Label_State') + ' of Incorporation'} *</label>
                            {(this.state.validations.ResidentialAddressStateId02 != null && !this.state.validations.ResidentialAddressStateId02) &&
                                <label id="residential_address_state_name-error02" class="error" htmlFor="residential_address_state_name02">Field Required.</label>
                            }
                        </div> */}
                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.website)}>
                            <input type="text" className="form-control" id="website" name="website" value={this.state.company.website} onChange={this.handleChangeCompanyWebsite} />
                            <label htmlFor="website" className="small">{getResource('Label_Business_Website')}*</label>
                            {!this.state.validations.website &&
                                <label id="website-error" className="error" for="website">Field required.</label>}
                        </div>
                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.IndustrySector)}>
                            <DynamicSearchSelect
                                className="form-control use-select2 select2-selection__placeholder"
                                id="IndustrySectorSearch"
                                name="IndustrySector"
                                style={{ width: "100%" }}
                                data-select2-type="simple"
                                data-select2-placeholder="Select ..."
                                data-rule-required="true"
                                data-msg-required="Field Required."
                                objValue={{ value: this.state.company.IndustrySector, name: this.state.company.IndustrySector }}
                                getValue={this.handleChangeIndustrySector}
                                searchFunction={this.searchIndustrySector}
                                data={this.context.lookUpTableList != null ? this.context.lookUpTableList['Industry Sectors'] : []}
                            />
                            {/* <DynamicSelect
                                autoFocus={true}
                                className="form-control use-select2 select2-selection__placeholder" id="IndustrySector" name="IndustrySector" style={{ width: "100%" }}
                                data-select2-type="simple"
                                data-select2-placeholder="Select ..."
                                data-rule-required="true"
                                data-msg-required="Field Required."
                                objValue={{ value: this.state.company.IndustrySector, name: this.state.company.IndustrySector }}
                                getValue={this.handleChangeIndustrySector}
                                listHasPlaceholder={true}
                                data={this.context.lookUpTableList != null ? this.context.lookUpTableList['Industry Sectors'] : []} /> */}
                            <label htmlFor="IndustrySector" className="small">Industry Sector*</label>
                            {!this.state.validations.IndustrySector &&
                                <label id="industry_sector-error" className="error" for="industry_sector">Field required.</label>}
                        </div>
                        {/* <div className="col-sm-6 col-xs-12 form-group">
                            <NumberInput id="affiliate-referred-by" name="affiliate-referred-by" type="Integer" className="form-control" value={this.state.company.AffiliateReferredBy} onChange={this.handleAffiliateReferredBy}/>
                            <label htmlFor="affiliate-referred-by" className="small">{getResource('Label_AffiliateReferredBy')}</label>
                        </div> */}
                        {/**------------------------NEW--------------------- 
                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.doingBusinessAs)}>
                            <input type="text"
                                className="form-control"
                                id="doingBusinessAs"
                                name="doingBusinessAs"
                                value={this.state.company.doingBusinessAs}
                                onChange={this.handleChangeDoingBusinessAs} />
                            <label htmlFor="doingBusinessAs" className="small">Doing Business As*</label>
                            {!this.state.validations.doingBusinessAs &&
                                <label id="doing_business_as-error" className="error" for="doing_business_as">Field required.</label>}
                        </div>
                        <div className={"col-sm-6 col-xs-12 form-group  relative col-middle " + formatValidInput(this.state.validations.dateOfFormation)}>
                            <DatePicker
                                className="form-control"
                                readOnly={true}
                                endDate={today}
                                value={this.state.company.DateOfBirth}
                                onDayChange={this.handleUpdateDateOfBirth} />

                            <label htmlFor="date_of_birth" className="small">Date of Formation*</label>
                            {!this.state.validations.dateOfFormation &&
                                <label id="date_of_incorporation-error" className="error" for="date_of_incorporation">Field required.</label>}
                        </div>
                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.FederalTaxID)}>
                            <DynamicSelect
                                autoFocus={true}
                                className="form-control use-select2 select2-selection__placeholder" id="FederalTaxID" name="FederalTaxID" style={{ width: "100%" }}
                                data-select2-type="simple"
                                data-select2-placeholder="Select ..."
                                data-rule-required="true"
                                data-msg-required="Field Required."
                                objValue={{ value: this.state.company.FederalTaxID, name: this.state.company.FederalTaxName }}
                                getValue={this.handleChangeFederalTax}
                                listHasPlaceholder={true}
                                data={this.context.lookUpTableList != null ? this.context.lookUpTableList['AFEX Business Identification Type'] : []} />
                            <label htmlFor="FederalTaxID" className="small">Federal Tax*</label>
                            {!this.state.validations.FederalTaxID &&
                                <label id="federal_tax-error" className="error" for="federal_tax">Field required.</label>}
                        </div>
                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.companyRegNo)}>
                            <input type="text" className="form-control" id="registration_number" name="registration_number" value={this.state.company.registrationNumber} onChange={this.handleChangeRegistrationNumber} />
                            <label htmlFor="registration_number" className="small">Company Registration No*</label>
                            {!this.state.validations.companyRegNo &&
                                <label id="company_reg_no-error" className="error" for="company_reg_no">Field required.</label>}
                        </div>
                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.naicsID)}>
                            <DynamicSelect
                                className="form-control use-select2 select2-selection__placeholder" id="naicsID" name="naicsID" style={{ width: "100%" }}
                                data-select2-type="simple"
                                data-select2-paging="false"
                                data-select2-placeholder="Select NAICS"
                                data-rule-required="true"
                                data-msg-required="Field Required"
                                objValue={{ value: this.state.company.naicsID, name: this.state.company.naicsName }}
                                getValue={this.handleUpdateNAICS}
                                listHasPlaceholder={true}
                                data={this.context.lookUpTableList != null ? this.context.lookUpTableList['AFEX NAICS Codes'] : []} />
                            <label htmlFor="naicsID" className="small">NAICS*</label>
                            {!this.state.validations.naicsID &&
                                <label id="naics-error" className="error" for="naics">Field required.</label>}
                        </div>


                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.companyStructure)}>
                            <DynamicSelect
                                autoFocus={true}
                                className="form-control use-select2 select2-selection__placeholder" id="companyStructure" name="companyStructure" style={{ width: "100%" }}
                                data-select2-type="simple"
                                data-select2-placeholder="Select ..."
                                data-rule-required="true"
                                data-msg-required="Field Required."
                                objValue={{ value: this.state.company.companytypeID, name: this.state.company.companytypeName }}
                                getValue={this.handleChangeCompanyStructure}
                                listHasPlaceholder={true}
                                data={this.context.lookUpTableList != null ? this.context.lookUpTableList['CompanyType'] : []} />
                            <label htmlFor="company_structure" className="small">{getResource("Label_CompanyStructure")} *</label>
                            {!this.state.validations.companyStructure &&
                                <label id="company_structure-error" className="error" for="company_structure">Field required.</label>}
                        </div>
                        */}
                    </div>
                </div>
            </div>
        );
    }
}