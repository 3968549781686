import { Link } from '@reach/router';
import React from 'react';

const NotFound = ({ location }) => {
    return (
        <div className="container">
            <h1>Not Found</h1>
            <p>The requested URL {location.pathname} was not found on this server</p>
            <Link to="/">Return to home</Link>
        </div>
    );
}

export default NotFound;