import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import SignatureCanvas from "react-signature-canvas";
import AppContext from '../../../AppContext';
import { formatDate, formatValidInput } from '../../../helper/FormatHelper';
import { getCurrentCompanyName } from '../../../helper/PackageJsonHelper';
import { getResource } from '../../../helper/ResourcesHelper';
import { validate } from '../../../helper/ValidateHelper';
import { getCustomerInfoByRequestID } from '../../../networking/Networking';
import { sendEmailMainContact } from '../../../networking/NetworkingRegForm';
import AlertBox from '../../shared/AlertBox';
import LoadingBox from '../../shared/LoadingBox';
import { MyDocumentCorporate, returnFileContent, savePdf } from '../../shared/MyDocumentCorporate';
import { MyDocumentCorporateOther } from '../../shared/MyDocumentCorporateOther';
import { MyDocumentIndividual, returnIndividualFileContent, saveIndividualPdf } from '../../shared/MyDocumentIndividual';
import { MyDocumentIndividualOther } from '../../shared/MyDocumentIndividualOther';


const stylesTitle = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

export const DialogTitle = withStyles(stylesTitle)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default class SignatureForm extends React.Component {
    static contextType = AppContext;
    state = {
        allObjts: {
            PreliminaryDetailsCorporate: null,
            CompanyDetails: null,
            DirectorsDetails: null,
            ShareholderOwner: null,
            TradingRequirements: null,
            AdditionalProperties: { step1: [], step2: [], step3: [], step4: [], step5: [] }
        },
        documents: {
            accountFile: '',
            accountFileName: '',
        },
        
        activeTab: "step-1",
        openPopup:false,
        //signature box
        signatureDataURL: null,
        FullName: '',
        Title: '',
        validations: {
            FullName: true,
            Title: true,
            Signature: true
        },

        showAlert: false,
        title: '',
        message: '',
        errors:[],
        //
        showAttachmentsDialog: false,
        rowClick: false,
        signatureClick: false,
        signatureResponse: false,
        openSaveSignature: false,
        isManual: false,

        signaturePopup: null,
        fileList: [],
        loading: false
    }
    componentDidMount() {
        this.adaptList();
    }
    adaptList = (customer) => {
        this.setState({ FullName: this.props.contactName }, () => {
            getCustomerInfoByRequestID(this.props.requestId).then(
                (json) => {
                    if (json.customer != null) {
                        customer = json.customer;
                        if (customer != null) {
                            sessionStorage.setItem('ActualCustomerID', customer.CustomerId);
                            sessionStorage.setItem('CustomerType', customer.type);
                            sessionStorage.setItem('CustomerUpdateToken', customer.UpdateToken);
                            sessionStorage.setItem('signatureImg', null);
                            sessionStorage.setItem('CustomerPersonID', customer.PersonID);
                            let objPreliminaryDetailsCorporate = {
                                companyName: customer.name,
                                companyStructure: '',
                                registrationNumber: ''
                            };
                            let objCompanyDetails = {
                                AddressId: customer.CustomerAddresses != null ? customer.CustomerAddresses[0].AddressId : '',
                                AddressUpdateToken: customer.CustomerAddresses != null ? customer.CustomerAddresses[0].UpdateToken : '',
                                ResidentialAddressStreet: customer.CustomerAddresses != null ? customer.CustomerAddresses[0].address1 : '',
                                ResidentialAddressLine2: customer.CustomerAddresses != null ? customer.CustomerAddresses[0].address2 : '',
                                countryID: customer.CustomerAddresses != null ? customer.CustomerAddresses[0].countryid : '',
                                countryName: customer.CustomerAddresses != null ? this.countryName(customer.CustomerAddresses[0].countryid) : '',
                                OtherState: '',
                                ResidentialAddressStateId: customer.CustomerAddresses != null ? customer.CustomerAddresses[0].stateid : '',
                                ResidentialAddressStateName: customer.CustomerAddresses != null ? customer.CustomerAddresses[0].statename : '',
                                ResidentialAddressCity: customer.CustomerAddresses != null ? customer.CustomerAddresses[0].city : '',
                                ResidentialAddressPostalCode: customer.CustomerAddresses != null ? customer.CustomerAddresses[0].postalcode : '',
                                clickRadio: getResource('Checkbox_Yes')
                            };
                            let objDirectorsDetails = {};
                            let objShareholderOwner = {};
                            if (customer.Contacts != null) {
                                objShareholderOwner.clickRadio = getResource('Checkbox_Yes');
                                let numContact = 1, numOwner = 1;
                                customer.Contacts.forEach((value, index) => {
                                    if (value.mainContact) {
                                        sessionStorage.setItem('MainContactName', value.firstname + ' ' + value.lastname);
                                        sessionStorage.setItem('MainContactEmail', value.email);

                                        objDirectorsDetails['ContactAddressIdDirector0' + numContact] = value.AddressId != null ? value.AddressId : '';
                                        objDirectorsDetails['ContactAddressUpdateTokenDirector0' + numContact] = value.AddressUpdateToken != null ? value.AddressUpdateToken : '';
                                        objDirectorsDetails['ContactIdDirector0' + numContact] = value.ContactId != null ? value.ContactId : '';
                                        objDirectorsDetails['ContactUpdateTokenDirector0' + numContact] = value.UpdateToken != null ? value.UpdateToken : '';
                                        objDirectorsDetails['saludationIDDirector0' + numContact] = value.Salutation != null ? value.Salutation : '';

                                        objDirectorsDetails['FirstNameDirector0' + numContact] = value.firstname != null ? value.firstname : '';
                                        objDirectorsDetails['ShortNameDirector0' + numContact] = value.middlename != null ? value.middlename : '';
                                        objDirectorsDetails['LastNameDirector0' + numContact] = value.lastname != null ? value.lastname : '';
                                        objDirectorsDetails['DateOfBirthDirector0' + numContact] = value.dateofbirth != null ? value.dateofbirth : '';
                                        objDirectorsDetails['clickRadioGenderDirector0' + numContact] = '';

                                        objDirectorsDetails['ResidentialAddressStreetDirector0' + numContact] = value.address1 != null ? value.address1 : '';
                                        objDirectorsDetails['ResidentialAddressLine2Director0' + numContact] = value.address2 != null ? value.address2 : '';
                                        objDirectorsDetails['countryIDDirector0' + numContact] = value.countryid != null ? value.countryid : '';
                                        objDirectorsDetails['countryIDIdentificationDirector0' + numContact] = value.countryid != null ? value.countryid : '';
                                        objDirectorsDetails['countryNameDirector0' + numContact] = value.countryid != null ? this.countryName(value.countryid) : '';
                                        objDirectorsDetails['countryNameIdentificationDirector0' + numContact] = value.countryid != null ? this.countryName(value.countryid) : '';
                                        objDirectorsDetails['OtherStateDirector0' + numContact] = '';
                                        objDirectorsDetails['ResidentialAddressStateIdDirector0' + numContact] = value.stateid != null ? value.stateid : '';
                                        objDirectorsDetails['ResidentialAddressStateNameDirector0' + numContact] = value.statename != null ? value.statename : '';
                                        objDirectorsDetails['ResidentialAddressCityDirector0' + numContact] = value.city != null ? value.city : '';
                                        objDirectorsDetails['ResidentialAddressPostalCodeDirector0' + numContact] = value.PostalCode != null ? value.PostalCode : '';
                                        objDirectorsDetails['ResidentialPhoneNumberDirector0' + numContact] = value.phonenumber != null ? value.phonenumber : '';
                                        //-----------
                                        objDirectorsDetails['FaxDirector0' + numContact] = value.fax;
                                        objDirectorsDetails['MobileNumberDirector0' + numContact] = value.mobilenumber;
                                        //------------
                                        objDirectorsDetails['EmailAddressDirector0' + numContact] = value.email != null ? value.email : '';
                                        objDirectorsDetails['clickRadioDirector0' + numContact] = value.politicallyexposedperson != null ? value.politicallyexposedperson : getResource('Checkbox_Yes');

                                        objDirectorsDetails['TaskListDirector0' + numContact] = value.ContactTask != null ? value.ContactTask : [];
                                        objDirectorsDetails['documentTypeIDDirector0' + numContact] = value.ContactTask != null ? this.switchDocumentType(value.ContactTask) : [];
                                        objDirectorsDetails['director0' + numContact] = true;

                                        //----Properties
                                        let propertyDirector = this.contactProperty(value.ContactProperties);
                                        if (propertyDirector.length > 0) {
                                            propertyDirector.forEach(element => {
                                                switch (element.number) {
                                                    case 1:
                                                        objDirectorsDetails['numberIdentificationDirector0' + numContact] = element.numberIdentification;
                                                        break;
                                                    case 2:
                                                        objDirectorsDetails['DriverLicenseNumberDirector0' + numContact] = element.numberIdentification;
                                                        break;
                                                    case 3:
                                                        objDirectorsDetails['PassportNumberDirector0' + numContact] = element.numberIdentification;
                                                        break;
                                                    default:
                                                        break;
                                                }
                                            })
                                        }
                                        //Contador
                                        numContact += 1;

                                    } else {
                                        switch (this.contactPropertyAFEX(value.ContactProperties, 'AFEX Roles')) {
                                            case 'DIRECTOR':
                                                objDirectorsDetails['ContactAddressIdDirector0' + numContact] = value.AddressId != null ? value.AddressId : '';
                                                objDirectorsDetails['ContactAddressUpdateTokenDirector0' + numContact] = value.AddressUpdateToken != null ? value.AddressUpdateToken : '';
                                                objDirectorsDetails['ContactIdDirector0' + numContact] = value.ContactId != null ? value.ContactId : '';
                                                objDirectorsDetails['ContactUpdateTokenDirector0' + numContact] = value.UpdateToken != null ? value.UpdateToken : '';
                                                objDirectorsDetails['saludationIDDirector0' + numContact] = value.Salutation != null ? value.Salutation : '';

                                                objDirectorsDetails['FirstNameDirector0' + numContact] = value.firstname != null ? value.firstname : '';
                                                objDirectorsDetails['ShortNameDirector0' + numContact] = value.middlename != null ? value.middlename : '';
                                                objDirectorsDetails['LastNameDirector0' + numContact] = value.lastname != null ? value.lastname : '';
                                                objDirectorsDetails['DateOfBirthDirector0' + numContact] = value.dateofbirth != null ? value.dateofbirth : '';
                                                objDirectorsDetails['clickRadioGenderDirector0' + numContact] = '';

                                                objDirectorsDetails['ResidentialAddressStreetDirector0' + numContact] = value.address1 != null ? value.address1 : '';
                                                objDirectorsDetails['ResidentialAddressLine2Director0' + numContact] = value.address2 != null ? value.address2 : '';
                                                objDirectorsDetails['countryIDDirector0' + numContact] = value.countryid != null ? value.countryid : '';
                                                objDirectorsDetails['countryIDIdentificationDirector0' + numContact] = value.countryid != null ? value.countryid : '';
                                                objDirectorsDetails['countryNameDirector0' + numContact] = value.countryid != null ? this.countryName(value.countryid) : '';
                                                objDirectorsDetails['countryNameIdentificationDirector0' + numContact] = value.countryid != null ? this.countryName(value.countryid) : '';
                                                objDirectorsDetails['OtherStateDirector0' + numContact] = '';
                                                objDirectorsDetails['ResidentialAddressStateIdDirector0' + numContact] = value.stateid != null ? value.stateid : '';
                                                objDirectorsDetails['ResidentialAddressStateNameDirector0' + numContact] = value.statename != null ? value.statename : '';
                                                objDirectorsDetails['ResidentialAddressCityDirector0' + numContact] = value.city != null ? value.city : '';
                                                objDirectorsDetails['ResidentialAddressPostalCodeDirector0' + numContact] = value.PostalCode != null ? value.PostalCode : '';
                                                objDirectorsDetails['ResidentialPhoneNumberDirector0' + numContact] = value.phonenumber != null ? value.phonenumber : '';
                                                //-----------
                                                objDirectorsDetails['FaxDirector0' + numContact] = value.fax;
                                                objDirectorsDetails['MobileNumberDirector0' + numContact] = value.mobilenumber;
                                                //------------
                                                objDirectorsDetails['EmailAddressDirector0' + numContact] = value.email != null ? value.email : '';
                                                objDirectorsDetails['clickRadioDirector0' + numContact] = value.politicallyexposedperson != null ? value.politicallyexposedperson : getResource('Checkbox_Yes');

                                                objDirectorsDetails['TaskListDirector0' + numContact] = value.ContactTask != null ? value.ContactTask : [];
                                                objDirectorsDetails['documentTypeIDDirector0' + numContact] = value.ContactTask != null ? this.switchDocumentType(value.ContactTask) : [];
                                                objDirectorsDetails['director0' + numContact] = true;

                                                //----Properties
                                                let propertyDirector = this.contactProperty(value.ContactProperties);
                                                if (propertyDirector.length > 0) {
                                                    propertyDirector.forEach(element => {
                                                        switch (element.number) {
                                                            case 1:
                                                                objDirectorsDetails['numberIdentificationDirector0' + numContact] = element.numberIdentification;
                                                                break;
                                                            case 2:
                                                                objDirectorsDetails['DriverLicenseNumberDirector0' + numContact] = element.numberIdentification;
                                                                break;
                                                            case 3:
                                                                objDirectorsDetails['PassportNumberDirector0' + numContact] = element.numberIdentification;
                                                                break;
                                                            default:
                                                                break;
                                                        }
                                                    })
                                                }
                                                //Contador
                                                numContact += 1;
                                                break;
                                            case 'SHAREHOLDER':
                                                objShareholderOwner['trader0' + numOwner] = true;
                                                objShareholderOwner['TaskListTrader0' + numOwner] = value.ContactTask != null ? value.ContactTask : [];
                                                objShareholderOwner['documentTypeIDTrader0' + numOwner] = value.ContactTask != null ? this.switchDocumentType(value.ContactTask) : [];
                                                objShareholderOwner['AddressIdTrader0' + numOwner] = value.AddressId;
                                                objShareholderOwner['AddressUpdateTokenTrader0' + numOwner] = value.AddressUpdateToken;
                                                objShareholderOwner['ContactIdTrader0' + numOwner] = value.ContactId;
                                                objShareholderOwner['UpdateTokenTrader0' + numOwner] = value.UpdateToken;
                                                objShareholderOwner['FirstNameTrader0' + numOwner] = value.firstname;
                                                objShareholderOwner['LastNameTrader0' + numOwner] = value.lastname;
                                                objShareholderOwner['DateOfBirthTrader0' + numOwner] = value.dateofbirth != null ? value.dateofbirth : '';
                                                objShareholderOwner['ResidentialAddressStreetTrader0' + numOwner] = value.address1;
                                                objShareholderOwner['ResidentialAddressLine2Trader0' + numOwner] = value.address2;
                                                objShareholderOwner['countryIDTrader0' + numOwner] = value.countryid;
                                                objShareholderOwner['countryIDIdentificationTrader0' + numOwner] = value.countryid;
                                                objShareholderOwner['countryIDDrivingLicenseTrader0' + numOwner] = value.countryid;
                                                objShareholderOwner['countryIDPassportTrader0' + numOwner] = value.countryid;
                                                objShareholderOwner['countryNameIdentificationTrader0' + numOwner] = this.countryName(value.countryid);
                                                objShareholderOwner['countryNameDrivingLicenseTrader0' + numOwner] = this.countryName(value.countryid);
                                                objShareholderOwner['countryNamePassportTrader0' + numOwner] = this.countryName(value.countryid);
                                                objShareholderOwner['ResidentialAddressStateNameTrader0' + numOwner] = value.statename != null ? value.statename : '';

                                                objShareholderOwner['OtherStateTrader0' + numOwner] = '';
                                                objShareholderOwner['ResidentialAddressStateIdTrader0' + numOwner] = value.stateid;
                                                objShareholderOwner['ResidentialAddressCityTrader0' + numOwner] = value.city;
                                                objShareholderOwner['ResidentialAddressPostalCodeTrader0' + numOwner] = value.PostalCode;
                                                objShareholderOwner['ResidentialPhoneNumberTrader0' + numOwner] = value.phonenumber;
                                                objShareholderOwner['MobileNumberTrader0' + numOwner] = '';
                                                objShareholderOwner['EmailAddressTrader0' + numOwner] = value.email;
                                                objShareholderOwner['radio' + numOwner] = value.politicallyexposedperson;

                                                //----Properties
                                                let propertyShareholder = this.contactProperty(value.ContactProperties);
                                                if (propertyShareholder.length > 0) {
                                                    propertyShareholder.forEach(element => {
                                                        switch (element.number) {
                                                            case 1:
                                                                objShareholderOwner['numberIdentificationTrader0' + numOwner] = element.numberIdentification;
                                                                break;
                                                            case 2:
                                                                objShareholderOwner['DriverLicenseNumberTrader0' + numOwner] = element.numberIdentification;
                                                                break;
                                                            case 3:
                                                                objShareholderOwner['PassportNumberTrader0' + numOwner] = element.numberIdentification;
                                                                break;
                                                            default:
                                                                break;
                                                        }
                                                    })
                                                }
                                                //Contador
                                                numOwner += 1;
                                                break;
                                            default: break;
                                        }
                                    }
                                });
                            }
                            let objTradingRequirements = {
                                CurrenciesInterestedTradingOther: '',
                                AmountTradedMonthID: '',
                                PurposeExchangingCurrencyID: '',
                                PurposeExchangingCurrencyIDName: '',
                                PurposeExchangingCurrencyOther: '',
                                clickRadio: getResource('Checkbox_Yes'),
                                WhereYouHearAboutUsSalesCall: '',
                                WhereYouHearAboutUsReferral: '',
                                WhereYouHearAboutUsEvent: '',
                                WhereYouHearAboutUsOther: '',
                                checkEUR: false,
                                checkGBP: false,
                                checkUSD: false,
                                checkAED: false,
                                checkOther: false,
                            };

                            if (customer.CustomerProperties != null) {
                                customer.CustomerProperties.forEach(value => {
                                    switch (value.name) {
                                        case 'HasTraders':
                                            objShareholderOwner.clickRadio = (value.value != null && value.value !== '') ? value.value : getResource('Checkbox_Yes');
                                            break;
                                        case 'Where did you hear about us':
                                            objTradingRequirements.clickRadio = (value.value != null && value.value !== '') ? value.value : getResource('Checkbox_Yes');
                                            break;
                                        case 'Currencies interested in trading':
                                            //all currencies
                                            objTradingRequirements.CurrenciesInterestedTradingOther = value.value;
                                            var x = value.value.split(",");
                                            x.forEach(element => {
                                                switch (element) {
                                                    case 'EUR':
                                                        objTradingRequirements.checkEUR = true;
                                                        objTradingRequirements.CurrenciesInterestedTradingOther = objTradingRequirements.checkOther ? objTradingRequirements.CurrenciesInterestedTradingOther : '';
                                                        break;
                                                    case 'GBP':
                                                        objTradingRequirements.checkGBP = true;
                                                        objTradingRequirements.CurrenciesInterestedTradingOther = objTradingRequirements.checkOther ? objTradingRequirements.CurrenciesInterestedTradingOther : '';
                                                        break;
                                                    case 'USD':
                                                        objTradingRequirements.checkUSD = true;
                                                        objTradingRequirements.CurrenciesInterestedTradingOther = objTradingRequirements.checkOther ? objTradingRequirements.CurrenciesInterestedTradingOther : '';
                                                        break;
                                                    case 'AED':
                                                        objTradingRequirements.checkAED = true;
                                                        objTradingRequirements.CurrenciesInterestedTradingOther = objTradingRequirements.checkOther ? objTradingRequirements.CurrenciesInterestedTradingOther : '';
                                                        break;
                                                    default:
                                                        if (element !== '') {
                                                            objTradingRequirements.checkOther = true;
                                                            objTradingRequirements.CurrenciesInterestedTradingOther = element;
                                                        }
                                                        break;
                                                }
                                            });
                                            break;
                                        case 'Purpose for exchanging currency':
                                            if (objTradingRequirements.PurposeExchangingCurrencyOther === '') {
                                                objTradingRequirements.PurposeExchangingCurrencyID = value.value;
                                                objTradingRequirements.PurposeExchangingCurrencyIDName = value.value;
                                            }
                                            break;
                                        case 'Purpose for exchanging currency - others':
                                            objTradingRequirements.PurposeExchangingCurrencyID = 'Others';
                                            objTradingRequirements.PurposeExchangingCurrencyOther = value.value;
                                            break;
                                        case 'Amount traded per month':
                                            objTradingRequirements.AmountTradedMonthID = value.value;
                                            break;
                                        case 'Where did you hear about us - detail':
                                            objTradingRequirements.WhereYouHearAboutUsSalesCall = value.value;
                                            objTradingRequirements.WhereYouHearAboutUsReferral = value.value;
                                            objTradingRequirements.WhereYouHearAboutUsEvent = value.value;
                                            objTradingRequirements.WhereYouHearAboutUsOther = value.value;
                                            break;
                                        case 'Company Structure':
                                            objPreliminaryDetailsCorporate.companyStructure = value.value;
                                            break;
                                        case 'Company Registration No':
                                            objPreliminaryDetailsCorporate.registrationNumber = value.value;
                                            break;
                                        case 'Trading address same as registered address':
                                            objCompanyDetails.clickRadio = (value.value != null && value.value !== '') ? value.value : getResource('Checkbox_Yes');
                                            break;
                                        default:
                                            break;
                                    }
                                })
                            }

                            this.setState({
                                allObjts: {
                                    ...this.state.allObjts,
                                    PreliminaryDetailsCorporate: objPreliminaryDetailsCorporate,
                                    CompanyDetails: objCompanyDetails,
                                    DirectorsDetails: objDirectorsDetails,
                                    ShareholderOwner: objShareholderOwner,
                                    TradingRequirements: objTradingRequirements,
                                    AdditionalProperties: { step1: [], step2: [], step3: [], step4: [], step5: [] }
                                },
                                //----Expiration Link
                                isCustomerAvailable: true
                            });
                        }
                    } else {
                        this.setState({ isCustomerAvailable: false })
                    }
                }
            );
        })
    }
    countryName = (countryID) => {
        let countryName = '';
        let countries = this.context.countries;
        if (countries.length > 0) {
            countries.forEach(element => {
                if (element.value === countryID) {
                    countryName = element.name;
                }
            })
        }
        return countryName;
    }
    contactProperty = (obj) => {
        let numberIdentification = '', number = 0, helper = [];
        let driver = "Driver's License Number";
        if (obj != null) {
            obj.forEach(value => {
                switch (value.name) {
                    case 'Identification Number':
                        number = 1;
                        numberIdentification = value.value;
                        helper.push({ numberIdentification, number });
                        break;
                    case driver:
                        number = 2;
                        numberIdentification = value.value;
                        helper.push({ numberIdentification, number });
                        break;
                    case 'Passport Number':
                        number = 3;
                        numberIdentification = value.value;
                        helper.push({ numberIdentification, number });
                        break;
                    default:
                        break;
                }
            });
        }
        return helper;
    }
    contactPropertyAFEX = (obj, name) => {
        let value = '';
        if (obj != null) {
            obj.forEach(element => {
                switch (element.name) {
                    case name:
                        value = element.value != null ? element.value : '';
                        break;
                    default:
                        break;
                }
            });
        }
        return value;
    }
    //Document Type
    switchDocumentType = (obj) => {
        let documentTypeIDDirector = '';
        if (obj.length > 0) {
            obj.forEach(value => {
                switch (value.Title) {
                    case 'Personal Identification document - 1':
                        documentTypeIDDirector = value.DocumentType != null ? value.DocumentType : 'Identity Card';
                        break;
                    case 'Personal Identification document - 2':
                        documentTypeIDDirector = value.DocumentType != null ? value.DocumentType : 'Identity Card';
                        break;
                    case 'Driving License Document - 1':
                        documentTypeIDDirector = value.DocumentType != null ? value.DocumentType : 'Identity Card';
                        break;
                    case 'Driving License Document - 2':
                        documentTypeIDDirector = value.DocumentType != null ? value.DocumentType : 'Identity Card';
                        break;
                    case 'Personal Passport Document - 1':
                        documentTypeIDDirector = value.DocumentType != null ? value.DocumentType : 'Identity Card';
                        break;
                    default:
                        break;
                }
            })
        }
        return documentTypeIDDirector;
    }
    handleChangeUtilityBillFile = async (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            const newDocuments = {
                ...this.state.documents,
                accountFile: fileContent,
                accountFileName: file.name,
            };
            this.setState({ documents: newDocuments });
        } else {
            const newDocuments = {
                ...this.state.documents,
                accountFile: '',
                accountFileName: '',
            };
            this.setState({ documents: newDocuments });
        }
    }
    blobToBase64 = blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    };
    readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                resolve(event.target.result);
            };
            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsDataURL(file);
        });
    }
    SaveSignature = (isSave) => {//Your signature is in the PDF AGREEMENT FOR SIGNATURE
        let valid = this.validateRequiredFields().valid;
        let errors = this.validateRequiredFields().errors;
        if (isSave) {
            if (valid) {
                let signatureDataURL = this.sigCanvas.getTrimmedCanvas().toDataURL('image/png')
                let signatureDate = new Date();
                sessionStorage.setItem('signatureImg', signatureDataURL)
                sessionStorage.setItem('signatureDate', formatDate(signatureDate))
                sessionStorage.setItem('signatureFullName', this.state.FullName)
                sessionStorage.setItem('signatureTitle', this.state.Title)
                this.SubmitPersonalRegistration()
            } else {
                this.setState({
                    showAlert: true,
                    title: 'There are some errors/required field(s) need to fix or fill, please, review.',
                    message: 'Please, correct the following errors.',
                    errors: errors
                });
            }
        } else {
            this.Clear()
            this.onClickTab('step-2')
        }
    }
    AddSignature = () => {
        if (this.state.isManual) {
            //Manual Signature
            this.SaveSignature(false);
        } else {
            //Digital Signature
            this.SaveSignature(true);
        }
    }
    //Fields Validations
    validateRequiredFields() {
        const fields = [
            { displayName: 'Full Name', validateName: 'FullName', value: this.state.FullName, type: 'string' },
            { displayName: 'Title', validateName: 'Title', value: this.state.Title, type: 'string' },
            { displayName: 'Signature', validateName: 'Signature', value: this.sigCanvas.isEmpty() ? '' : 'noEmpty', type: 'string' }
        ]
        const [valid, validations, errors] = validate(fields);
        this.setState({ validations });
        return { valid, errors };
    }
    getPDF = async () => {
        const customerType = sessionStorage.getItem('CustomerType');
        switch (customerType) {
            case 'Corporate':
                switch (getCurrentCompanyName()) {
                    case 'Xushi':
                        savePdf(<MyDocumentCorporateOther obj={this.state.allObjts} />, 'CorporateForm.pdf', this.state.openPopup, this.closePopup)
                        break;
                    default:
                        savePdf(<MyDocumentCorporate obj={this.state.allObjts} />, 'CorporateForm.pdf', this.state.openPopup, this.closePopup)
                        break;
                }
                break;
            case 'Individual':
                switch (getCurrentCompanyName()) {
                    case 'Xushi':
                        saveIndividualPdf(<MyDocumentIndividualOther obj={this.state.allObjts} />, 'IndividualForm.pdf')
                        break;
                    default:
                        saveIndividualPdf(<MyDocumentIndividual obj={this.state.allObjts} />, 'IndividualForm.pdf')
                        break;
                }
                break;
            default:
                break;
        }
    }
    PreviewPDF = async () => {
        //Add Signature
        let signatureDataURL = this.sigCanvas.getTrimmedCanvas().toDataURL('image/png')
        let signatureDate = new Date();
        sessionStorage.setItem('signatureImg', signatureDataURL)
        sessionStorage.setItem('signatureDate', formatDate(signatureDate))
        sessionStorage.setItem('signatureFullName', this.state.FullName)
        sessionStorage.setItem('signatureTitle', this.state.Title)
        //Add Signature
        const customerType = sessionStorage.getItem('CustomerType');
        let signaturePreview = <MyDocumentCorporate obj={this.state.allObjts} />;
        let blob ='';
        switch (customerType) {
            case 'Corporate':
                switch (getCurrentCompanyName()) {
                    case 'Xushi':
                        signaturePreview = <MyDocumentCorporateOther obj={this.state.allObjts} />;
                        break;
                    default:
                        signaturePreview = <MyDocumentCorporate obj={this.state.allObjts} />;
                        break;
                }
                blob = await returnFileContent(signaturePreview)
                break;
            case 'Individual':
                switch (getCurrentCompanyName()) {
                    case 'Xushi':
                        signaturePreview = <MyDocumentIndividualOther obj={this.state.allObjts} />;
                        break;
                    default:
                        signaturePreview = <MyDocumentIndividual obj={this.state.allObjts} />;
                        break;
                }
                blob = await returnIndividualFileContent(signaturePreview)
                break;
            default:
                break;
        }
        let url = window.URL.createObjectURL(blob);
        let fileList = [{
            uri: url,
            fileType: 'pdf'
        }]
        this.setState({ openPopup: true, fileList: fileList });
    }
    closePopup = () => {
        //signaturePopup = null;
        this.setState({ openPopup: false, signaturePopup: null })
    }
    Clear = () => {
        sessionStorage.setItem('signatureImg', null)
        sessionStorage.setItem('signatureDate', '')
        sessionStorage.setItem('signatureFullName', '')
        sessionStorage.setItem('signatureTitle', '')
        this.setState({
            FullName: '',
            Title: '',
            validations: {
                FullName: true,
                Title: true,
                Signature: true
            }
        });
        this.sigCanvas.clear()
    }
    ClearSignature = () =>{
        sessionStorage.setItem('signatureImg', null)
        this.sigCanvas.clear()
    }
    SubmitPersonalRegistration = async () => {
        this.setState({ openSaveSignature: true });
    }
    CloseSaveSignature = () => {
        this.setState({ openSaveSignature: false });
    }
    YesSaveSignature = async () => {
        const customerType = sessionStorage.getItem('CustomerType');
        let signaturePreview = <MyDocumentCorporate obj={this.state.allObjts} />;
        let file = '';
        switch (customerType) {
            case 'Corporate':
                signaturePreview = <MyDocumentCorporate obj={this.state.allObjts} />;
                file = await returnFileContent(signaturePreview);
                break;
            case 'Individual':
                signaturePreview = <MyDocumentIndividual obj={this.state.allObjts} />;
                file = await returnIndividualFileContent(signaturePreview);
                break;
            default:
                break;
        }
        const base64 = await this.blobToBase64(file);
        const fileContent123 = base64.replace(/^data:.+;base64,/, '');
        this.setState({ loading: true }, () => {
            const FileContent = this.state.documents.accountFile !== '' ? this.state.documents.accountFile : fileContent123;
            const FileName = this.state.documents.accountFileName !== '' ? this.state.documents.accountFileName : customerType + '.pdf';
            let model = {
                CustomerID: parseInt(sessionStorage.getItem('ActualCustomerID')),
                //Name: sessionStorage.getItem('MainContactName'),
                Name: this.state.FullName,
                Email: sessionStorage.getItem('MainContactEmail'),
                Url: '',
                TaskName: 'Original signed agreement',
                File: FileContent,
                Filename: FileName,
                RequestID:this.props.requestId
            }
            sendEmailMainContact(model).then(json => {
                switch (json.httpStatusCode) {
                    case 200:
                        this.setState({ loading: false, showAlert: true, title: 'Success', message: 'Thanks for submitting your signature.', errors: [], showSuccess: true });
                        break;
                    default:
                        this.setState({ loading: false, showAlert: true, title: 'Error', message: 'An error ocurred while sending Corporate Account Registration. Please, try again', errors: [] });
                        break;
                }
            })
        })
    }
    ManualSignatureClick = () => {
        this.setState({ isManual: true }, () => this.onClickTab('step-2'))
    }
    PreviewDigitalSignature = () => {
        this.setState({ isManual: false }, () => this.onClickTab('step-1'))
    }
    CancelPersonalRegistration = async () => {
        this.props.navigate('/');
    }
    closeDeleteAlert = () => {
        this.setState({ showAlert: false });
        if (this.state.showSuccess != null && this.state.showSuccess) {
            this.CancelPersonalRegistration()
        }
    }
    //RowClickTable
    handleRowClick = () => {
        this.setState({ showAttachmentsDialog: true, rowClick: true })
    }
    showAttachmentsDialog = () => {
        this.setState({ showAttachmentsDialog: false })
    }
    changeState = () => {
        this.setState({ rowClick: false })
    }
    handleUpdateFirstName = (event) => {
        this.setState({
            FullName: event.target.value,
            validations: { ...this.state.validations, FullName: true }
        })
    }
    handleUpdateTitle = (event) => {
        this.setState({
            Title: event.target.value,
            validations: { ...this.state.validations, Title: true }
        })
    }
    onClickTab = (tabName) => {
        this.setState({ activeTab: tabName });
    }
    render() {
        var customerType = sessionStorage.getItem('CustomerType');
        var classNameStep1 = this.state.isManual ? 'uk-disabled' : '', classNameStep2 = '';

        var currentScreen;
        switch (this.state.activeTab) {
            case 'step-1':
                classNameStep1 = 'uk-active';
                currentScreen = (
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row uk-margin">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div>
                                                <button
                                                    type="button"
                                                    id="btn-generate-pdf"
                                                    className="btn btn-primary"
                                                    style={{ float: 'right' }}
                                                    onClick={this.ManualSignatureClick}>
                                                    <i class="fa fa-pencil"></i> MANUAL SIGNATURE
                                            </button>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div>
                                                <h4>{getResource('Label_Declaration')}</h4>
                                            </div>
                                        </div>
                                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.FullName)}>
                                            <input type="text"
                                                className="form-control"
                                                id="full_name"
                                                name="full_name"
                                                maxLength="70"
                                                data-rule-required="true"
                                                data-msg-required="Field Required."
                                                value={this.state.FullName}
                                                onChange={this.handleUpdateFirstName} />
                                            <label htmlFor="full_name" className="small">{getResource('Label_FullName')} *</label>
                                            {(this.state.validations.FullName != null && !this.state.validations.FullName) &&
                                                <label id="full_name-error" class="error" htmlFor="full_name"> Field Required.</label>
                                            }
                                        </div>
                                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.Title)}>
                                            <input type="text"
                                                className="form-control"
                                                id="title"
                                                name="title"
                                                maxLength="70"
                                                data-rule-required="true"
                                                data-msg-required="Field Required."
                                                value={this.state.Title}
                                                onChange={this.handleUpdateTitle} />
                                            <label htmlFor="title" className="small">{getResource('Label_TitleOccupation')} *</label>
                                            {(this.state.validations.Title != null && !this.state.validations.Title) &&
                                                <label id="title-error" class="error" htmlFor="title"> Field Required.</label>
                                            }
                                        </div>

                                        <div className={"col-md-12" + formatValidInput(this.state.validations.Signature)}>
                                            <SignatureCanvas
                                                canvasProps={{
                                                    width: 1000,
                                                    height: 200,
                                                    style: { border: '1px solid #e2e2e2e2' }
                                                }}
                                                minWidth={2}
                                                maxWidth={3}
                                                ref={(ref) => { this.sigCanvas = ref }}
                                            />
                                            <label htmlFor="signature" className="small">Signature *</label>
                                            {(this.state.validations.Signature != null && !this.state.validations.Signature) &&
                                                <label id="signature-error" class="error" htmlFor="signature"> Field Required.</label>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-margin">
                                    <button
                                        type="button"
                                        id="add_signature"
                                        className="btn btn-primary"
                                        style={{ float: 'right' }}
                                        onClick={this.AddSignature}>SAVE SIGNATURE</button>
                                    <button
                                        type="button"
                                        id="clear_signature"
                                        className="btn btn-primary"
                                        style={{ float: 'right' }}
                                        onClick={this.ClearSignature}>CLEAR</button>
                                    <button
                                        type="button"
                                        id="preview-pdf"
                                        className="btn btn-primary"
                                        style={{ float: 'right' }}
                                        onClick={this.PreviewPDF}>
                                        <i class="fa fa-search"></i> PREVIEW PDF
                                        </button>
                                </div>
                            </div>
                        </div>
                    </div>);
                break;
            case 'step-2':
                classNameStep2 = 'uk-active';
                currentScreen = (
                    <div className="row">
                        <div className="col-md-12">
                            <button type="button" id="btn-generate-pdf" className="btn btn-primary" onClick={this.getPDF}>
                                {getResource('Button_Download')} *
                            </button>

                            <div className="row uk-margin">
                                <div className="col-md-12">
                                    <div className="over-hidden">
                                        <input type="file"
                                            name="document_form" id="document_form"
                                            onChange={this.handleChangeUtilityBillFile}
                                            data-rule-required="true"
                                            data-msg-required="Attachment required"
                                            data-accept-size="512000"
                                            data-accept-size-message="Maximum 500 kb"
                                            data-accept-file-types="jpg|jpeg|png|gif|xls|xlsx|pps|ppt|pptx|doc|docx|pdf"
                                            data-wrong-file-types-message="Only Images, PDF or Office Document is allowed"
                                        />
                                        <label htmlFor="document_form" className='small'>{getResource('Customer_Attachment')}</label>
                                    </div>
                                </div>
                                <div className="uk-margin">
                                    <button
                                        type="button"
                                        id="add_signature"
                                        className="btn btn-primary"
                                        style={{ float: 'right' }}
                                        onClick={this.SubmitPersonalRegistration}>SAVE SIGNATURE</button>
                                    <button
                                        type="button"
                                        id="preview_signature"
                                        className="btn btn-primary"
                                        style={{ float: 'right' }}
                                        onClick={this.PreviewDigitalSignature}>{getResource('Button_Prev')}</button>
                                    <button
                                        type="button"
                                        id="cancel"
                                        className="btn btn-primary"
                                        style={{ float: 'right' }}
                                        onClick={this.CancelPersonalRegistration}>{getResource('Button_Cancel')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                break;
            default: break;
        }
        var customerAvailable = <LoadingBox loading={true} />
        if (this.state.isCustomerAvailable != null && this.state.isCustomerAvailable) {
            customerAvailable =
                <div className="row" id="customer_agreement">
                    <div className="col-sm-12 col-xs-12">
                        <div className="accordion-style">
                            <ul className="uk-tab">
                                <li className={classNameStep1}><a onClick={() => this.onClickTab('step-1')}>Digital Signature</a></li>
                                {this.state.isManual && <li className={classNameStep2}><a>Manual Signature</a></li>}
                            </ul>
                            {currentScreen}
                        </div>
                    </div>

                    <Dialog
                        open={this.state.openPopup}
                        onClose={() => this.closePopup()}
                        fullWidth={true}
                        maxWidth={"lg"}
                    >
                        <DialogTitle id="current-attachment-dialog-title" onClose={() => this.closePopup()}>
                            {customerType + '.pdf'}
                        </DialogTitle>
                        <DialogContent dividers>
                            <DocViewer
                                pluginRenderers={DocViewerRenderers}
                                documents={this.state.fileList}
                                config={{
                                    header: {
                                        disableHeader: true,
                                        disableFileName: true,
                                        retainURLParams: false
                                    }
                                }}
                            />
                        </DialogContent>
                    </Dialog>
                    <AlertBox open={this.state.showAlert} onClose={this.closeDeleteAlert} title={this.state.title} message={this.state.message} messageBoldList={this.state.errors} type="Ok" okClick={this.closeDeleteAlert} />
                    <AlertBox
                        open={this.state.openSaveSignature}
                        onClose={this.CloseSaveSignature}
                        title="Save Signature"
                        message="Do you want to save your signature?. This action can not be undone."
                        type="Yes/No"
                        yesClick={this.YesSaveSignature}
                        noClick={this.CloseSaveSignature}
                    />
                    <LoadingBox loading={this.state.loading} />
                </div>
        } else if (this.state.isCustomerAvailable != null && !this.state.isCustomerAvailable) {
            customerAvailable =
                <div className="row uk-margin">
                    <div className="col-md-12 row">
                        <div style={{ textAlign: 'center' }}>
                            <h3 style={{ fontWeight: 'bolder', paddingTop: 20 }}>Ops....</h3>
                            <hr></hr>
                            <p>This link is expired</p>
                        </div>
                        <div className="uk-margin">
                            <button
                                type="button"
                                id="cancel"
                                className="btn btn-primary"
                                style={{ float: 'right' }}
                                onClick={this.CancelPersonalRegistration}>RETURN TO HOME PAGE</button>
                        </div>
                    </div>
                </div>
        }

        return customerAvailable
    }
}