import { Document, Font, Image, Page, pdf, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { getExtended } from '../../helper/FormatHelper';
import { getCompanyLogoFromProject } from '../../helper/PackageJsonHelper';
import { getResource, getResourceByLanguage } from '../../helper/ResourcesHelper';
import { sanitizeStringForPDF } from '../../helper/StringHelper';

// var img = getCurrentURLImage();
var img = `${window.location.origin}${getCompanyLogoFromProject()}`;
var checked = require('../../css/images/black-check-box.png');
var checkbox = require('../../css/images/blank-check-box.png');

const saveBlob = (blob, filename) => {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  let url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const saveIndividualPdf = async (document, filename) => {
  saveBlob(await pdf(document).toBlob(), filename);
};

export const saveIndividualPdfBuffer = async (document) => {
  var blob = await pdf(document).toBlob();
  // var url = window.URL.createObjectURL(blob); 
  return blob;
};

const addPropertiesStep = (obj) => (
  <View style={styles.section}>
    <View style={styles.columnDetails}>
      <Text style={styles.textLabel}>{obj.description}</Text>
      <Text style={styles.boxText}>
        <Text style={styles.textSmall}>
          {obj.value !== '' ?
            obj.value : ' '}
        </Text>
      </Text>
    </View>
  </View>
);

Font.register({
  family: 'ZCool',
  src: 'https://fonts.gstatic.com/s/zcoolkuaile/v7/tssqApdaRQokwFjFJjvM6h2WpozzoXhC2g.ttf'
});

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30
  },
  section: {
    flexDirection: 'row',
  },
  imageSection: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 6,
    paddingTop: 7,
    // padding: 5,
    marginBottom: 7,
    borderBottom: 1,
    borderBottomColor: '#555555', //backgroundColor: '#00548d',
    flexDirection: 'row',
  },
  titleHeader: {
    position: 'static',
    // flexDirection: 'row',
    // flexGrow: 1,
    textAlign: 'center',
    backgroundColor: '#c2922c',
    color: '#FFFFFF', //#666
    fontSize: 13,
    fontWeight: 'ultrabold',
    // width: '450px',
    height: '20px',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 2,
    paddingBottom: 2
  },
  subTitleDocument: {
    // position: 'static',
    flexDirection: 'column',
    flexGrow: 1,
    textAlign: 'left',
    backgroundColor: '#c2922c',
    color: '#FFFFFF', //#666
    fontSize: 12,
    padding: 1,
    fontWeight: 'ultrabold',
    width: '530px',
    height: '20px'
  },
  footerSection: {
    padding: 10,
    paddingTop: 30,
    borderTop: 1,
    borderBottomColor: '#555555',
    color: '#fff',
    backgroundColor: '#00548d',
    flexDirection: 'row',
    position: 'relative',
  },
  columnDetails: {
    flexDirection: 'column',
    flexGrow: 1,
  },
  rowDetails: {
    flexDirection: 'row',
    flexGrow: 1,
  },
  paragraph: {
    fontSize: 8,
    justifyContent: 'space-between',
    marginTop: 10,
    paddingBottom: 10
  },
  textLarge: {
    fontSize: 12,
    marginTop: 5,
    paddingTop: 5,
  },
  subtitle: {
    color: '#13417a',
    fontSize: 9,
    marginTop: 5,
    paddingTop: 5,
    fontWeight: 700
  },
  textLabel: {
    fontSize: 8, //6
    marginTop: 5,
    paddingTop: 5,
    marginBottom: 1
  },
  whiteBackground: {
    backgroundColor: "#ffffff"
  },
  textLabelBottom: {
    fontSize: 6,
    marginBottom: 5,
    paddingBottom: 5,
    marginTop: 1
  },
  textSmall: {
    fontSize: 9,
    marginTop: 5,
    paddingTop: 5,
  },
  boxText: {
    border: '1px',
    // borderRadius: 5,
    borderRadius: 0,
    // borderBottomColor: '#555555',
    borderBottomColor: '#000000',
    padding: 5,
  },
  boxText1: {
    border: 1,
    borderRadius: 0,
    borderBottomColor: '#000000',
    padding: 5,
    width: '210px',
    height: '20px',
    backgroundColor: '#ffffff'
  },
  boxText2: {
    border: 1,
    borderRadius: 0,
    borderBottomColor: '#000000',
    padding: 7,
    width: '530px',
    height: '20px'
  },
  boxText3: {
    border: '1px',
    borderRadius: 0,
    borderBottomColor: '#000000',
    padding: 5,
    bordercollapse: 'collapse',
    width: '100%'
  },
  boxCheckbox: {
    border: 1,
    borderRadius: 0,
    borderBottomColor: '#000000',
    padding: 7,
    width: '15px',
    height: '15px',
  },
  smallPaddingCenter: {
    border: 1,
    borderRadius: 0,
    borderBottomColor: '#000000',
    padding: 0.5,
    width: '15px',
    height: '15px',
    textAlign: 'center'
  },
  boxDeclarationSignature: {
    borderBottom: 0.5,
    // borderBottomColor: '#555555',
    borderBottomColor: '#000000',
    padding: 5,
    lineHeight: 6,
    flexGrow: 4,
    alignSelf: "center",
    width: 270,
    height: 50
  },
  boxDeclarationSignatureByImg: {
    borderBottom: 0.5,
    // borderBottomColor: '#555555',
    borderBottomColor: '#000000',
    padding: 5,
    lineHeight: 6,
    flexGrow: 4,
    alignSelf: "center",
    paddingRight: 35,
    paddingLeft: 35,
  },
  signatureImg: {
    width: 200,
    height: 50
  },
  boxDeclarationDate: {
    //border: 0.5,
    // borderBottomColor: '#555555',
    borderBottomColor: '#000000',
    padding: 5,
    lineHeight: 6,
    flexGrow: 2
  },
  boxDeclarationFullName: {
    //border: 0.5,
    // borderBottomColor: '#555555',
    borderBottomColor: '#000000',
    padding: 5,
    lineHeight: 2
  },
  bulletPoint: {
    width: 6
  },
  spaceTopBottom: {
    paddingBottom: 12
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  footer: {
    position: 'absolute',
    bottom: 25,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  titlefooter: {
    fontSize: 9,
    marginTop: 5,
    marginBottom: 5,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#c2922c'
  },

  breakable: {
    width: '100%',
    height: 25,
  },
});

const styleChinesse = {
  margin: 0,
  marginTop: 10,
  fontSize: 8,
  textAlign: 'justify',
  fontFamily: 'ZCool',
  padding: 0
}

//Documents for Steps
/* --------------------STEP 1------------------- */
const ApplicantGeneralInformation = (obj, addObj) => {
  return (
    <View>
      <Text>
        <Text style={styles.textLabel}>* {getResourceByLanguage('Label_Mandatory', 'English') + ' '}</Text>
        <Text style={styleChinesse}>* {getResourceByLanguage('Label_Mandatory', 'Chinese')}</Text>
      </Text>
      <View style={styles.section}>
        <View style={[styles.rowDetails, { paddingRight: 10 }]}>
          <Text style={styles.textLabel} break={true}>{getResourceByLanguage('Label_Salutation', 'English') + ' '}</Text>
          <Text style={styleChinesse} break={true}>{getResourceByLanguage('Label_Salutation', 'Chinese')}</Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}></Text>
            </View>
          </View>

          <View style={[styles.rowDetails, { paddingRight: 5 }]}>
            <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
              {sanitizeStringForPDF(obj.saludationName != null && obj.saludationName === 'Mr.' ? 'X' : '')}
            </Text>
            <View style={styles.section}>
              <View style={[styles.columnDetails, { paddingRight: 5 }]}>
                <Text style={styles.textSmall}>

                </Text>
              </View>
            </View>
            <Text style={styles.textLabel}>Mr</Text>
          </View>

          <View style={[styles.rowDetails, { paddingRight: 5 }]}>
            <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
              {sanitizeStringForPDF(obj.saludationName != null && obj.saludationName === 'Miss.' ? 'X' : '')}
            </Text>
            <View style={styles.section}>
              <View style={[styles.columnDetails, { paddingRight: 5 }]}>
                <Text style={styles.textSmall}></Text>
              </View>
            </View>
            <Text style={styles.textLabel}>Miss</Text>
          </View>

          <View style={[styles.rowDetails, { paddingRight: 5 }]}>
            <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
              {sanitizeStringForPDF(obj.saludationName != null && obj.saludationName === 'Mrs.' ? 'X' : '')}
            </Text>
            <View style={styles.section}>
              <View style={[styles.columnDetails, { paddingRight: 5 }]}>
                <Text style={styles.textSmall}></Text>
              </View>
            </View>
            <Text style={styles.textLabel}>Mrs</Text>
          </View>

          <View style={[styles.rowDetails, { paddingRight: 5 }]}>
            <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
              {sanitizeStringForPDF(obj.saludationName != null && obj.saludationName === 'Ms.' ? 'X' : '')}
            </Text>
            <View style={styles.section}>
              <View style={[styles.columnDetails, { paddingRight: 5 }]}>
                <Text style={styles.textSmall}></Text>
              </View>
            </View>
            <Text style={styles.textLabel}>Ms</Text>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { paddingRight: 5 }]}>
          <Text style={styles.textSmall}></Text>
        </View>
      </View>

      <View style={styles.section}>
        {/*COLUMN 1 */}
        <View style={[{ width: '33.3%', paddingRight: 10 }]}>
          <Text>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_FirstName', 'English') + ' '} </Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_FirstName1', 'Chinese')}</Text>
          </Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF((obj.FirstName !== '' && obj.FirstName != null) ?
                obj.FirstName : ' ')}
            </Text>
          </Text>
        </View>
        {/*COLUMN 2 */}
        <View style={[{ width: '33.3%', paddingRight: 10 }]}>
          <Text>
            <Text style={styles.textLabel}>{getResource('Label_MiddleName1')}</Text>
          </Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF((obj.ShortName !== '' && obj.ShortName != null) ?
                obj.ShortName : ' ')}
            </Text>
          </Text>
        </View>
        {/*COLUMN 3 */}
        <View style={[{ width: '33.4%', paddingRight: 0 }]}>
          <Text>
            <Text style={styles.textLabel}>*{getResourceByLanguage('Label_LastName', 'English') + ' '}</Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_LastName1', 'Chinese')}</Text>
          </Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF((obj.LastName !== '' && obj.LastName != null) ?
                obj.LastName : ' ')}
            </Text>
          </Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { paddingRight: 5 }]}>
          <Text style={styles.textSmall}></Text>
        </View>
      </View>

      <View style={styles.section}>
        {/*COLUMN 1 */}
        <View style={[{ width: '33.3%', paddingRight: 10 }]}>
          <Text>
            <Text style={styles.textLabel}>{getResource('Label_DateOfBirth', 'English')}</Text>
          </Text>
          <Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_DateOfBirth1', 'Chinese')}</Text>
          </Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF((obj.DateOfBirth !== '' && obj.DateOfBirth != null) ?
                obj.DateOfBirth : ' ')}
            </Text>
          </Text>
        </View>
        {/*COLUMN 2 */}
        <View style={[{ width: '33.3%', paddingRight: 10 }]}>
          <Text>
            <Text style={styles.textLabel}>*{getResourceByLanguage('Label_ABN', 'English')}</Text>
          </Text>
          <Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_ABN', 'Chinese')}</Text>
          </Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF(getExtended(addObj.step2, 'ABN ID'))}
            </Text>
          </Text>
        </View>
        {/*COLUMN 3 */}
        <View style={[{ width: '33.4%', paddingRight: 0 }]}>
          <Text>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_NumberIdentification1', 'English')}</Text>
          </Text>
          <Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_NumberIdentification', 'Chinese')}</Text>
          </Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF((obj.numberIdentification !== '' && obj.numberIdentification != null) ?
                obj.numberIdentification : ' ')}
            </Text>
          </Text>
        </View>
      </View> 

      <View style={styles.section}>
        <View style={styles.columnDetails}>
          <Text style={styles.textSmall}></Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { paddingRight: 5 }]}>
          <Text>
            <Text style={styles.textSmall}>*{getResourceByLanguage('Subtitle_ResidentialAddress', 'English') + ' '}</Text>
            <Text style={styleChinesse}>*{getResourceByLanguage('Subtitle_ResidentialAddress', 'Chinese')}</Text>
          </Text>
        </View>
      </View>
      <View style={styles.section}>
        {/* COLUMN 1 */}
        <View style={[{ width: '50%', paddingRight: 5 }]}>
          <Text>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_StreetAddress', 'English') + ' '}</Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_StreetAddress', 'Chinese')}</Text>
          </Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF((obj.ResidentialAddressStreet !== '' && obj.ResidentialAddressStreet != null) ?
                obj.ResidentialAddressStreet : ' ')}
            </Text>
          </Text>

        </View>
        {/* COLUMN 2 */}
        <View style={{ width: '50%', paddingLeft: 5 }}>
          <Text>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_City', 'English') + ' '}</Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_City', 'Chinese')}</Text>
          </Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF((obj.ResidentialAddressCity !== '' && obj.ResidentialAddressCity != null) ?
                obj.ResidentialAddressCity : ' ')}
            </Text>
          </Text>

        </View>
      </View>

      <View style={styles.section}>
        <View style={styles.columnDetails}>
          <Text style={styles.textSmall}></Text>
        </View>
      </View>

      <View style={styles.section}>
        {/*COLUMN 1 */}
        <View style={[{ width: '33.3%', paddingRight: 10 }]}>
          <Text>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_State1', 'English') + ' '}</Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_State1', 'Chinese')}</Text>
          </Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF((obj.ResidentialAddressStateName !== '' && obj.ResidentialAddressStateName != null) ?
                obj.ResidentialAddressStateName : ' ')}
            </Text>
          </Text>

        </View>
        {/*COLUMN 2 */}
        <View style={[{ width: '33.3%', paddingRight: 10 }]}>
          <Text>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_Postal1', 'English') + ' '}</Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_Postal', 'Chinese')}</Text>
          </Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF((obj.ResidentialAddressPostalCode !== '' && obj.ResidentialAddressPostalCode != null) ?
                obj.ResidentialAddressPostalCode : ' ')}
            </Text>
          </Text>
        </View>
        {/* COLUMN 3 */}
        <View style={[{ width: '33.4%', paddingRight: 0 }]}>
          <Text>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_Country', 'English') + ' '}</Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_Country', 'Chinese')}</Text>
          </Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF((obj.countryName !== '' && obj.countryName != null) ?
                obj.countryName : ' ')}
            </Text>
          </Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={styles.columnDetails}>
          <Text style={styles.textSmall}></Text>
        </View>
      </View>

      <View style={styles.section}>
        {/*COLUMN 1 */}
        <View style={[{ width: '33.3%', paddingRight: 10 }]}>
          <Text>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_TelephoneNumber', 'English') + ' '}</Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_TelephoneNumber', 'Chinese')}</Text>
          </Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF((obj.ResidentialPhoneNumber !== '' && obj.ResidentialPhoneNumber != null) ?
                obj.ResidentialPhoneNumber : ' ')}
            </Text>
          </Text>
        </View>
        {/* COLUMN 2 */}
        <View style={[{ width: '33.3%', paddingRight: 10 }]}>
          <Text>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_MobileNumber', 'English') + ' '}</Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_MobileNumber', 'Chinese')}</Text>
          </Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF((obj.ResidentialPhoneNumber !== '' && obj.ResidentialPhoneNumber != null) ?
                obj.ResidentialPhoneNumber : ' ')}
            </Text>
          </Text>
        </View>
        {/*COLUMN 3 */}
        <View style={[{ width: '33.4%', paddingRight: 0 }]}>
          <Text>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_ContactMail', 'English') + ' '}</Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_ContactMail', 'Chinese')}</Text>
          </Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF((obj.EmailAddress !== '' && obj.EmailAddress != null) ?
                obj.EmailAddress : ' ')}
            </Text>
          </Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={styles.columnDetails}>
          <Text style={styles.textSmall}></Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { paddingRight: 10 }]}>
          <Text>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_EmployedStatus', 'English') + ' '}</Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_EmployedStatus', 'Chinese')}</Text>
          </Text>
          <View style={[styles.rowDetails, { paddingRight: 10 }]}>

            <View style={[styles.rowDetails, { paddingRight: 5 }]}>
              <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
                {sanitizeStringForPDF(getExtended(addObj.step2, 'Employment Status') === 'Employed' ? 'X' : '')}
              </Text>
              <View style={styles.section}>
                <View style={[styles.columnDetails, { paddingRight: 5 }]}>
                  <Text style={styles.textSmall}></Text>
                </View>
              </View>
              <Text style={styles.textLabel}>Employed</Text>
            </View>

            <View style={[styles.rowDetails, { paddingRight: 5 }]}>
              <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>

                {sanitizeStringForPDF(getExtended(addObj.step2, 'Employment Status') === 'Self-Employed' ? 'X' : '')}

              </Text>
              <View style={styles.section}>
                <View style={[styles.columnDetails, { paddingRight: 5 }]}>
                  <Text style={styles.textSmall}></Text>
                </View>
              </View>
              <Text style={styles.textLabel}>Self-Employed</Text>
            </View>

            <View style={[styles.rowDetails, { paddingRight: 5 }]}>
              <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
                {sanitizeStringForPDF(getExtended(addObj.step2, 'Employment Status') === 'Unemployed' ? 'X' : '')}

                {/* {obj.employmentStatusName != null && obj.employmentStatusName === 'Unemployed' ? 'X' : ''} */}
              </Text>
              <View style={styles.section}>
                <View style={[styles.columnDetails, { paddingRight: 5 }]}>
                  <Text style={styles.textSmall}></Text>
                </View>
              </View>
              <Text style={styles.textLabel}>Unemployed</Text>
            </View>

            <View style={[styles.rowDetails, { paddingRight: 5 }]}>
              <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>

                {sanitizeStringForPDF(getExtended(addObj.step2, 'Employment Status') === 'Retired' ? 'X' : '')}
                {/* {obj.employmentStatusName != null && obj.employmentStatusName === 'Retired' ? 'X' : ''} */}
              </Text>
              <View style={styles.section}>
                <View style={[styles.columnDetails, { paddingRight: 5 }]}>
                  <Text style={styles.textSmall}></Text>
                </View>
              </View>
              <Text style={styles.textLabel}>Retired</Text>
            </View>

            <View style={[styles.rowDetails, { paddingRight: 5 }]}>
              <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
                {sanitizeStringForPDF(getExtended(addObj.step2, 'Employment Status') === 'Student' ? 'X' : '')}

              </Text>
              <View style={styles.section}>
                <View style={[styles.columnDetails, { paddingRight: 5 }]}>
                  <Text style={styles.textSmall}></Text>
                </View>
              </View>
              <Text style={styles.textLabel}>Student</Text>
            </View>
          </View>

          <View style={[styles.rowDetails, { paddingRight: 10 }]}>
            <View style={[styles.rowDetails, { paddingRight: 5 }]}>
              <View>
                <Text>
                  <Text style={styleChinesse}>{getResourceByLanguage('Label_Employed', 'Chinese')}</Text>
                </Text>
              </View>
            </View>

            <View style={[styles.rowDetails, { paddingRight: 5 }]}>
              <Text>
                <Text style={styleChinesse}>{getResourceByLanguage('Label_SelfEmployed', 'Chinese')}</Text>
              </Text>
            </View>

            <View style={[styles.rowDetails, { paddingRight: 5 }]}>
              <Text>
                <Text style={styleChinesse}>{getResourceByLanguage('Label_Unemployed', 'Chinese')}</Text>
              </Text>
            </View>

            <View style={[styles.rowDetails, { paddingRight: 5 }]}>
              <Text>
                <Text style={styleChinesse}>{getResourceByLanguage('Label_Retired', 'Chinese')}</Text>
              </Text>
            </View>

            <View style={[styles.rowDetails, { paddingRight: 5 }]}>
              <Text>
                <Text style={styleChinesse}>{getResourceByLanguage('Label_Student', 'Chinese')}</Text>
              </Text>
            </View>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <View style={styles.columnDetails}>
          <Text style={styles.textSmall}></Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[{ width: '100%', paddingRight: 5 }]}>
          <Text>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_Occupation', 'English') + ' '}</Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_Occupation', 'Chinese')}</Text>
          </Text>
          <Text style={[styles.boxText1, styles.textLabel]} break={true}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF(getExtended(addObj.step2, 'Occupation'))}
            </Text>
          </Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={styles.columnDetails}>
          <Text style={styles.textSmall}></Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[{ width: '100%', paddingRight: 5 }]}>
          <Text>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_TransactionPursose', 'English') + ' '}</Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_TransactionPursose', 'Chinese')}</Text>
          </Text>
          <Text style={[styles.boxText1, styles.textLabel]} break={true}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF(getExtended(addObj.step2, 'Purpose of transfer'))}
            </Text>
          </Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={styles.columnDetails}>
          <Text style={styles.textSmall}></Text>
        </View>
      </View>

      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text>
          <Text style={styles.textLabel}>{getResourceByLanguage('Label_SourceOfIncome', 'English')}</Text>
        </Text>
        <Text>
          <Text style={styleChinesse}>{getResourceByLanguage('Label_SourceOfIncome', 'Chinese')}</Text>
        </Text>
        <View style={[styles.rowDetails, { paddingRight: 10 }]}>

          <View style={[styles.rowDetails, { paddingRight: 5 }]}>
            <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel]} break={true}>
              {sanitizeStringForPDF(obj.sourceOfWealthID != null && obj.sourceOfWealthID === 'Salary and savings' ? 'X' : '')}
            </Text>
            <View style={styles.section}>
              <View style={[styles.columnDetails, { paddingRight: 5 }]}>
                <Text style={styles.textSmall}></Text>
              </View>
            </View>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_SalaryAndSavings', 'English')}</Text>
          </View>

          <View style={[styles.rowDetails, { paddingRight: 5 }]}>
            <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
              {sanitizeStringForPDF(obj.sourceOfWealthID != null && obj.sourceOfWealthID === 'Investment' ? 'X' : '')}
            </Text>
            <View style={styles.section}>
              <View style={[styles.columnDetails, { paddingRight: 5 }]}>
                <Text style={styles.textSmall}></Text>
              </View>
            </View>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_Investment', 'English')}</Text>
          </View>

          <View style={[styles.rowDetails, { paddingRight: 5 }]}>
            <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
              {sanitizeStringForPDF(obj.sourceOfWealthID != null && obj.sourceOfWealthID === 'Parents Gifts' ? 'X' : '')}
            </Text>
            <View style={styles.section}>
              <View style={[styles.columnDetails, { paddingRight: 5 }]}>
                <Text style={styles.textSmall}></Text>
              </View>
            </View>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_ParentsGifts', 'English')}</Text>
          </View>

          <View style={[styles.rowDetails, { paddingRight: 5 }]}>
            <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
              {sanitizeStringForPDF(obj.sourceOfWealthID != null && obj.sourceOfWealthID === 'Inheritance' ? 'X' : '')}
            </Text>
            <View style={styles.section}>
              <View style={[styles.columnDetails, { paddingRight: 5 }]}>
                <Text style={styles.textSmall}></Text>
              </View>
            </View>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_Inheritance', 'English')}</Text>
          </View>

          <View style={[styles.rowDetails, { paddingRight: 5 }]}>
            <Text style={[styles.boxCheckbox, styles.smallPaddingCenter, styles.textLabel, styles.whiteBackground]} break={true}>
              {sanitizeStringForPDF(obj.sourceOfWealthID != null && obj.sourceOfWealthID === 'Others' ? 'X' : '')}
            </Text>
            <View style={styles.section}>
              <View style={[styles.columnDetails, { paddingRight: 5 }]}>
                <Text style={styles.textSmall}></Text>
              </View>
            </View>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_Others', 'English')}</Text>
          </View>
        </View>

        <View style={[styles.rowDetails, { paddingRight: 10 }]}>
          <View style={[styles.rowDetails, { paddingRight: 5 }]}>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_SalaryAndSavings', 'Chinese')}</Text>
          </View>

          <View style={[styles.rowDetails, { paddingRight: 5 }]}>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_Investment', 'Chinese')}</Text>
          </View>

          <View style={[styles.rowDetails, { paddingRight: 5 }]}>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_ParentsGifts', 'Chinese')}</Text>
          </View>

          <View style={[styles.rowDetails, { paddingRight: 5 }]}>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_Inheritance', 'Chinese')}</Text>
          </View>

          <View style={[styles.rowDetails, { paddingRight: 5 }]}>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_Others', 'Chinese')}</Text>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { paddingRight: 5 }]}>
          <Text style={styles.textSmall}></Text>
        </View>
      </View>

      <View style={[styles.rowDetails, { paddingRight: 5 }]}>
        <Text style={[styles.boxText1, styles.textLabel]} break={true}>
          {sanitizeStringForPDF((obj.SourceOfIncomeOther !== '' && obj.SourceOfIncomeOther != null) ?
            obj.SourceOfIncomeOther : ' ')}
        </Text>
        <View style={styles.section}>
          <Text style={styles.textSmall}></Text>
        </View>
        <View style={styles.section}>
          <View style={[styles.columnDetails, { paddingRight: 5 }]}>
            <Text style={styles.textSmall}></Text>
          </View>
        </View>
        <Text style={[styles.textLabel, { paddingTop: 5 }]}>
          <Text style={styles.textLabel}>{getResourceByLanguage('Label_PleaseEspecify', 'English') + ' '}</Text>
          <Text style={styleChinesse}>{getResourceByLanguage('Label_PleaseEspecify', 'Chinese')}</Text>
        </Text>
      </View>

      <View style={[styles.columnDetails, { paddingRight: 10 }]}>

        <View style={[styles.rowDetails, { paddingRight: 5 }]}>
          <Text style={[styles.boxCheckbox, styles.textLabel, styles.whiteBackground, styles.smallPaddingCenter]} break={true}>
            {sanitizeStringForPDF(obj.PEP ? '' : 'X')}
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}></Text>
            </View>
          </View>
          <Text style={[styles.textLabel, { paddingTop: 5 }]}>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_NotAPEP', 'English')}</Text>
          </Text>
        </View>
        <Text style={styleChinesse}>{getResourceByLanguage('Label_NotAPEP', 'Chinese')}</Text>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { paddingRight: 5 }]}>
          <Text style={styles.textSmall}></Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { paddingRight: 10 }]}>
          <Text>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_APEPisanIndividual', 'English')}</Text>
          </Text>
          <Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_APEPisanIndividual', 'Chinese')}</Text>
          </Text>
        </View>
      </View>

      {/* Required Proof of ID */}
      {/* Break of Page */}

    </View>
  );
}
/* --------------------STEP 2------------------- */
const AccountPurpose = (addObj) => (
  <View>
    {/* <Text style={styles.subtitle}>{getResource('Title_2')}</Text>
    {
      addObj.step2.length > 0 &&
      <View>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>
        {
          addObj.step2.map((detail) => {
            return (addPropertiesStep(detail))
          })
        }
      </View>
    } */}
  </View>
);

/* --------------------STEP 2------------------- */
const PreliminaryDetails = (obj, addObj) => (
  <View break={true}>
    {/* <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.subtitle}>{getResource('Title_2')}</Text>
        <Text style={styles.textLabel}>{getResource('Label_EmployerName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.EmployerName !== '' && obj.EmployerName != null) ?
              obj.EmployerName : ' '}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_TitleOccupation')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {(obj.TitleOccupationIDName !== '' && obj.TitleOccupationIDName != null) ?
              obj.TitleOccupationIDName : ' '}
          </Text>
        </Text>
      </View>
    </View>
    {
      addObj.step2.length > 0 &&
      <View>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>
        {
          addObj.step2.map((detail) => {
            return (addPropertiesStep(detail))
          })
        }
      </View>
    } */}
  </View>
);
/* --------------------STEP 3------------------- */
const DetailsAbout = (obj, addObj) => (
  <View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.subtitle}>{getResource('Title_3')}</Text>
        <Text style={styles.textLabel}>{getResource('Label_CurrenciesInterested')}</Text>
      </View>
    </View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>
          {
            obj.checkEUR &&
            <Image src={checked} style={styles.bulletPoint} />
          }
          {
            !obj.checkEUR &&
            <Image src={checkbox} style={styles.bulletPoint} />
          } {getResource('Radio_EUR')}</Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>
          {
            obj.checkGBP &&
            <Image src={checked} style={styles.bulletPoint} />
          }
          {
            !obj.checkGBP &&
            <Image src={checkbox} style={styles.bulletPoint} />
          } {getResource('Radio_GBP')}</Text>
      </View>
      {/*COLUMN 3 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>
          {
            obj.checkUSD &&
            <Image src={checked} style={styles.bulletPoint} />
          }
          {
            !obj.checkUSD &&
            <Image src={checkbox} style={styles.bulletPoint} />
          } {getResource('Radio_USD')}</Text>
      </View>
      {/*COLUMN 4 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>
          {
            obj.checkAED &&
            <Image src={checked} style={styles.bulletPoint} />
          }
          {
            !obj.checkAED &&
            <Image src={checkbox} style={styles.bulletPoint} />
          } {getResource('Radio_AED')}</Text>
      </View>
      {/*COLUMN 5 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>
          {
            obj.checkOther &&
            <Image src={checked} style={styles.bulletPoint} />
          }
          {obj.CurrenciesInterestedTradingOther}</Text>
      </View>
    </View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_AmountPerMonth')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF((obj.AmountTradedMonthIDName !== '' && obj.AmountTradedMonthIDName != null) ?
              obj.AmountTradedMonthIDName : ' ')}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_PurposeForExchanging')}</Text>
        {!obj.PurposeExchangingCurrencyIDName.includes('Others') &&
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF((obj.PurposeExchangingCurrencyIDName !== '' && obj.PurposeExchangingCurrencyIDName != null) ?
                obj.PurposeExchangingCurrencyIDName : ' ')}
            </Text>
          </Text>
        }
        {obj.PurposeExchangingCurrencyIDName.includes('Others') &&
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF((obj.PurposeExchangingCurrencyOther !== '' && obj.PurposeExchangingCurrencyOther != null) ?
                obj.PurposeExchangingCurrencyOther : ' ')}
            </Text>
          </Text>
        }
      </View>
    </View>

    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_HearAboutUs')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {obj.clickRadio}
          </Text>
        </Text>
      </View>
    </View>
    <View style={styles.section}>
      {obj.clickRadio === obj.SalesCallState &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_SalesCall')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF((obj.WhereYouHearAboutUsSalesCall !== '' && obj.WhereYouHearAboutUsSalesCall != null) ?
                obj.WhereYouHearAboutUsSalesCall : ' ')}
            </Text>
          </Text>
        </View>
      }
      {obj.clickRadio === obj.ReferralState &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_Referral')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF((obj.DetailsAbout.WhereYouHearAboutUsReferral !== '' && obj.DetailsAbout.WhereYouHearAboutUsReferral != null) ?
                obj.DetailsAbout.WhereYouHearAboutUsReferral : ' ')}
            </Text>
          </Text>
        </View>
      }
      {obj.clickRadio === obj.EventState &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_Event')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF((obj.WhereYouHearAboutUsEvent !== '' && obj.WhereYouHearAboutUsEvent != null) ?
                obj.WhereYouHearAboutUsEvent : ' ')}
            </Text>
          </Text>
        </View>
      }
      {obj.clickRadio === obj.OtherState &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_Other')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF((obj.WhereYouHearAboutUsOther !== '' && obj.WhereYouHearAboutUsOther != null) ?
                obj.WhereYouHearAboutUsOther : ' ')}
            </Text>
          </Text>
        </View>
      }
    </View>
    {
      addObj.step3.length > 0 &&
      <View>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.textSmall}>{getResource('SubTitle_AdditionalInformation')}</Text>
          </View>
        </View>
        {
          addObj.step3.map((detail) => {
            return (addPropertiesStep(detail))
          })
        }
      </View>
    }
  </View>
);
/* --------------------STEP 4------------------- */
const AuthorisedPersons = (obj, trader) => (
  <View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>SHAREHOLDER / OWNER {trader}</Text>
      </View>
    </View>
    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_FirstName')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF((obj['FirstNameTrader0' + trader] !== '' && obj['FirstNameTrader0' + trader] != null) ?
              obj['FirstNameTrader0' + trader] : ' ')}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_DateOfBirth')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF((obj['DateOfBirthTrader0' + trader] !== '' && obj['DateOfBirthTrader0' + trader] != null) ?
              obj['DateOfBirthTrader0' + trader] : ' ')}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_Surname')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF((obj['LastNameTrader0' + trader] !== '' && obj['LastNameTrader0' + trader] != null) ?
              obj['LastNameTrader0' + trader] : ' ')}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_City')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF((obj['ResidentialAddressCityTrader0' + trader] !== '' && obj['ResidentialAddressCityTrader0' + trader] != null) ?
              obj['ResidentialAddressCityTrader0' + trader] : ' ')}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_State')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(obj['ResidentialAddressStateNameTrader0' + trader])}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_Country')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF((obj['countryNameTrader0' + trader] !== '' && obj['countryNameTrader0' + trader] != null) ?
              obj['countryNameTrader0' + trader] : ' ')}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_Postal')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF((obj['ResidentialAddressPostalCodeTrader0' + trader] !== '' && obj['ResidentialAddressPostalCodeTrader0' + trader] != null) ?
              obj['ResidentialAddressPostalCodeTrader0' + trader] : ' ')}
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section} break={true}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('SubTitle_ContactNumbers')}</Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_ResidentialPhoneNumber')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF((obj['ResidentialPhoneNumberTrader0' + trader] !== '' && obj['ResidentialPhoneNumberTrader0' + trader] != null) ?
              obj['ResidentialPhoneNumberTrader0' + trader] : ' ')}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_Email')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF((obj['EmailAddressTrader0' + trader] !== '' && obj['EmailAddressTrader0' + trader] != null) ?
              obj['EmailAddressTrader0' + trader] : ' ')}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      <View style={styles.columnDetails}>
        <Text style={styles.textLabel}>{getResource('Label_MobileNumber')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF((obj['MobileNumberTrader0' + trader] !== '' && obj['MobileNumberTrader0' + trader] != null) ?
              obj['MobileNumberTrader0' + trader] : ' ')}
          </Text>
        </Text>
        <Text style={styles.textLabel}>{getResource('Label_PoliticallyExposed')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF(obj['radio' + trader])}
          </Text>
        </Text>
      </View>
    </View>

    {/*Personal Identification */}
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.textSmall}>{getResource('SubTitle_PersonalIdentification')}</Text>
      </View>
    </View>

    <View style={styles.section}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}>{getResource('Label_Country')}</Text>
        <Text style={styles.boxText}>
          <Text style={styles.textSmall}>
            {sanitizeStringForPDF((obj['countryNameIdentificationTrader0' + trader] !== '' && obj['countryNameIdentificationTrader0' + trader] != null) ?
              obj['countryNameIdentificationTrader0' + trader] : ' ')}
          </Text>
        </Text>
      </View>
      {/*COLUMN 2 */}
      {
        obj['documentTypeIDTrader0' + trader].includes('Identity') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_NumberIdentification')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF((obj['numberIdentificationTrader0' + trader] !== '' && obj['numberIdentificationTrader0' + trader] != null) ?
                obj['numberIdentificationTrader0' + trader] : ' ')}
            </Text>
          </Text>
        </View>
      }
      {
        obj['documentTypeIDTrader0' + trader].includes('Driving') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_LicenseNumber')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF((obj['DriverLicenseNumberTrader0' + trader] !== '' && obj['DriverLicenseNumberTrader0' + trader] != null) ?
                obj['DriverLicenseNumberTrader0' + trader] : ' ')}
            </Text>
          </Text>
        </View>
      }
      {
        obj['documentTypeIDTrader0' + trader].includes('Passport') &&
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_PassportNumber')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF((obj['PassportNumberTrader0' + trader] !== '' && obj['PassportNumberTrader0' + trader] != null) ?
                obj['PassportNumberTrader0' + trader] : ' ')}
            </Text>
          </Text>
        </View>
      }
    </View>

    {/* Attachments 
    {
      obj['documentTypeIDTrader0' + trader].includes('Identity') &&
      <View style={styles.section}>
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_PersonalIdentification1')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj['frontPITrader0' + trader] !== '' && obj['frontPITrader0' + trader] != null) ?
                obj['frontPITrader0' + trader][0].filename : (
                  (obj['personalIdentification1FileNameTrader0' + trader] !== '' && obj['personalIdentification1FileNameTrader0' + trader] != null) ?
                    obj['personalIdentification1FileNameTrader0' + trader] : ' '
                )}
            </Text>
          </Text>
          <Text style={styles.textLabel}>{getResource('Label_PersonalIdentification2')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj['endPITrader0' + trader] !== '' && obj['endPITrader0' + trader] != null) ?
                obj['endPITrader0' + trader][0].filename : (
                  (obj['personalIdentification2FileNameTrader0' + trader] !== '' && obj['personalIdentification2FileNameTrader0' + trader] != null) ?
                    obj['personalIdentification2FileNameTrader0' + trader] : ' '
                )}
            </Text>
          </Text>
        </View>
      </View>
    }
    {
      obj['documentTypeIDTrader0' + trader].includes('Driving') &&
      <View style={styles.section}>
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_PersonalIdentification1')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj['frontDLTrader0' + trader] !== '' && obj['frontDLTrader0' + trader] != null) ?
                obj['frontDLTrader0' + trader][0].filename : (
                  (obj['drivingLicense1FileNameTrader0' + trader] !== '' && obj['drivingLicense1FileNameTrader0' + trader] != null) ?
                    obj['drivingLicense1FileNameTrader0' + trader] : ' '
                )}
            </Text>
          </Text>
          <Text style={styles.textLabel}>{getResource('Label_PersonalIdentification2')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj['endDLTrader0' + trader] !== '' && obj['endDLTrader0' + trader] != null) ?
                obj['endDLTrader0' + trader][0].filename : (
                  (obj['drivingLicense2FileNameTrader0' + trader] !== '' && obj['drivingLicense2FileNameTrader0' + trader] != null) ?
                    obj['drivingLicense2FileNameTrader0' + trader] : ' '
                )}
            </Text>
          </Text>
        </View>
      </View>
    }
    {
      obj['documentTypeIDTrader0' + trader].includes('Passport') &&
      <View style={styles.section}>
        <View style={styles.columnDetails}>
          <Text style={styles.textLabel}>{getResource('Label_PersonalIdentification1')}</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {(obj['frontPssTrader0' + trader] !== '' && obj['frontPssTrader0' + trader] != null) ?
                obj['frontPssTrader0' + trader][0].filename : (
                  (obj['passportFileNameTrader0' + trader] !== '' && obj['passportFileNameTrader0' + trader] != null) ?
                    obj['passportFileNameTrader0' + trader] : ' '
                )}
            </Text>
          </Text>
        </View>
      </View>
    }*/}
  </View>
);
/* --------------------STEP 5------------------- */

/* --------------------STEP DECLARATION------------------- */
const Declaration = () => (
  <View>
    <View style={styles.section}>
      <View style={styles.columnDetails}>
        <Text style={styles.subtitle}>{getResource('Title_Declaration')}</Text>
        <Text style={styles.paragraph}>{getResource('Label_Declaration')}</Text>
      </View>
    </View>
    <View style={[styles.section, { paddingTop: 10 }]}>
      {/*COLUMN 1 */}
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        {/**------------SIGNATURE IMAGE----------- */}
        {sessionStorage.getItem('signatureImg') !== 'null' ?
          <Text style={styles.boxDeclarationSignatureByImg}>
            <Text style={styles.paragraph}>
              <Image style={styles.signatureImg} src={sessionStorage.getItem('signatureImg')} />
            </Text>
          </Text>
          :
          <Text style={styles.boxDeclarationSignature}>
            <Text style={styles.paragraph}>
              {' '}
            </Text>
          </Text>
        }

        {/**------------SIGNATURE FULLNAME----------- */}
        {sessionStorage.getItem('signatureImg') !== 'null' ?
          <Text style={[styles.paragraph, { width: 300, textAlign: 'right' }]}>
            {/*Full Name: Daniela Soto*/}
            {
              getResource('Label_FullName') + ': ' + sessionStorage.getItem('signatureFullName')
            }
          </Text> :
          <Text style={[styles.paragraph, { width: 210, textAlign: 'right' }]}>
            {/*Full Name: Daniela Soto*/}
            {
              getResource('Label_FullName') + ': '
            }
          </Text>
        }
        {/**------------SIGNATURE DATE----------- */}
        {sessionStorage.getItem('signatureImg') !== 'null' ?
          <Text style={[styles.paragraph, { width: 300, textAlign: 'right' }]}>
            {/*Full Name: Daniela Soto*/}
            {
              getResource('Label_Date') + ': ' + sessionStorage.getItem('signatureDate')
            }
          </Text> :
          <Text style={[styles.paragraph, { width: 210, textAlign: 'right' }]}>
            {/*Full Name: Daniela Soto*/}
            {
              getResource('Label_Date') + ': '
            }
          </Text>
        }
      </View>
    </View>
  </View>
);

const RemitterInformation = (obj, addObj) => (
  <View>
    <View style={styles.breakable} />
    <View style={styles.section}>
        <View style={[styles.columnDetails, { paddingRight: 5, paddingTop: 5, backgroundColor: '#c2922c'  }]}>
          <Text style={[styles.subTitleDocument, { paddingLeft: 10 }]}>Remitter Information</Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { paddingRight: 5 }]}>
          <Text style={styles.textSmall}></Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { paddingRight: 5 }]}>
          <Text>
            <Text style={styles.textLabel}>{getResourceByLanguage('Remitter_Paragraph', 'English')}</Text>
          </Text>
          <Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Remitter_Paragraph', 'Chinese')}</Text>
          </Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { paddingRight: 5 }]}>
          <Text style={styles.textSmall}></Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[{ width: '50%', paddingRight: 5 }]}>
          <Text>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_RemitterName', 'English') + ' '}</Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_RemitterName', 'Chinese')}</Text>
          </Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF((obj.RemitterName !== '' && obj.RemitterName != null) ?
                obj.RemitterName : ' ')}
            </Text>
          </Text>
        </View>

        <View style={{ width: '50%', paddingLeft: 5 }}>
          <Text>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_RemitterContactNumber', 'English') + ' '}</Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_RemitterContactNumber', 'Chinese')}</Text>
          </Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF((obj.RemitterContactNumber !== '' && obj.RemitterContactNumber != null) ?
                obj.RemitterContactNumber : ' ')}
            </Text>
          </Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { paddingRight: 5 }]}>
          <Text style={styles.textSmall}></Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[{ width: '50%', paddingRight: 5 }]}>
          <Text>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_RemitterRelationship', 'English') + ' '}</Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_RemitterRelationship', 'Chinese')}</Text>
          </Text>
          <Text style={[styles.boxText]}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF((obj.RemitterRelationship !== '' && obj.RemitterRelationship != null) ?
                obj.RemitterRelationship : ' ')}
            </Text>
          </Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { paddingRight: 5 }]}>
          <Text style={styles.textSmall}></Text>
        </View>
      </View>

      {/* Foreign Exchange and Payment Requirements */}

      <View style={styles.section}>
        <View style={[styles.columnDetails, { paddingRight: 5, paddingTop: 5, backgroundColor: '#c2922c'  }]}>
          <Text style={[styles.subTitleDocument, { paddingLeft: 10 }]}>Foreign Exchange and Payment Requirements </Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { paddingRight: 5 }]}>
          <Text style={styles.textSmall}></Text>
        </View>
      </View>

      <View style={styles.section}>

        <View style={[{ width: '50%', paddingRight: 5 }]}>
          <Text>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_CurrenciesRequired', 'English') + ' '}</Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_CurrenciesRequired', 'Chinese')}</Text>
          </Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF(getExtended(addObj.step2, 'Currencies required'))}
            </Text>
          </Text>
        </View>

        <View style={{ width: '50%', paddingLeft: 5 }}>
          <Text>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_ApproxiVolumePerTrade', 'English') + ' '}</Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_ApproxiVolumePerTrade', 'Chinese')}</Text>
          </Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF(getExtended(addObj.step2, 'Expected monthly trade volume'))}
            </Text>
          </Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { paddingRight: 5 }]}>
          <Text style={styles.textSmall}></Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[{ width: '50%', paddingRight: 5 }]}>
          <Text>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_ApproxiNumberOfTransactionsPerMonth', 'English') + ' '}</Text>
          </Text>
          <Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_ApproxiNumberOfTransactionsPerMonth', 'Chinese')}</Text>
          </Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF(getExtended(addObj.step2, 'Expected number of monthly payments'))}
            </Text>
          </Text>
        </View>

        <View style={{ width: '50%', paddingLeft: 5 }}>
          <Text>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_ApproxiMonthlyFXRequirement', 'English') + ' '}</Text>
          </Text>
          <Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_ApproxiMonthlyFXRequirement', 'Chinese')}</Text>
          </Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF(getExtended(addObj.step2, 'Expected monthly FX requirements'))}
            </Text>
          </Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { paddingRight: 5 }]}>
          <Text style={styles.textSmall}></Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[{ width: '50%', paddingRight: 5 }]}>
          <Text>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_CountriesToGo', 'English') + ' '}</Text>
          </Text>
          <Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_CountriesToGo', 'Chinese')}</Text>
          </Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF(getExtended(addObj.step2, 'Trading Countries (which funds transfer to go)'))}
            </Text>
          </Text>
        </View>

        <View style={{ width: '50%', paddingLeft: 5 }}>
          <Text>
            <Text style={styles.textLabel}>{getResourceByLanguage('Label_CountriesToComeFrom', 'English') + ' '}</Text>
          </Text>
          <Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_CountriesToComeFrom', 'Chinese')}</Text>
          </Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF(getExtended(addObj.step2, 'Trading Countries (where funds come from)'))}
            </Text>
          </Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { paddingRight: 5 }]}>
          <Text style={styles.textSmall}></Text>
        </View>
      </View>

      {/* Declaration */}

      <View style={styles.section}>
        <View style={[styles.columnDetails, { paddingRight: 5, paddingTop: 5, backgroundColor: '#c2922c'  }]}>
          <Text style={[styles.subTitleDocument, { paddingLeft: 10 }]}>Declaration</Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { paddingRight: 5 }]}>
          <Text style={styles.textSmall}></Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { paddingRight: 10 }]}>
          <Text>
            <Text style={styles.textLabel}>•  {getResource('Label_Declaration1', 'English')}</Text>
          </Text>
          <Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_Declaration1', 'Chinese')}</Text>
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}></Text>
            </View>
          </View>
          <Text>
            <Text style={styles.textLabel}>•  {getResource('Label_Declaration2', 'English')}</Text>
          </Text>
          <Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_Declaration2', 'Chinese')}</Text>
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}></Text>
            </View>
          </View>
          <Text>
            <Text style={styles.textLabel}>•  {getResource('Label_Declaration3', 'English')}</Text>
          </Text>
          <Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_Declaration3', 'Chinese')}</Text>
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}></Text>
            </View>
          </View>
          <Text>
            <Text style={styles.textLabel}>•  {getResource('Label_Declaration4', 'English')}</Text>
          </Text>
          <Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_Declaration4', 'Chinese')}</Text>
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}></Text>
            </View>
          </View>
          <Text>
            <Text style={styles.textLabel}>•  {getResource('Label_Declaration5', 'English')}</Text>
          </Text>
          <Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_Declaration5', 'Chinese')}</Text>
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}></Text>
            </View>
          </View>
          <Text>
            <Text style={styles.textLabel}>•  {getResource('Label_Declaration6', 'English')}</Text>
          </Text>
          <Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_Declaration6', 'Chinese')}</Text>
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}></Text>
            </View>
          </View>
          <Text>
            <Text style={styles.textLabel}>•  {getResource('Label_Declaration7', 'English')}</Text>
          </Text>
          <Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_Declaration7', 'Chinese')}</Text>
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}></Text>
            </View>
          </View>
          <Text>
            <Text style={styles.textLabel}>•  {getResource('Label_Declaration8', 'English')}</Text>
          </Text>
          <Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_Declaration8', 'Chinese')}</Text>
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}></Text>
            </View>
          </View>
          <Text>
            <Text style={styles.textLabel}>•  {getResource('Label_Declaration9', 'English')}</Text>
          </Text>
          <Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_Declaration9', 'Chinese')}</Text>
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}></Text>
            </View>
          </View>
          <Text>
            <Text style={styles.textLabel}>•  {getResource('Label_Declaration10', 'English')}</Text>
          </Text>
          <Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_Declaration10', 'Chinese')}</Text>
          </Text>
          <View style={styles.section}>
            <View style={[styles.columnDetails, { paddingRight: 5 }]}>
              <Text style={styles.textSmall}></Text>
            </View>
          </View>
          <Text>
            <Text style={styles.textLabel}>•  {getResource('Label_Declaration11', 'English')}</Text>
          </Text>
          <Text>
            <Text style={styleChinesse}>{getResourceByLanguage('Label_Declaration11', 'Chinese')}</Text>
          </Text>
        </View>
      </View>

      <View style={styles.section} break={true}>
        <View style={[styles.columnDetails, { paddingRight: 5 }]}>
          <Text style={styles.textSmall}></Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { paddingRight: 5 }]}>
          <Text style={styles.textSmall}></Text>
        </View>
      </View>

      {/* <View style={styles.breakable} /> */}
      <View style={styles.section}>
        {/* <View style={styles.section} break={true}> */}

        <View style={[styles.columnDetails, { paddingRight: 10 }]}>
          <Text style={styles.textLabel}>* Print Name</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF(sessionStorage.getItem('signatureFullName'))}
            </Text>
          </Text>
        </View>

        <View style={[styles.columnDetails, { paddingRight: 10 }]}>
          <Text style={styles.textLabel}>* Title</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF(sessionStorage.getItem('signatureTitle'))}
            </Text>
          </Text>
        </View>

        <View style={[styles.columnDetails, { paddingRight: 10 }]}>
          <Text style={styles.textLabel}>* Date</Text>
          <Text style={styles.boxText}>
            <Text style={styles.textSmall}>
              {sanitizeStringForPDF(sessionStorage.getItem('signatureDate'))}
            </Text>
          </Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { paddingRight: 10 }]}>
          <Text style={styles.textLabel}>* Signature</Text>
          <Text style={styles.boxText}>
            {sessionStorage.getItem('signatureImg') !== 'null' ?
              <Image style={styles.signatureImg} src={sessionStorage.getItem('signatureImg')} />
              :
              <Text style={styles.textSmall}>
                {sanitizeStringForPDF('')}
              </Text>
            }
          </Text>
        </View>
      </View>
  </View>
);

const RequiredProofOfID = () => (
  <View>
    <View style={styles.breakable} />
    <View style={styles.section}>
      <View style={[styles.columnDetails, { paddingRight: 5, paddingTop: 5, backgroundColor: '#c2922c'  }]}>
        <Text style={[styles.subTitleDocument, { paddingLeft: 10 }]}>Required Proof of ID</Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { paddingRight: 5 }]}>
        <Text style={styles.textSmall}>The IDs of the individuals must include a primary form of photo identification and a secondary form of non-photo Identification
          showing name and residential address.</Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { paddingRight: 5 }]}>
        <Text style={styles.textSmall}></Text>
      </View>
    </View>

    {/* Acceptable forms of Primary Photo Identification */}

    <View style={styles.section}>
      <View style={[styles.columnDetails, { paddingRight: 5, paddingTop: 5, backgroundColor: '#c2922c'  }]}>
        <Text style={[styles.subTitleDocument, { paddingLeft: 10 }]}>Acceptable forms of Primary Photo Identification</Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { paddingRight: 5 }]}>
        <Text style={styles.textSmall}></Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Document type</Text>
          </Text>
        </Text>
      </View>

      <View style={[styles.columnDetails, { width: 150, }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Description</Text>
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Australian driver’s licence</Text>
          </Text>
        </Text>
      </View>

      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Full licence with photo and signature</Text>
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      {/* <View style={[styles.rowDetails]}> */}
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={[styles.boxText3, { height: 92 }]}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Valid Australian passport</Text>
          </Text>
        </Text>
      </View>

      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>With photo, date of birth and signature</Text>
          </Text>
        </Text>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Important: Some documents that look like an Australian passport are not acceptable
              forms of ID. These include:</Text>
          </Text>
        </Text>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>· Certificate of Identity (number begins with C)</Text>
          </Text>
        </Text>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>· Titre de Voyage/Travel Document (number begins with U)</Text>
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={[styles.boxText3, { height: 181 }]}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Australian/State Government issued Proof of Age/Identity Card
              only when combined with a Medicare card which includes the
              applicant’s name</Text>
          </Text>
        </Text>
      </View>

      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>QLD: Adult Proof of Age card issued by QLD Govt Transport and Main Roads</Text>
          </Text>
        </Text>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>NT: Evidence of Age card issued by NT Govt Transport Group</Text>
          </Text>
        </Text>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>ACT: Proof of Age card issued by ACT Govt Road User Services</Text>
          </Text>
        </Text>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>SA: Proof of Age card issued by SA Govt Dept for Transport Energy and Infrastructure</Text>
          </Text>
        </Text>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>VIC: Proof of Age card issued by Victorian Commission for Gambling and Liquor
              Regulation</Text>
          </Text>
        </Text>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>WA: Proof of Age card issued by WA Govt Department of Transport</Text>
          </Text>
        </Text>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>TAS: Tasmanian Government Personal Information card issued by Services Tasmania</Text>
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={[styles.boxText3]}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Australian learner’s permit</Text>
          </Text>
        </Text>
      </View>

      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Only when combined with a Medicare card which has the applicant’s name</Text>
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={[styles.boxText3, { height: 38.5 }]}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>International passport with valid visa with expiry date beyond the
              contract expiry date</Text>
          </Text>
        </Text>
      </View>

      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Must include date of birth and visa’s expiry date* that is beyond the contract term. If no
              current visa is visible, relevant immigration papers confirming the end date of the
              current visa must be provided</Text>
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={[styles.boxText3, { height: 50.5 }]}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Blind Citizens Australia identity card</Text>
          </Text>
        </Text>
      </View>

      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Card must be current</Text>
          </Text>
        </Text>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Good to know: Blind Citizens Australia have stopped issuing or renewing identity cards
              from 1 January 2015</Text>
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Valid Police/Defence Force ID</Text>
          </Text>
        </Text>
      </View>
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>With photo</Text>
          </Text>
        </Text>
      </View>
    </View>

    <View style={styles.section}>
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={[styles.boxText3]}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>Valid Shooters/Firearms licence</Text>
          </Text>
        </Text>
      </View>
      <View style={[styles.columnDetails, { width: 150 }]}>
        <Text style={styles.boxText3}>
          <Text style={styles.textSmall}>
            <Text style={styles.textLabel}>With photo</Text>
          </Text>
        </Text>
      </View>
    </View>

    {/* Acceptable forms of Secondary non-photo Identification */}
    <View break={true}>
      <View style={styles.breakable} />
      <View style={styles.section}>
        <View style={[styles.columnDetails, { paddingRight: 5, paddingTop: 5, backgroundColor: '#c2922c'  }]}>
          <Text style={[styles.subTitleDocument, { paddingLeft: 10 }]}>Acceptable forms of Secondary non-photo Identification</Text>
        </View>
      </View>
      <View style={[styles.columnDetails, { paddingRight: 10 }]}>
        <Text style={styles.textLabel}></Text>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { width: 150 }]}>
          <Text style={styles.boxText3}>
            <Text style={styles.textSmall}>
              <Text style={styles.textLabel}>Document type</Text>
            </Text>
          </Text>
        </View>

        <View style={[styles.columnDetails, { width: 150, }]}>
          <Text style={styles.boxText3}>
            <Text style={styles.textSmall}>
              <Text style={styles.textLabel}>Description</Text>
            </Text>
          </Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { width: 150 }]}>
          <Text style={[styles.boxText3, { height: 29.8 }]}>
            <Text style={styles.textSmall}>
              <Text style={styles.textLabel}>Medicare card</Text>
            </Text>
          </Text>
        </View>

        <View style={[styles.columnDetails, { width: 150 }]}>
          <Text style={styles.boxText3}>
            <Text style={styles.textSmall}>
              <Text style={styles.textLabel}>The preferred secondary ID option and the first form of secondary ID that will normally
                be requested</Text>
            </Text>
          </Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { width: 150 }]}>
          <Text style={[styles.boxText3, { height: 80 }]}>
            <Text style={styles.textSmall}>
              <Text style={styles.textLabel}>Credit, debit or ATM card</Text>
            </Text>
          </Text>
        </View>

        <View style={[styles.columnDetails, { width: 150 }]}>
          <Text style={styles.boxText3}>
            <Text style={styles.textSmall}>
              <Text style={styles.textLabel}>With photo, date of birth and signature</Text>
            </Text>
          </Text>
          <Text style={styles.boxText3}>
            <Text style={styles.textSmall}>
              <Text style={styles.textLabel}>Only Australian financial institution cards with your name and the financial institution’s
                name printed</Text>
            </Text>
          </Text>
          <Text style={styles.boxText3}>
            <Text style={styles.textSmall}>
              <Text style={styles.textLabel}>·Important: Credit, debit or ATM cards are the last option if you are unable to provide
                any other type of secondary ID</Text>
            </Text>
          </Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { width: 150 }]}>
          <Text style={[styles.boxText3, { height: 29.5 }]}>
            <Text style={styles.textSmall}>
              <Text style={styles.textLabel}>Birth certificate or original birth extract</Text>
            </Text>
          </Text>
        </View>

        <View style={[styles.columnDetails, { width: 150 }]}>
          <Text style={styles.boxText3}>
            <Text style={styles.textSmall}>
              <Text style={styles.textLabel}>Important: If your name has been changed by marriage, you’ll need to provide the
                marriage certificate as well</Text>
            </Text>
          </Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { width: 150 }]}>
          <Text style={styles.boxText3}>
            <Text style={styles.textSmall}>
              <Text style={styles.textLabel}>Valid Working with Children card</Text>
            </Text>
          </Text>
        </View>

        <View style={[styles.columnDetails, { width: 150 }]}>
          <Text style={styles.boxText3}>
            <Text style={styles.textSmall}>
              <Text style={styles.textLabel}>Must include photograph</Text>
            </Text>
          </Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { width: 150 }]}>
          <Text style={[styles.boxText3, { height: 29.5 }]}>
            <Text style={styles.textSmall}>
              <Text style={styles.textLabel}>Valid Australian Government issued benefits card</Text>
            </Text>
          </Text>
        </View>

        <View style={[styles.columnDetails, { width: 150 }]}>
          <Text style={styles.boxText3}>
            <Text style={styles.textSmall}>
              <Text style={styles.textLabel}>This can include Aged Pension card, Seniors Card and Disability Support Pension card</Text>
            </Text>
          </Text>
        </View>
      </View>


      <View style={styles.section}>

        <View style={[styles.columnDetails, { width: 150 }]}>
          <Text style={styles.boxText3}>
            <Text style={styles.textSmall}>
              <Text style={styles.textLabel}>Utility bill, vehicle registration or rates notice</Text>
            </Text>
          </Text>
        </View>

        <View style={[styles.columnDetails, { width: 150 }]}>
          <Text style={styles.boxText3}>
            <Text style={styles.textSmall}>
              <Text style={styles.textLabel}>Must be less than six months old</Text>
            </Text>
          </Text>
        </View>
      </View>


      <View style={styles.section}>

        <View style={[styles.columnDetails, { width: 150 }]}>
          <Text style={[styles.boxText3, { height: 50.5 }]}>
            <Text style={styles.textSmall}>
              <Text style={styles.textLabel}>Tertiary student ID card</Text>
            </Text>
          </Text>
        </View>

        <View style={[styles.columnDetails, { width: 150 }]}>
          <Text style={styles.boxText3}>
            <Text style={styles.textSmall}>
              <Text style={styles.textLabel}>Card must be current</Text>
            </Text>
          </Text>
          <Text style={styles.boxText3}>
            <Text style={styles.textSmall}>
              <Text style={styles.textLabel}>Must have photo and student number issuing body such as a university or TAFE</Text>
            </Text>
          </Text>
        </View>
      </View>

      <View style={styles.section}>
        <View style={[styles.columnDetails, { width: 150 }]}>
          <Text style={styles.boxText3}>
            <Text style={styles.textSmall}>
              <Text style={styles.textLabel}>Private health insurance membership card</Text>
            </Text>
          </Text>
        </View>

        <View style={[styles.columnDetails, { width: 150 }]}>
          <Text style={styles.boxText3}>
            <Text style={styles.textSmall}>
              <Text style={styles.textLabel}>Card must be current</Text>
            </Text>
          </Text>
        </View>
      </View>
    </View>
  </View>
);

// Create Document Component
export const MyDocumentIndividualOther = (props) => (
  <Document>
    <Page style={styles.page}>
      {/*-----------HEADER--------------*/}
      <View style={styles.section}>
        <View style={[styles.imageSection, { alignItems: 'center', padding: 2, marginTop: 25 }]}>
          {/* COLUMN 1 */}
          <View style={[{ padding: 1, marginRight: 10 }]}>
            <Image src={{ uri: img, method: 'GET', headers: {}, body: '' }} style={{ width: 275.5, height: 33.5 }} />
          </View>
          {/* COLUMN 2 */}
          <View style={[{ display: 'flex', width: 'auto', backgroundColor: '#c2922c', paddingTop: 2, paddingLeft: 2, paddingBottom: 2, paddingRight: 25 }]}>
            <Text style={[styles.titleHeader]}>Individual Client Application Form</Text>
          </View>
        </View>
      </View>
      {/* <View style={styles.imageSection} fixed={true}>
        <Text>
        </Text>
      </View> */}
      {/*  ------------STEP 1--------------*/}
      {
        <View style={styles.spaceTopBottom}>
          {
            ApplicantGeneralInformation(props.obj.ApplicantGeneralInformation, props.obj.AdditionalProperties)
          }
        </View>
      }
      {/*  ------------STEP 2--------------*/}
      {
        // <View style={styles.spaceTopBottom} break={true}>
        //   {
        //     AccountPurpose(props.obj.AdditionalProperties)
        //   }
        // </View>
      }
      {/*  ------------STEP 3--------------
      {
        <View style={styles.spaceTopBottom}>
          {
            DetailsAbout(props.obj.DetailsAbout, props.obj.AdditionalProperties)
          }
        </View>
      }
      {/* ------------STEP 4-------------- 
      <View>
        <View style={styles.section}>
          <View style={styles.columnDetails}>
            <Text style={styles.subtitle}>{getResource('Title_4')}</Text>

            <Text style={styles.textLabel}>Will there be any other person authorised to trade on your behalf with this account?</Text>
            <Text style={styles.boxText}>
              <Text style={styles.textSmall}>
                {props.obj.AuthorisedPersons.clickRadio}
              </Text>
            </Text>
          </View>
        </View>
        {/* ONLY YES
       SHAREHOLDER / OWNER 1
        {
          props.obj.AuthorisedPersons.clickRadio === getResource('Checkbox_Yes') &&
          <View style={styles.spaceTopBottom}>
            {
              AuthorisedPersons(props.obj.AuthorisedPersons, 1)
            }
          </View>
        }
        {
          (props.obj.AuthorisedPersons.clickRadio === getResource('Checkbox_Yes') && props.obj.AuthorisedPersons.trader02) &&
          <View style={styles.spaceTopBottom}>
            {
              AuthorisedPersons(props.obj.AuthorisedPersons, 2)
            }
          </View>
        }
        {
          (props.obj.AuthorisedPersons.clickRadio === getResource('Checkbox_Yes') && props.obj.AuthorisedPersons.trader03) &&
          <View style={styles.spaceTopBottom}>
            {
              AuthorisedPersons(props.obj.AuthorisedPersons, 3)
            }
          </View>
        }
        {
          (props.obj.AuthorisedPersons.clickRadio === getResource('Checkbox_Yes') && props.obj.AuthorisedPersons.trader04) &&
          <View style={styles.spaceTopBottom}>
            {
              AuthorisedPersons(props.obj.AuthorisedPersons, 4)
            }
          </View>
        }
        {
          (props.obj.AuthorisedPersons.clickRadio === getResource('Checkbox_Yes') && props.obj.AuthorisedPersons.trader05) &&
          <View style={styles.spaceTopBottom}>
            {
              AuthorisedPersons(props.obj.AuthorisedPersons, 5)
            }
          </View>
        }
      </View>
      */}

      {/* ------------STEP DECLARATION-------------- */}
      {
        // <View style={styles.spaceTopBottom}>
        //   {
        //     Declaration()
        //   }
        // </View>
      }
      {
        <View style={styles.spaceTopBottom} break={true}>
          {
            RemitterInformation(props.obj.ApplicantGeneralInformation, props.obj.AdditionalProperties)
          }
          {
            RequiredProofOfID()
          }
        </View>
      }

      {/*-----------FOOTER--------------
      <View style={styles.footerSection} fixed={true}>
        <View style={styles.columnDetails}>
          <Text>© Copyright 2011-{(new Date().getFullYear())} - E2E Foreign Exchange</Text>
          <Text>ABN: 22 151 337 852 | AFSL: 405948</Text>
        </View>
      </View>*/}
      <View style={[styles.footer, { alignItems: 'center' }]} fixed={true}>
        <Text style={styles.titlefooter}  >EasyPay Forex <Text style={styleChinesse}>易速汇</Text> | T: +61 3 8080 1533| A: Level 11, 333 Collins Street, Melbourne, VIC 3000| www.xushi-exchange.com.au</Text>
      </View>
      {/* <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed={true} /> */}
    </Page>
  </Document>
);
const returnUrlBlob = (blob) => {
  let url = blob //window.URL.createObjectURL(blob);
  return url;
}
export const returnIndividualFileContent = async (document) => {
  return returnUrlBlob(await pdf(document).toBlob())
}