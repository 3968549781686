import React from 'react';
import { getCustomerInfoByID, newSearchCustomers } from '../../networking/Networking';
import { getStyle } from '../../styles/styles';
import NewDynamicTable from '../shared/NewDynamicTable';
import CustomersSearchForm from './CustomerSearchForm';
const style = getStyle();

const pjson = require('../../../package.json');
export default class Customers extends React.Component {

    state = {
        searchedCustomerName: '',
        customers: [],
        customerID: 0,
        customerName: '',
        customerType: '',
        pages: 0,
        totalPages: 0,
        newSearch: false,
        useFilter: false,
        isLogin: false,
        customerBody: null,
        originOnline: '',
        isOwnerClick: false
    }

    componentDidMount() {
        this.reload();
    }

    reload() {
        if (this.props.originOnline != null) {
            this.setState({ originOnline: this.props.originOnline })
        }
        if (Number(this.props.customerId) > 0) {
            this.setState({ customerID: this.props.customerId }, () => this.getCustomer(this.props.customerId))
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.customerId !== this.props.customerId) {
            this.reload();
        }
    }

    handleUpdateSearchedCustomerName = (value) => {
        this.setState({ searchedCustomerName: value });
    }

    newHandleTableData = async () => {
        const value = await newSearchCustomers(this.state.searchedCustomerName, 0, 0);
        if (value != null && value.totalCount > 0) {
            const totalCount = value.totalCount;
            const totalPages = Math.ceil(totalCount / 15);
            this.setState({ customers: value.customers, pages: 1, totalPages, newSearch: true, useFilter: true });
        } else {
            this.setState({ customers: [], pages: 0, totalPages: 0, newSearch: true, useFilter: false });
        }
    }


    handleRowClick = (obj) => {
        console.group("Customer handleRowClick")
        console.log(obj);
        console.groupEnd();
        this.setState({
            customerID: obj["Customer ID"],
            customerType: obj["Customer Type"],
            customerName: obj['Customer Name']
        }, () => {
            this.getCustomer(obj["Customer ID"]);
        });
    }

    getCustomer = (customerID) => {
        getCustomerInfoByID(customerID).then(
            (json) => {
                console.log(json)
                if (json != null) {//json.Parent = 'Newport'
                    this.setState({ isLogin: true, customerBody: json, customerType: json.type });
                }
            }
        );
    }

    clearData = () => {
        this.setState({
            customers: [], searchedCustomerName: '', pages: 0, totalPages: 0, newSearch: false, useFilter: false
        });
    }

    changePage = (value) => {
        this.setState({ pages: value });
    }

    getAllCustomers = async () => {
        const value = await newSearchCustomers(this.state.searchedCustomerName, 0, 0);
        return value.customers;
    }

    handleOwnerClick = () => {
        this.setState({ isOwnerClick: true })
    }
    render() {
        if (this.state.isLogin) {
            if (this.state.customerType === "Corporate") {
                this.props.navigate('/corporate-account/:regionID', { state: { email: this.state.email, customerID: this.state.customerID, customer: this.state.customerBody, originOnline: this.state.originOnline } });
            } else if (this.state.customerType === "MSB") {
                this.props.navigate('/msb-account/:regionID', { state: { email: this.state.email, customerID: this.state.customerID, customer: this.state.customerBody, originOnline: this.state.originOnline } });
            } else if (this.state.customerType === "Individual") {
                this.props.navigate('/individual-account/:regionID', { state: { email: this.state.email, customerID: this.state.customerID, customer: this.state.customerBody, originOnline: this.state.originOnline } });
            }
        }
        if (this.state.isOwnerClick) {
            this.props.navigate('/owner-invitation', { state: { email: this.state.email, customerID: this.state.customerID, customer: this.state.customerBody, originOnline: this.state.originOnline } });
        }
        return (
            <div>
                <h3 className="uk-heading-divider" style={style.titleStyle}>Customers</h3>
                
                {/* <button type="button" className="btn btn-primary" onClick={this.handleOwnerClick.bind(this)}>Owner Screen</button> */}
                
                <CustomersSearchForm
                    searchedString={this.state.searchedCustomerName}
                    updateSearchedCustomerName={this.handleUpdateSearchedCustomerName}
                    updateTable={this.newHandleTableData}
                    clearData={this.clearData}
                />
                <NewDynamicTable
                    data={this.state.customers}
                    enableClick={true}
                    clickFunction={this.handleRowClick}
                    newSearch={this.state.newSearch}
                    useDeleteButton={false}
                    usePaginator={true}
                    changePage={this.changePage}
                    numberOfPages={this.state.totalPages}
                    pageNumber={this.state.pages}
                    numberPerPage={15}
                    enableSortByHeader={true}
                    useFilter={this.state.useFilter}
                    filterFunction={this.getAllCustomers}
                    useExportButton={this.state.useFilter}
                    tableName="customers"
                />
            </div>
        );
    }
}