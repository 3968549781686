import { genericGetWithParameters } from "./Networking";

export async function getCurrencyList() {
  try {
      let params = {}
      let response = await genericGetWithParameters('Currency/GetCurrenciesMin', params);
      return response;
  } catch (error) {
      console.error(error);
  }
}