import { useLocation, useParams } from "@reach/router";
import queryString from 'query-string';
import React, { useMemo, useState } from 'react';
import { getCurrentURLImage, getShowLanguages } from '../../helper/PackageJsonHelper';
import { getStyle } from '../../styles/styles';
import Customers from '../preregistration/Customers';
import Registration from '../preregistration/Registration';
import DynamicSelect from '../shared/DynamicSelect';
import FormScreen from './360Form';
import CorporateScreen from './CorporateScreen';
import EmailVerified from './EmailVerified';
import Footer from './Footer';
import Header360 from './Header360';
import InvalidRequest from './InvalidRequest';
import LoadingBlankScreen from './LoadingBlankScreen';
import OwnerScreen from './OwnerForm/OwnerScreen';
import SignatureScreen from './SignatureInvitation/SignatureScreen';
import SuccessForm from './SuccessForm';



const style = getStyle();

const en = 'English';
const chi = 'Chinese';
let requestId=null;
let contactType='';
let contactName='';
let country = null;

//https://usehooks.com/useRouter/
export function useRouter() {

    const params = useParams();
    const location = useLocation();
    //const history = useHistory();  
    //const match = useRouteMatch(); 


    // Return our custom router object  
    // Memoize so that a new object is only returned if something changes  
    return useMemo(() => {

        return {
            // For convenience add push(), replace(), pathname at top level  
            //push: history.push,  
            //replace: history.replace,  
            pathname: location.pathname,
            // Merge params and parsed query string into single "query" object  
            // so that they can be used interchangeably.  
            // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }  
            query: {
                ...queryString.parse(location.search), // Convert string to object  
                ...params
            },
            // Include match, location, history objects so we have  
            // access to extra React Router functionality if needed.

            //match,  
            location/*,  
        history*/
        };

    }, [params/*, matc*/, location/*, history*/]);

}

const Screen360 = (props) => {


    const [, setLanguage] = useState('English');
    //Dropdown
    var languageID = '';
    var languageName = '';
    const languageList = [
        { value: 'English', name: 'English' },
        { value: 'Chinese', name: 'Chinese' }
    ];
    //Dropdown
    const changeLanguage = (language) => {
        sessionStorage.setItem('360Language', language);
        setLanguage(language);
    };

    const handleUpdateLanguage = (value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    switch (value.value) {
                        case 'English':
                            changeLanguage(en);
                            break;
                        case 'Chinese':
                            changeLanguage(chi);
                            break;
                        default:
                            changeLanguage(en);
                            break;
                    }
                    languageID = value.value;
                    languageName = value.name;
                } else {
                    languageID = '';
                    languageName = '';
                }
            } else {
                languageID = '';
                languageName = '';
            }
        }
    }

    const sessionLanguage = sessionStorage.getItem('360Language');
    if (sessionLanguage !== 'English') {
        languageID = sessionLanguage;
        languageName = sessionLanguage;
    }

    const showScreen = () => {
        //window.pageYOffset = 0;
        //window.scrollY = 0;
        switch (props.path) {
            default:
                return <Registration navigate={props.navigate} referralID={props.referralID != null ? props.referralID : null} />
            case '/individual-account/:regionID': return (
                <div className="row">
                    <div className="col-sm-2 col-xs-12"></div>
                    <div className="col-sm-8 col-xs-12 border-shadow" style={style.backForm}>
                        <div>
                            <div className="uk-margin">
                            </div>
                            <FormScreen navigate={props.navigate} location={props.location} />
                        </div>
                    </div>
                    <div className="col-sm-2 col-xs-12"></div>
                </div>);
            case '/corporate-account/:regionID': return (
                <div className="row">
                    <div className="col-sm-2 col-xs-12"></div>
                    <div className="col-sm-8 col-xs-12 border-shadow" style={style.backForm}>
                        <div>
                            <div className="uk-margin">
                            </div>
                            <CorporateScreen navigate={props.navigate} location={props.location} type={'Corporate'} />
                        </div>
                    </div>
                    <div className="col-sm-2 col-xs-12"></div>
                </div>);
            case '/msb-account/:regionID': return (
                <div className="row">
                    <div className="col-sm-2 col-xs-12"></div>
                    <div className="col-sm-8 col-xs-12 border-shadow" style={style.backForm}>
                        <div>
                            <div className="uk-margin">
                            </div>
                            <CorporateScreen navigate={props.navigate} location={props.location} type={'MSB'} />
                        </div>
                    </div>
                    <div className="col-sm-2 col-xs-12"></div>
                </div>);
            case '/owner-invitation': return (
                <div className="row">
                    <div className="col-sm-2 col-xs-12"></div>
                    <div className="col-sm-8 col-xs-12 border-shadow" style={style.backForm}>
                        <div>
                            <div className="uk-margin">
                            </div>
                            <OwnerScreen navigate={props.navigate} location={props.location} contactType={contactType} requestId={requestId} country={country} />
                        </div>
                    </div>
                    <div className="col-sm-2 col-xs-12"></div>
                </div>);
            case '/success-account': return (
                <div className="row">
                    <div className="col-sm-2 col-xs-12"></div>
                    <div className="col-sm-9 col-xs-12 border-shadow" style={style.backForm}>
                        <div>
                            <div className="uk-margin">
                            </div>
                            <SuccessForm />
                        </div>
                    </div>
                </div>);
            case '/invalid-request': return (
                <div className="row">
                    <div className="col-sm-2 col-xs-12"></div>
                    <div className="col-sm-9 col-xs-12 border-shadow" style={style.backForm}>
                        <div>
                            <div className="uk-margin">
                            </div>
                            <InvalidRequest />
                        </div>
                    </div>
                </div>);
            case '/customers': return (
                <div style={style.backForm}>
                    <Customers navigate={props.navigate} location={props.location} />
                </div>);
            case '/email-verified': return (
                <div className="row">
                    <div className="col-sm-2 col-xs-12"></div>
                    <div className="col-sm-8 col-xs-12 border-shadow" style={style.backForm}>
                        <EmailVerified navigate={props.navigate} />
                    </div>
                    <div className="col-sm-2 col-xs-12"></div>
                </div>);
            case '/signature-invitation': return (
                <div className="row">
                    <div className="col-sm-2 col-xs-12"></div>
                    <div className="col-sm-9 col-xs-12 border-shadow" style={style.backForm}>
                        <div>
                            <div className="uk-margin">
                            </div>
                            <SignatureScreen navigate={props.navigate} location={props.location} requestId={requestId} contactName={contactName}/>
                        </div>
                    </div>
                </div>);
        }
    };

    const router = useRouter();

    const showOwnerScreen = (country = null) => {
        requestId = router.query.requestId;
        contactType = router.query.referralID.replace('-invitation','');
        sessionStorage.setItem('ContactType', contactType);
        return(
            <div className="App" id="container">
                <div className="container" id="header">
                    <Header360 path={props.path} navigate={props.navigate} location={props.location} logo={image_logo}>
                       {getShowLanguages() == 'true' && <DynamicSelect
                            className="form-control navbar-right use-select2 select2-selection__placeholder"
                            objValue={{ value: languageID, name: languageName }}
                            getValue={handleUpdateLanguage}
                            listHasPlaceholder={false}
                            data={languageList}
                        />}
                    </Header360>
                </div>
                <div className="container uk-margin" id="body">
                    <OwnerScreen navigate={props.navigate} location={props.location} contactType={contactType} requestId={requestId} country={country} />
                </div>
                <div id="footer" >
                    <Footer />
                </div>
            </div>
        )
    }
    if (Number(router.query.customerId) > 0) {
        return <LoadingBlankScreen navigate={props.navigate} location={props.location} customerId={router.query.customerId} originOnline={router.query.origin} />
    }
    if (router.pathname != null && router.pathname.includes('signature-invitation')) {
        requestId = router.query.requestId;
        contactName = router.query.contactName;
        return (<div
            className="App"
            id="container"
            style={{
                display: "flex",
                flexDirection: 'column',
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <div className="container" id="header">
                <Header360 path={props.path} navigate={props.navigate} location={props.location} logo={image_logo}>
                {getShowLanguages() == 'true' && <DynamicSelect
                        className="form-control navbar-right use-select2 select2-selection__placeholder"
                        objValue={{ value: languageID, name: languageName }}
                        getValue={handleUpdateLanguage}
                        listHasPlaceholder={false}
                        data={languageList}
                    />}
                </Header360>
            </div>
            <div className="container uk-margin" id="body" >
                <div className="row">
                    <div className="col-sm-2 col-xs-12"></div>
                    <div className="col-sm-9 col-xs-12 border-shadow" style={style.backForm}>
                        <div>
                            <div className="uk-margin">
                            </div>
                            <SignatureScreen navigate={props.navigate} location={props.location} requestId={requestId} contactName={contactName}/>
                        </div>
                    </div>
                </div>
            </div>
            <div id="footer" >
                <Footer />
            </div>
        </div>
        )
    } else 
        if (router.pathname != null && router.pathname.includes('invitation')
            && router.query.requestId != null && router.query.requestId !== '') {//shareholder?requestId=2ef89f44-3c87-4763-9140-638bd895766d
            if (router.query.country != null && router.query.country !== '') {
                country = router.query.country;
                sessionStorage.setItem('Country', country);
                sessionStorage.setItem('RequestID', router.query.requestId);
            }
        return showOwnerScreen()
    }
    //Imagelogo
    var image_logo = getCurrentURLImage();
    return (
        <div className="App" id="container">
            <div className="container" id="header">
                <Header360 path={props.path} navigate={props.navigate} location={props.location} logo={image_logo}>
                {getShowLanguages() == 'true' && <DynamicSelect
                        className="form-control navbar-right use-select2 select2-selection__placeholder"
                        objValue={{ value: languageID, name: languageName }}
                        getValue={handleUpdateLanguage}
                        listHasPlaceholder={false}
                        data={languageList}
                    />}
                </Header360>
            </div>
            <div className="container uk-margin" id="body">
                {showScreen()}
            </div>
            <div id="footer" >
                <Footer />
            </div>
        </div>
    );
}

export default Screen360;