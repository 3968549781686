export const validate = (fields) => {
    let valid = true;
    let errorList = [];
    let validations = {};
    fields.forEach(
        (field) => {
            validations[field.validateName] = true;
    
            if (field.validation != null) {
                if (field.validationParameter != null) {
                    if (!field.validation(field.validationParameter)) {
                        valid = false;
                        errorList.push(field.displayName);
                        if(field.validateName != null){
                            validations[field.validateName] = false;
                        }
                    }
                } else {
                    if (!field.validation()) {
                        valid = false;
                        errorList.push(field.displayName);
                        if(field.validateName != null){
                            validations[field.validateName] = false;
                        }
                    }
                }
            } else {
                switch(field.type) {
                    default:
                    case 'string': {
                        if (field.value === undefined || field.value === null ||field.value === 'select' || field.value === '' || field.value === 0) {
                            valid = false;
                            errorList.push(field.displayName);
                            validations[field.validateName] = false;
                        }
                        break;
                    }
                    case 'number': {
                        if (field.value === undefined || field.value === '' || field.value === 0) {
                            valid = false;
                            errorList.push(field.displayName);
                            validations[field.validateName] = false;
                        }
                        break;
                    }
                    case 'wholeNumber': {
                        if (field.value === undefined || field.value === '') {
                            valid = false;
                            errorList.push(field.displayName);
                            validations[field.validateName] = false;
                        }
                        break;
                    }
                    case 'radio': {
                        if (field.value === undefined || field.value === 'none') {
                            valid = false;
                            errorList.push(field.displayName);
                            validations[field.validateName] = false;
                        }
                        break;
                    }
                    case 'email': {
                        if (!validateEmail(field.value)) {
                            valid = false;
                            errorList.push(field.displayName);
                            validations[field.validateName] = false;
                        }
                        break;
                    }
                }
            }
        }
    )

    return [valid, validations, errorList];
}

const validateEmail = (email) => {
    const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (email != null && email !== '') {
        if (email.includes(';')) {
            const emails = email.split(';');
            if (emails.some(e => !e.match(regex))) {
                return false;
            }
        } else {
            if (!email.match(regex)) {
                return false;
            }
        }
    } else {
        return false;
    }
    return true;
}