
import React from 'react';
import * as mime from 'mime-types';

import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogTitle from '@material-ui/core/DialogTitle';

import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import DocViewer, { PDFRenderer, DocViewerRenderers } from "react-doc-viewer";

import NewDynamicTable from './NewDynamicTable';
import AlertBox from './AlertBox';
import { getAttachment, getAttachments } from '../../networking/NetworkingAttachments';
import { getEntityTasks } from '../../networking/NetworkingEntityTasks';
import { getEntityProperties } from '../../networking/NetworkingAdditionalPropertiesDefs';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

export const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const clone = (obj) => Object.assign({}, obj);

const renameKey = (object, key, newKey) => {
    const clonedObj = clone(object);
    const targetKey = clonedObj[key];
    delete clonedObj[key];
    clonedObj[newKey] = targetKey;

    return clonedObj;
};

export default class AttachmentsDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            entityProperties: [],
            tasks: [],
            listAttachments: [],
            fullWidth: true,
            maxWidth: 'md',

            currentFileURLList: [],
            currentFileType: '',
            currentExtension: '',


            //Dialog Attachment
            fullWidthAttachment: true,
            maxWidthAttachment: 'lg',
            openAttachment: false,

            //Alert no preview
            showOpenAlert: false,

            //Dowload file
            currentBlob: null,
            isNewId: false,
        };
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        //debugger
        if ((this.props.rowClick !== prevProps.rowClick) && this.props.rowClick) {
            this.onAttachmentRowClick(this.props.file)
            this.props.changeState()
        }
    }

    reorderObjAttachment = (objAttachment) => {
        var newdObj = clone(objAttachment)
        var newAttachment =
        {
            Type: newdObj.Type,
            Description: newdObj.Description,
            Filename: newdObj.Filename,
            'File Description': newdObj['File Description'],
            'Expiry Date': newdObj['Expiry Date'],
            ID: newdObj.ID
        }

        return newAttachment
    }

    onAttachmentRowClick = (attachmentClicked) => {
        console.log("attachmentClicked: " + JSON.stringify(attachmentClicked))
        if (this.props.attachmentId === 'TermsCondition') {
            this.setState({
                currentFileName: 'Terms and Conditions.pdf',
                currentFileURLList: this.props.file,
                openAttachment: true,
            })
        } else {
            getAttachment(attachmentClicked.AttachmentId).then((jsonResponse) => {
                console.log(jsonResponse);
                var byteCharacters = atob(jsonResponse.data);
                var byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var bytes = new Uint8Array(byteNumbers);
                var blob = new Blob([bytes], { type: mime.lookup(attachmentClicked.filename) });

                //var url = window.URL.createObjectURL(blob)

                var arrayName = attachmentClicked.filename.split(".")

                let fileList = [{
                    uri: window.URL.createObjectURL(blob),
                    fileType: arrayName[arrayName.length - 1].toLowerCase()
                }]

                if (arrayName.length !== 0) {
                    if (arrayName[arrayName.length - 1] === 'xlsx' || arrayName[arrayName.length - 1] === 'xls' ||
                        arrayName[arrayName.length - 1] === 'docx' || arrayName[arrayName.length - 1] === 'doc' ||
                        arrayName[arrayName.length - 1] === 'docm') {
                        this.setState({
                            showOpenAlert: true,
                            currentBlob: blob,
                            currentFileName: attachmentClicked.filename,
                            currentExtension: arrayName[arrayName.length - 1],
                        });
                    } else {
                        this.setState({
                            currentFileName: attachmentClicked.filename,
                            currentFileURLList: fileList,
                            openAttachment: true,
                        })
                    }
                }
            });
        }
    }

    onCloseAttachment = () => {
        this.setState({
            currentFileName: '',
            currentFileURL: [],
            currentFileType: '',
            openAttachment: false,
        })
    }

    closeAlert = () => {
        this.setState({
            showOpenAlert: false,
        })
    }

    yesDowloadFile = () => {

        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(this.state.currentBlob);
        link.download = this.state.currentFileName;
        link.click();
    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.state.openAttachment}
                    onClose={this.onCloseAttachment}
                    fullWidth={this.state.fullWidthAttachment}
                    maxWidth={this.state.maxWidthAttachment}
                >
                    <DialogTitle id="current-attachment-dialog-title" onClose={this.onCloseAttachment}>
                        {this.state.currentFileName}
                    </DialogTitle>
                    <DialogContent dividers>
                        <div>
                            <DocViewer
                                pluginRenderers={DocViewerRenderers}
                                //pluginRenderers={[PDFRenderer]}
                                documents={this.state.currentFileURLList}
                                config={{
                                    header: {
                                        disableHeader: true,
                                        disableFileName: true,
                                        retainURLParams: false
                                    }
                                }}
                            />
                        </div>
                    </DialogContent>
                </Dialog>
                <AlertBox
                    id="open-attachment"
                    open={this.state.showOpenAlert}
                    onClose={this.closeAlert.bind(this)}
                    title="Warning"
                    message="The file has no preview. Do you want to download the file?"
                    type="Yes/No"
                    yesClick={this.yesDowloadFile.bind(this)}
                    noClick={this.closeAlert.bind(this)}
                />
            </div>
        )
    }
}