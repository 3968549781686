import React from 'react';


export default class CustomersSearchForm extends React.Component {
    state = {
        customerName: '',
    }

    componentDidMount() {
        var name = this.props.searchedString;
        this.setState({customerName: name});
    }

    handleClearClick() {
        this.setState({customerName: ''});
        this.props.clearData();
    }

    handleSearchClick() {
        this.props.updateTable();
    }

    handleUpdateSearchString(event){
        this.setState({customerName: event.target.value});
        this.props.updateSearchedCustomerName(event.target.value);
    }

    render() {
        return (
            <div className="uk-margin row">
                <div className="col-lg-6 col-sm-12">
                    <div className="uk-margin" style={{ marginBottom: '20px' }}>
                        <input
                            className="uk-input"
                            id="form-stacked-text"
                            type="text"
                            value={this.state.customerName}
                            onChange={this.handleUpdateSearchString.bind(this)} />
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                    <button type="button" className="btn btn-primary" onClick={this.handleSearchClick.bind(this)}>SEARCH</button> &nbsp;
                    <button type="button" className="btn btn-primary" onClick={this.handleClearClick.bind(this)}>CLEAR FIELDS</button>
                </div>
            </div>
        );
    }
}