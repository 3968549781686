import React from 'react';
import AppContext from '../../AppContext';
import { formatValidInput } from '../../helper/FormatHelper';
import { getResource } from '../../helper/ResourcesHelper';
import { validate } from '../../helper/ValidateHelper';
import { getStatesByCountry } from '../../networking/NetworkingRegForm';
import DynamicSelect from '../shared/DynamicSelect';

export default class PrincipalPlaceOfBusiness extends React.Component {
    static contextType = AppContext;
    state = {
        BusinessAddressStreet: '',
        BusinessAddressLine2: '',
        BusinessAddressCityID: '',
        BusinessAddressCityName: '',
        BusinessAddressStateId: '',
        BusinessAddressStateName: '',
        BusinessAddressPostalCode: '',
        //ddwns values
        countryID: '',
        countryName: '',
        countries: [],
        statesByCountryID: [],

        OtherState: '',

        //Validations
        validations: {
            BusinessAddressStateId: true,
            BusinessAddressStreet: true,
            BusinessAddressCityID: true,
            BusinessAddressPostalCode: true,
            countryID: true,
        },
        valid: true
    }
    handleClick() {
        if (this.BusinessAddressStreet) {
            setTimeout(() => {
                this.BusinessAddressStreet.focus();
            }, 100);
        }
    }
    componentDidMount() {
        this.context.validated2(this.validateRequiredInputs())
        this.context.validatedPartial(this.validateRequiredInputsPartial(), 2);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.notFirstLoad !== this.props.notFirstLoad && !this.props.notFirstLoad) {
            this.validateRequiredFields();
        }
        if (prevProps.notFirstLoadPartial !== this.props.notFirstLoadPartial && !this.props.notFirstLoadPartial) {
            this.validateRequiredFieldsPartial();
        }
        if (prevProps.clickNext !== this.props.clickNext && !this.props.clickNext) {
            //document.getElementById("residential_address_street").focus();

            if (this.BusinessAddressStreet != null) {
                this.BusinessAddressStreet.focus();
                this.handleClick();
            }
            this.props.NoClickNext();
        }
        if (prevProps.objGrincipalplaceofbusiness !== this.props.objGrincipalplaceofbusiness && this.props.objGrincipalplaceofbusiness != null) {
            this.setState({ ...this.props.objGrincipalplaceofbusiness }, () => {
                this.searchState(this.state.countryID);
                if(this.props.objGrincipalplaceofbusiness.countryID != ''){
                    this.setState({countryID: this.props.objGrincipalplaceofbusiness.countryID});
                }
                this.props.Principalplaceofbusiness(this.state)
                this.context.validated2(this.validateRequiredInputs())
                this.context.validatedPartial(this.validateRequiredInputsPartial(), 2);
            });
        }
    }
    searchState = (countryID) => {
        if (countryID != null && countryID !== '') {
            getStatesByCountry(countryID, 0, 0).then(
                (value) => {
                    if (value.states) {
                        const states = value.states.map(c => ({ ...c, name: c["State Name"], value: c["State Id"] }));
                        this.setState({ statesByCountryID: states });
                    } else {
                        this.setState({ statesByCountryID: [] });
                    }
                }
            );
        }
    }
    handleUpdateCountry = (value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.setState({
                        countryID: value.value, countryName: value.name,
                        validations: { ...this.state.validations, countryID: true }
                    }, () => {
                        this.searchState(value.value)
                        this.context.validated2(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputsPartial(), 2);
                        this.props.Principalplaceofbusiness(this.state)
                    });
                } else {
                    this.setState({
                        countryID: '', countryName: ''
                    }, () => {
                        this.context.validated2(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputsPartial(), 2);
                        this.props.Principalplaceofbusiness(this.state)
                    });
                }
            }
        }
    }
    handleUpdateState = (event) => {
        this.setState({
            validations: { ...this.state.validations, BusinessAddressStateId: true },
            OtherState: event.target.value, BusinessAddressStateId: ''
        },
            () => {
                this.context.validated2(this.validateRequiredInputs())
                this.context.validatedPartial(this.validateRequiredInputsPartial(), 2);
                this.props.Principalplaceofbusiness(this.state)
            }
        );
    }
    handleUpdateBusinessAddressStreet = (event) => {
        this.setState({
            BusinessAddressStreet: event.target.value,
            validations: { ...this.state.validations, BusinessAddressStreet: true }
        }, () => {
            this.context.validated2(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 2);
            this.props.Principalplaceofbusiness(this.state)
        });
    }
    handleUpdateBusinessAddressLine2 = (event) => {
        this.setState({
            BusinessAddressLine2: event.target.value,
            //validations: { ...this.state.validations, BusinessAddressLine2: true }
        }, () => {
            this.context.validated2(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 2);
            this.props.Principalplaceofbusiness(this.state)
        });
    }
    handleUpdateBusinessAddressCity = (event) => {
        this.setState({
            BusinessAddressCityID: event.target.value,
            validations: { ...this.state.validations, BusinessAddressCityID: true }
        }, () => {
            this.context.validated2(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 2);
            this.props.Principalplaceofbusiness(this.state)
        });
        /* if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.setState({
                        BusinessAddressCityID: value.value, BusinessAddressCityName: value.name,
                        validations: { ...this.state.validations, BusinessAddressCityID: true }
                    }, () => {
                        this.context.validated2(this.validateRequiredInputs())
                        this.props.Principalplaceofbusiness(this.state)
                    });
                } else {
                    this.setState({
                        BusinessAddressCityID: '', BusinessAddressCityName: ''
                    }, () => {
                        this.context.validated2(this.validateRequiredInputs())
                this.context.validatedPartial(this.validateRequiredInputsPartial(),2);
                        this.props.Principalplaceofbusiness(this.state)
                    });
                }
            }
        } */
    }
    handleUpdateBusinessAddressState = (value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.setState({
                        BusinessAddressStateId: value.value, BusinessAddressStateName: value.name, OtherState: '',
                        validations: { ...this.state.validations, BusinessAddressStateId: true }
                    }, () => {
                        this.context.validated2(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputsPartial(), 2);
                        this.props.Principalplaceofbusiness(this.state)
                    });
                } else {
                    this.setState({
                        BusinessAddressStateId: '', BusinessAddressStateName: ''
                    }, () => {
                        this.context.validated2(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputsPartial(), 2);
                        this.props.Principalplaceofbusiness(this.state)
                    });
                }
            }
        }
    }
    handleUpdateBusinessAddressPostalCode = (event) => {
        this.setState({
            BusinessAddressPostalCode: event.target.value,
            validations: { ...this.state.validations, BusinessAddressPostalCode: true }
        }, () => {
            this.context.validated2(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputsPartial(), 2);
            this.props.Principalplaceofbusiness(this.state)
        });
    }
    //Inputs Validations
    validateRequiredInputs() {
        const fields = [
            { displayName: getResource('Label_StreetAddress'), validateName: 'BusinessAddressStreet', value: this.state.BusinessAddressStreet, type: 'string' },
            { displayName: getResource('Label_City'), validateName: 'BusinessAddressCityID', value: this.state.BusinessAddressCityID, type: 'string' },
            { displayName: getResource('Label_Country'), validateName: 'countryID', value: this.state.countryID, type: 'string' },
            { displayName: getResource('Label_Postal'), validateName: 'BusinessAddressPostalCode', value: this.state.BusinessAddressPostalCode, type: 'string' },
        ]

        if (this.state.statesByCountryID.length > 0) {
            fields.push({ displayName: getResource('Label_State'), validateName: 'BusinessAddressStateId', value: this.state.BusinessAddressStateId, type: 'string' });
        } else {
            fields.push({ displayName: getResource('Label_State'), validateName: 'BusinessAddressStateId', value: this.state.OtherState, type: 'string' });
        }
        const [valid, , errors] = validate(fields);
        return { valid, errors };
    }
    //Fields Validations
    validateRequiredFields() {
        const fields = [
            { displayName: getResource('Label_StreetAddress'), validateName: 'BusinessAddressStreet', value: this.state.BusinessAddressStreet, type: 'string' },
            { displayName: getResource('Label_City'), validateName: 'BusinessAddressCityID', value: this.state.BusinessAddressCityID, type: 'string' },
            { displayName: getResource('Label_Country'), validateName: 'countryID', value: this.state.countryID, type: 'string' },
            { displayName: getResource('Label_Postal'), validateName: 'BusinessAddressPostalCode', value: this.state.BusinessAddressPostalCode, type: 'string' },
        ]
        if (this.state.statesByCountryID.length > 0) {
            fields.push({ displayName: getResource('Label_State'), validateName: 'BusinessAddressStateId', value: this.state.BusinessAddressStateId, type: 'string' });
        } else {
            fields.push({ displayName: getResource('Label_State'), validateName: 'BusinessAddressStateId', value: this.state.OtherState, type: 'string' });
        }
        const [valid, validations] = validate(fields);
        this.setState({ validations });
        return valid;
    }

    //Inputs Validations
    validateRequiredInputsPartial() {
        const fields = [];
        if (this.state.statesByCountryID.length > 0) {
            if (
                this.state.BusinessAddressStreet !== '' ||
                this.state.BusinessAddressCityID !== '' ||
                this.state.countryID !== '' ||
                this.state.BusinessAddressStateId !== ''
            ) {
                fields.push(
                    { displayName: getResource('Label_StreetAddress'), validateName: 'BusinessAddressStreet', value: this.state.BusinessAddressStreet, type: 'string' },
                    { displayName: getResource('Label_City'), validateName: 'BusinessAddressCityID', value: this.state.BusinessAddressCityID, type: 'string' },
                    { displayName: getResource('Label_Country'), validateName: 'countryID', value: this.state.countryID, type: 'string' },
                    { displayName: getResource('Label_State'), validateName: 'BusinessAddressStateId', value: this.state.BusinessAddressStateId, type: 'string' },
                );
            }
        } else {
            if (
                this.state.BusinessAddressStreet !== '' ||
                this.state.BusinessAddressCityID !== '' ||
                this.state.countryID !== '' ||
                this.state.OtherState !== ''
            ) {
                fields.push(
                    { displayName: getResource('Label_StreetAddress'), validateName: 'BusinessAddressStreet', value: this.state.BusinessAddressStreet, type: 'string' },
                    { displayName: getResource('Label_City'), validateName: 'BusinessAddressCityID', value: this.state.BusinessAddressCityID, type: 'string' },
                    { displayName: getResource('Label_Country'), validateName: 'countryID', value: this.state.countryID, type: 'string' },
                    { displayName: getResource('Label_State'), validateName: 'BusinessAddressStateId', value: this.state.OtherState, type: 'string' }
                );
            }
        }
        const [valid, , errors] = validate(fields);
        return { valid, errors };
    }
    //Fields Validations
    validateRequiredFieldsPartial() {
        const fields = [];
        if (this.state.statesByCountryID.length > 0) {
            if (
                this.state.BusinessAddressStreet !== '' ||
                this.state.BusinessAddressCityID !== '' ||
                this.state.countryID !== '' ||
                this.state.BusinessAddressStateId !== ''
            ) {
                fields.push(
                    { displayName: getResource('Label_StreetAddress'), validateName: 'BusinessAddressStreet', value: this.state.BusinessAddressStreet, type: 'string' },
                    { displayName: getResource('Label_City'), validateName: 'BusinessAddressCityID', value: this.state.BusinessAddressCityID, type: 'string' },
                    { displayName: getResource('Label_Country'), validateName: 'countryID', value: this.state.countryID, type: 'string' },
                    { displayName: getResource('Label_State'), validateName: 'BusinessAddressStateId', value: this.state.BusinessAddressStateId, type: 'string' },
                );
            }
        } else {
            if (
                this.state.BusinessAddressStreet !== '' ||
                this.state.BusinessAddressCityID !== '' ||
                this.state.countryID !== '' ||
                this.state.OtherState !== ''
            ) {
                fields.push(
                    { displayName: getResource('Label_StreetAddress'), validateName: 'BusinessAddressStreet', value: this.state.BusinessAddressStreet, type: 'string' },
                    { displayName: getResource('Label_City'), validateName: 'BusinessAddressCityID', value: this.state.BusinessAddressCityID, type: 'string' },
                    { displayName: getResource('Label_Country'), validateName: 'countryID', value: this.state.countryID, type: 'string' },
                    { displayName: getResource('Label_State'), validateName: 'BusinessAddressStateId', value: this.state.OtherState, type: 'string' }
                );
            }
        }

        const [valid, validations] = validate(fields);
        this.setState({ validations });
        return valid;
    }

    render() {
        let countries = [];
        countries = this.context != null ? (this.context.countries != null ? this.context.countries : []) : [];
        return (
            <div className="row">
                <div className="col-sm-12 col-xs-12">
                    <div className="row">
                        <div className={"col-md-12" + formatValidInput(this.state.validations.BusinessAddressStreet)}>
                            <input type="text"
                                ref={(input) => { this.BusinessAddressStreet = input; }}
                                autoFocus
                                className="form-control"
                                id="BusinessAddressStreet"
                                name="BusinessAddressStreet"
                                maxLength="100"
                                data-rule-required="true"
                                data-msg-required="Field Required."
                                value={this.state.BusinessAddressStreet}
                                onChange={this.handleUpdateBusinessAddressStreet} />
                            <label htmlFor="BusinessAddressStreet" className="small">{getResource('Label_Business_StreetAddress')}*</label>
                            {(this.state.validations.BusinessAddressStreet != null && !this.state.validations.BusinessAddressStreet) &&
                                <label id="BusinessAddressStreet-error" className="error" htmlFor="BusinessAddressStreet">Field Required.</label>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <input type="text" className="form-control" id="residential_address_line_2" name="residential_address_line_2" maxLength="100"
                                value={this.state.BusinessAddressLine2}
                                onChange={this.handleUpdateBusinessAddressLine2} />
                            <label htmlFor="residential_address_line_2" className="small">{getResource('Label_Business_AddressLine2')}</label>
                        </div>
                    </div>

                    <div className="row">
                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.BusinessAddressCityID)}>
                            {/* <DynamicSelect
                                className="form-control use-select2 select2-selection__placeholder" id="residential_address_city" name="residential_address_city" style={{ width: "100%" }}
                                data-select2-type="simple"
                                data-select2-paging="false"
                                data-select2-placeholder="Select City"
                                data-rule-required="true"
                                data-msg-required="Field Required"
                                objValue={{ value: this.state.BusinessAddressCityID, name: this.state.BusinessAddressCityName }}
                                getValue={this.handleUpdateBusinessAddressCity}
                                listHasPlaceholder={false}
                                placeholder={"Select City"}
                                data={countries != null ? countries : []} />
                            <label htmlFor="residential_address_city" className="small">Business City</label>
                            {(this.state.validations.BusinessAddressCityID != null && !this.state.validations.BusinessAddressCityID) &&
                                <label id="residential_address_city-error" className="error" htmlFor="residential_address_city">Field Required.</label>
                            } */}
                            <input type="text" className="form-control" id="residential_address_city" name="residential_address_city" maxLength="100"
                                value={this.state.BusinessAddressCityID}
                                onChange={this.handleUpdateBusinessAddressCity} />
                            <label htmlFor="residential_address_city" className="small">{getResource('Label_Business_City')}*</label>
                            {(this.state.validations.BusinessAddressCityID != null && !this.state.validations.BusinessAddressCityID) &&
                                <label id="BusinessAddressCityID-error" className="error" htmlFor="BusinessAddressCityID">Field Required.</label>
                            }
                        </div>
                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.countryID)}>
                            <DynamicSelect
                                className="form-control use-select2 select2-selection__placeholder" id="residential_address_country" name="residential_address_country" style={{ width: "100%" }}
                                data-select2-type="simple"
                                data-select2-paging="false"
                                data-select2-placeholder="Select Country"
                                data-rule-required="true"
                                data-msg-required="Field Required"
                                objValue={{ value: this.state.countryID, name: this.state.countryName }}
                                getValue={this.handleUpdateCountry}
                                listHasPlaceholder={false}
                                placeholder={"Select Country"}
                                data={countries != null ? countries : []} />
                            <label htmlFor="residential_address_country" className="small">{getResource('Label_Business_Country')}*</label>
                            {(this.state.validations.countryID != null && !this.state.validations.countryID) &&
                                <label id="residential_address_country-error" className="error" htmlFor="residential_address_country">Field Required.</label>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.BusinessAddressStateId)}>
                            {
                                this.state.statesByCountryID.length > 0 ?
                                    <DynamicSelect
                                        className="form-control use-select2 select2-selection__placeholder" id="residential_address_state" name="residential_address_state" style={{ width: "100%" }}
                                        data-select2-type="simple"
                                        data-select2-paging="false"
                                        data-select2-placeholder="Select State"
                                        data-rule-required="true"
                                        data-msg-required="Field Required"
                                        objValue={{ value: this.state.BusinessAddressStateId, name: this.state.BusinessAddressStateName }}
                                        getValue={this.handleUpdateBusinessAddressState}
                                        listHasPlaceholder={false}
                                        placeholder={"Select State"}
                                        data={this.state.statesByCountryID} />
                                    :
                                    <input type="text" className="form-control"
                                        id="residential_address_postalcode"
                                        name="residential_address_postalcode" maxLength="50"
                                        data-rule-required="true"
                                        data-msg-required="Field Required."
                                        value={this.state.OtherState}
                                        onChange={this.handleUpdateState} />
                            }
                            <label htmlFor="residential_address_state" className="small">{getResource('Label_Business_State')}*</label>
                            {(this.state.validations.BusinessAddressStateId != null && !this.state.validations.BusinessAddressStateId) &&
                                <label id="residential_address_state_name-error" className="error" htmlFor="residential_address_state_name">Field Required.</label>
                            }
                        </div>
                        <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.BusinessAddressPostalCode)}>
                            <input type="text" className="form-control" id="residential_address_postalcode" name="residential_address_postalcode" maxLength="50"
                                data-rule-required="true"
                                data-msg-required="Field Required."
                                value={this.state.BusinessAddressPostalCode}
                                onChange={this.handleUpdateBusinessAddressPostalCode} />
                            <label htmlFor="residential_address_postalcode" className="small">{getResource('Label_Business_Postal')}*</label>
                            {(this.state.validations.BusinessAddressPostalCode != null && !this.state.validations.BusinessAddressPostalCode) &&
                                <label id="residential_address_postalcode-error" className="error" htmlFor="residential_address_postalcode">Field Required.</label>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}